import React, { useEffect, useState } from "react";
import { _postApi, unflatten } from "../../redux/action/api";
///post_taxes

export default function TreeChat() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataTree = async () => {
    setLoading(true);
    await _postApi(
      "/post_taxes",
      { query_type: "select" },
      (res) => {
         const formattedData = unflatten(res.result);
        setData(formattedData);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getDataTree();
  }, []);

  return (
    <div>
      <p>{JSON.stringify(data)}</p>
    </div>
  );
}
