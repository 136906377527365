import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Card, CardBody, CardHeader, Label, Button, Input } from "reactstrap";
import CustomTypeahead from "./UI/CustomTypeahead";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../routes";
import DaterangeSelector from "../pages/dashboard/DaterangeSelector";
import { useQuery } from "../utils";
import moment from "moment";

function PayeDateRange() {
  //   const [taxType, setTaxType] = useState("");
  const navigate = useHistory();
  const [form, setForm] = useState({ from: "", to: "" });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const query = useQuery();
  const taxType = query.get("taxType");
  const [date, setDate] = useState();
  return (
    <div>
      <Card>
        <CardHeader
          style={{
            fontWeight: "bolder",
          }}
          className="bg-light"
        >
          TAX FILING DATE RANGE
        </CardHeader>
        <CardBody>
          <Label>Select Month to file</Label>
          <Input
            type="month"
            value={date}
            name="date"
            onChange={({ target: { value } }) => {
              const startDate = moment(value, "YYYY-MM")
                .startOf("month")
                .format("YYYY-MM-DD");
              const endDate = moment(value, "YYYY-MM")
                .endOf("month")
                .format("YYYY-MM-DD");
              setForm((p) => ({ ...p, from: startDate, to: endDate }));
              setDate(value);
            }}
          />
          <div className="d-flex justify-content-between p-3">
            <div></div>
            <Button
              onClick={() => {
                navigate.push(
                  `${Routes.NominalRollUpload.path}?taxType=${taxType}&from=${form.from}&to=${form.to}`
                );
              }}
              className="w-25 "
              style={{
                backgroundColor: "#262B40",
                color: "white",
                border: "none",
                boxShadow: "none",
                float: "right",
              }}
            >
              Next
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default PayeDateRange;
