import React, { forwardRef, useEffect, useState } from "react";
import CustomInput from "./examples/CustomInput";
// import { Card } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleLeft,
  faCheckCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Button, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import {  getTaxPayerInfo, signup } from "../redux/action/auth";
import { useHistory } from "react-router-dom";
import { ButtonGroup, FormControl, Form, InputGroup, Card } from "@themesberg/react-bootstrap";
import { Routes } from "../routes";
import { toast } from "react-hot-toast";
import { stateLga } from "../statelga";
import { Countries } from '../assets/Counties';
import { Occupations } from '../assets/Occupations';
import { taxStations } from '../utils/tax-stations';
import { IndurialSectors, orgType } from '../assets/IndurialSectors';
import SelectInput from "./components/SelectInput";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import { TAX_ACCOUNT } from "../redux/action/type";
import { _fetchApi, _postApi } from "../redux/action/api";
import { IncomeSources } from "../assets/IncomeSources";
import ReactCodeInput from 'react-verification-code-input';
import { CustomButton } from '../components/UI/';
// import { SelectInput } from "../components/UI";
// import CustomInput from "../examples/CustomInput";

const AddAccout = (props) => {
  const {
    tax_id = 0,
    account_type = "individual",
    isModal = false,
    isAddAccount = false,
    handleFocus = (f) => f,
  } = props;
  const [form, setForm] = useState({
    contact_name: "",
    name: "",
    contact_phone: "",
    contact_address: "",
    password: "123456",
    account_type,
    ward: "",
    state: "Kano",
    lga: "",
    idetifier: false,
    tax_id,
    nationality:'Nigerian'
  });
  // const [type, setType] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChanges = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value, idetifier: true }));
  };
  const [selectedAccount, setSelectedAccount] = useState({});
  const [tax_payers, setTaxPayers] = useState([]);
  const [tax_payer, setTapayer] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [taxID, setTaxID] = useState(null);
  const [showDefault, setShowDefault] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [phoneTimer, setPhoneTimer] = useState(180);
  const [showPhoneResend, setShowPhoneResend] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState({ phone_verified: 0 });
  const [error, setError] = useState(null)

  useEffect(() => {
    if (tax_payer)
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
  }, [tax_payer]);

  useEffect(() => {
    if (tax_id) {
      _fetchApi(`/users/get-tax-payer-info?user_id=${tax_id}`, (resp) => {
        if (resp.success) {
          setForm((p) => ({
            ...p,
            ...resp.data,
            tax_id: null,
            contact_name: resp.data.name,
            contact_phone: resp.data.phone,
            contact_address: resp.data.address,
          }));
          if (resp?.data.phone === "") {
            // setType(false);
          }
          setSelectedAccount(resp.data);
        }
      });
    }
  }, [tax_id]);

  const handleApi = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      form.contact_name === "" ||
      form.contact_phone === "" ||
      (form.contact_address === "" && form.office_address === "") ||
      form.idetifier === false
    ) {
      toast.error("Please complete the form");
      setLoading(false);
      return;
    } else {
      setLoadingVerification(true)
      // _postApi('/users/verify',
      //   { phone: form.account_type === 'individual' ? form.contact_phone : form.org_phone, query_type: form.query_type },
      //   (resp) => {
      //     setLoading(false);
      //     setShowVerification(true)
      //     setLoadingVerification(false)
      //   }
      // )
      // setShowDefault(false);
    }
  };

  const handleChange1 = (value, type) => {
    setForm(prevState => ({
      ...prevState,
      [`${type}_vcodes`]: value
    }));
  };

  const handleVerifyCode = (type, action) => {
    setLoadingVerification(true);
    if (type === "phone") {
      if (form.phone_vcodes.length < 4) {
        toast.error("Code Must be at least 4 characters long");
        return;
      }
      if (action === "resend-phone") {
        setPhoneTimer(180);
      }
      setShowPhoneResend(false);
      // handleResendVerification('phone');
      _postApi(
        "/users/verify",
        {
          query_type: action,
          phone:
            form.account_type === "individual"
              ? form.contact_phone
              : form.org_phone,
          phone_vcodes: form.phone_vcodes,
        },
        (resp) => {
          console.log(`Resent ${type} verification code:`, resp);
          setShowVerification(true);
          setShowDefault(false);
          setLoadingVerification(false);
          if (resp.success && resp.data[0]?.phone_verified > 0) {
            setPhoneVerification(resp.data[0]);
          } else {
            toast.error("Please enter valid verification code");
          }
        },
        (error) => {
          setLoadingVerification(false);
          toast.error("error occured, Please click back button and re-submit");
          console.error(`Error resending ${type} verification:`, error);
        }
      );
    }
    // } else if (type === 'email') {
    //   if (action === 'resend-email') {
    //     setEmailTimer(180);
    //   }
    //   setShowEmailResend(false);
    //   // handleResendVerification('email');
    //   _postApi(
    //     '/users/verify',
    //     { query_type: action, email: form.account_type === 'org' ? form.org_email : form.contact_email, email_vcodes: form.email_vcodes },
    //     (resp) => {
    //       setShowVerification(true);
    //       setShowDefault(false);
    //       setLoadingVerification(false)
    //       if (resp.success) {
    //         setEmailVerification(resp.data[0])
    //       }
    //     },
    //     (error) => {
    //       setLoadingVerification(false)
    //       console.error(`Error resending ${type} verification:`, error);
    //     }
    //   );
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (phoneVerification.phone_verified < 1) {
    //   toast.error("Verify Phone First");
    // } else {
    setLoading(true);
    dispatch(
      signup(
        {
          ...form,
          address: form.contact_address,
          query_type: 'insert'
        },
        (result) => {
          setLoading(false);
          if (result.success) {
            setError(null)
            setTaxID(result.taxID);
            setShowDefault(true);
          } else {
            toast.error(result.msg);
            setError(result.msg)
          }
        },
        (err) => {
          setLoading(false);
          console.log(err);
          setError(err.msg)
          toast.error(err.msg);
        }
      )
    );
    // }
  };

  useEffect(() => {
    if (phoneTimer > 0) {
      const phoneInterval = setInterval(() => {
        setPhoneTimer(phoneTimer - 1);
      }, 1000);
      return () => clearInterval(phoneInterval);
    } else {
      setShowPhoneResend(true);
    }
  }, [phoneTimer]);

  return (
    <div>
      {/* {JSON.stringify(form)} */}
      {showDefault ? (
        <div>
          {/**<div as={Modal.Dialog} centered show={showDefault}
        //   style={{ zIndex: showDefault ? 999999 : 1 }}>
        //   <Modal.Header>
      //     <Modal.Title className="h6"> **/}
          {form.tax_id ? (
            <h4 className="text-center">
              {" "}
              Tax Account has been updated successfully
            </h4>
          ) : (
            <h4 className="text-center"> Your Account created successfully</h4>
          )}
          {/** </Modal.Title >
          <Button variant="close" aria-label="Close" onClick={toggle} />
          // </Modal.Header>
    // <Modal.Body> **/}

          {isAddAccount ? (
            <h2 className="text-center">
              New account has been added to your TaxID ({taxID})
            </h2>
          ) : (
            <h2 className="text-center">Your KIRMAS TaxID is: {taxID}</h2>
          )}
          {/**  </div> </Modal.Body>
          // <Modal.Footer>
           **/}
          <Col className="text-center">
            <Button
              onClick={() => {
                if (isModal) {
                  handleFocus(taxID);
                  // ref.current.focus();
                } else {
                  dispatch(
                    getTaxPayerInfo(taxID, () => {
                      setLoading(false);
                      history.push(`${Routes.Selection.path}`);
                    }),
                    () => {
                      setLoading(false);
                      toast.error("Failed!");
                    }
                  );
                }
              }}
            >
              Proceed
            </Button>
          </Col>

          {/* <Button variant="link" className="text-gray ms-auto" onClick={toggle}>
            Close
          </Button>
          </Modal.Footer> */}
        </div>
      ) : (
        <>
          {isModal ? (
            <>
              {isAddAccount ? (
                <Row>
                  <Col md={12} className="mb-4">
                    <CustomTypeahead
                      onInputChange={(e) => {
                        setTapayer(e);
                      }}
                      labelKey={(e) =>
                        `${e.account_type === "org" ? e.org_name : e.name}(${e.taxID
                        }) ${e.phone ? `:${e.phone || e.office_phone}` : ""}`
                      }
                      onChange={(e) => {
                        if (e && e[0]?.id) {
                          dispatch({
                            type: TAX_ACCOUNT,
                            payload: e[0],
                          });
                          setForm((p) => ({
                            ...p,
                            // contact_name: e[0].name,
                            contact_phone: e[0].phone,
                            taxID: e[0].taxID,
                            user_id: e[0].user_id,
                            idetifier: true,
                          }));
                        }
                      }}
                      clearButton
                      col={12}
                      label={`Select Main Account `}
                      options={tax_payers}
                      placeholder="Search Name | TaxID | Phone No."
                    />
                  </Col>
                </Row>
              ) : null}
              {!showVerification ? (
                <>
                  <ButtonGroup className="w-100 pb-4">
                    <Button
                      outline={form.account_type === "org"}
                      onClick={() =>
                        setForm((p) => ({ ...p, account_type: "individual" }))
                      }
                      color="dark"
                      className={`col-md-6`}
                    >
                      INDIVIDUAL
                    </Button>
                    <Button
                      color="dark"
                      outline={form.account_type === "individual"}
                      onClick={() =>
                        setForm((p) => ({ ...p, account_type: "org" }))
                      }
                      className="col-md-6"
                    >
                      ORGANIZATION/COMPANY
                    </Button>
                  </ButtonGroup>

                  {form.account_type === "individual" ? (
                    <>
                      <Row>
                        <Col md={6}>
                          <CustomInput
                            label="Taxpayer Full name"
                            name="contact_name"
                            value={form.contact_name}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="Tax payer Phone No."
                            name="contact_phone"
                            value={form.contact_phone}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="TIN"
                            type="text"
                            name="tin"
                            value={form.tin}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Contact Email"
                            // required={true}
                            required={form.account_type === 'org' ? false : true}
                            type="email"
                            name="contact_email"
                            value={form.contact_email}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="Nationality"
                            type="text"
                            options={Countries.map((item) => item.nationality)}
                            name="nationality"
                            value={form.nationality}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="L.G.A"
                            type="text"
                            name="lga"
                            // required={true}
                            options={
                              stateLga.filter((item) => item.state === form.state)[0]
                                ?.lgas
                            }
                            value={form.lga}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Ward"
                            type="text"
                            name="ward"
                            value={form.ward}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={12}>
                          <CustomInput
                            type="textarea"
                            required={true}
                            label={
                              form.account_type === "org" ? "Office Address" : "Address"
                            }
                            name={
                              form.account_type === "org"
                                ? "office_address"
                                : "contact_address"
                            }
                            value={
                              form.account_type === "org"
                                ? form.office_address
                                : form.contact_address
                            }
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="Type of Occupation"
                            type="text"
                            name="occupation"
                            // required={true}
                            options={Occupations}
                            value={form.occupation}
                            onChange={onChanges}
                            className="mb-1"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="Income Source"
                            type="text"
                            name="income_source"
                            // required={true}
                            options={IncomeSources}
                            value={form.income_source}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="mb-4">
                          <SelectInput
                            label="Tax Station"
                            name="tax_station"
                            required={true}
                            options={taxStations}
                            value={form.tax_station}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-4">
                          <Form.Label>
                            Password <span style={{ color: 'red' }}> *</span>{' '}
                          </Form.Label>
                          <InputGroup>
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              value={form.password}
                              required={true}
                              onChange={onChanges}
                              placeholder="Password"
                            />
                            <InputGroup.Text
                              style={{ cursor: 'pointer' }}
                              onClick={toggleShowPassword}
                            >
                              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col md={6}>
                          <CustomInput
                            label="Organization Name"
                            name="org_name"
                            value={form.org_name}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="Office Phone No."
                            name="org_phone"
                            value={form.org_phone}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Office Email"
                            required={true}
                            type="email"
                            name="org_email"
                            value={form.org_email}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="Type of Organization"
                            type="text"
                            name="org_type"
                            required={true}
                            options={orgType?.map((item) => item)}
                            value={form.org_type}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="Business Sector"
                            type="text"
                            name="business_sector"
                            // required={true}
                            options={IndurialSectors}
                            value={form.business_sector}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="RC/BN"
                            name="rc"
                            value={form.rc}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="ORG TIN"
                            type="text"
                            name="org_tin"
                            value={form.org_tin}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Date of Incorporation"
                            type="date"
                            name="incorporation_date"
                            value={form.incorporation_date}
                            onChange={onChanges}
                            required={true}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="Average Annual Turnover"
                            type="text"
                            name="annual_turnover"
                            required={true}
                            options={[
                              '500k - 1 milion naira',
                              '1 milion - 10 milion naira',
                              'Above 10 milion naira',
                            ].map((item) => item)}
                            value={form.annual_turnover}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            type="textarea"
                            required={true}
                            label={
                              "Office Address"
                            }
                            name={
                              "office_address"
                            }
                            value={
                              form.office_address
                            }
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="L.G.A"
                            type="text"
                            name="lga"
                            // required={true}
                            options={
                              stateLga.filter((item) => item.state === form.state)[0]
                                ?.lgas
                            }
                            value={form.lga}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Ward"
                            type="text"
                            name="ward"
                            value={form.ward}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-4">
                          <SelectInput
                            label="Tax Station"
                            name="tax_station"
                            required={true}
                            options={taxStations}
                            value={form.tax_station}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={12} className="mb-4">
                          <Form.Label>
                            Password <span style={{ color: 'red' }}> *</span>{' '}
                          </Form.Label>
                          <InputGroup>
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              value={form.password}
                              required={true}
                              onChange={onChanges}
                              placeholder="Password"
                            />
                            <InputGroup.Text
                              style={{ cursor: 'pointer' }}
                              onClick={toggleShowPassword}
                            >
                              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                        <Card.Header bg="light" className="bg-light text-center mb-4 ">
                          <Card.Title>Contact Person</Card.Title>
                        </Card.Header>

                        <Col md={6}>
                          <CustomInput
                            label="Name"
                            name="contact_name"
                            value={form.contact_name}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="Phone No."
                            name="contact_phone"
                            value={form.contact_phone}
                            onChange={onChanges}
                            disabled={tax_id > 0}
                            required={true}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="TIN"
                            type="text"
                            name="tin"
                            value={form.tin}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Contact Email"
                            // required={true}
                            required={form.account_type === 'org' ? false : true}
                            type="email"
                            name="contact_email"
                            value={form.contact_email}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="Nationality"
                            type="text"
                            options={Countries.map((item) => item.nationality)}
                            name="nationality"
                            value={form.nationality}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={onChanges}
                            className="mb-4"
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="L.G.A"
                            type="text"
                            name="lga"
                            // required={true}
                            options={
                              stateLga.filter((item) => item.state === form.state)[0]
                                ?.lgas
                            }
                            value={form.lga}
                            onChange={onChanges}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Ward"
                            type="text"
                            name="ward"
                            value={form.ward}
                            onChange={onChanges}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {error ? <p className="text-center text-danger">
                    {error}
                  </p> : null}
                  <center>
                    {" "}
                    <CustomButton onClick={handleSubmit} loading={loading}>
                      {" "}
                      {isAddAccount
                        ? "Add account"
                        : tax_id > 1
                          ? "Submit"
                          : "Create"}
                    </CustomButton>
                  </center>
                </>
              ) : (
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                    <Card.Body>
                      {/* Phone Verification */}
                      <Row className="mb-4">
                        <div className="d-flex justify-content-center row">
                          <Col md={6} lg={7}>
                            <div className="mb-2">
                              <ReactCodeInput
                                type="number"
                                fields={4}
                                onChange={(value) =>
                                  handleChange1(value, "phone")
                                }
                                value={form.phone_vcodes}
                              />
                            </div>
                          </Col>
                          <Col md={6} lg={5}>
                            {phoneVerification?.phone_verified > 0 ? (
                              <Col className="text-center">
                                <FontAwesomeIcon
                                  color="green"
                                  size="3x"
                                  icon={faCheckCircle}
                                />
                              </Col>
                            ) : (
                              <div className="d-flex align-items-center mt-0 mt-md-2">
                                <div className="text-center mt-1 me-2 ">
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handleVerifyCode("phone", "verify-phone")
                                    }
                                  >
                                    Verify Phone
                                  </Button>
                                </div>
                                <div className="text-center align-items-center">
                                  {showPhoneResend ? (
                                    <Button
                                      color="warning"
                                      onClick={() =>
                                        handleVerifyCode("phone", "resend-phone")
                                      }
                                    >
                                      Resend SMS
                                    </Button>
                                  ) : (
                                    <span className="text-danger mt-2">{`${Math.floor(
                                      phoneTimer / 60
                                    )}:${phoneTimer % 60 < 10 ? "0" : ""}${phoneTimer % 60
                                      }`}</span>
                                  )}
                                </div>
                              </div>
                            )}
                          </Col>
                        </div>
                        <span className="m-0 mt-2 mb-2">
                          If you didn't receive the OTP SMS Dial <b>*347*621#</b>{" "}
                          or text STOP to 2442 from your line to remove your
                          number from the Do Not Disturb list
                        </span>
                      </Row>

                      {/* Back and Submit Buttons */}
                      <Row>
                        <Col md={6} className="mb-4">
                          <Button color="dark" onClick={() => setShowVerification(false)}>
                            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
                          </Button>
                        </Col>
                        {/* {JSON.stringify({ phoneVerification, emailVerification })} */}
                        <Col md={6} className="mb-4 text-right">
                          <CustomButton
                            loading={loading}
                            disabled={
                              phoneVerification.phone_verified < 1
                            }
                            color="dark"
                            onClick={handleSubmit}
                          >
                            Submit
                          </CustomButton>
                        </Col>
                      </Row>
                    </Card.Body>
                  </div>
                </Col>
              )}

            </>
          ) : (
            <Card>
              <CardHeader>Express Account</CardHeader>
              <CardBody>
                <ButtonGroup className="w-100 pb-4">
                  <Button
                    outline={form.account_type === "org"}
                    onClick={() =>
                      setForm((p) => ({ ...p, account_type: "individual" }))
                    }
                    color="dark"
                    className={`col-md-6`}
                  >
                    INDIVIDUAL
                  </Button>
                  <Button
                    color="dark"
                    outline={form.account_type === "individual"}
                    onClick={() =>
                      setForm((p) => ({ ...p, account_type: "org" }))
                    }
                    className="col-md-6"
                  >
                    ORGANIZATION/COMPANY
                  </Button>
                </ButtonGroup>
                <Row>
                  <Col md={6}>
                    <CustomInput
                      label="Enter Phone No."
                      name="contact_phone"
                      value={form.contact_phone}
                      disabled={tax_id > 0 && selectedAccount.contact_phone}
                      onChange={onChanges}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomInput
                      label="Tax payer name"
                      name="contact_name"
                      value={form.contact_name}
                      onChange={onChanges}
                      disabled={tax_id > 0 && selectedAccount.name}
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <CustomInput
                      type="textarea"
                      label={
                        form.account_type === "org"
                          ? "Office Address"
                          : "Address"
                      }
                      name={
                        form.account_type === "org"
                          ? "office_address"
                          : "contact_address"
                      }
                      value={
                        form.account_type === "org"
                          ? form.office_address
                          : form.contact_address
                      }
                      required={true}
                      onChange={onChanges}
                    />
                  </Col>
                </Row>
                <center>
                  <Button onClick={handleApi} disabled={loading}>
                    {loading ? <Spinner size="sm" /> : null} Create
                  </Button>
                </center>
              </CardBody>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default AddAccout;
