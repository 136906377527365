import React, { useEffect, useState } from 'react';
import './profile.css';
import { Button, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import CustomInput from '../examples/CustomInput';
import SelectInput from '../components/SelectInput';
import { _fetchApi, _postApi } from '../../redux/action/api';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { stateLga } from '../../statelga';

export default function TaxpayerProfile() {
    const history = useHistory();
    // const [stepProfile, setStepProfile] = useState(true);
    const { tax_account, user } = useSelector((state) => state.auth);

    const [form, setForm] = useState({
        account_type: tax_account?.account_type || '',
        contact_name: tax_account?.name || '',
        contact_phone: tax_account?.phone || '',
        contact_email: tax_account?.email || '',
        ward: tax_account?.ward || '',
        state: tax_account?.state || 'Kano',
        lga: tax_account?.lga || '',
        org_name: tax_account?.org_name || '',
        rc: tax_account?.rc || '',
        org_tin: tax_account?.org_tin || '',
        tin: tax_account?.tin || '',
        contact_address: tax_account?.address || '',
        office_address: tax_account?.office_address || '',
        office_phone: tax_account?.office_phone || '',
        org_email: tax_account?.office_email || '',
        taxID: tax_account?.taxID || '',
    });

    useEffect(() => {
        setForm({
            account_type: tax_account?.account_type || '',
            contact_name: tax_account?.name || '',
            contact_phone: tax_account?.phone || '',
            contact_email: tax_account?.email || '',
            ward: tax_account?.ward || '',
            state: tax_account?.state || '',
            lga: tax_account?.lga || '',
            org_name: tax_account?.org_name || '',
            rc: tax_account?.rc || '',
            org_tin: tax_account?.org_tin || '',
            tin: tax_account?.tin || '',
            address:
                user.account_type === 'individual' ? tax_account?.office_address || '' : tax_account?.address || '',
            office_address: tax_account?.office_address || '',
            office_phone: tax_account?.office_phone || '',
            org_email: tax_account?.office_email || '',
            taxID: tax_account?.taxID || '',
            address: tax_account?.address || '',
        });
    }, [tax_account]);

    const handleSubmit = () => {
        _postApi('/auths/-post-users', form, (result) => {
            // setLoading(false);
            // console.log(result);
            if (result.success) {
                toast.success('Submitted');
            } else {
                console.error(result); // Log the error for debugging
                toast.error('Submission failed');
            }
        });
    };

    const handleChange = ({ target: { name, value } }) => {
        setForm((prevState) => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <Button size="sm mb-3" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
            {/* <p
        style={{
          marginTop: -40,
          width: "100%",
          textAlign: "center",
        }}
        onClick={() => {
          setStepProfile(!stepProfile);
        }}
      >
        click
      </p> */}
            {/* {JSON.stringify(form)} */}
            {user?.account_type === 'individual' ? (
                <div className="profile_div">
                    <div className="user_profile_side">
                        <div className="user_image">
                            <img
                                className="rounded-circle"
                                width="180px"
                                height={210}
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                alt="Profile Picture "
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '22px',
                                paddingTop: 0,
                                gap: 10,
                                width: '100%',
                            }}
                        >
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <p className="user_name">
                                    <b>User Name:</b> {tax_account?.name}
                                </p>
                                <p className="user_name">
                                    <b>Tax ID:</b> {tax_account?.taxID}
                                </p>
                                <p className="user_name">{/* <b>Phone Number:</b> {tax_account.phone} */}</p>
                            </div>
                        </div>
                    </div>
                    <div className="second_div">
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <h3>Individual Profile</h3>
                        </div>
                        <Row>
                            <Col md={6}>
                                <CustomInput
                                    label="Name"
                                    type="text"
                                    name="contact_name"
                                    required={true}
                                    disabled={true}
                                    value={form.contact_name}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <CustomInput
                                    label="Phone No."
                                    type="text"
                                    name="contact_phone"
                                    required={true}
                                    value={form.contact_phone}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="TIN"
                                    type="text"
                                    name="tin"
                                    value={form.tin}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="Contact Email"
                                    type="text"
                                    name="contact_email"
                                    value={form.contact_email}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={12} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="Contact address"
                                    type="text"
                                    name="address"
                                    value={form.address}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <SelectInput
                                    label="State of residence"
                                    type="text"
                                    name="state"
                                    options={stateLga.map((item) => item.state)}
                                    value={form.state}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <SelectInput
                                    label="L.G.A"
                                    type="text"
                                    name="lga"
                                    options={stateLga.filter((item) => item.state === form.state)[0]?.lgas}
                                    value={form.lga}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} className="mt-1">
                                <CustomInput
                                    label="Ward"
                                    type="text"
                                    name="ward"
                                    value={form.ward}
                                    onChange={handleChange}
                                />
                            </Col>
                            {/* <Col md={6} className="mt-1">
                <CustomInput
                  label="Bank Name"
                  type="text"
                  required={true}
                  name="bank_name"
                  value={form.bank_name}
                  onChange={handleChange}
                />
              </Col> */}
                        </Row>
                        <div
                            style={{
                                width: '100%',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginTop: -10,
                            }}
                        >
                            <Button onClick={handleSubmit}>Update Profile</Button>
                        </div>
                    </div>
                </div>
            ) : form.account_type === 'org' ? (
                <div className="profile_div">
                    <div className="user_profile_side">
                        <div className="user_image">
                            <img
                                className="rounded-circle"
                                width="180px"
                                height={210}
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                alt="Profile Picture "
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '22px',
                                paddingTop: 0,
                                gap: 10,
                                width: '100%',
                            }}
                        >
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <p className="user_name">
                                    <b> Name: </b>
                                    <p>{tax_account.org_name}</p>
                                </p>
                                <p className="user_name">
                                    <b>Tax ID: </b> {tax_account.taxID}
                                </p>
                                {/* <p className="user_name">
                  <b>Phone Number: </b>
                  {tax_account.office_phone}
                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="second_div">
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <h3>Organisation Profile</h3>
                        </div>
                        <Row>
                            <Col md={6}>
                                <CustomInput
                                    label="Organization Name"
                                    type="text"
                                    name="org_name"
                                    required={true}
                                    disabled={true}
                                    value={form.org_name}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <CustomInput
                                    label="Office Phone."
                                    type="text"
                                    name="contact_phone"
                                    required={true}
                                    value={form.contact_phone}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="RC/BN"
                                    type="text"
                                    name="rc"
                                    value={form.rc}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="Office E-mail"
                                    type="text"
                                    name="contact_email"
                                    required={true}
                                    value={form.contact_email}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="ORG TIN"
                                    type="text"
                                    name="org_tin"
                                    value={form.org_tin}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <CustomInput
                                    label="Office address / state"
                                    type="textarea"
                                    required={true}
                                    name="office_address"
                                    value={form.office_address}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <SelectInput
                                    label="State of residence"
                                    type="text"
                                    name="state"
                                    options={stateLga.map((item) => item.state)}
                                    value={form.state}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} style={{ marginTop: -20 }}>
                                <SelectInput
                                    label="L.G.A"
                                    type="text"
                                    name="lga"
                                    options={stateLga.filter((item) => item.state === form.state)[0]?.lgas}
                                    value={form.lga}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6} className="mt-1">
                                <CustomInput
                                    label="Ward"
                                    type="text"
                                    name="ward"
                                    value={form.ward}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <div
                            style={{
                                width: '100%',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginTop: -10,
                            }}
                        >
                            <Button onClick={handleSubmit}>Update Profile</Button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
