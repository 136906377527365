import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import CustomInput from "./CustomInput";
import { _fetchApi, _postApi } from "../../redux/action/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { toCurrency } from "../../utils";
import moment from "moment";

export default function RaiseRequest() {
  const [modal, setModal] = useState(false);
  const [refNo, setRefNo] = useState("");
  const { user } = useSelector((s) => s.auth);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRemark, setLoadingRemark] = useState(false);
  const _form = {
    ref_no: "",
    status: "Rissing Request",
    invoice_status: "request for authorization",
    remark: "",
    staff_name: "",
    staff_id: "",
    interswitch_ref_no: "",
    date_from: null,
    date_to: null,
    ticket_id:moment().format("YYYYMMDDhhmmss")
  };

  const [form, setForm] = useState(_form);

  const toggle = () => setModal(!modal);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleSearch = () => {
    setLoading(true)
    _fetchApi(
      `/adjust-transactions?reference_number=${refNo}`,
      (resp) => {
        if (resp.success) {
          setTransactionData(resp.data);
          setLoading(false);
          if(!resp?.data?.length){
            toast.error("No Record Found or Paid Already");
          }
        }
      },
      (err) => {
        console.log(err);
        toast.error("Transaction failed");
        setLoading(false);
      }
    );
  };

  const handleRemarkSubmit = () => {
    setLoadingRemark(true);
    _postApi(
      "/reciept_logs?query_type=insert",
      { ...form, ref_no: refNo, staff_name: user.name, staff_id: user.id },
      () => {
        setLoadingRemark(false);
        toast.success("Successfully");
        setTransactionData([]);
        toggle();
        setForm(_form);
      },
      (err) => {
        console.log(err);
        setLoadingRemark(false);
        toast.error("Error Occured", err);
      }
    );
  };

  return (
    <div>
      {/* {JSON.stringify(loading)} */}
      <Row>
        <Col md={6}>
          <CustomInput
            label="Reference Id"
            type="text"
            name="refNo"
            placeholder="Search Reference Id"
            value={refNo}
            onChange={(e) => setRefNo(e.target.value)}
          />
        </Col>
        <Col md={6}>
          <Button
            color="dark"
            className="col-md-6 "
            style={{
              marginTop: 30,
            }}
            disabled={loading}
            onClick={handleSearch}
          >
            {loading ? (
              <>
                <Spinner
                  size="sm"
                  color="light"
                  style={{ marginRight: "5px" }}
                />
                Searching...
              </>
            ) : (
              "Search"
            )}
          </Button>
        </Col>
        {transactionData.length ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              overflowY: "auto",
              margin: 10,
              borderRadius: 10,
              border: "1px solid #E8EDF2",
            }}
          >
            <Table
              hover
              className="user-table align-items-center table-hover"
              style={{
                width: "100%",
                overflow: "scroll",
              }}
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Tay Payer</th>
                  <th>Transaction ID</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {transactionData.map((data) => (
                  <tr
                    style={{ textAlign: "center" }}
                    key={data.reference_number}
                  >
                    <td>{moment(data.created_at).format("YYYY/MM/DD")}</td>
                    <td>{data.tax_payer}</td>
                    <td>{data.reference_number}</td>
                    <td>{data.description}</td>
                    <td>{toCurrency(data.dr)}</td>
                    <td style={{ fontSize: 15 }}>
                      <Badge color="blue">{data.status}</Badge>
                    </td>
                    <td>
                      <Col>
                        <Button color="dark" onClick={toggle}>
                          Request
                        </Button>
                      </Col>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            {/* Render modal content based on transactionData */}
            {transactionData.filter((x) => x.dr > 0).length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 50,
                }}
              >
                {/* Render details based on transactionData */}
                <div>
                  <p>Tay Payer: {transactionData[0].tax_payer}</p>
                  <p>Transaction ID: {transactionData[0].reference_number}</p>
                  <p>Description: {transactionData[0].description}</p>
                  <p>Status: {transactionData[0].status}</p>
                </div>
              </div>
            )}
            <hr />
            <Row>
              {/* {JSON.stringify(form)} */}
              <Col md={12}>
                <Label>Interswitch Reference</Label>
                <Input
                  type="Text"
                  onChange={handleChange}
                  value={form.interswitch_ref_no}
                  name="interswitch_ref_no"
                />
              </Col>
              <Col md={12}>
                <Label>Remark</Label>
                <Input
                  type="textarea"
                  value={form.remark}
                  name="remark"
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Col md={12}>
              <center>
                <Button
                  color="dark"
                  className="mt-3 ms-auto"
                  onClick={() => {
                    handleRemarkSubmit();
                  }}
                >
                  {loadingRemark ? (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                        style={{ marginRight: "5px" }}
                      />
                      Sending...
                    </>
                  ) : (
                    "Send"
                  )}
                </Button>
              </center>
            </Col>
          </ModalBody>
        </Modal>
      </Row>
    </div>
  );
}
