// import { _put } from "components/Utils/Helper";
// import { _get } from "components/Utils/Helper";
// import { _post } from "components/Utils/Helper";
import moment from "moment";
import React, { useEffect, useState, useNavigate, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { Routes } from "../../routes";

function BlogList() {
  const _form = {
    id: "",
    query_type: "delete",
  };

  const navigate = useHistory();
  const [data, setData] = useState([]);
  const [form, setForm] = useState(_form);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [currentItem, setCurrentItem] = useState({});

  const handleDelete = () => {
    _postApi(
      "/contentes/blog?query_type=delete",
      {
        id: currentItem.id,
        query_type: "delete",
      },
      (res) => {
        getBlog();
        toggle();
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    console.log(_form);
  };
  const handleEdit = () => {
    _postApi(
      "contentes/blog?query_type=update",
      {
        id: currentItem.id,
        query_type: "update",
      },
      (res) => {
        getBlog();
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    console.log(_form);
  };

  const getBlog = useCallback(() => {
    _fetchApi(
      "/contentes/get_blog?query_type=select",
      (res) => {
        if (res.success) setData(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    getBlog();
  }, [getBlog]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Button className="m-2" onClick={() => navigate.goBack()}>
              back
            </Button>
            <Card className="strpied-tabled-with-hover">
              {/* <Card.Header>
                <Card.Title as="h4">Blog List</Card.Title>
              </Card.Header> */}
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  {/* {JSON.stringify(currentItem)} */}
                  <thead>
                    <tr>
                      <th className="border-0">ACTION</th>
                      <th className="border-0">TITLE</th>
                      <th className="border-0">DATE</th>
                      <th className="border-0">ACTION</th>
                    </tr>
                  </thead>{" "}
                  <tbody>
                    {data.length ? (
                      data.map((i, idx) => (
                        <tr key={idx}>
                          <td>
                            <Button
                              onClick={() =>
                                navigate.push(
                                  `${Routes.AdminBlogView.path}?id=${i.id}`
                                )
                              }
                            >
                              View
                            </Button>
                          </td>
                          <td>{i.title}</td>
                          <td>{moment(i.created_at).format("DD/MM/YYYY")}</td>
                          <td>
                            <Row>
                              <Col md={6}>
                                <Button
                                  title="Delete"
                                  variant="danger"
                                  onClick={() => {
                                    setCurrentItem(i);
                                    toggle();
                                  }}
                                >
                                  X
                                </Button>
                              </Col>
                              <Col md={6}>
                                <Button
                                  title="Edit"
                                  variant="info"
                                  className="ml-3"
                                  onClick={() =>
                                    navigate.push(
                                      `${Routes.AdminBlogUpdate.path}?id=${i.id}`
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <center>
                        {" "}
                        <h3>Data not loaded</h3>
                      </center>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={modal} toggle={toggle}>
          <Modal.Header toggle={toggle}>Modal title</Modal.Header>
          <Modal.Body>Are Sure the Delete ?</Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={toggle}>
              Cancel
            </Button>{" "}
            <Button variant="danger" onClick={handleDelete}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default BlogList;
