import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card } from '@themesberg/react-bootstrap';
import { _fetchApi,_postApi } from '../../redux/action/api'
import { Input, Label } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from 'react-hot-toast';

export default function CreateTax() {
  const _form = {
    type: '',
    tax_code: '',
    parent_code: '',
    description: '',
    tax_fee: '',
    query_type:'create'

  }
  const [form, setForm] = useState(_form)

  const [taxList, setTaxList] = useState([])
  const [, setSubTaxList] = useState([])

  const handleChange = ({ target: { name, value, selected, checked, type } }) => {
    if (type === 'checkbox') {
      if (checked) {
        setForm((prevState) => ({
          ...prevState,
          [name]: prevState[name] ? `${prevState[name]}, ${value}` : value
        }));
      } else {
        setForm((prevState) => {
          if (prevState[name]) {
            const updatedValues = prevState[name].split(', ').filter(item => item !== value);
            const updatedValue = updatedValues.length ? updatedValues.join(', ') : '';
            return { ...prevState, [name]: updatedValue };
          }
          return prevState;
        });
      }
    }
    else if (type === 'select') {
      setForm((prevState) => ({ ...prevState, [name]: selected }));
    }
    else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  useEffect(() => {
    _fetchApi('/kigra-taxes?query_type=select-main',
      (resp) => {
        setTaxList(resp.result)
      }, () => { })
    _fetchApi('/kigra-taxes?query_type=select-sub',
      (resp) => {
        setSubTaxList(resp.result)
      }, () => { })
  }, [])

  const handleSubmit = () => {
    _postApi('/kigra-taxes',
      form,
      (resp) => {
        toast.success('Submited')
      },
      (err) => {
        toast.danger('System failure: ' + err.message)
      })
  }
  return (<>
    <Card className="m-0 p-3" style={{ height: '70vh' }}>
      <Card.Header className="m-0 p-0 text-center pt-3" >
        <h5>TAX CREATION FORM</h5>
      </Card.Header>
      {/* {JSON.stringify(form)} */}
      <Row className="m-0 p-3">

        <Col md={6}>
          <Label>Tax parent head</Label>
          <Typeahead
            allowNew
            labelKey={(key) => key.tax_code}
            placeholder='Select tax head'
            options={taxList}
            clearButton
            onChange={(e) => {
              if (e[0]?.tax_code)
                setForm((p) => ({
                  ...p,
                  parent_code: e[0].tax_code,
                }))
              else if (e[0]?.label)
                setForm((p) => ({
                  ...p,
                  parent_code: e[0].label,
                }))
            }}
            style={{ display: 'flex', flexDirection: 'row' }}
          />
        </Col>
        <Col md={6}>
          <Label>Tax code</Label>
          <Input
            type="text"
            name="org"
            value={form.org}
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <Label>Title</Label>
          <Input
            type="text"
            name="description"
            placeholder="Tax title"
            value={form.description}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <Label>Tax fee</Label>
          <Input
            type="text"
            name="tax_fee"
            placeholder="Tax fee"
            value={form.tax_fee}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {form.rows && form.rows.length > 0 ? (
            <table sm style={{ width: '90%', height: '100%' }}>
              <thead>
                <tr>
                  {form.rows[0].map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {form.rows.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </Col>
        <Col md={12} className='text-center mt-4'>
          <Button
            onClick={handleSubmit}
          >Submit</Button></Col>
      </Row>
    </Card>
  </>
  );
};