import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // Added faDownload
import { Col, Row, Button } from "@themesberg/react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"; // Added PDFDownloadLink
// import AssessmentInvoicePdf from "../../components/AssessmentInvoicePdf";
import { useQuery } from "../../utils";
import { _fetchApi, _postApi, apiURL } from "../../redux/action/api";
// import { useCallback } from "react";
import PaymentButton from "../payments/PaymentButton";
import { useSelector } from "react-redux";
// import useIsPhoneSize from "../../utils/useIsPhoneSize";
import moment from "moment";
import toast from "react-hot-toast";
import AssessmentInvoicePdf from "../../components/AssessmentInvoicePdf";

const PayAssessment = ({ refNo = null }) => {
	const q = useQuery();
	const ref_no = refNo ? refNo : q.get("ref_no");
	const [qrcode, setQrCode] = useState(null);
	const userInfo = useSelector((state) => state.auth.user);
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	// const [payModal, setPayModal] = useState(false);
	const [user, setUser] = useState({});
	const [user_id, setUserID] = useState(null);
	const [list, setList] = useState([]);
	// const location = useLocation();
	// const isAdmin = location.pathname.includes("/dashboard/");
	const [loading, setLoading] = useState(false);
	// const agent = useSelector((state) => state.auth.user);
	const { tax_account = [] } = useSelector((state) => state.auth);
	// const isMobile = useIsPhoneSize();

	useEffect(() => {
		if (ref_no) {
			setLoading(true);
			_fetchApi(
				`/transactions/retrieve?query_type=view_invoice&reference_number=${ref_no}&user_id=${userInfo.taxID}`,
				(resp) => {
					if (resp.success) {
						setList(resp.data);
						if (resp.data[0]?.user_id) {
							setLoading(false);
							setUserID(resp.data[0].user_id);
						}
					}
				},
				(err) => {
					console.error(err);
					setLoading(false);
				},
				(err) => {
					console.log(err);
					setList([]);
					setLoading(false);
				}
			);
		}
		if (user_id) {
			_fetchApi(
				`/users/get-tax-payer?user_id=${user_id}`,
				(resp) => {
					if (resp.success) {
						setUser(resp.data);
					}
				},
				(err) => {
					console.error(err);
					setUser({});
				}
			);
		}
		if (ref_no) {
			const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${ref_no}`;
			fetch(qrCodeUrl, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					authorization: localStorage.getItem("@@auth_token"),
				},
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.blob();
				})
				.then((blob) => {
					const qrCodeImageUrl = URL.createObjectURL(blob);
					setQrCode(qrCodeImageUrl);
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		}
	}, [ref_no, user_id]);

	const final_list = list;

	// const [refNo, setRefNo] = useState(null);
	const handleSubmit = () => {
		console.log("Handled payment");
		if (final_list.length) {
			setLoading(true);
			_postApi(
				"/transactions/execute",
				final_list,
				(resp) => {
					if (resp.success) {
						setIsOpen(true);
						setLoading(false);
					} else {
						toast.error("Error");
						setLoading(false);
					}
				},
				(e) => {
					console.error("Error", e);
				}
			);
		}
	};

	return (
		<div className="p-0 m-0 h-100 text-center">
			{loading ? (
				<SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
					<p>
						<Skeleton count={15} height={50} />
					</p>
				</SkeletonTheme>
			) : (
				<>
					<Row className="mb-4">
						<Col md={6} >
							<PaymentButton
								onMouseOver={() => { }}
								addStyle={{ with: "100%", marginTop: 10 }}
								amount={final_list.reduce((it, id) => it + parseFloat(id.dr), 0)}
								reference_no={ref_no}
								email={tax_account.org_email || tax_account.email}
								name={tax_account.org_name || tax_account.name}
								taxID={tax_account.taxID}
								phone={tax_account.office_phone || tax_account.phone}
								data={final_list}
								sector={final_list[0]?.sector}
								callback={handleSubmit}
								// setPayModal={setPayModal}
								toggle={toggle}
							/>
						</Col>
						<Col md={6}>
							<PDFDownloadLink
								document={
									<AssessmentInvoicePdf
										user={user}
										data={final_list
											.map((l) => ({ ...l, tax_fee: l.cr }))}
										qrcode={qrcode}
									/>
								}
								fileName="invoice.pdf"
							>
								{({ blob, url, loading, error }) =>
									loading ? (
										"Loading document..."
									) : error ? (<Col className="text-danger"> {error}</Col>)
										: (
											<Button variant="primary">
												<FontAwesomeIcon icon={faDownload} className="me-2" />
												Download PDF
											</Button>
										)
								}
							</PDFDownloadLink>
						</Col>
					</Row>
					<Row>
						<Col style={{ height: '70vh' }}>
							<PDFViewer height="100%" width="70%">
								<AssessmentInvoicePdf
									user={user}
									data={final_list
										.map((l) => ({ ...l, tax_fee: l.cr }))}
									qrcode={qrcode}
								/>
							</PDFViewer>
						</Col>
					</Row>
				</>
			)
			}
		</div>
	);
};
export default PayAssessment