import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Form,
  Card,
  FormCheck,
  Container,
  Modal,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Button, ButtonGroup, Label, Spinner } from "reactstrap";
import CustomInput from "./CustomInput";
// import { UserContext } from "../../redux/contextApi/user_context";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, signup } from "../../redux/action/auth";
import {
  faArrowCircleLeft,
  faCheckCircle,
  // faCaretDown,
  // faCaretUp,
  // faList,
  faEye,
  faEyeSlash,
  // faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../assets/img/kigra.jpg";

import { Routes } from "../../routes";
import SelectInput from "../components/SelectInput";
// import { kanoLGAs, states } from "../../utils/states";
import { toast } from "react-hot-toast";
import Contact from "../../contact/Contact";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { stateLga } from "../../statelga";
import { _postApi } from "../../redux/action/api";
// import { useHint } from "react-bootstrap-typeahead";
import { CustomButton } from "../../components/UI";
import { Occupations } from "../../assets/Occupations";
import { IncomeSources } from "../../assets/IncomeSources";
import { Countries } from "../../assets/Counties";
import { Typeahead } from "react-bootstrap-typeahead";
import { IndurialSectors, orgType } from "../../assets/IndurialSectors";
import { taxStations } from "../../utils/tax-stations";
import ReactCodeInput from "react-verification-code-input";
import { useQuery } from "../../utils";

export default () => {

  const q = useQuery()
  const type = q.get('type')
  const [form, setForm] = useState({
    account_type: type ? type : "individual",
    contact_name: "", // Individual's full name
    contact_phone: "", // Individual's contact_phone number
    contact_email: "",
    ward: "",
    nin: "", // Individual's nin
    state: "Kano", // Individual's state
    lga: "", // Individual's local government
    org_name: "", // Organization's name
    rc: "", // Organization's RC/BN
    org_tin: "", // Organization's TIN
    tin: "", // Individual's TIN
    contact_address: "",
    office_address: "",
    office_phone: "",
    org_email: "",
    occupation: "",
    income_source: "",
    password: "",
    org_type: "",
    retypePassword: "",
    nationality: "Nigerian",
    tax_station: "",
    phone_vcodes: "",
    email_vcodes: "",
    query_type: "save-contact",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const dispatch = useDispatch();
  const [showDefault, setShowDefault] = useState(false);
  const toggle = () => setShowDefault(!showDefault);
  const [taxID, setTaxID] = useState("");
  const isPhoneSize = useIsPhoneSize();
  const handleChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  const history = useHistory();
  const [phoneTimer, setPhoneTimer] = useState(180); // 3 minutes in seconds
  const [emailTimer, setEmailTimer] = useState(180); // 3 minutes in seconds
  const [showPhoneResend, setShowPhoneResend] = useState(false);
  const [showEmailResend, setShowEmailResend] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState({
    phone_verified: 0,
  });
  const [emailVerification, setEmailVerification] = useState({
    email_verified: 0,
  });

  useEffect(() => {
    logout();
  }, []);

  const handleApi = (e) => {
    e.preventDefault();
    // if (emailVerification.email_verified < 1) {
    //   toast.error("Verify Email First");
    // } else
    // if (phoneVerification.phone_verified < 1) {
    //   toast.error("Verify Phone First");
    // } else {
      setLoading(true);
      dispatch(
        signup(
          {
            ...form,
            address: form.contact_address,
            query_type: "insert",
          },
          (result) => {
            setLoading(false);
            if (result.success) {
              setTaxID(result.taxID);
              setShowDefault(true);
            } else {
              toast.error(result.msg);
            }
          },
          (err) => {
            setLoading(false);
            console.log(err);
            toast.error(err.msg);
          }
        )
      );
    // }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleProceed = (e) => {
    e.preventDefault();
    if (form.account_type === "org" && !form.org_name) {
      toast.error("Organization Name is require");
    } else if (form.tax_station === "") {
      toast.error("Please select Tax station");
    } else if (form.account_type === "org" && !form.office_phone) {
      toast.error("Office Phone No. is require");
    } else if (!form.contact_address) {
      toast.error("Please fill your Contact address");
    } else if (form.password.length < 6) {
      toast.error("Password must be at least 6 characters long");
    } else {
      setLoadingVerification(true);
      _postApi(
        "/users/verify",
        {
          phone:
            form.account_type === "org"
              ? form.office_phone
              : form.contact_phone,
          email:
            form.account_type === "org" ? form.org_email : form.contact_email,
          query_type: form.query_type,
        },
        (resp) => {
          setShowVerification(true);
          setLoadingVerification(false);
        },
        () => {
          setShowVerification(true);
          setLoadingVerification(false);
        }
      );
      setShowDefault(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (emailVerification.email_verified < 1) {
    //   toast.error("Verify Email First");
    // } else
    // if (phoneVerification.phone_verified < 1) {
    //   toast.error("Verify Phone First");
    // } else {
      setLoading(true);
      // Example API call or dispatch action to submit form data
      _postApi(
        "/auths/-post-users",
        {
          ...form,
          query_type: "create-admin",
          role: "agent",
          name: form.contact_name,
          phone: form.contact_phone,
          email: form.contact_email,
          accessTo: "",
          rank: "",
          state: form.state,
          lga: form.lga,
          address: form.office_address || form.contact_address,
          password: form.password,
          account_type: "agent",
        },
        (result) => {
          setLoading(false);
          if (result.success) {
            toast.success("Submitted successfully");
            setShowDefault(true);
          } else {
            toast.error(result.msg);
          }
        },
        (err) => {
          setLoading(false);
          toast.error(err.msg);
        }
      );
    // }
  };

  const handleVerifyCode = (type, action) => {
    setLoadingVerification(true);
    if (type === "phone") {
      if (form.phone_vcodes.length < 4) {
        toast.error("Code Must be at least 4 characters long");
        return;
      }
      if (action === "resend-phone") {
        setPhoneTimer(180);
      }
      setShowPhoneResend(false);
      // handleResendVerification('phone');
      _postApi(
        "/users/verify",
        {
          query_type: action,
          phone:
            form.account_type === "org"
              ? form.office_phone
              : form.contact_phone,
          phone_vcodes: form.phone_vcodes,
        },
        (resp) => {
          console.log(`Resent ${type} verification code:`, resp);
          setShowVerification(true);
          setShowDefault(false);
          setLoadingVerification(false);
          if (resp.success && resp.data[0]?.phone_verified > 0) {
            setPhoneVerification(resp.data[0]);
          } else {
            toast.error("Please enter valid verification code");
          }
        },
        (error) => {
          setLoadingVerification(false);
          toast.error("error occured, Please click back button and re-submit");
          console.error(`Error resending ${type} verification:`, error);
        }
      );
    } else if (type === 'email') {
      if (action === 'resend-email') {
        setPhoneTimer(180);
      }
      setShowEmailResend(false);
      // handleResendVerification('email');
      _postApi(
        '/users/verify',
        { query_type: action, email: form.account_type === 'org' ? form.org_email : form.contact_email, email_vcodes: form.email_vcodes },
        (resp) => {
          setShowVerification(true);
          setShowDefault(false);
          setLoadingVerification(false)
          if (resp.success) {
            setPhoneVerification(resp.data[0])
          }
        },
        (error) => {
          setLoadingVerification(false)
          console.error(`Error resending ${type} verification:`, error);
        }
      );
    }
  };
  const handleChange1 = (value, type) => {
    setForm((prevState) => ({
      ...prevState,
      [`${type}_vcodes`]: value,
    }));
  };
  useEffect(() => {
    if (phoneTimer > 0) {
      const phoneInterval = setInterval(() => {
        setPhoneTimer(phoneTimer - 1);
      }, 1000);
      return () => clearInterval(phoneInterval);
    } else {
      setShowPhoneResend(true);
    }
  }, [phoneTimer]);

  return (
    <main>
      {/* {JSON.stringify(type)} */}
      <section className="d-flex align-items-center my-5 mt-lg-2 mb-lg-5">
        <Modal as={Modal.Dialog} centered show={showDefault}>
          <Modal.Header>
            <Modal.Title className="h6">
              Your Account created successfully{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggle} />
          </Modal.Header>
          <Modal.Body>
            <h2>Your KIRMAS TaxID is: {taxID}</h2>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              loading={loading}
              onClick={() => {
                toggle();
                setLoading(true);
                dispatch(
                  login(
                    {
                      password: form.password,
                      username: form.contact_phone,
                      history,
                    },
                    () => {
                      history.push(`/selection`);
                      setLoading(false);
                      setTimeout(() => window.location.reload(), 400);
                    },
                    () => {
                      toast.error("Error occured");
                      setLoading(false);
                    }
                  )
                );
              }}
            >
              Login
            </CustomButton>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={toggle}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Container>
          <Row
            className="justify-content-center form-bg-image"
          // style={{ backgroundImage: `url(${require('../../assets/img/kano.png')})` }}
          >
            {isPhoneSize ? (
              <img
                src={logo}
                alt="logo"
                style={{ width: 100, marginBottom: -17, zIndex: 1 }}
              />
            ) : (
              <img
                src={logo}
                alt="logo"
                style={{ width: 170, marginBottom: "-30px", zIndex: 1 }}
              />
            )}
            {!showVerification ? (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Create an account</h3>
                  </div>

                  <ButtonGroup className="w-100 pb-1">
                    <Button
                      outline={
                        form.account_type === "individual" ? false : true
                      }
                      onClick={() =>
                        setForm((p) => ({
                          ...p,
                          account_type: "individual",
                        }))
                      }
                      color="dark"
                      className={`col-md-6`}
                    >
                      INDIVIDUAL
                    </Button>
                    <Button
                      color="dark"
                      outline={form.account_type === "org" ? false : true}
                      onClick={() =>
                        setForm((p) => ({
                          ...p,
                          account_type: "org",
                        }))
                      }
                      className="col-md-6"
                    >
                      ORGANIZATION/COMPANY
                    </Button>
                    {/* <Button
                                        color="dark"
                                        outline={form.account_type === 'agent' ? false : true}
                                        onClick={() => setForm((p) => ({ ...p, account_type: 'agent' }))}
                                        className="col-md-6"
                                    >
                                        BANK
                                    </Button> */}
                    <Button
                      color="dark"
                      outline={form.account_type === "agent" ? false : true}
                      onClick={() =>
                        setForm((p) => ({ ...p, account_type: "agent" }))
                      }
                      className="col-md-6"
                    >
                      TAX CONSULTANT
                    </Button>
                  </ButtonGroup>
                  <Form
                    className="mt-1"
                    onSubmit={handleProceed}
                    autoComplete="off"
                  >
                    {/* {JSON.stringify(form)} */}
                    <Row>
                      {form.account_type === "org" && (
                        <>
                          <Card.Body>
                            <Row>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label={
                                    <>
                                      Organization Name{" "}
                                      {/* <span style={{ color: 'red' }}> *</span> */}
                                    </>
                                  }
                                  required={
                                    form.account_type === "org" ? true : false
                                  }
                                  type="text"
                                  name="org_name"
                                  value={form.org_name}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label={
                                    <>
                                      Office Phone{" "}
                                      {/* <span style={{ color: 'red' }}> *</span> */}
                                    </>
                                  }
                                  required={
                                    form.account_type === "org" ? true : false
                                  }
                                  type="text"
                                  name="office_phone"
                                  value={form.office_phone}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label={
                                    <>
                                      Office Email{" "}
                                      {/* <span style={{ color: 'red' }}> *</span> */}
                                    </>
                                  }
                                  required={
                                    form.account_type === "org" ? true : false
                                  }
                                  type="text"
                                  name="org_email"
                                  value={form.org_email}
                                  onChange={handleChange}
                                />
                              </Col>

                              <Col md={6} className="mb-1">
                                <SelectInput
                                  label={
                                    <>
                                      Type of Organizaion
                                      {/* <span style={{ color: 'red' }}> *</span> */}
                                    </>
                                  }
                                  required={
                                    form.account_type === "org" ? true : false
                                  }
                                  type="text"
                                  name="org_type"
                                  // required={true}
                                  options={orgType?.map((item) => item)}
                                  value={form.org_type}
                                  onChange={handleChange}
                                />
                              </Col>

                              <Col md={6} className="mb-1">
                                <Label>Business Sector</Label>
                                <Typeahead
                                  id="bus"
                                  placeholder="Select Business Sector"
                                  type="text"
                                  name="business_sector"
                                  options={IndurialSectors}
                                  value={form.occupation}
                                  onChange={(selected) => {
                                    setForm((p) => ({
                                      ...p,
                                      business_sector: selected,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label="RC/BN"
                                  required
                                  type="text"
                                  name="rc"
                                  value={form.rc}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label="ORG TIN"
                                  type="text"
                                  name="org_tin"
                                  value={form.org_tin}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label={
                                    <>
                                      Date of Incorporation
                                      <span style={{ color: "red" }}> *</span>
                                    </>
                                  }
                                  type="date"
                                  name="incorporation_date"
                                  value={form.incorporation_date}
                                  onChange={handleChange}
                                />
                              </Col>

                              {/* <Col md={6} className="mb-1">
                              <CustomInput
                                label={<>
                                  Commencent Date
                                  <span style={{ color: "red" }}> *</span>
                                </>}
                                type="date"
                                name="commencement_date"
                                value={form.commencement_date}
                                onChange={handleChange}
                              />
                            </Col> */}
                              <Col md={6} className="mb-1">
                                <SelectInput
                                  label={
                                    <>
                                      Average Annual Turnover
                                      <span style={{ color: "red" }}> *</span>
                                    </>
                                  }
                                  type="text"
                                  name="annual_turnover"
                                  // required={true}
                                  options={[
                                    "500k - 1 milion naira",
                                    "1 milion - 10 milion naira",
                                    "Above 10 milion naira",
                                  ].map((item) => item)}
                                  value={form.annual_turnover}
                                  onChange={handleChange}
                                />
                              </Col>

                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label="Office address / state"
                                  type="textarea"
                                  required={true}
                                  name="office_address"
                                  value={form.office_address}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-4">
                                <SelectInput
                                  label="State of residence"
                                  type="text"
                                  name="state"
                                  // required={true}
                                  options={stateLga.map((item) => item.state)}
                                  value={form.state}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-4">
                                {/* {JSON.stringify(s)} */}
                                <SelectInput
                                  label="L.G.A"
                                  type="text"
                                  name="lga"
                                  // required={true}
                                  options={
                                    stateLga.filter(
                                      (item) => item.state === form.state
                                    )[0].lgas
                                  }
                                  value={form.lga}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={6} className="mb-1">
                                <CustomInput
                                  label="Ward"
                                  type="text"
                                  name="ward"
                                  value={form.ward}
                                  onChange={handleChange}
                                />
                              </Col>

                              <Col md={6} className="mb-1">
                                <Label>
                                  Tax Station{" "}
                                  <span style={{ color: "red" }}> *</span>
                                </Label>
                                <Typeahead
                                  id="station"
                                  placeholder="Tax Station"
                                  name="tax_station"
                                  options={taxStations}
                                  value={form.tax_station}
                                  onChange={(selected) => {
                                    setForm((p) => ({
                                      ...p,
                                      tax_station: selected,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col md={12} className="mb-1">
                                <Form.Label>
                                  Password{" "}
                                  <span style={{ color: "red" }}> *</span>{" "}
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={form.password}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="Password"
                                  />
                                  <InputGroup.Text
                                    style={{ cursor: "pointer" }}
                                    onClick={toggleShowPassword}
                                  >
                                    <FontAwesomeIcon
                                      icon={showPassword ? faEye : faEyeSlash}
                                    />
                                  </InputGroup.Text>
                                </InputGroup>
                              </Col>
                            </Row>
                          </Card.Body>
                        </>
                      )}
                      {/* {form.account_type==} <Card bg="white" className="mb-4 mt-0 mx-0 px-0"></Card>*/}
                      {form.account_type === "org" ? (
                        // <CardH bg="white" className="mb-4 mt-0 mx-0 px-0">Contact Person</CardH>
                        <Card.Header
                          bg="light"
                          className="bg-light text-center mb-4 "
                        >
                          <Card.Title>Contact Person</Card.Title>
                        </Card.Header>
                      ) : null}

                      {form.account_type === "agent" ? (
                        <>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Bank Name"
                              onChange={handleChange}
                              type="text"
                              required={true}
                              name="contact_name"
                              value={form.contact_name}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Contact Phone Number"
                              onChange={handleChange}
                              type="text"
                              required={true}
                              name="contact_phone"
                              value={form.contact_phone}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Contact Email"
                              onChange={handleChange}
                              type="text"
                              // required={form.account_type === 'org' ? false : true}
                              name="contact_email"
                              value={form.contact_email}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Office address / state"
                              type="textarea"
                              // required={true}
                              name="office_address"
                              value={form.office_address}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <SelectInput
                              label="State of residence"
                              type="text"
                              name="state"
                              // required={true}
                              options={stateLga.map((item) => item.state)}
                              value={form.state}
                              onChange={handleChange}
                            />
                          </Col>{" "}
                          <Col md={6} className="mb-1">
                            <SelectInput
                              label="L.G.A"
                              type="text"
                              name="lga"
                              // required={true}
                              options={
                                stateLga.filter(
                                  (item) => item.state === form.state
                                )[0].lgas
                              }
                              value={form.lga}
                              onChange={handleChange}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Full Name"
                              onChange={handleChange}
                              type="text"
                              required={true}
                              name="contact_name"
                              value={form.contact_name}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Phone No."
                              type="text"
                              name="contact_phone"
                              required={true}
                              value={form.contact_phone}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="TIN"
                              type="text"
                              name="tin"
                              value={form.tin}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <CustomInput
                              label="Contact Email"
                              // required={true}
                              required={
                                form.account_type === "org" ? false : true
                              }
                              type="email"
                              name="contact_email"
                              value={form.contact_email}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <SelectInput
                              label="Nationality"
                              type="text"
                              options={Countries.map(
                                (item) => item.nationality
                              )}
                              name="nationality"
                              value={form.nationality}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1">
                            <SelectInput
                              label="State of residence"
                              type="text"
                              name="state"
                              // required={true}
                              options={stateLga.map((item) => item.state)}
                              value={form.state}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1  mt-4">
                            <SelectInput
                              label="L.G.A"
                              type="text"
                              name="lga"
                              // required={true}
                              options={
                                stateLga.filter(
                                  (item) => item.state === form.state
                                )[0].lgas
                              }
                              value={form.lga}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6} className="mb-1 mt-4">
                            <CustomInput
                              label="Ward"
                              type="text"
                              name="ward"
                              value={form.ward}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={12} className="mb-1">
                            <CustomInput
                              label="Contact address"
                              type="text"
                              required={
                                form.account_type === "org" ? false : true
                              }
                              name="contact_address"
                              value={form.contact_address}
                              onChange={handleChange}
                            />
                          </Col>
                        </>
                      )}
                      {["individual", "agent"].includes(form.account_type) && (
                        <>
                          {/* <Col md={6}>
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={handleChange}
                          />
                        </Col> */}
                          {/* <Col md={6}>
                        <SelectInput
                          label="L.G.A"
                          type="text"
                          name="lga"
                          // required={true}
                          options={
                            stateLga.filter(
                              (item) => item.state === form.state
                            )[0].lgas
                          }
                          value={form.lga}
                          onChange={handleChange}
                        />
                      </Col> */}
                          <Col md={6} className="mb-1  ">
                            <Label>Occupation</Label>
                            <Typeahead
                              id="type"
                              placeholder="Type of Occupation"
                              type="text"
                              name="occupation"
                              // required={true}
                              options={Occupations}
                              value={form.occupation}
                              onChange={(selected) => {
                                setForm((p) => ({
                                  ...p,
                                  occupation: selected,
                                }));
                              }}
                            />
                          </Col>
                          <Col md={6} className="mb-1  ">
                            <Label>Income Source</Label>
                            <Typeahead
                              id="Source"
                              placeholder="Source of Income"
                              name="income_source"
                              // required={true}
                              options={IncomeSources}
                              value={form.income_source}
                              onChange={(selected) => {
                                setForm((p) => ({
                                  ...p,
                                  income_source: selected,
                                }));
                              }}
                            />
                          </Col>
                        </>
                      )}
                      {form.account_type === "org" ? null : (
                        <Col md={6} className="mb-4 mt-4">
                          <Label>
                            Tax Station <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Typeahead
                            id="station"
                            placeholder="Tax Station"
                            name="tax_station"
                            options={taxStations}
                            value={form.tax_station}
                            onChange={(selected) => {
                              setForm((p) => ({
                                ...p,
                                tax_station: selected[0],
                              }));
                            }}
                          />
                        </Col>
                      )}
                      {form.account_type === "org" ? null : (
                        <Col md={6} className="mb-4 mt-4">
                          <Form.Label>
                            Password <span style={{ color: "red" }}> *</span>{" "}
                          </Form.Label>
                          <InputGroup>
                            <FormControl
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={form.password}
                              required={true}
                              onChange={handleChange}
                              placeholder="Password"
                            />
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={toggleShowPassword}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEye : faEyeSlash}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                      )}
                      <FormCheck type="checkbox" className="d-flex mb-4">
                        <FormCheck.Input required id="terms" className="me-2" />
                        <FormCheck.Label htmlFor="terms">
                          Please confirm that the data above is correct
                        </FormCheck.Label>
                      </FormCheck>
                      <CustomButton
                        loading={loading}
                        type="submit"
                        color="dark"
                        onClick={
                          form.account_type !== "agent"
                            ? handleApi
                            : handleSubmit
                        }
                        className="w-100"
                      >
                        Submit
                      </CustomButton>
                    </Row>
                  </Form>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Already have an account?
                      <Card.Link
                        as={Link}
                        to={Routes.Signin.path}
                        className="fw-bold"
                      >
                        {` Login here `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            ) : (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                  <Card.Body>
                    {/* Phone Verification */}
                    <Row className="mb-4">
                      <div className="d-flex justify-content-center row">
                        <Col md={6} lg={7}>
                          <div className="mb-2">
                            <ReactCodeInput
                              type="number"
                              fields={4}
                              onChange={(value) =>
                                handleChange1(value, "phone")
                              }
                              value={form.phone_vcodes}
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={5}>
                          {phoneVerification?.phone_verified > 0 ? (
                            <Col className="text-center">
                              <FontAwesomeIcon
                                color="green"
                                size="3x"
                                icon={faCheckCircle}
                              />
                            </Col>
                          ) : (
                            <div className="d-flex align-items-center mt-0 mt-md-2">
                              <div className="text-center mt-1 me-2 ">
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    handleVerifyCode("phone", "verify-phone")
                                  }
                                >
                                  Verify Phone
                                </Button>
                              </div>
                              <div className="text-center align-items-center">
                                {showPhoneResend ? (
                                  <Button
                                    color="warning"
                                    onClick={() =>
                                      handleVerifyCode("phone", "resend-phone")
                                    }
                                  >
                                    Resend SMS
                                  </Button>
                                ) : (
                                  <span className="text-danger mt-2">{`${Math.floor(
                                    phoneTimer / 60
                                  )}:${phoneTimer % 60 < 10 ? "0" : ""}${phoneTimer % 60
                                    }`}</span>
                                )}
                              </div>
                            </div>
                          )}
                        </Col>
                      </div>
                      <p className="m-0 mt-2 mb-2">
                        If you didn't receive the OTP SMS Dial <b>*347*621#</b>{" "}
                        or text STOP to 2442 from your line to remove your
                        number from the Do Not Disturb list.
                        Or choose <span
                          onClick={() =>
                            handleVerifyCode("email", "verify-email")
                          }>Send OTP to Email</span>
                      </p>
                    </Row>

                    {/* Email Verification
                      <Row className="mb-4">
                        <Col md={6}>
                          <ReactCodeInput
                            type="number"
                            fields={6}
                            onChange={(value) => handleChange1(value, 'email')}
                            value={form.email_vcodes}
                          />
                        </Col>
                        <Col>
                          {emailVerification.email_verified > 0 ? (
                            <Col className="text-center">
                              <FontAwesomeIcon size='3x' color="green" icon={faCheckCircle} />
                            </Col>
                          ) : (
                            <Row>
                              <Col md={6} className="text-center mt-1">
                                <Button variant="primary" onClick={() => handleVerifyCode('email', 'verify-email')}>
                                  Verify Email
                                </Button>
                              </Col>
                              <Col md={6} className="text-center mt-1">
                                {showEmailResend ? (
                                  <Button color="warning" onClick={() => handleVerifyCode('email', 'resend-email')}>
                                    Resend Email
                                  </Button>
                                ) : (
                                  <span className="text-danger mt-2">{`${Math.floor(emailTimer / 60)}:${emailTimer % 60 < 10 ? '0' : ''}${emailTimer % 60}`}</span>
                                )}
                              </Col>
                            </Row>
                          )}
                        </Col>
                        
                      </Row> */}

                    {/* Back and Submit Buttons */}
                    <Row>
                      <Col xm={6} className="mb-4">
                        <Button
                          color="dark"
                          onClick={() => setShowVerification(false)}
                        >
                          <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
                        </Button>
                      </Col>
                      {/* {JSON.stringify({ phoneVerification, emailVerification })} */}
                      <Col xm={6} className="mb-4 text-right">
                        <CustomButton
                          loading={loading}
                          disabled={
                            phoneVerification.phone_verified < 1
                            // emailVerification.email_verified < 1
                          }
                          color="dark"
                          onClick={
                            form.account_type !== "agent"
                              ? handleApi
                              : handleSubmit
                          }
                        >
                          Submit
                        </CustomButton>
                      </Col>
                    </Row>
                  </Card.Body>
                </div>
              </Col>
            )}
          </Row>
          <Contact />
        </Container>
      </section>
    </main>
  );
};
