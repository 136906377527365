import React from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
// import {  headerColor } from "../utils/constants";
import CustomButton from "./CustomButton";

function CustomCard(props) {
  const { header, footer, back, headerRight, headerLeft, bg=null, textColor=null } = props;

  return (
    <Card
      className={`${props.container}`}
  >
      {header && (
        <CardHeader
          // className={`py-2`}
          style={{
            borderBottom: `1px solid  ${textColor?textColor:'#eef1f5'}`,
            paddingTop: ".4rem",
            paddingBottom: ".4rem",
            backgroundColor: bg?bg :
        '#eef1f5',
         color: textColor?textColor: "white"
            
          }}
          className={
            back || headerLeft || headerRight
              ? "row m-0 align-items-center"
              : ""
          }
        >
          {headerLeft && <div className="col-md-3">{headerLeft}</div>}
          {back && (
            <div className="col-md-3">
              <CustomButton />
            </div>
          )}
          <h4
            className={
              back || headerLeft || headerRight
                ? "col-md-6 text-center"
                : "text-center"
            }
            style={{ color: "white" }}
          >
            {header}
          </h4>
          {headerRight && <div className="col-md-3">{headerRight}</div>}
        </CardHeader>
      )}
      <CardBody className={props.body}>{props.children}</CardBody>
      {footer && (
        <CardFooter
          style={{
            // backgroundColor: headerColor,
          }}
        >
          {footer}
        </CardFooter>
      )}
    </Card>
  );
}

export default CustomCard;