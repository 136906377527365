import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowCircleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import { Modal } from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
// import { Input, Modal } from "reactstrap";
import { Routes } from "../../routes";
import { CustomButton } from "../../components/UI";
import toast from "react-hot-toast";
import { _postApi } from "../../redux/action/api";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../redux/action/auth";

export default function NewResetPassword() {
  const _form = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [form, setForm] = useState(_form);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((item) => item.auth);
  const [showDefault, setShowDefault] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setShowDefault(!showDefault);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.newPassword !== form.confirmPassword) {
      toast.error(
        "Oops! The password and confirm password do not match. Please try again."
      );
    } else {
      setLoading(true);
      _postApi(
        "/users/reset-password-new",
        { ...user, ...form },
        (resp) => {
          if (resp.success) {
            setShowDefault(true);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(resp.msg);
          }
        },
        (error) => {
          setLoading(false);
          toast.error("Error Occurred");
          console.log(error);
        }
      );
    }
  };

  const onChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset your password</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Currect Password</Form.Label>
                    <InputGroup>
                      <FormControl
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={form.password}
                        required={true}
                        onChange={onChange}
                        placeholder="Enter your currect Password"
                      />
                      <InputGroup.Text
                        style={{ cursor: "pointer" }}
                        onClick={toggleShowPassword}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="password" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="New Password"
                        value={form.newPassword}
                        onChange={onChange}
                        name="newPassword"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        value={form.confirmPassword}
                        name="confirmPassword"
                        onChange={onChange}
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <CustomButton
                    loading={loading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    Reset password
                  </CustomButton>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal as={Modal.Dialog} centered show={showDefault}>
        <Modal.Header>
          <Modal.Title className="h6">
            Your Password Updated successfully{" "}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              toggle();
              setForm(_form);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <h4>
            <span role="img" aria-label="Panda">
              🎉 Congratulation! Your password has been reset. You can now log
              in with your new password. 😃
            </span>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton onClick={() => dispatch(logout(history))}>
            Login
          </CustomButton>
          <CustomButton
            variant="link"
            className="text-gray ms-auto"
            onClick={() => {
              toggle();
              setForm(_form);
            }}
          >
            Close
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </main>
  );
}
