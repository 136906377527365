import React, { useCallback, useEffect, useState } from "react"
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Table } from "reactstrap"
import { _fetchApi } from "../../redux/action/api"
import { SearchBar } from "../../components/UI"
import CustomModal from "../../components/UI/CustomModal"
import CustomForm from "../../components/UI/CustomForm"
import CustomTable from "../../components/UI/CustomTable"
import CustomTypeahead from "../../components/UI/CustomTypeahead"
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes"
import moment from "moment"
import { toCurrency } from "../../utils"
import { useSelector } from "react-redux"
import RevDashboardCard from "./RevDashboardCard"
import { FaArrowCircleLeft, FaRegEye, FaUserFriends } from "react-icons/fa"
import toast from "react-hot-toast"

const _form = {
    org_name: "",
    jtb_no: "",
    nin: "",
    staff_id: "",
    phone: "",
    basic_salary: "",
    other_allowance: "",
    NHF: "",
    pension: "",
    life_insurance: "",
    NHIS: "",
    gross: "",
    taxID: "",
};

const AllOrgs = () => {
    const [assesments, setAssessments] = useState([]);
    // const [orgs, setOrgs] = useState([]);
    const { user } = useSelector(s => s.auth)
    const [org_name, setOrgName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState(_form);
    const history = useHistory();
    const [data_status, setDataStatus] = useState("");

    const handleChange = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }));
    };

    useEffect(() => {
        if (form.org_name) {
            _fetchApi(
                `/assessments/paye-list?org_id=${form.org_name}&year=${moment().format('YYYY')}&query_type=orgs-summary`,
                (resp) => {
                    if (resp.success && resp.data.length) {
                        setDataStatus(null);
                        setAssessments(resp.data);
                    } else {
                        setDataStatus("Record not found!");
                        setForm((p) => ({ ...p, org_name: '' }))
                    }
                },
                () => {
                    toast.error('Network failed')
                }
            );
        } else {
            _fetchApi(
                `/assessments/paye-list?year=${moment().format('YYYY')}&query_type=unpaid-orgs`,
                (resp) => {
                    if (resp.success && resp.data.length) {
                        setDataStatus(null);
                        setAssessments(resp.data);
                    } else {
                        setDataStatus("Record not found!");
                    }
                }
            );
        }
    }, [form.org_name]);

    // useEffect(() => {
    //     getPayments()
    // }, [])

    const _form_fields = [
        {
            name: "taxID",
            label: "KIRMAS ID",
            value: form.staff_id,
        },
        {
            name: "org_name",
            label: "Staff name",
            value: form.org_name,
        },
        {
            name: "phone",
            label: "Phone No.",
            value: form.phone,
        },
        {
            name: "nin",
            label: "NIN",
            value: form.nin,
        },
        {
            name: "jtb_no",
            label: "JTB No.",
            value: form.jtb_no,
        },
        {
            name: "total_staff",
            label: "No. of Staff",
            value: form.total_staff,
        },
        {
            name: "annual_tax",
            label: "Annual Tax",
            value: form.annual_tax,
        },
    ];
    const _data = [
        {
            org_name: "Brainstorm",
            taxID: "717",
            nin: "176312787128",
            jtb_no: "12908218",
            phone: "0901289128921",
            total_staff: "50",
            annual_tax: "50,000,000.00",
        },
        {
            org_name: "Nour Takaful",
            taxID: "716",
            nin: "31278712812",
            jtb_no: "34908218",
            phone: "0801289128921",
            total_staff: "100",
            annual_tax: "100,000,000.00",
        },
    ];

    // useEffect(() => {
    //   if (org_name.length > 2) {
    //     _fetchApi(
    //       `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=org`,
    //       (resp) => {
    //         if (resp.success) {
    //           setOrgs(resp.data);
    //         }
    //       }
    //     );
    //   }
    // }, [org_name]);
    const new_data =
        // org_name.length
        //   ? assesments.filter((item) => {
        //     return (
        //       item.tax_payer.toLowerCase().includes(org_name.toLowerCase()) ||
        //       item.office_address.toLowerCase().includes(org_name.toLowerCase()) ||
        //       item.phone.includes(org_name)
        //     );
        //   })
        //   : 
        assesments;

    const containerStyle = {
        height: "80vh",
        overflowY: "scroll",
        scrollbarWidth: "thin", // For Firefox
        scrollbarColor: "transparent transparent", // For Firefox
        overflow: "-moz-scrollbars-none",
        zIndex: 1,
    };
    return (
        <div>
            {/* {JSON.stringify({ org_name })} */}
            {/* <RevDashboardCard /> */}
            <Card>
                <Row>
                    <Col className="text-left col-md-2 col-sm-3 col-lg-2" >
                        <Button color="dark" onClick={() => history.goBack()}><FaArrowCircleLeft /> Back</Button>
                    </Col>
                    <Col><h3 className="text-center mt-2" >LIABILITIES</h3></Col>
                </Row>
                <CardHeader>
                    <Row>
                        {/* <Col className="text-right mt-2 col-md-3 col-sm-3 col-lg-3" >Search Organization</Col> */}
                        <Col className="col-md-12 col-sm-12 col-lg-12">
                            {/* <CustomTypeahead
                onInputChange={(e) => {
                  setOrgName(e);
                  setForm((p) => ({
                    ...p,
                    taxID: e
                  }));
                }}
                labelKey={(e) =>
                  `${e.tax_payer}(${e.org_id})`
                }
                className="border border-primary rounded"
                onChange={(e) => {
                  if (e && e[0]?.org_id) {
                    // console.log(JSON.stringify(e[0]))
                    setForm((p) => ({
                      ...p,
                      taxID: e[0]?.org_id
                    }));
                  }
                }}
                clearButton
                col={12}
                // label={`Select Organization`}
                options={assesments}
                placeholder="Search Org. Name | TaxID | Phone No."
              /> */}
                            <Row style={{ display: 'flex', flexDirection: 'row' }}>
                                <Col md={11}><SearchBar
                                    onFilterTextChange={(e) => setOrgName(e)}
                                    filterText={org_name}
                                    placeholder='Search org name || KIRMAS ID'
                                /></Col>
                                <Col sm={3} md={1}><Button onClick={() => setForm((p) => ({ ...p, org_name }))}>Search</Button></Col>
                            </Row>
                        </Col>
                        {/* <Col className="text-right col-md-2 col-sm-2 col-lg-2" md={3}><Button onClick={() => setShowModal(true)}>Add staff</Button></Col> */}
                    </Row>
                </CardHeader>
                <CardBody>
                    <div style={containerStyle}>
                        {/* {JSON.stringify(form)} */}
                        <div hover className="user-table align-items-center pt-0">
                            <Table
                                className="table-zebra table-bordered"
                                size="sm"
                                style={{ width: "100%", border: "1px solid black" }}
                            >
                                <thead>
                                    <tr>
                                        <th>KIRMAS ID</th>
                                        <th>ORG. NAME</th>
                                        {/* <th>TAX YEAR</th> */}
                                        {/* <th>NIN</th> */}
                                        {/* <th>JTB No.</th> */}
                                        <th className="text-center">No. OF STAFF</th>
                                        <th>UNPAID</th>
                                        <th>ANNUAL TAX</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                {assesments.length && !data_status ? (
                                    <tbody>
                                        {new_data.map((item, idx) => (
                                            <tr key={idx}>
                                                <td>{item.org_id}</td>
                                                <td>{item.tax_payer}</td>
                                                {/* <td>{item.payment_year}</td> */}
                                                {/* <td>{item.nin}</td> */}
                                                {/* <td>{item.jtb_no}</td> */}
                                                <td className="text-center">{parseInt(item.total_staff)}</td>
                                                <td className="text-right">{toCurrency(item.total_paid || 0)}</td>
                                                <td className="text-right">{toCurrency(item.total_payable || 0)}</td>
                                                <td>
                                                    <Button size="sm" color="dark" onClick={() => { history.push(`${Routes.OrgLiabilities.path}?org_kirmas_id=${item.org_id}`) }}><FaUserFriends /> Staff</Button>
                                                    {" "} <Button size="sm" color="dark" onClick={() => { history.push(`${Routes.OrgLedger.path}?org_kirmas_id=${item.org_id}`) }}><FaRegEye /> Ledger</Button>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <h3 className="text-danger text-center">{data_status}</h3>
                                )}
                            </Table>
                        </div>
                    </div>
                </CardBody>
                <CustomModal
                    size="lg"
                    toggle={() => {
                        setShowModal(!showModal);
                    }}
                    isOpen={showModal}
                >
                    <Card>
                        <CardHeader>New Staff Form</CardHeader>
                        <CardBody>
                            <CustomForm fields={_form_fields} handleChange={handleChange} />
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button>Submit</Button>
                        </CardFooter>
                    </Card>
                </CustomModal>
            </Card>
        </div>
    );
};

export default AllOrgs;
