import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import moment from 'moment';
import ReceiptHeader from './TermalInvoiceHeader';
import DM_SANS_NORMAL from '../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf';
import DM_SANS_BOLD from '../assets/DM_Sans/static/DMSans_24pt-Bold.ttf';
import DM_SANS_ITALIC from '../assets/DM_Sans/static/DMSans-Italic.ttf';
import { formatNumber } from '../utils';
import QRCode from 'qrcode';
const TermalInvoice = ({ user = {}, data = [], qrcode = '', name = '', location = '', agent = {} }) => {
  const descriptions = data?.map((item) => item?.description);
  const joinedDescriptions = descriptions?.join(', ');
  const total = data.reduce((it, id) => it + parseFloat(id.tax_fee), 0);
  let canvas;
  // For QR Code
  canvas = document.createElement('canvas');
  QRCode.toCanvas(
      canvas,
      `https://verify.kirmas.kn.gov.ng/${data[0]?.reference_number}/tax/type/clearance-certificate/invoice/view`.replace(/\|/g, '%7C'),
  );
  const qr = canvas.toDataURL();
  return (
    <Document>
      <Page size={{ width: 200 }} style={styles.body} wrap={false}>
        <View>
          <ReceiptHeader mda={data[0]?.mda_name || ''} />
          <Text>- - - - - - - - - - - - - - - - - - - - - - - - - - - -</Text>
          <View style={styles.item2}>
            <View style={{ fontWeight: 'bold', fontSize: 12 }}>
              <Text>
                {data[0]?.status === 'success'
                  ? 'ONLINE RECEIPT'
                  : data[0]?.status === 'PAID'
                    ? 'EVIDENCE OF PAYMENT'
                    : 'INVOICE'}
              </Text>
            </View>
            <Text>- - - - - - - - - - - - - - - - - - - - - - - - - - - -</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Terminal No:</Text>
            <View>
              <Text>TILL003</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Invoice Date:</Text>
            <View>
              <Text>{moment(data[0]?.created_at).format('YYYY-MM-DD hh:mm:ss A')}</Text>
            </View>
          </View>

          {data[0]?.status === 'PAID' || data[0]?.status === 'success' ? (
            <View style={styles.item}>
              <Text style={styles.mr5}>Payment Date:</Text>
              <View>
                <Text>{moment(data[0]?.created_at).format('YYYY-MM-DD')}</Text>
              </View>
            </View>
          ) : null}
          <View style={styles.item}>
            <Text style={styles.mr5}>Date:</Text>
            <View>
              <Text>
                {data[0]?.date_from && data[0]?.date_to
                  ? `${moment(data[0]?.date_from).format(' YYYY')} - ${moment(
                    data[0]?.date_to,
                  ).format(' YYYY')}`
                  : moment(data[0]?.transaction_date).format(' YYYY')}
              </Text>
            </View>
          </View>

          <View style={styles.item}>
            <Text style={styles.mr5}>Collection:</Text>
            <View>
              <Text>App-Offline E-Cash</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Location:</Text>
            <View>
              <Text>{data[0]?.department}</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Tax Payer:</Text>
            <View>
              <Text>
                {data[0]?.tax_payer}({data[0]?.taxID})
              </Text>
            </View>
          </View>

          {data[0]?.payer_phone ? (
            <View style={styles.item}>
              <Text style={styles.mr5}>Phone:</Text>
              <View>
                <Text>{data[0]?.payer_phone}</Text>
              </View>
            </View>
          ) : null}
        </View>

        <View style={styles.item2}>
          <Text style={styles.mr5}>Transaction Id:</Text>
          <View style={{ fontWeight: 'bold', fontSize: 12 }}>
            <Text>{data[0]?.reference_number}</Text>
          </View>
          <View>
            <Text>- - - - - - - - - - - - - - - - - - - - -</Text>
          </View>
        </View>
        <View>
          <View>
            <Text style={styles.mr5}>Payment for:</Text>
            <View>
              <Text>{joinedDescriptions}</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Economic Code:</Text>
            <View>
              <Text>{data[0]?.mda_code}</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Item Code:</Text>
            <View>
              <Text>{data[0]?.item_code}</Text>
            </View>
          </View>
        </View>
        <View style={styles.item2}>
          <Text>- - - - - - - - - - - - - - - - - - - - -</Text>
        </View>
        <View>
          <View style={styles.item}>
            <Text style={styles.mr5}>FEE:</Text>
            <View>
              <Text>N{formatNumber(total)}</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Schedule officer:</Text>
            <View>
              <Text>{agent?.name}</Text>
            </View>
          </View>
          <View style={styles.item}>
            <Text style={styles.mr5}>Status:</Text>
            <View>
              <Text>{data[0]?.status === 'success' ? 'Payment Successful' : data[0]?.status}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image src={qr} style={styles.qrcodeImage} />
        </View>
        <View style={styles.goodbyeTextContainer}>
          <Text style={styles.goodbyeText}>Please retain your receipt</Text>
          <Text style={styles.goodbyeText}>Thank You</Text>
        </View>
        <Text style={styles.poweredBy}>Powered by:KANO STATE GOVERNMENT</Text>
        <Text style={styles.poweredBy1}>Developed by:BRAINSTORM IT SOLUTIONS</Text>
      </Page>
    </Document>
  );
};

Font.register({
  family: 'DM_SANS',
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: 'bold',
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: 'italic',
    },
  ],
});

const COL1_WIDTH = 40;
const COL_AMT_WIDTH = 20;
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2;

const styles = StyleSheet.create({
  body: {
    paddingVertical: 5,
    fontSize: 8,
    paddingHorizontal: 10,
    fontFamily: 'DM_SANS',
  },
  image: {
    height: 40,
    width: 40,
  },
  headerContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    flexDirection: 'row',
    marginVertical: 3,
  },

  item2: {
    textAlign: 'center',
  },
  title: {
    fontSize: 8,
    // textAlign: 'center',
    fontFamily: 'DM_SANS',
  },
  subtitle: {
    fontSize: 8,
    fontFamily: 'DM_SANS',
  },
  table: {
    display: 'table',
    width: '100%',
    marginVertical: 6,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableRowTotal: {
    flexDDirection: 'row',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
  },
  tableColAmtHeader: {
    width: COL_AMT_WIDTH + '%',
  },
  qrcodeContainer: {
    textAlign: 'center',
    marginHorizontal: 60,
  },
  qrcodeImage: {
    height: '70px',
    width: '70px',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
  },
  tableColAmt: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
  },
  tableColTotal: {
    width: 2 * COLN_WIDTH + '%',
  },
  tableCellHeader: {
    // marginRight: 5,
    fontWeight: 'bold',
  },
  tableCell: {
    marginVertical: 1,
    // marginRight: 4,
  },
  goodbyeText: {
    fontSize: 8,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  goodbyeTextContainer: {
    marginTop: 2,
  },
  docTitle: {
    marginVertical: 6,
    fontSize: 10,
    fontWeight: 'bold',
  },
  textRight: { textAlign: 'right' },
  textCenter: { textAlign: 'center' },
  mr5: { marginRight: 5 },
  fontWeightBold: { fontWeight: 'bold' },
  grandTotal: {
    borderTopWidth: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    paddingTop: 3,
  },
  mt1: {
    marginTop: 2,
  },
  receiptNo: {
    fontWeight: 'bold',
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
  },
  paymentRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  poweredBy: {
    fontSize: 8,
    marginTop: 6,
    textAlign: 'center',
    fontFamily: 'DM_SANS',
    fontStyle: 'italic',
  },
  poweredBy1: {
    fontSize: 8,
    marginTop: 2,
    textAlign: 'center',
    fontFamily: 'DM_SANS',
    fontStyle: 'italic',
  },
  amtCol: {},
});

export default TermalInvoice;
