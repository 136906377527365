import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Container } from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "../../routes";
import { CustomButton } from "../../components/UI";
import { _fetchApi } from "../../redux/action/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import ReactCodeInput from "react-verification-code-input";

export default () => {
  const [loading, setLoading] = useState();
  const history = useHistory();
  const [result, setResult] = useState("");
  const { recoverPhone = {} } = useSelector((item) => item.auth);
  const handleOnChange = (res) => {
    setResult(res);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (result === recoverPhone.code) {
      _fetchApi(
        `/user/code-verification?phone=${recoverPhone.phone}&code=${recoverPhone.code}`,
        (resp) => {
          console.log(resp);
          if (resp.success) {
            toast.success(resp.message);
            history.push(Routes.ResetPassword.path);
            setLoading(false);
          } else {
            toast.success(resp.message);
            setLoading(false);
          }
        },
        (error) => {
          toast.error(error);
          setLoading(false);
        }
      );
    } else {
      toast.error("The code is not correct, provide a correct code");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (result.length === 6) {
      setLoading(true);
      if (result === recoverPhone.code) {
        _fetchApi(
          `/user/code-verification?phone=${recoverPhone.phone}&code=${recoverPhone.code}`,
          (resp) => {
            console.log(resp);
            if (resp.success) {
              toast.success(resp.message);
              history.push('/reset-password');
              setLoading(false);
            } else {
              toast.error(resp.message);
              setLoading(false);
            }
          },
          (error) => {
            toast.error(error);
            setLoading(false);
          }
        );
      } else {
        toast.error("The code is not correct, provide a correct code");
        setLoading(false);
      }
    }
  }, [history, recoverPhone.code, recoverPhone.phone, result]);
  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                {/* <h3>You're closer to recovering your password.</h3> */}
                <h3>Two-Factor Authentication</h3>
                <p className="mb-4">
                  A message with a verification code has been sent to your
                  phone number({recoverPhone.phone}). Enter the code to continue
                </p>
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4 ">
                    <Form.Label htmlFor="email">Enter Your Code</Form.Label>
                    <ReactCodeInput
                      onChange={handleOnChange}
                      allowedCharacters="numeric"
                      fields={6}
                      autoFocus={true}
                    />
                  </div>
                  <CustomButton
                    loading={loading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    Recover password
                  </CustomButton>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
