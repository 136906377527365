import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowCircleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Modal } from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { _postApi } from "../redux/action/api";
import { CustomButton } from "../components/UI";
import { login } from "../redux/action/auth";
import { Routes } from "../routes";
// import { Input, Modal } from "reactstrap";

export const ResetPass = () => {
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDefault, setShowDefault] = useState(false);
  const toggle = () => setShowDefault(!showDefault);
  const { recoverPhone = {} } = useSelector((item) => item.auth);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.password !== form.confirmPassword) {
      toast.error(
        "Oops! The password and confirm password do not match. Please try again."
      );
    } else {
      setLoading(true);
      _postApi(
        "/user/generate-new-password",
        { ...recoverPhone, password: form.password },
        (resp) => {
          if (resp.success) {
            setShowDefault(true);
            setLoading(false);
          }
        },
        (error) => {
          toast.error("Error Occurred");
          console.log(error);
          setLoading(false);
        }
      );
    }
  };
  const onChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  return (
    <main>
     
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link
              as={Link}
              to={Routes.Signin.path}
              className="text-gray-700"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              sign in
            </Card.Link>
          </p>
          <Row className="justify-content-center">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        value={form.password}
                        onChange={onChange}
                        name="password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        value={form.confirmPassword}
                        name="confirmPassword"
                        onChange={onChange}
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <CustomButton
                    loading={loading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    Reset password
                  </CustomButton>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal as={Modal.Dialog} centered show={showDefault}>
        <Modal.Header>
          <Modal.Title className="h6">
            Your Account recovered successfully{" "}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              //   history.push(Routes.Signin.path);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <h4>
            <span role="img" aria-label="Panda">
              {" "}
              🎉 Congratulation! Your password has been reset. You can now log
              in with your new password. 😃
            </span>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            onClick={() => {
              setLoading(true);
              dispatch(
                login(
                  {
                    password: form.password,
                    username: recoverPhone.phone,
                    history,
                  },
                  () => {
                    history.push(`/selection`);
                    setLoading(false);
                    // setTimeout(() => window.location.reload(), 300);
                  },
                  () => {
                    toast.error("Error occured");
                    setLoading(false);
                  }
                )
              );
            }}
          >
            Log In
          </CustomButton>
          <CustomButton
            variant="link"
            className="text-gray ms-auto"
            onClick={() => {
              //   history.push(Routes.Signin.path);
            }}
          >
            Close
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </main>
  );
};
