import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import ChartComponent from "./dashboard/ChartComponent";
import { useHistory } from "react-router";
import { Routes } from "../routes";
import DaterangeSelector from "./dashboard/DaterangeSelector";
import PieChart from "./dashboard/charts/PieChart";
import { toast } from "react-hot-toast";
import { _fetchApi } from "../redux/action/api";
import { CustomButton } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { getMonth, toCurrency, useQuery } from "../utils";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const Feature = (props) => {
  const { total, name, onClick } = props;

  return (
    <Col xs={12} sm={6} lg={3} className="mb-1">
      <Card
        border="light"
        className="shadow-sm"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <Card.Body>
          <div className="">
            <h6 className="text-center">{name}</h6>
            <h3 className="mb-1 text-center">{total}</h3>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

function MdaReportsDashboard() {
  const history = useHistory();

  const [mdas, setMDAs] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const today = moment().format("YYYY-MM-DD");
  const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
  const q = useQuery();
  const sector = q.get("sector") || "";
  const view_by = q.get("view_by") || "Tax items";

  const [form, setForm] = useState({
    from: oneYearAgo,
    to: today,
    sector,
    view_by,
    mda_code: "",
  });
  //
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total_revenue, setTotalRevenue] = useState(0);
  const [today_revenue, setTodayRevenue] = useState(0);
  const [monthlyRevenues, setMonthlyRevenues] = useState([]);
  const [banklyRevenues, setBanklyRevenues] = useState([]);
  const handleChange = ({ target: { name, value } }) => {
    if (name === "from" || name === "to") {
      setForm((prevState) => ({
        ...prevState,
        [name]: moment(value).format("YYYY-MM-DD"),
      }));
    } else setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  const getMDAs = useCallback(() => {
    _fetchApi(
      "/get/mdas?query_type=all-mda-reports",
      (resp) => {
        if (resp.success) {
          setMDAs(resp.data);
        }
      },
      (err) => {
        console.error(err);
        toast.error("Errorr occured!");
      }
    );
  }, []);

  useEffect(() => {
    getMDAs();
  }, [getMDAs]);

  const getData = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/get/mdas?query_type=bankly-reports`,
      (res) => {
        setLoading(false);
        setBanklyRevenues(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=monthly-reports&start_date=2023-01-01&end_date=2023-12-31`,
      (res) => {
        setLoading(false);
        setMonthlyRevenues(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=total-revenue`,
      (res) => {
        setLoading(false);
        setTotalRevenue(res.data[0]);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=total-revenue&start_date=${today}&end_date=${today}`,
      (res) => {
        setLoading(false);
        setTodayRevenue(res.data[0]);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get-transaction-details?department=${user?.department}&role=${user?.role}&mda_name=${user?.mda_name}&agent_id=${user?.agent_id}&from=${form.from}&to=${form.to}`,
      (res) => {
        setLoading(false);
        setData(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [user, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  const total = data?.reduce((item, d) => item + parseFloat(d.total_amt), 0);

  return (
    <div>
      <h4 className="my-4 text-center fw-bold">MDA Reports Dashboard</h4>
      <Row>
        <Col>
          <CustomButton
            onClick={() => history.push(Routes.DashboardOverview.path)}
          >
            <FontAwesomeIcon icon={faBackward} />
            Back
          </CustomButton>
        </Col>
        <Col></Col>
      </Row>
      <div className="mb-1">
        <DaterangeSelector
          from={form.from}
          to={form.to}
          onChange={handleChange}
        />
      </div>
      <Row>
        <Col md={6}>
          <ChartComponent
            data={monthlyRevenues.map((item) => ({
              ...item,
              name: getMonth(item.month),
              [moment().format("YYYY")]: item.total,
            }))}
            type="bar"
          />
        </Col>
        <Col md={6}>
          <PieChart />
        </Col>
      </Row>
      <Row>
        {mdas.map((a, i) => (
          <Feature
            {...{ name: a.mda_name, total: toCurrency(a.total) }}
            key={i}
            onClick={() =>
              history.push(`${Routes.MDAReport.path}/${a.mda_code}`)
            }
          />
        ))}
      </Row>
    </div>
  );
}

export default MdaReportsDashboard;
