import React, { useState, useEffect, useCallback, useRef } from "react";
import { Col, Row, Button, Table, Modal } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import PaymentsContainer from "./components/PaymentsContainer";
import { SearchBar } from "../../components/UI";
import { formatNumber, toCurrency } from "../../utils";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { WelcomeMessage } from "./LandPayments";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import DaterangeSelector from "../dashboard/DaterangeSelector";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import ExpressAcct from "../ExpressAccount";
import { useDispatch, useSelector } from "react-redux";
import { TAX_ACCOUNT } from "../../redux/action/type";
import { Input, Label } from "reactstrap";
import SelectInput from "../components/SelectInput";
export default function TaxPayments() {
  const _form = {
    type: "",
    month: "",
    org: "",
    amount: "",
    attachment: "",
    sector: "",
    tax_id: "",
    step: 0,
    attachment: null,
    rows: [],
    type_child: "",
    tax_code: "",
    tax_parent_code: "",
    taxes: "",
    sector: "LGA",
    lga_name: "",
  };
  const [tax_payer, setTapayer] = useState("");
  const [showDefault, setShowDefault] = useState(false);
  const [tax_payers, setTaxPayers] = useState([]);
  const toggleModal = () => setShowDefault(!showDefault);
  const [form, setForm] = useState(_form);
  const [filterText, setFilterText] = useState("");
  const [filterLga, setFilterLga] = useState("");
  const [lgaList, setLgaList] = useState([]);
  const [subTaxList, setSubTaxList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const dispatch = useDispatch();
  const { tax_account = {}, tax_accounts = [], user = {} } = useSelector((p) => p.auth);
  const isMobile = useIsPhoneSize();
  // const ref = useRef();

  const getList = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/get/lga-list`,
      (resp) => {
        if (resp.result.length) {
          setLgaList(resp.result);
          setLoading(false);
        }
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, []);
  const getList2 = useCallback(() => {
    setLoading1(true);
    _fetchApi(
      `/kigra-taxes?query_type=select-sector-taxes&sector=LGA`,
      (resp) => {
        if (resp.result.length) {
          setSubTaxList(
            resp.result.map((item) => ({ ...item, checked: false }))
          );
          setLoading1(false);
        }
        setLoading1(false);
      },
      (err) => {
        console.error(err);
        setLoading1(false);
      }
    );
  }, []);
  useEffect(() => {
    getList();
    getList2();
  }, [getList, getList2]);

  const handleAmountChange = ({ target: { value } }, tax) => {
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: value,
          checked: parseInt(value) > 0 ? true : false,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };
  const handleItemSelected = ({ target: { value } }, tax) => {
    let arr = [];
    // alert( value)
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: value,
          checked: parseInt(value) > 0 ? true : false,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };

  const filteredTaxList = filterText.length
    ? subTaxList.filter((item) => {
      return item.title.toLowerCase().includes(filterText.toLowerCase());
    })
    : subTaxList;

  const uniqueTitles = [...new Set(filteredTaxList.map((item) => item.title))];

  const uniqueTaxObjects = uniqueTitles
    .map((title) => {
      const firstItemWithTitle = filteredTaxList.find(
        (item) => item.title === title
      );

      if (!firstItemWithTitle) {
        return null;
      }

      return firstItemWithTitle;
    })
    .filter(Boolean);

  const titleCounts = filteredTaxList.reduce((counts, item) => {
    counts[item.title] = (counts[item.title] || 0) + 1;
    return counts;
  }, {});

  const handleCheckBox = (tax) => {
    let arr = [];
    setForm((p) => ({ ...p, economic_code: tax.economic_code }));
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: item.tax_fee || item.amount,
          sqr_meter: item.checked ? 1 : item.sqr_meter || 1,
          checked: !item.checked,
          tax_parent_code: form.lga_name,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };

  const LGA_LIST = lgaList.filter((lga) =>
    lga.lga_name.toLowerCase().includes(filterLga.toLowerCase())
  );
  const new_form = Object.assign({}, form, { tax_parent_code: form.lga_name });

  const handleTaxRateChenged = (e, tax) => {
    const { value } = e.target;
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          sqr_meter: value,
          amount: parseFloat(value <= 0 ? 1 : value) * Math.max(tax.tax_fee, 1),
          checked: parseInt(value) > 0 ? true : false,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };
  const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);
  const handleFocus = (name) => {
    setTapayer(name);
    setTimeout(() => {
      toast.success("You can now close and search the tax payer by name or ID");
      toggleModal();
      // myRef.current.focus();
    }, 2000);
  };
  const handleDateRangeChange = ({ target: { name, value } }) => {
    if (name === "from") {
      setForm((p) => ({ ...p, from: value }));
    } else if (name === "to") {
      setForm((p) => ({ ...p, to: value }));
    }
  };
  const handleAddList = (cb = (f) => f) => {
    if (!form.from || !form.to) {
      toast.error("Please select date");
    } else {
      cb();
    }
  };
  const getTaxPayers = useCallback(() => {
    if (tax_payer.length > 2) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    } else if (tax_payer.length === 0) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    }
  }, [tax_payer]);

  useEffect(() => {
    getTaxPayers();
  }, [getTaxPayers]);
  return (
    <PaymentsContainer
      open={open}
      handleAddList={handleAddList}
      openModal={openModal}
      form={new_form}
      handleTaxFeeChecked={handleCheckBox}
      search={
        <SearchBar
          filterText={filterLga}
          onFilterTextChange={(value) => setFilterLga(value)}
          placeholder="Search lga"
          className="bg-dark text-white"
        />
      }
      menu={
        loading ? (
          <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
            <p>
              <Skeleton count={10} height={50} />
            </p>
          </SkeletonTheme>
        ) : (
          <>
            {LGA_LIST.map((lga, idx) => (
              <Button
                key={idx}
                size="sm"
                onClick={() => {
                  setForm((p) => ({
                    ...p,
                    lga_name: lga.lga_name,
                    tax_parent_code: lga.lga_name,
                    geo_code: lga.geo_code,
                  }));
                  openModal();
                }}
                className="text-left btn-block"
                value={lga.lga_name}
              >
                {lga.lga_name}
              </Button>
            ))}
          </>
        )
      }
      selected_taxes={subTaxList
        .filter((item) => item.checked)
        .map((item) => ({
          ...item,
          tax_fee: item.amount,
          mda_name: form.lga_name,
          org_name: form.lga_name,
          org_code: form.geo_code,
          economic_code: item.economic_code,
          sector: form.sector,
        }))}
    >
      {/* {JSON.stringify(form)} */}
      {/* {isMobile ? ( */}
      <div className="m-0 d-flex text-center justify-content-center">
        <div>{form.lga_name ? <h5 className="text-center">LGA:</h5> : ""}</div>
        <div>
          <h5 className="text-center">{form.lga_name.toUpperCase()}</h5>
        </div>
      </div>
      {/* ) : (
        <div className="d-flex  text-center">
          {form.lga_name ? <h5 className="text-">LGA:</h5> : ""}
          <h5 className="text-left">{form.lga_name.toUpperCase()}</h5>
        </div>
      )} */}
      <Row className="m-0">
        <Col md={6}>
          {form.economic_code ? (
            <h5 className="text-right">Revenue Code:</h5>
          ) : (
            ""
          )}
        </Col>
        <Col>
          <h5 className="text-left">{form.economic_code}</h5>
        </Col>
      </Row>
      {form.lga_name === "" ? (
        <WelcomeMessage />
      ) : (
        <Row className="m-0 p-0" md={9}>
          <Col style={{ paddingBottom: "50px" }} className=" p-3 shadow ">
            <DaterangeSelector
              from={form.from}
              to={form.to}
              handleChange={handleDateRangeChange}
            />
            <Col md={12}>
              {user.role !== "user" ? (
                <Row>
                  <Col md={6} className="mb-2">
                    <CustomTypeahead
                      onInputChange={(e) => {
                        setTapayer(e);
                        console.log(e, "TYPED");
                      }}
                      labelKey={(e) =>
                        `${e.account_type === "org" ? e.org_name : e.name}(${e.taxID
                        }) ${e.phone ? `:${e.phone || e.office_phone}` : ""}`
                      }
                      onChange={(e) => {
                        if (e && e[0]?.id) {
                          dispatch({
                            type: TAX_ACCOUNT,
                            payload: e[0],
                          });
                        }
                      }}
                      // onInputChange={(e) => {
                      //   setTapayer(e);
                      // }}
                      selected={tax_account.taxID?[tax_account]:[]}
                      clearButton
                      col={12}
                      label={`Select Tax payer`}
                      options={tax_payers}
                      placeholder="Search Name | TaxID | Phone No."
                    />
                  </Col>
                  <Col>
                    <br />
                    <Button
                      onClick={() => toggleModal()}
                      className="bg-white mt-2 ml--2 text-dark"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <Label>Tax Payer</Label>{" "}
                    <SelectInput
                      options={tax_accounts.map((item) => [
                        item.org_name || item.name
                      ])}
                      defaultValue={tax_account.org_name || tax_account.name}
                      value={
                        tax_account.org_name || tax_account.name
                      }
                      name="tax_payer"
                      onChange={(e) => {
                        console.log(e.target.value);
                        let val = e.target.value;
                        let sel = tax_accounts.filter(
                          (item) => item.org_name == val || item.name == val
                        );
                        console.log(sel);
                        dispatch({
                          type: TAX_ACCOUNT,
                          payload: sel[0],
                        });
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <div className="mb-2">
              <SearchBar
                filterText={filterText}
                onFilterTextChange={(value) => setFilterText(value)}
                placeholder="Search revenue"
              />
            </div>

            <Row>
              <Col>
                {uniqueTaxObjects && uniqueTaxObjects.length > 0 ? (
                  <table
                    size="sm"
                    style={{ width: "100%", border: "1px solid black" }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderWidth: 1,
                            borderColor: "black",
                            fontWeight: "bold",
                            margin: "50px",
                          }}
                          className=" m-5 p-2"
                          width="5%"
                        >
                          <center> SN</center>
                        </th>
                        <th
                          style={{
                            borderWidth: 1,
                            borderColor: "black",
                            fontWeight: "bold",
                          }}
                          width="40%"
                          className="m-4"
                        >
                          <center> Description</center>
                        </th>

                        {uniqueTaxObjects.length &&
                          uniqueTaxObjects.some((tax) => tax.uom === "per m2") ? (
                          <th
                            style={{
                              borderWidth: 1,
                              borderColor: "black",
                              fontWeight: "bold",
                            }}
                            width="15%"
                            className="text-right"
                          >
                            <center> No of Activity</center>
                          </th>
                        ) : null}

                        <th
                          style={{
                            borderWidth: 1,
                            borderColor: "black",
                            fontWeight: "bold",
                          }}
                          width="15%"
                          className="text-right"
                        >
                          <center> Amount</center>
                        </th>

                        <th
                          style={{
                            borderWidth: 1,
                            borderColor: "black",
                            fontWeight: "bold",
                          }}
                          width="15%"
                          className="text-right"
                        >
                          <center> Action</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {uniqueTaxObjects?.map((item, idx) => (
                        <tr key={idx}>
                          <td
                            className="text-center"
                            style={{ borderWidth: 1, borderColor: "black" }}
                          >
                            {idx + 1}
                          </td>
                          <td
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="m-5 p-2"
                          >
                            {item.title}
                          </td>
                          {uniqueTaxObjects.length &&
                            uniqueTaxObjects.some(
                              (tax) => tax.uom === "per m2"
                            ) ? (
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                              className="text-right px-2"
                            >
                              {item.uom === "per m2" ? (
                                <input
                                  onChange={(e) =>
                                    handleTaxRateChenged(e, item)
                                  }
                                  value={item.sqr_meter}
                                  type="number"
                                  name="sqr_meter"
                                  className="text-center"
                                />
                              ) : null}
                            </td>
                          ) : null}
                          <td
                            className="text-right m-5 p-2"
                            style={{ borderWidth: 1, borderColor: "black" }}
                          >
                            {titleCounts[item.title] > 1 && item.checked ? (
                              <select
                                onChange={(e) => {
                                  handleItemSelected(e, item);
                                }}
                              >
                                {filteredTaxList
                                  .filter(
                                    (dataItem) => dataItem.title === item.title
                                  )
                                  .map((dataItem) => (
                                    <option
                                      key={dataItem.id}
                                      value={dataItem.tax_fee}
                                    >
                                      {dataItem.uom}
                                    </option>
                                  ))}
                              </select>
                            ) : null}
                            {item.amount <= 0 || item.tax_fee <= 0 ? (
                              <input
                                value={item.amount}
                                onChange={(e) => {
                                  handleAmountChange(e, item);
                                }}
                                type="number"
                                style={{ width: "80px" }}
                                name="tax_fee"
                                className="p-0 m-0 text-center"
                              />
                            ) : item.amount > 0 ? (
                              formatNumber(item.amount)
                            ) : (
                              formatNumber(item.tax_fee)
                            )}{" "}
                          </td>

                          <td
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="text-center"
                          >
                            <input
                              className="large-check"
                              onChange={(e) => handleCheckBox(item)}
                              checked={item.checked}
                              value={item.id}
                              type="checkbox"
                              name="taxes"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Modal as={Modal.Dialog} centered show={showDefault} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">EXPRESS TAX PAYER FORM</Modal.Title>
          <Button
            variant="danger"
            className="bg-danger text-white"
            aria-label="Close"
            onClick={toggleModal}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ExpressAcct isModal={true} handleFocus={handleFocus} />
        </Modal.Body>
      </Modal>
    </PaymentsContainer>
  );
}
