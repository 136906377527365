import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { FaPen } from 'react-icons/fa';
import moment from 'moment';
import { _fetchApi, _postApi } from "../../redux/action/api";
import { formatNumber } from '../../utils';
import { Card, Table } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

const NewConfigModal = ({ isOpen, toggle, onSave, config }) => {
  const currentYear = moment().format('YYYY') - 1;
  const years = [];
  for (let year = currentYear; year <= currentYear + 2; year++) {
    years.push(year);
  }

  const [formData, setFormData] = useState({
    year: currentYear + 1,
    amountPerPoint: '',
    incentivePerPoint: '',
  });

  useEffect(() => {
    if (config) {
      setFormData({
        year: config.year,
        amountPerPoint: config.points_per_million,
        incentivePerPoint: config.incentive_per_point,
      });
    }
  }, [config]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    onSave(formData, config ? config.id : null);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{config ? 'Edit Config' : 'New Config'}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="year">Year</Label>
            <Input type="select" name="year" id="year" value={formData.year} onChange={handleChange}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="amountPerPoint">Amount per Point</Label>
            <Input type="number" name="amountPerPoint" id="amountPerPoint" value={formData.amountPerPoint} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label for="incentivePerPoint">Incentive per Point</Label>
            <Input type="number" name="incentivePerPoint" id="incentivePerPoint" value={formData.incentivePerPoint} onChange={handleChange} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const IncentiveConfig = () => {
  const [modal, setModal] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(null);

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    _fetchApi('/select/incentive-config',
      response => {
        console.log('Fetched response:', response);
        if (response.success && response.data && response.data.length) {
          setConfigs(response.data);
        } else {
          console.error('Unexpected response structure:', response);
        } 
      },
      error => {
        setConfigs([]);
        console.error('Error fetching configs:', error);
      }
    );
  };

  const toggle = () => setModal(!modal);

  const handleSave =  (formData, id) => {
      if (id) {
        _postApi('/update/incentive-config', {
          query_type: 'update',
          year: formData.year,
          min_collection: 1000000.00,
          points_per_million: formData.amountPerPoint,
          incentive_per_point: formData.incentivePerPoint,
          status: 'active',
        },()=>{
          toast.success('Submitted')
        },
        (error)=>{
          console.error(error);
          console.error('Error')
        }
        );
      }
      const handleEdit = async () => {
        _postApi('/incentive-config', {
          query_type: 'insert',
          year: formData.year,
          min_collection: 1000000.00,
          points_per_million: formData.amountPerPoint,
          incentive_per_point: formData.incentivePerPoint,
          status: 'active',
        },()=>{
          toast.success('Submitted')
        },
        (error)=>{
          console.error(error);
          console.error('Error')
        }
        );
      }
  };

  const handleEdit = (config) => {
    setSelectedConfig(config);
    toggle();
  };

  const history = useHistory()

  return (
    <Card>
        <Card.Header>
          <Row>
            <Col  className='text-left'>
      <Button color="primary" onClick={() => {history.back() }}>
      Back
      </Button>
            </Col>
            <Col className='text-right'>
      <Button color="primary" onClick={() => { setSelectedConfig(null); toggle(); }}>
      New Setup
      </Button></Col>
          </Row>
        </Card.Header>
        <Card.Body>
      <Table responsive>
        <thead>
          <tr>
            <th>Year</th>
            <th>Minimum Collection</th>
            <th>Amount per Point</th>
            <th>Incentive per Point</th>
            <th>Status</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {configs.length > 0 ? configs.map((config) => (
            <tr key={config.id}>
              <td>{config.year}</td>
              <td className='text-right'>{formatNumber(config.min_collection)}</td>
              <td  className='text-center'>{config.points_per_million}</td>
              <td className='text-right'>{formatNumber(config.incentive_per_point)}</td>
              <td>{config.status}</td>
              <td><FaPen onClick={() => handleEdit(config)} /></td>
            </tr>
          )) : (
            <tr>
              <td colSpan="6" className="text-center">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
      <NewConfigModal isOpen={modal} toggle={toggle} onSave={handleSave} config={selectedConfig} />
      </Card.Body>
    </Card>
  );
};

//Naibawa 332332

export default IncentiveConfig;