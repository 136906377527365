import React from "react";
import { Modal, Table, Button } from "@themesberg/react-bootstrap";

function SelectedTaxModal({
  showModal,
  handleCloseModal,
  selectedOption,
  cartItems,
  formData,
}) {
  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Selected Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Select Tax</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {selectedOption && (
              <tr>
                <td>
                  {selectedOption.tax_parent_code}-{selectedOption.title}
                </td>
                <td>{formData.dateFrom}</td>
                <td>{formData.dateTo}</td>
                <td>{formData.amount}</td>
              </tr>
            )}
            {cartItems.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.dateFrom}</td>
                <td>{item.dateTo}</td>
                <td>{item.amount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectedTaxModal;
