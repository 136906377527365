import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row, Spinner, Table } from 'reactstrap';
import { _postApi } from '../../redux/action/api';
import moment from 'moment';
import { Card } from '@themesberg/react-bootstrap';
import { formatNumber } from '../../utils';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


const StarRating = ({ value }) => {
    const maxStars = 5;
    const starsPerStep = 20; // 25% per star
    const filledStars = Math.floor(value / starsPerStep);
    const emptyStars = maxStars - filledStars;
  
    return (
      <div style={{ display: 'inline-flex' }}>
        {[...Array(filledStars)].map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStar} size="2x" color="yellow" />
        ))}
        {[...Array(emptyStars)].map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStar} size="2x" color="gray" />
        ))}
      </div>
    );
  };  

const TeamRevenueTarget = () => {
    const today = moment();

    const currentMonth = today.format('MMMM');

    const [form, setForm] = useState({
        query_type: 'station-performance',
        type: "daily",
        month: today.format("MM"),
        quarter: Math.ceil(today.format("MM") / 3).toString(),
        year: today.format("YYYY"),
        from: today.format("YYYY-MM-DD"),
        to: today.format("YYYY-MM-DD"),
        selectedMonth: currentMonth,
        selectedYear: today.format('YYYY')
    });
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [budgeted, setBudgeted] = useState({});

    useEffect(() => {
        if (form.selectedMonth) {
            setLoading(true)
            _postApi('/revenue/targets',
                {
                    month: form.selectedMonth,
                    year: form.selectedYear,
                    query_type: 'select-collections'
                },
                (response) => {
                    setLoading(false)
                    if (response.success && response.data.length) {
                        console.log('Data fetched:', response);
                        setList(response.data);
                        setBudgeted(response.data.find(x => x.tax_station === 'General'));
                    } else {
                        setList([]);
                    }
                },
                (error) => {
                    setLoading(false)
                    console.error('Error fetching data:', error);
                    setList([]);
                    toast.error('Error fetching data');
                },
                () => {
                    console.log('Empty response');
                    setList([]);
                }
            );
        }
    }, [form.selectedMonth, form.selectedYear])

    const handleMonthChange = (selectedMonth) => {
        const selectedYear = moment().year();
        setForm({
            selectedMonth: selectedMonth,
            selectedYear: selectedYear,
        });
    };

    const nformatNumber = (num) => {
        return new Intl.NumberFormat().format(num);
    };
    return (
        <Card>
            {/* {JSON.stringify(staffPerformance)} */}
            <Card.Header>
                <Row>
                    <Col ><h3 className='text-center'>COLLECTION LEADERBAORD</h3></Col>
                    {/* <Col className="text-right">
            <ButtonGroup>
              <Button
                color={form.query_type === 'staff-performance' ? 'primary' : 'light'}
                onClick={() => setForm((p) => ({ ...p, query_type: 'staff-performance' }))}
              >
                Staff RatingLeaderboard
              </Button>
              <Button
                color={form.query_type === 'station-performance' ? 'primary' : 'light'}
                onClick={() => setForm((p) => ({ ...p, query_type: 'station-performance' }))}
              >
                Stations RatingLeaderboard
              </Button>
            </ButtonGroup>
          </Col> */}
                </Row>
            </Card.Header>
            <Card.Body>
                {loading && <Spinner />}
                <Row>

                    {/* Budgeted header */}

                    {/* Month Selection */}
                    <FormGroup>
                        <Label for="monthSelect">Select Month</Label>
                        <Input
                            type="select"
                            id="monthSelect"
                            defaultValue={currentMonth} // Set the current month as the default value
                            onChange={(e) => handleMonthChange(e.target.value)}
                        >
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                        </Input>
                        {budgeted?.budgeted_target > 0 && (
                            <p className="text-center mt-2">
                                Revenue Budgeted for {budgeted?.month} {budgeted?.year}: <strong>{formatNumber(budgeted.budgeted_target)}</strong>
                            </p>
                        )}
                    </FormGroup>
                </Row>
                {/* <RatingLeaderboard
            data={stationPerformance}
            title="Station Performance"
            query_type={form.query_type}
            form={form}
            setForm={setForm}
          />  */}

                {/* Table */}
                <Table striped>
                    <thead>
                        <tr>
                            <th>Tax Station</th>
                            <th>Revenue Target</th>
                            <th>Revenue Collected</th>
                            <th>RANK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list
                            .filter(item => item.tax_station !== 'General')
                            .map((item, index) => (
                                <tr key={index}>
                                    <td>{item.tax_station}</td>
                                    <td className='text-right'>{formatNumber(item.budgeted_target)}</td>
                                    <td className='text-right'>{formatNumber(item.total_revenue)}</td>
                                    <td><StarRating value={(item.total_revenue /item.budgeted_target) * 100} /> </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Card.Body>

        </Card>
    );
};

export default TeamRevenueTarget;