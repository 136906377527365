import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js"; // Import convertToRaw
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css"

export default function ContentArea({ editorState, setData  }) {
  const handleEditorChange = (newState) => {

    setData(newState); // Update the editor state
    const rawContentState = convertToRaw(newState.getCurrentContent());
  // setData(rawContentState); 
    // Log plain text

  // Convert raw content to HTML
  const htmlContent = draftToHtml(rawContentState);
  // console.log("HTML Content:", htmlContent);

  };

  return (
    <div className="content-area">
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editor"
        onEditorStateChange={setData}
      />
    </div>
  );
}
