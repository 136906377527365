import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import logo from '../assets/img/kano.png';
import logo2 from '../assets/img/kigra.jpg';
import { formatNumber } from '../utils';
import moment from 'moment';
import DM_SANS_NORMAL from '../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf';
import DM_SANS_BOLD from '../assets/DM_Sans/static/DMSans_24pt-Bold.ttf';
import DM_SANS_ITALIC from '../assets/DM_Sans/static/DMSans-Italic.ttf';
import QRCode from 'qrcode';

Font.register({
    family: 'DM_SANS',
    fonts: [
        { src: DM_SANS_NORMAL, fontWeight: 700 },
        { src: DM_SANS_BOLD, fontStyle: 'bold' },
        { src: DM_SANS_ITALIC, fontStyle: 'italic' },
    ],
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '20px',
        fontSize: '12px',
        fontFamily: 'DM_SANS',
    },
    header: {
        textAlign: 'center',
        fontSize: '25px',
        marginTop: -40,
    },
    logo: {
        width: '50px',
        height: '50px',
    },
    logo2: {
        textAlign: 'right',
        width: '50px',
        height: '50px',
    },
    logo1: {
        width: '300px',
        height: '300px',
    },
    watermark: {
        opacity: 0.1,
        position: 'absolute',
        top: 200,
        left: 170,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemContainer: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #000',
    },
    itemContainer2: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    qrcodeContainer: {
        textAlign: 'center',
        marginTop: '0px',
        alignSelf: 'center',
    },
    qrcodeImage: {
        maxWidth: '120px',
        height: '120px',
    },
    col1: {
        width: '30%',
    },
    hederContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    logoRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        marginTop: '-12px',
    },
    itemcenter: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 12,
    },
    poweredBy: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    poweredBy1: {
        position: 'absolute',
        fontSize: 9,
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    poweredBy2: {
        position: 'absolute',
        fontSize: 9,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

const GenericInvoicePdf = ({ data = [], user = {}, widthHeader = true }) => {
    const [qr, setQr] = useState('');

    useEffect(() => {
        const canvas = document.createElement('canvas');
        QRCode.toCanvas(
            canvas,
            `https://verify.kirmas.kn.gov.ng/${data[0]?.reference_number}/tax/type/clearance-certificate/invoice/view`.replace(
                /\|/g,
                '%7C'
            )
        ).then(() => {
            setQr(canvas.toDataURL());
        });
    }, [data]);

    // Group items by MDA
    const groupedItems = data.reduce((acc, item) => {
        if (!acc[item.mda_name]) {
            acc[item.mda_name] = [];
        }
        acc[item.mda_name].push(item);
        return acc;
    }, {});

    const renderMDAItems = (items, mdaName) => (
        <View key={mdaName}>
            <View style={{ ...styles.itemContainer2, fontWeight: '900', color: '#000', fontSize: 15 }}>
                <Text style={{ width: '8%' }}>{items[0]?.sector === 'LGA' ? 'LGA:' : 'MDA:'}</Text>
                <Text style={{ textAlign: 'left' }}>{mdaName}</Text>
            </View>
            <View style={styles.itemContainer}>
                <Text
                    style={{
                        width: '10%',
                        textAlign: 'center',
                        borderRight: '1px solid #000',
                    }}
                >
                    S/N
                </Text>
                <Text
                    style={{
                        textAlign: 'center',
                        width: '70%',
                        borderRight: '1px solid #000',
                    }}
                >
                    Description
                </Text>
                <Text
                    style={{
                        textAlign: 'center',
                        width: '20%',
                        borderRight: '1px solid #000',
                    }}
                >
                    Amount (N)
                </Text>
            </View>
            {items?.map((item, idx) => (
                <View key={idx} style={styles.itemContainer}>
                    <Text
                        style={{
                            width: '10%',
                            textAlign: 'center',
                            borderRight: '1px solid #000',
                        }}
                    >
                        {idx + 1}.
                    </Text>
                    <Text
                        style={{
                            textAlign: 'justify',
                            width: '70%',
                            borderRight: '1px solid #000',
                        }}
                    >
                        {item?.description}
                    </Text>
                    <Text
                        style={{
                            width: '20%',
                            borderRight: '1px solid #000',
                            textAlign: 'right',
                        }}
                    >
                        {formatNumber(item?.tax_fee)}
                    </Text>
                </View>
            ))}
            <View style={[{ display: 'flex', flexDirection: 'row', marginTop: 1 }, styles.itemContainer]}>
                <Text style={{ width: '10%', textAlign: 'center' }}></Text>
                <Text
                    style={{
                        width: '70%',
                        borderRight: '1px solid #000',
                        textAlign: 'right',
                    }}
                >
                    Total
                </Text>
                <Text
                    style={{
                        width: '20%',
                        borderRight: '1px solid #000',
                        textAlign: 'right',
                    }}
                >
                    {formatNumber(items.reduce((sum, item) => sum + parseFloat(item?.tax_fee), 0))}
                </Text>
            </View>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {widthHeader ? (
                    <View>
                        <View style={styles.watermark}>
                            <Image src={logo} style={styles.logo1} />
                        </View>
                        <View>
                            <View style={styles.logoRow}>
                                <View>
                                    <Image src={logo} style={styles.logo} />
                                </View>
                                <View>
                                    <Image src={logo2} style={styles.logo2} />
                                </View>
                            </View>
                            <Text style={styles.header}>{'Kano State Government'.toUpperCase()}</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    marginTop: '15px',
                                    marginBottom: 10,
                                    fontFamily: 'DM_SANS',
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                }}
                            >
                                KANO INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM (KIRMAS)
                            </Text>
                        </View>
                    </View>
                ) : null}
                <View style={styles.hederContainer}>
                    <View style={styles.hederContainer}>
                        <View style={{ width: '79%' }}>
                            <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>Invoice Date:</Text>
                                <Text>{moment(data[0]?.created_at).format('YYYY/MM/DD HH:MM:SS:A')}</Text>
                            </View>
                            <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>Tax ID:</Text>
                                <Text>{data[0]?.user_id}</Text>
                            </View>
                            {user?.org_tin || user?.tin ? (
                                <View style={styles.itemContainer2}>
                                    <Text style={styles.col1}>TIN No:</Text>
                                    <Text>{user?.org_tin || user?.tin}</Text>
                                </View>
                            ) : null}
                            {user?.org_nin || user?.nin ? (
                                <View style={styles.itemContainer2}>
                                    <Text style={styles.col1}>NIN:</Text>
                                    <Text>{user?.org_nin || user?.nin}</Text>
                                </View>
                            ) : null}
                            <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>Ref No.:</Text>
                                <Text>{data[0]?.reference_number}</Text>
                            </View>
                            <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>Name:</Text>
                                <Text>{data[0]?.tax_payer || user?.org_name || user?.name}</Text>
                            </View>
                            <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>Mobile No:</Text>
                                <Text>{data[0]?.payer_phone}</Text>
                            </View>
                            {data[0]?.department && data[0]?.description !== data[0]?.department ? (
                                <View style={styles.itemContainer2}>
                                    <Text style={styles.col1}>Department:</Text>
                                    <Text>{data[0]?.department}</Text>
                                </View>
                            ) : null}
                            {data[0]?.status === 'success' ? (
                                <View style={styles.itemContainer2}>
                                    <Text style={styles.col1}>Payment Date:</Text>
                                    <Text>{moment(data[0]?.paymentdate).format('YYYY/MM/DD')}</Text>
                                </View>
                            ) : data[0]?.status === 'PAID' ? (
                                <View style={styles.itemContainer2}>
                                    <Text style={styles.col1}>Payment Date:</Text>
                                    <Text>{moment(data[0]?.paymentdate).format('YYYY/MM/DD')}</Text>
                                </View>
                            ) : null}
                            {/* <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>{data[0]?.sector === 'LGA' ? 'LGA:' : 'MDA:'}</Text>
                                <Text>{data[0]?.mda_name}</Text>
                            </View> */}
                            {/* <View style={styles.itemContainer2}>
                                <Text style={styles.col1}>Invoice for:</Text>
                                <Text>{data[0]?.description}</Text>
                            </View> */}
                            {/* <View style={styles.itemContainer2}>
                                {data[0]?.date_from && data[0]?.date_to ? <Text style={styles.col1}>Period:</Text> : null}
                                <Text>
                                    {data[0]?.date_from && data[0]?.date_to
                                        ? `${moment(data[0]?.date_from).format(
                                            'D  MMMM, YYYY',
                                        )} - ${moment(data[0]?.date_to).format('D  MMMM, YYYY')}`
                                        : moment(data[0]?.transaction_date).format('D  MMMM, YYYY')}
                                </Text>
                            </View> */}
                        </View>
                        <View style={{ width: '40%' }}>
                            <View style={styles.qrcodeContainer}>
                                <Image src={qr} style={styles.qrcodeImage} />
                                <View style={{
                                        alignItems: 'center',
                                        marginTop: '5px',
                                        fontSize: 15,
                                        marginBottom: '30px',
                                        }}>
                                <Text
                                    style={{
                                        fontFamily: 'DM_SANS',
                                        textDecoration: 'underline',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {
                                    data[0]?.status === 'success'
                                        ?  'ONLINE RECEIPT'
                                        : data[0]?.status === 'PAID'
                                        ?'EVIDENCE OF PAYMENT'
                                             :'INVOICE'
                                            }
                                </Text>
                            </View>
                            </View>
                        </View>
                    </View>
                   
                </View>
                <View style={{...styles.hederContainer, fontSize:15}}>
                        <Text
                            style={{
                                textAlign: 'center',
                                marginTop: '-25px',
                                marginLeft: '80%',
                                fontSize: 12,
                            }}
                        >
                            Total:
                        </Text>
                        <Text
                           style={{
                            textAlign: 'center',
                            marginTop:  '-25px',
                            fontSize: 12,
                        }}> {formatNumber(data.reduce((sum, item) => sum + parseFloat(item?.tax_fee), 0))}</Text>
                    </View>
                {Object.entries(groupedItems).map(([mdaName, items]) => renderMDAItems(items, mdaName))}
                <Text style={styles.poweredBy}>Powered by: Kano State Internal Revenue Service (KIRS)</Text>
                <Text style={styles.poweredBy1}>Designed by: KIRS IT Department</Text>
                <Text style={styles.poweredBy2}>Generated by: KIRS Billing System</Text>
            </Page>
        </Document>
    );
};

export default GenericInvoicePdf;
