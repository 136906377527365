import React from 'react'
import { Card, CardBody, CardTitle, FormGroup, Label, Input, Button } from 'reactstrap';
function Payee() {
  return (
    <div>
      <Card>
      <CardTitle tag="h5" className="bg-light p-3 mb-1">Payee-Salary Type</CardTitle>
      <CardBody>
     
        <FormGroup>
          <Label for="salaryType">Salary Type</Label>
          <Input type="select" name="select" id="salaryType">
            <option>Hourly</option>
            <option>Daily</option>
            <option>Weekly</option>
            <option>Monthly</option>
            <option>Yearly</option>
          </Input>
        </FormGroup>
        <div className="d-flex justify-content-around">
          <Button className='w-25'  style={{ backgroundColor: "#262B40", color: "white", border: "none", boxShadow: "none" }}color="primary">Back</Button>
          <Button  className='w-25' style={{ backgroundColor: "#262B40", color: "white", border: "none", boxShadow: "none" }} color="primary">Next</Button>
        </div>
      </CardBody>
    </Card>
    </div>
  )
}

export default Payee 