import React, { useRef } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Col, Row } from "@themesberg/react-bootstrap";

import { TransactionsTable } from "../components/Tables2";

import DaterangeSelector from "./dashboard/DaterangeSelector";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { _fetchApi } from "../redux/action/api";
import { toast } from "react-hot-toast";
import { useCallback } from "react";
import { useQuery } from "../utils";
import { SearchBar } from "../components/UI";

import { downloadExcel } from "react-export-table-to-excel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LegderPDF from "../components/LegderPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import PrintInvoice from "./PrintInvoice";
import PaymentInvoice from "./PaymentInvoice";

export default ({ taxPayer = {}, viewOnly = false }) => {
  const tableRef = useRef(null);
  const today = moment().format("YYYY-MM-DD");
  const { tax_account, user } = useSelector((s) => s.auth);
  const [trx,   setTrx] = useState([]);
  const [refNo, setRef] = useState(null);
  const [loading,setLoading] = useState(false);
  const q = useQuery();
  const trx_ref = q.get("trx_ref");
  const from = q.get('from')
  const to = q.get('to')
  const _form = {
    from: from?? moment(today).add(-1, "days").format("YYYY-MM-DD"),
    to: to ??  moment().format("YYYY-MM-DD"),
    user_id: user.id,
  };
  const [form, setForm] = useState(_form);
  const [filterText, setFilterText] = useState("");
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getTrx = useCallback(() => {
    setLoading(true)
    if (user.id > 1) {
      _fetchApi(
        `/transactions/retrieve?query_type=view_agent_history&agent_id=${user.id}&start_date=${form.from}&end_date=${form.to}`,
        (resp) => {
          setLoading(false)
          if(resp.success){
          setTrx(resp.data);
          }
        },
        (err) => {
          setLoading(false)
          setTrx([]);
          console.error(err);
          toast.error("Failed to retrieve transactions");
        }
      );
    }
  }, [form.from, form.to, user.id, trx_ref]);

  useEffect(() => {
    getTrx();
  }, [getTrx]);

  const getUser = useCallback(() => {
    if (taxPayer.taxID && !form.user_id) {
      setForm((p) => ({ ...p, user_id: taxPayer.taxID }));
    } else if (user.taxID && !form.user_id) {
      setForm((p) => ({
        ...p,
        user_id: user.taxID,
      }));
    }
  }, [user, taxPayer, form.user_id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const filtered_trx = filterText.length
    ? trx.filter(
        (item) =>
          item.description.toLowerCase().includes(filterText.toLowerCase()) ||
          item?.transaction_id.toString().includes(filterText)
      )
    : trx;

  const header = [
    "SN",
    "DATE",
    "Ref No.",
    "NIN",
    "TIN",
    "TAX ID",
    "DESCRIPTION",
    "AMOUNT",
    "STATUS",
  ];
  const RECORDS = trx?.length
    ? trx.map((item, idx) => ({
        id: idx + 1,
        date: item.transaction_date,
        ref_no: item.reference_number,
        nin: taxPayer.nin ? taxPayer.nin : tax_account?.nin,
        tin: taxPayer.tin ? taxPayer.tin : tax_account?.tin,
        tax_id: taxPayer.taxID ? taxPayer.taxID : tax_account?.taxID,
        description: item.description,
        amount: Math.max(item.dr),
        status: item.status,
      }))
    : [];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "kirmas-user_ledger",
      sheet: "user_ledger",
      tablePayload: {
        header,
        body: RECORDS.filter((_) => _.amount > 0),
      },
    });
  }
  const payer = taxPayer.id ? taxPayer : tax_account;

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        {refNo ? null : (
          <div className="d-block mb-4 mb-md-0">
            <h4>Transactions</h4>
          </div>
        )}
        {refNo ? (
          <Row
            className="w-100"
            style={{ border: "1px solid black", height: "1000px !important" }}
          >
            <Col
              md={12}
              className="w-100 h-100"
              style={{ height: "1000px !important" }}
            >
              <Button onClick={() => setRef(null)} className="btn-warning">
                Close
              </Button>
              <PaymentInvoice refNo={refNo} />
            </Col>
          </Row>
        ) : (
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <PDFDownloadLink
                document={
                  <LegderPDF
                    user={payer}
                    data={RECORDS.filter((_) => _.amount > 0)}
                  />
                }
                fileName={`ledger-${moment().format("YYYYMMDDMMSS")}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      style={{ borderRadius: "10px 0px 0px 10px" }}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="sm"
                        className="me-2"
                      />
                      Export PDF
                    </Button>
                  )
                }
              </PDFDownloadLink>

              <Button
                onClick={handleDownloadExcel}
                variant="outline-primary"
                size="sm"
              >
                Export Excel{" "}
                <FontAwesomeIcon icon={faDownload} size="sm" className="me-2" />
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
      {refNo ? null : (
        <>
          <div className="table-settings mb-4">
            <Row className="justify-content-between align-items-center">
              <Col xs={8} md={6} lg={3} xl={4}>
                <SearchBar
                  filterText={filterText}
                  onFilterTextChange={(val) => setFilterText(val)}
                />
              </Col>
              <Col xs={12} md={6} lg={9} xl={8} className="ps-md-0 text-end">
                <Row>
                  <Col md={3}></Col>
                  <Col>
                    <DaterangeSelector
                      from={form.from}
                      to={form.to}
                      handleChange={handleChange}
                      inline
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <TransactionsTable
            setRef={setRef}
            viewOnly={viewOnly}
            _ref={tableRef}
            data={filtered_trx
              ?.map((t) => ({
                ...t,
                amount: Math.max(t.cr, t.dr),
              }))
              .filter((_) => _.amount)}
            user={payer}
          />
        </>
      )}
    </>
  );
};
