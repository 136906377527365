import React, { useState, useEffect } from "react";
import { Badge, Button, Col, Input, Row, Spinner, Table } from "reactstrap";
import CustomInput from "./CustomInput";
import { _fetchApi, _postApi } from "../../redux/action/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { formatNumber, toCurrency } from "../../utils";
import moment from "moment";

export default function NameAdjustment() {
  const [refNo, setRefNo] = useState("");
  const { user } = useSelector((s) => s.auth);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingh,setLoadinghan]= useState(false);

  const handleChange = (name, value) => {
    let arr = [];
    transactionData.forEach((item) => {
      arr.push({ ...item, [name]: value });
    });
    setTransactionData(arr);
  };

  const handleSearch = () => {
    setLoading(true);
    _fetchApi(
      `/adjust-transactions?reference_number=${refNo}`,
      (resp) => {
        setLoading(false);
        if (resp.success) {
          setTransactionData(resp.data);
        }
      },
      (err) => {
        console.error("API Error:", err);
        setLoading(false);
      }
    );
  };

  const handleUpdateTable = () => {
    setLoadinghan(true);
    _postApi(
      "/reciept_logs?query_type=update",
      {
        // staff_name: user.name,
        tax_payer: user.tax_payer
        // staff_id: user.id,
        // transactionData,
        // ref_no: transactionData[0].reference_number,
      },
      (res) => {
        setLoadinghan(false);
        if (res.success) {
          toast.success("Successfully updated the table.");
          setTransactionData([]);
          setRefNo("")
        }
      },
      (err) => {
        console.log(err);
        setLoadinghan(false);
        toast.error("Error occurred while updating the table.", err);
      }
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <CustomInput
            label="Reference Id"
            type="text"
            name="refNo"
            placeholder="Search Reference Id"
            value={refNo}
            onChange={(e) => setRefNo(e.target.value)}
          />
        </Col>
        <Col md={6}>
          <Button
            color="dark"
            className="col-md-6 "
            style={{
              marginTop: 30,
            }}
            disabled={loading}
            onClick={handleSearch}
          >
            {loading ? (
              <>
                <Spinner
                  size="sm"
                  color="light"
                  style={{ marginRight: "5px" }}
                />
                Searching...
              </>
            ) : (
              "Search"
            )}
          </Button>
        </Col>
        {transactionData.length ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              overflowY: "auto",
              margin: 10,
              borderRadius: 10,
              border: "1px solid #E8EDF2",
            }}
          >
            <Table
              bordered
              className="user-table align-items-center table-hover"
              style={{
                width: "100%",
                overflow: "scroll",
              }}
            >
              <thead>
                <tr>
                 <center> <th>Tax Payer</th></center>
                </tr>
              </thead>
              <tbody>
                {transactionData.map((data) => (
                  <tr
                    style={{ textAlign: "center" }}
                    key={data.reference_number}
                  >
                   <center> <td>{data.tax_payer}</td>   </center>
                  </tr>
                ))}
              </tbody>
            </Table>
            <center>
              <Button
                color="dark"
                style={{ margin: 10 }}
                disabled={loadingh}
                onClick={handleUpdateTable}
              >
                {loadingh ? (
                  <>
                    <Spinner
                      size="sm"
                      color="light"
                      style={{ marginRight: "5px" }}
                    />
                    Updating...
                  </>
                ) : (
                  "Update"
                )}
              </Button>
            </center>
          </div>
        ) : null}
      </Row>
    </div>
  );
}
