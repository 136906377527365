import React, { useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap';
import { CustomButton } from '../components/UI';
import { Routes } from '../routes';
// import { Button } from 'bootstrap';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom';
import { getColor } from './tax-clearance/TaxClearanceTab';
// import { Alert } from 'bootstrap';
import { toParagraph } from '../utils';
export default function MyTccAccount() {
    const history = useHistory();
    const [data, setData] = useState([]);
    return (
        <div>
            <Card>
                <div className="bg-light p-2 w-100 m-0">
                    <CardHeader className="bg-primary  d-flex justify-content-between">
                        {/* <Button
                            onClick={() => {
                                history.push(Routes.RequestTCC.path);
                            }}
                            size="sm"
                        >
                            Raise TCC
                        </Button> */}

                        <h4 style={{ color: '#fff' }} className="">
                            TCC Application
                        </h4>
                        <div></div>
                    </CardHeader>
                </div>
                <CardBody>
                    <div>
                        <div style={{ overflow: 'scroll', height: '60vh' }}>
                            <Table responsive className="mt-3">
                                <thead>
                                    <tr>
                                        <th>Tax Payer</th>
                                        <th>File No.</th>
                                        <th>TCC Ref.</th>
                                        <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length
                                        ? data?.map((x, i) => (
                                              <tr key={i}>
                                                  <td>{x.tax_payer}</td>

                                                  <td>{x.tax_file_no}</td>

                                                  {/* <td>{x.income_source?.toUpperCase()}</td> */}
                                                  <td scope="row">{x.tcc_ref}</td>
                                                  <td scope="row">
                                                      <Badge color={getColor(x.status)}>{toParagraph(x.status)}</Badge>
                                                  </td>
                                              </tr>
                                          ))
                                        : null}
                                </tbody>
                            </Table>
                            {!data?.length ? <Alert className="text-center">No Record Found</Alert> : null}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
