import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Button,
    Col,
    Container,
    Image,
    Modal,
    Row,
} from "@themesberg/react-bootstrap";
import { _fetchApi } from "../../redux/action/api";
import { CustomButton, SearchBar } from "../../components/UI";
import PaymentsContainer from "./components/PaymentsContainer";
import CustomDropdown from "./components/TaxDropdown";
import { formatNumber, toCurrency } from "../../utils";
// import BackToSelection from "../../components/UI/BackToSelection";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { Routes } from "../../routes";
import WelcomeImage from "../../assets/img/kano.png";
import { useDispatch, useSelector } from "react-redux";
// import { FaFedora } from "react-icons/fa";
// import { useLocation } from "react-router-dom";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import DaterangeSelector from "../dashboard/DaterangeSelector";
import { TAX_ACCOUNT } from "../../redux/action/type";
import { Card, Form, Input, Label, ModalBody } from "reactstrap";
// import moment from "moment";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import toast from "react-hot-toast";
import { convertToMenu } from "./NonTaxPayments";
import ExpressAcct from "../ExpressAccount";
import CustomModal from "../../components/UI/CustomModal";
import AddAccout from "../AddAccount";
import SelectInput from "../components/SelectInput";

export default function MDAPayments() {
    const _form = {
        page_title: "MDA FEES/CHARGES",
        type: "",
        month: "",
        org: "",
        amount: "",
        attachment: "",
        sector: "",
        tax_id: "",
        rows: [],
        tax_code: "",
        tax_parent_code: "",
        taxes: "",
        selected: [],
        sector: "NON TAX",
        isLastParent: false,
        from: "",
        to: "",
        phone: "",
        invoice_status: "Full payment",
    };

    const [form, setForm] = useState(_form);
    const [filterText, setFilterText] = useState("");
    const [mainTaxes, setMainTaxes] = useState([]);
    const [subTaxList, setSubTaxList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [tab, setTab] = useState(0);
    const [tax_payers, setTaxPayers] = useState([]);
    const {
        tax_account = {},
        tax_accounts = [],
        user = {},
    } = useSelector((p) => p.auth);
    const [tax_payer, setTapayer] = useState("");
    const dispatch = useDispatch();
    const handleFilterChange = (e) => {
        setFilterText(e);
    };
    // const ref = useRef();
    const [searchText, setSearchText] = useState("");

    const [isOpen, setIsOpen] = useState(false);

    const toggle1 = () => {
        setIsOpen(!isOpen);
    };

    const [showDefault, setShowDefault] = useState(false);
    const toggleModal = () => setShowDefault(!showDefault);

    const filteredTaxList = filterText.length
        ? subTaxList.filter((item) => {
            return item.title.toLowerCase().includes(filterText.toLowerCase());
        })
        : subTaxList;

    const SELECTED_TAX_ITEMS = subTaxList.some((tax) => tax.amount > 0)
        ? subTaxList.filter((tax) => tax.amount > 0)
        : [];

    const getList2 = useCallback(
        (tax_code) => {
            setLoading1(true);
            if (tax_code) {
                _fetchApi(
                    `/kigra-taxes?query_type=select&sector=NON TAX&parent_code=${tax_code}`,
                    (resp) => {
                        if (resp.result) {
                            const arr = [];
                            resp.result.forEach((item) => {
                                arr.push({ ...item, checked: false });
                            });
                            setSubTaxList(arr);
                            setLoading1(false);
                        }
                    },
                    (err) => {
                        console.error(err);
                        setLoading1(false);
                    }
                );
            }
        },
        [form.tax_code]
    );

    useEffect(() => {
        getList2(form.tax_code);
    }, [getList2, form.tax_code]);

    const getList = useCallback(() => {
        setLoading(true);
        _fetchApi(
            `/kigra-taxes?query_type=mda-taxes&mda_code=${user.mda_code}&sector=${user.sector}`,
            (resp) => {
                setMainTaxes(resp.result);
                setLoading(false);
            },
            (err) => {
                console.log(err);
                setMainTaxes([]);
                setLoading(false);
            }
        );
    }, [user.mda_code]);
    useEffect(() => {
        getList();
    }, [getList]);

    // Manually provide the rootData if not present in the data array
    const rootData = mainTaxes?.find((item) => item.tax_parent_code ==='MDAs Fees and Charges');
    //   const menuData = convertToMenu(data, rootData);
    //   console.log(menuData);
    const rearrangedArray = convertToMenu(mainTaxes, rootData);

    const filteredArray = rearrangedArray.flatMap((parent) => {
        // Check if the parent title includes the search text
        if (parent.title.toLowerCase().includes(searchText.toLowerCase())) {
            return [parent]; // Include the parent if there's a match
        }

        // Check if any of the children titles include the search text
        if (parent.children) {
            const matchingChildren = parent.children.filter((child) =>
                child.title.toLowerCase().includes(searchText.toLowerCase())
            );

            if (matchingChildren.length > 0) {
                return [
                    {
                        ...parent,
                        children: matchingChildren,
                    },
                ]; // Include the parent with matching children if any child matches
            }
        }

        return []; // Exclude the parent if no match found in title or children titles
    });

    const handleCheckBox = (tax) => {
        let arr = [];
        subTaxList.forEach((item) => {
            if (item.id === tax.id) {
                arr.push({
                    ...item,
                    amount: item.tax_fee || item.amount,
                    sqr_meter: item.checked ? 1 : item.sqr_meter || 1,
                    checked: !item.checked,
                    department: form.department ? form.department : null,
                });
            } else {
                arr.push(item);
            }
        });
        setSubTaxList(arr);
    };

    const handleTaxRateChenged = (e, tax) => {
        const { value } = e.target;
        let arr = [];
        subTaxList.forEach((item) => {
            if (item.id === tax.id) {
                arr.push({
                    ...item,
                    sqr_meter: value,
                    amount: parseFloat(value <= 0 ? 1 : value) * Math.max(tax.tax_fee, 1),
                    checked: parseInt(value) > 0 ? true : false,
                });
            } else {
                arr.push(item);
            }
        });
        setSubTaxList(arr);
    };
    const handleAmountChange = (e, tax) => {
        let arr = [];
        subTaxList.forEach((item) => {
            if (item.id === tax.id) {
                arr.push({
                    ...item,
                    amount: e.target.value,
                    checked: parseInt(e.target.value) > 0 ? true : false,
                });
            } else {
                arr.push(item);
            }
        });
        setSubTaxList(arr);
    };

    const getTaxPayers = useCallback(() => {
        if (tax_payer.length > 2) {
            _fetchApi(
                `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
                (resp) => {
                    if (resp.success) {
                        setTaxPayers(resp.data);
                    }
                }
            );
        } else if (tax_payer.length === 0) {
            _fetchApi(
                `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
                (resp) => {
                    if (resp.success) {
                        setTaxPayers(resp.data);
                    }
                }
            );
        }
    }, [tax_payer]);

    useEffect(() => {
        getTaxPayers();
    }, [getTaxPayers]);

    const handleDateRangeChange = ({ target: { name, value } }) => {
        if (name === "from") {
            setForm((p) => ({ ...p, from: value }));
        } else if (name === "to") {
            setForm((p) => ({ ...p, to: value }));
        }
    };
    const isPhoneSize = useIsPhoneSize();
    const [open, setOpen] = useState(isPhoneSize ? true : false);
    const openModal = () => setOpen(isPhoneSize ? !open : false);
    const handleAddList = (cb = (f) => f) => {
        if (!form.from || !form.to) {
            toast.error("Please select date");
        } else {
            cb();
        }
    };

    const handleFocus = (name) => {
        setTapayer(name);
        setTimeout(() => {
            toast.success("You can now close and search the tax payer by name or ID");
            toggleModal();
            // myRef.current.focus();
        }, 2000);
    };
    const renderFileNo = () => {
        return (
            <>
               {/** <Col>
                    <Label>File No.</Label>{" "}
                    <Input
                        type="text"
                        name="mda_val"
                        onChange={({ target: { value } }) =>
                            setForm((p) => ({
                                ...p,
                                mda_val: value,
                                mda_var: "File No.",
                            }))
                        }
                        style={{ width: "100%" }}
                        value={form.mda_val}
                    />
                </Col> */}
                <Col>
                    <Label>Payment status</Label>{" "}
                    <Input
                        type="select"
                        name="invoice_status"
                        onChange={({ target: { value } }) =>
                            setForm((p) => ({
                                ...p,
                                invoice_status: value,
                            }))
                        }
                        style={{ width: "100%" }}
                        value={form.invoice_status}
                    >
                        {["Full payment", "Part payment", "Balance payment"].map(
                            (status, idx) => (
                                <option key={idx}>{status}</option>
                            )
                        )}
                    </Input>
                </Col>
            </>
        );
    };
    return (
        <PaymentsContainer
            handleAddList={handleAddList}
            open={open}
            openModal={openModal}
            form={form}
            menu={
                loading ? (
                    <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
                        <p>
                            <Skeleton count={10} height={50} />
                        </p>
                    </SkeletonTheme>
                ) : (
                    <CustomDropdown
                        form={form}
                        setForm={setForm}
                        data={filteredArray.filter(
                            (item) => item.title !== "Revenue|Account collection unit"
                        )}
                        department={
                            String(user.department).includes("Revenue")
                                ? null
                                : user.department
                        }
                        openModal={openModal}
                    />
                )
            }
            handleTaxFeeChecked={handleCheckBox}
            search={
                <SearchBar
                    filterText={searchText}
                    onFilterTextChange={(val) => setSearchText(val)}
                    placeholder="Search by tax name"
                    className="bg-dark text-white"
                />
            }
            selected_taxes={SELECTED_TAX_ITEMS.filter((item) => item.checked).map(
                (item) => ({
                    ...item,
                    tax_fee: item.amount,
                    sector: form.sector,
                    mda_var: form.mda_var,
                    mda_val: form.mda_val,
                    invoice_status: form.invoice_status,
                    phone: form.phone
                        ? form.phone
                        : tax_account?.phone || tax_account?.office_phone,
                })
            )}
        >
            <Row className="m-0 p-0">
                {/* {JSON.stringify(user)} */}
                <Col className=" mb-5 py-3" style={{ zIndex: 999 }}>
                    {form.tax_code ? (
                        <Row>
                            {loading1 ? (
                                <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
                                    <p>
                                        <Skeleton count={10} height={45} />
                                    </p>
                                </SkeletonTheme>
                            ) : (
                                <>
                                    <div className="mb-2">
                                        <DaterangeSelector
                                            from={form.from}
                                            to={form.to}
                                            handleChange={handleDateRangeChange}
                                        />
                                    </div>
                                    {/* {JSON.stringify(tax_payers)} */}
                                    <Col md={12}>
                                        {user.role !== "user" ? (
                                            <Row>
                                                <Col md={6} className="mb-2">
                                                    <CustomTypeahead
                                                        onInputChange={(e) => {
                                                            setTapayer(e);
                                                            console.log(e, "TYPED");
                                                        }}
                                                        labelKey={(e) =>
                                                            `${e.account_type === "org" ? e.org_name : e.name
                                                            }(${e.taxID}) ${e.phone ? `:${e.phone || e.office_phone}` : ""
                                                            }`
                                                        }
                                                        onChange={(e) => {
                                                            if (e && e[0]?.id) {
                                                                dispatch({
                                                                    type: TAX_ACCOUNT,
                                                                    payload: e[0],
                                                                });
                                                            }
                                                        }}
                                                        selected={tax_account.taxID?[tax_account]:[]}
                                                        clearButton
                                                        col={12}
                                                        label={`Select Tax payer`}
                                                        options={tax_payers}
                                                        placeholder="Search Name| TaxID | Phone No."
                                                    />
                                                </Col>
                                                <Col>
                                                    <br />
                                                    <Button
                                                        onClick={() => toggleModal()}
                                                        className="bg-white mt-2 ml--2 text-dark"
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                                                    </Button>
                                                </Col>
                                                {renderFileNo()}
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col>
                                                    <Label>Tax Payer</Label>{" "}
                                                    <SelectInput
                                                        options={tax_accounts.map((item) => [
                                                            item.org_name || item.name,
                                                        ])}
                                                        defaultValue={
                                                            tax_account.org_name || tax_account.name
                                                        }
                                                        value={tax_account.org_name || tax_account.name}
                                                        name="tax_payer"
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            let val = e.target.value;
                                                            let sel = tax_accounts.filter(
                                                                (item) =>
                                                                    item.org_name == val || item.name == val
                                                            );
                                                            console.log(sel);
                                                            dispatch({
                                                                type: TAX_ACCOUNT,
                                                                payload: sel[0],
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                {renderFileNo()}
                                            </Row>
                                        )}
                                    </Col>
                                    <br />
                                    <SearchBar
                                        filterText={filterText}
                                        onFilterTextChange={handleFilterChange}
                                        placeholder="Search tax"
                                    />
                                    <br />
                                    <Col md={12} sm={12} lg={12} className="">
                                        <table
                                            size="sm"
                                            style={{ width: "100%", border: "1px solid black" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th
                                                        style={{
                                                            borderWidth: 1,
                                                            borderColor: "black",
                                                            fontWeight: "bold",
                                                            margin: "50px",
                                                        }}
                                                        className=" m-5 p-2"
                                                        width="5%"
                                                    >
                                                        <center> SN</center>
                                                    </th>
                                                    <th
                                                        style={{
                                                            borderWidth: 1,
                                                            borderColor: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                        width="40%"
                                                        className="m-4"
                                                    >
                                                        <center> Description</center>
                                                    </th>
                                                    {filteredTaxList.length &&
                                                        filteredTaxList.some(
                                                            (tax) => tax.uom === "per m2"
                                                        ) ? (
                                                        <th
                                                            style={{
                                                                borderWidth: 1,
                                                                borderColor: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                            width="15%"
                                                            className="text-right"
                                                        >
                                                            <center> Rate (Meter Squre)</center>
                                                        </th>
                                                    ) : filteredTaxList.some(
                                                        (tax) => tax.uom === "per m3"
                                                    ) ? (
                                                        <th
                                                            style={{
                                                                borderWidth: 1,
                                                                borderColor: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                            width="15%"
                                                            className="text-right"
                                                        >
                                                            <center> Rate (Meter Cube)</center>
                                                        </th>
                                                    ) : null}

                                                    <th
                                                        style={{
                                                            borderWidth: 1,
                                                            borderColor: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                        width="15%"
                                                        className="text-right"
                                                    >
                                                        <center> Amount </center>
                                                    </th>

                                                    <th
                                                        style={{
                                                            borderWidth: 1,
                                                            borderColor: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                        width="15%"
                                                        className="text-right"
                                                    >
                                                        <center> Action</center>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredTaxList?.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td
                                                            className="text-center"
                                                            style={{ borderWidth: 1, borderColor: "black" }}
                                                        >
                                                            {idx + 1}
                                                        </td>
                                                        <td
                                                            style={{ borderWidth: 1, borderColor: "black" }}
                                                            className="m-5 p-2"
                                                        >
                                                            {item.title}
                                                        </td>
                                                        {filteredTaxList.length &&
                                                            filteredTaxList.some(
                                                                (tax) => tax.uom === "per m"
                                                            ) ? (
                                                            <td
                                                                style={{ borderWidth: 1, borderColor: "black" }}
                                                                className="text-right px-2"
                                                            >
                                                                {item.uom.includes("per m") ? (
                                                                    <input
                                                                        onChange={(e) =>
                                                                            handleTaxRateChenged(e, item)
                                                                        }
                                                                        value={item.sqr_meter}
                                                                        type="number"
                                                                        name="sqr_meter"
                                                                        className="text-center"
                                                                    />
                                                                ) : null}
                                                            </td>
                                                        ) : null}
                                                        <td
                                                            className="text-right m-5 p-2"
                                                            style={{ borderWidth: 1, borderColor: "black" }}
                                                        >
                                                            {user?.rank === "Department Head" ? (
                                                                <input
                                                                    // onFocus={() => {
                                                                    //   setFocusedInput(item.id);
                                                                    // }}
                                                                    // onBlur={(e) => {
                                                                    //   if (focusedInput === item.id) {
                                                                    //     setFocusedInput(null);
                                                                    //     handleTaxRateChenged(e, item);
                                                                    //   }
                                                                    //   setTemporaryValue("");
                                                                    // }}
                                                                    value={item.amount}
                                                                    onChange={(e) => {
                                                                        handleAmountChange(e, item);
                                                                        // if (e.target.value.length >= 10) {
                                                                        // setTemporaryValue(e.target.value);
                                                                        // }
                                                                    }}
                                                                    type="number"
                                                                    style={{ width: "80px" }}
                                                                    name="tax_fee"
                                                                    className="p-0 m-0 text-center"
                                                                />
                                                            ) : item.amount <= 0 || item.tax_fee <= 0 ? (
                                                                <input
                                                                    // onFocus={() => {
                                                                    //   setFocusedInput(item.id);
                                                                    // }}
                                                                    // onBlur={(e) => {
                                                                    //   if (focusedInput === item.id) {
                                                                    //     setFocusedInput(null);
                                                                    //     handleTaxRateChenged(e, item);
                                                                    //   }
                                                                    //   setTemporaryValue("");
                                                                    // }}
                                                                    value={item.amount}
                                                                    onChange={(e) => {
                                                                        handleAmountChange(e, item);
                                                                        // if (e.target.value.length >= 10) {
                                                                        // setTemporaryValue(e.target.value);
                                                                        // }
                                                                    }}
                                                                    type="number"
                                                                    style={{ width: "80px" }}
                                                                    name="tax_fee"
                                                                    className="p-0 m-0 text-center"
                                                                />
                                                            ) : item.amount > 0 ? (
                                                                formatNumber(item.amount)
                                                            ) : (
                                                                formatNumber(item.tax_fee)
                                                            )}
                                                        </td>

                                                        <td
                                                            style={{ borderWidth: 1, borderColor: "black" }}
                                                            className="text-center"
                                                        >
                                                            <input
                                                                className="large-check"
                                                                onChange={(e) => handleCheckBox(item)}
                                                                checked={item.checked}
                                                                value={item.id}
                                                                type="checkbox"
                                                                name="taxes"
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </>
                            )}
                        </Row>
                    ) : (
                        <WelcomeMessage />
                    )}
                </Col>
            </Row>
            <Modal
                as={Modal.Dialog}
                centered
                show={isOpen}
                onHide={toggle1}
                animation={true}
                style={{ zIndex: isOpen ? 99999 : 1 }}
            >
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header>
                        <h5 className="text-center">Add file information</h5>{" "}
                        <Button
                            onClick={() => setIsOpen(false)}
                            className="bg-danger text-white"
                            size="sm"
                        >
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Label>File No.</Label>{" "}
                                <Input
                                    type="text"
                                    name="mda_val"
                                    onChange={({ target: { value } }) =>
                                        setForm((p) => ({
                                            ...p,
                                            mda_val: value,
                                            mda_var: "File No.",
                                        }))
                                    }
                                    style={{ width: "100%" }}
                                    value={form.mda_val}
                                />
                            </Col>
                            <Col>
                                <Label>Phone No.</Label>{" "}
                                <Input
                                    type="text"
                                    name="phone"
                                    onChange={({ target: { value } }) =>
                                        setForm((p) => ({
                                            ...p,
                                            phone: value,
                                        }))
                                    }
                                    style={{ width: "100%" }}
                                    value={
                                        form.phone
                                            ? form.phone
                                            : tax_account?.phone || tax_account?.office_phone
                                    }
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
            <Modal
                as={Modal.Dialog}
                centered
                show={showDefault}
                size="lg"
                style={{ zIndex: showDefault ? 99999 : 1 }}
            >
                <Modal.Header>
                    <Modal.Title className="row" style={{ width: "50%" }}>
                        <Row>
                            <Col md={6}>
                                <Button
                                    className={!tab ? `` : `bg-white text-dark`}
                                    onClick={() => setTab(0)}
                                    size="sm"
                                >
                                    New Tax payer
                                </Button>
                            </Col>
                            <Col md={6}>
                                <Button
                                    className={tab ? `` : `bg-white text-dark`}
                                    onClick={() => setTab(1)}
                                    size="sm"
                                >
                                    Link with existing account
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Title>
                    <Button
                        variant="danger"
                        className="bg-danger text-white"
                        aria-label="Close"
                        onClick={toggleModal}
                    >
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {tab === 0 ? (
                        <AddAccout isModal={true} handleFocus={handleFocus} />
                    ) : (
                        <AddAccout
                            isModal={true}
                            isAddAccount={true}
                            handleFocus={handleFocus}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </PaymentsContainer>
    );
}
export const WelcomeMessage = () => {
    const isPhoneSize = useIsPhoneSize()
    return (
        <main>
            <section className="vh-75 mt-4 d-flex align-items-center justify-content-center">
                <Container>
                    <Row>
                        <Col
                            xs={12}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <div>
                                {/* <Card.Link as={Link} to={Routes.DashboardOverview.path}> */}
                                <Image
                                    src={WelcomeImage}
                                    style={{ width: isPhoneSize ? '100px' : "300px", height: isPhoneSize ? '100px' : "300px" }}
                                />
                                {/* </Card.Link> */}
                                <h1 className="text-primary mt-5">
                                    Select item from side bar to Proceed
                                </h1>
                                {/* <p className=" my-4">
                  Please extend a warm welcome to a valuable taxpayer. If you
                  don't see any taxpayers listed in the sidebar, kindly click
                  the button below.
                </p> */}
                                <Button
                                    // as={Link}
                                    variant="primary"
                                    className="animate-hover"
                                    // to={Routes.DashboardOverview.path}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Reload
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
