import React, { useState } from "react";
import CustomInput from "../examples/CustomInput";
import { CustomButton } from "../../components/UI";

import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { Card } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { getTaxPayerInfo } from "../../redux/action/auth";
import { toast } from "react-hot-toast";

function AgentGenerateInvoice() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [transactionRef, setTransactionRef] = useState("");
  const [loading, setLoading] = useState(false);

  const displayReceipt = () => {
    setLoading(true);
    if (transactionRef !== "") {
      dispatch(
        getTaxPayerInfo(transactionRef, () => {
          setLoading(false);
          history.push(`${Routes.Selection.path}?display=true`);
        }),
        () => {
          setLoading(false);
          toast.error("Failed!");
        }
      );
    } else {
      setLoading(false);
      toast.warning("Enter TaxID");
    }
  };

  return (
    <div>
      <div className="d-flex p-2  justify-content-center flex-wrap flex-md-nowrap align-items-center py-4 pt-8">
        <Card className="shadow col-md-6 py-5">
          <Card.Body className="text-center p-4 ">
            {/* {JSON.stringify(transactionRef)} */}
            <div className="mb-4 mb-md-0">
              <h4>GENERATE INVOICE</h4>
            </div>
            <div className="col-md-12">
              <CustomInput
                label="ENTER TAX PAYER ID"
                value={transactionRef}
                className="text-center"
                onChange={({ target: { value } }) => setTransactionRef(value)}
              />
            </div>
            <CustomButton loading={loading} onClick={() => displayReceipt()}>
              Generate
            </CustomButton>
          </Card.Body>
        </Card>
      </div>

      {/* {ref_no && ref_no !== "" ?  <PaymentInvoice transactionRef={transactionRef} /> : null} */}
    </div>
  );
}

export default AgentGenerateInvoice;
