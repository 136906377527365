import React, { useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
} from "@themesberg/react-bootstrap";
import { _postApi, apiURL } from "../../redux/action/api";
import { Route, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../routes";
import { CustomButton } from "../UI";
import toast from "react-hot-toast";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ContentArea from "./ContentArea";
// import { _post } from "../components/Utils/Helper";

function Blog() {
    const [editData, setEditData] = useState(EditorState.createEmpty());
  const _form = {
    title: "",
    content: "",
    attechment: "",
  };
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
   const [editorState, setEditorState] = useState(() =>
     EditorState.createEmpty()
   );

  const history = useHistory();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

   const handleEditorChange = (newState) => {
     setEditorState(newState);
     const rawContentState = convertToRaw(newState.getCurrentContent());
     const htmlContent = draftToHtml(rawContentState);
     setForm((p) => ({ ...p, content: htmlContent }));
   };

  const handleAdd = (e) => {
    e.preventDefault();
    setForm(_form);

    setLoading(true);
    _postApi(
      "/contentes/Blog?query_type=insert",
      form,
      (res) => {
        setLoading(false);
        if (res.success) {
          setForm(_form);
          toast.success("Submitted")
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        toast.error('Not Submitted')
      }
    );
    console.log(form);
  };

  const onChangeAttachment = (e) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);

    // Sending the attachment to the server
    fetch(`${apiURL}/contentes/upload-pictures`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming server returns URL of the uploaded image
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, attechment: data.url }));
        // You can store the URL in your component state or use it accordingly
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="div">
                  <Row>
                    <Col md={6}>CREATE Blog</Col>
                    <Col md={6} className="text-right">
                      <Button
                        size="sm"
                        onClick={() => history.push(Routes.AdminBlogList.path)}
                      >
                        Blog List
                      </Button>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label> Title</label>
                        <Form.Control
                          placeholder="Title"
                          type="text"
                          name="title"
                          value={form.title}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Attechment</label>
                        <Form.Control
                          placeholder="Attechment"
                          disabled={form.attechment.length > 10}
                          type="file"
                          name="attechment"
                          onChange={onChangeAttachment}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Content</label>
                        <ContentArea
                          editorState={editorState}
                          setData={handleEditorChange}
                        
                        />
                        {/* <Form.Control
                          placeholder="Description"
                          rows="4"
                          as="textarea"
                          name="content"
                          value={form.content}
                          onChange={handleChange}
                        ></Form.Control> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <CustomButton
                    className="btn-fill pull-right mt-3"
                    type="submit"
                    variant="info"
                    loading={loading}
                    onClick={handleAdd}
                  >
                    Submit
                  </CustomButton>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Blog;
