import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";

import { TransactionsTable } from "../components/Tables";
import { useParams } from "react-router";
import DaterangeSelector from "./dashboard/DaterangeSelector";
import { _fetchApi } from "../redux/action/api";
import { toast } from "react-hot-toast";
import moment from "moment";
import { useQuery } from "../utils";

export default () => {
  const { mda } = useParams();
  const [trx, setTrx] = useState([]);
  const today = moment().format("YYYY-MM-DD");
  const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
  const q = useQuery();
  const sector = q.get("sector") || "";
  const view_by = q.get("view_by") || "Tax items";

  const [form, setForm] = useState({
    from: oneYearAgo,
    to: today,
    sector,
    view_by,
    mda_code: "",
  });

  const getMDAs = useCallback(() => {
    _fetchApi(
      `/get/mdas?query_type=mda-transactions&mda_code=${mda}`,
      (resp) => {
        if (resp.success) {
          setTrx(resp.data);
        }
      },
      (err) => {
        console.error(err);
        toast.error("Errorr occured!");
      }
    );
  }, []);
  useEffect(() => {
    getMDAs();
  }, [getMDAs]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "from" || name === "to") {
      setForm((prevState) => ({
        ...prevState,
        [name]: moment(value).format("YYYY-MM-DD"),
      }));
    } else setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          
            <Breadcrumb.Item active>Payments report</Breadcrumb.Item>
          </Breadcrumb> */}
          <h4>{trx[0]?.mda_name || ""} TRANSACTIONS</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={12} md={6} lg={9} xl={8} className="ps-md-0 text-end">
            <DaterangeSelector
              from={form.from}
              to={form.to}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>

      <TransactionsTable data={trx} />
    </>
  );
};
