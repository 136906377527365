import { Card } from "@themesberg/react-bootstrap";
import React from "react";
import {
  BarChart,
  LineChart,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   {
//     name: "JAN",

//     2023: 100,
//     2022: 90,
//   },
//   {
//     name: "FEB",

//     2023: 80,
//     2022: 180,
//   },
//   {
//     name: "MAR",

//     2023: 90,
//     2022: 90,
//   },
//   {
//     name: "APR",

//     2023: 150,
//     2022: 250,
//   },
//   {
//     name: "MAY",

//     2023: 93,
//     2022: 113,
//   },
//   {
//     name: "JUN",

//     2023: 85,
//     2022: 150,
//   },
//   {
//     name: "JUL",
//     2023: 79,
//     2022: 89,
//   },
//   {
//     name: "AUG",
//     2023: 106,
//     2022: 136,
//   },
//   {
//     name: "SEP",
//     2023: 90,
//     2022: 130,
//   },
//   {
//     name: "OCT",
//     2023: 100,
//     2022: 100,
//   },
//   {
//     name: "NOV",
//     2023: 130,
//     2022: 130,
//   },
//   {
//     name: "DEC",
//     2023: 200,
//     2022: 200,
//   },
// ];

function ChartComponent({ type = "bar", data = [] }) {
  return data.length ? (
    <Card className="bg-white p-2">
      {type === "bar" ? (
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Bar dataKey={data[0]?.last_year} fill="#43f" /> */}
          <Bar dataKey={data[0]?.year} fill="#17f" />
        </BarChart>
      ) : type === "line" ? (
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      ) : null}
    </Card>
  ) : null;
}

export default ChartComponent;
