import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");

export default function DaterangeSelector({
  from = "",
  to = "",
  handleChange = (f) => f,
  inline = false,
  children,
  col= 4,
  col2= 4,
}) {
  return (
    <Row>
      <Col md={col || 4}>
        <FormGroup
          className={
            inline ? "d-flex flex-direction-row align-items-center" : ""
          }
        >
          <Label style={{ fontWeight: "bold" }} className="me-2">
           Date From:
          </Label>
          <Input type="date" value={from} name="from" onChange={handleChange} />
        </FormGroup>
      </Col>

      <Col md={col2 || 4}>{children}</Col>

      <Col md={col || 4}>
        <FormGroup
          className={
            inline ? "d-flex flex-direction-row align-items-center" : ""
          }
        >
          <Label style={{ fontWeight: "bold" }} className="me-2">
            Date To:
          </Label>
          <Input type="date" value={to} name="to" onChange={handleChange} />
        </FormGroup>
      </Col>
    </Row>
  );
}
