import React, { useEffect, useState } from "react";
import { formatNumber, useQuery } from "../../utils";
import moment from "moment";
import CustomDateRange from "./CustomDateRange";
import { _fetchApi } from "../../redux/action/api";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import { CURRENCY } from "../../components/Tables2";
function SectorReportByTaxPayer() {
  const query = useQuery();
  const date_from = query.get("date_from");
  const sector = query.get("sector");
  const date_to = query.get("date_to");
  const description = query.get("description");
  const service_category = query.get("service_category");
  const today = moment();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: date_from || today.format("YYYY-MM-DD"),
    to: date_to || today.format("YYYY-MM-DD"),
  });
  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };
  useEffect(() => {
    _fetchApi(
      `/rev-collection-report-history?description=${description}&date_from=${dateRange.from}&date_to=${dateRange.to}&service_category=${service_category}&sector=${sector}&query_type=Sector Report by Tax Item`,
      (res) => {
        console.log(res);
        if (res.success) {
          setData(res.results);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [dateRange.from, dateRange.to, description, service_category, sector]);
  // const filteredData = data;
  const filteredData = data.filter((item) =>
    item.tax_payer?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log(data[0]);
  const history = useHistory();
  return (
    <div style={{ margin: "5px" }}>
      <Button
        size="sm"
        className="btn btn-link"
        onClick={() => history.goBack()}
      >
        <FaArrowLeft /> Go back
      </Button>
      <h4 className="py-3 text-center">{description} Reports</h4>
      <CustomDateRange
        type={dateRange.type}
        month={dateRange.month}
        quarter={dateRange.quarter}
        year={dateRange.year}
        from={dateRange.from}
        to={dateRange.to}
        handleChangeDate={handleChangeDate}
      />
       <div style={{ margin: "10px 0" }}>
        <input
          type="text"
          placeholder="Search by taxpayer"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="text-right">
        <h4>
          <span>Total {description}: </span>
          <span>
            {CURRENCY}
            {formatNumber(
              filteredData.reduce((it, id) => it + parseFloat(id.amount), 0)
            )}
          </span>
        </h4>
      </div>
      <table
        style={{ width: "100%", border: "1px solid black" }}
        className="table table-responsive  table-striped table-zebra  pt-0 table-bordered"
      >
        <thead>
          <tr>
            <th className="border-bottom  text-">Tax Payer</th>
            <th className="border-bottom text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {/* {JSON.stringify(data)} */}
          {filteredData.map((item) => (
            <tr>
              <td className="text-">
                <Link
                  to={`${Routes.BankTransactions.path}?item_code=${item.item_code}&taxpayer=${item.taxID}&date_from=${dateRange.from}&date_to=${dateRange.to}&sector=${sector}`}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    fontSize: "13px",
                  }}
                >
                  {item.tax_payer}
                </Link>
              </td>
              <td className="text-right">{formatNumber(item.amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SectorReportByTaxPayer;
