import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup,Card, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import { TransactionsTable, ViewTable } from "../components/Tables";
import { PDFViewer } from "@react-pdf/renderer";
// import ReceiptPdf from "../components/ReceiptPdf";

// import { Card } from "reactstrap";

export default () => {
  return (
    <div className='p-0 m-0'>

      <PDFViewer height="900" width="1200">
     {/* <ReceiptPdf /> */}
      </PDFViewer>     
    </div>
  ); 
};
