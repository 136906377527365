import React, { useState } from 'react';
import { Card, CardBody, CardText, Row, Col, CardTitle, CardHeader, Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { _postApi, formatData } from '../redux/action/api';
import { CustomButton } from '../components/UI';
import toast from 'react-hot-toast';

const EmployeeRegisteration = () => {
    const [testData, setTestData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const handleUpload = (event) => {
        let fileObj = event.target.files[0];
        if (!fileObj) {
            // setErrorMessage('No file uploaded!');
            return false;
        }
        console.log('fileObj.type:', fileObj.type);
        if (
            !(
                fileObj.type === 'application/vnd.ms-excel' ||
                fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        ) {
            console.log('just pass the fileObj as parameter');
            // setErrorMessage('Unknown file format. Only Excel files are uploaded!');
            return false;
        }
        //just pass the fileObj as parameter
        console.log('just pass the fileObj as parameter');
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                let newRows = [];
                let data = [];
                // let basicInfo = []
                // let beginIndex = 0
                // let detailInfo = []
                // let items = []

                let colNames = resp.rows[6];

                resp.rows.slice(6).forEach((row, index) => {
                    if (row && row !== 'undefined' && row.length !== 0) {
                        console.log(row);
                        let obj = {};
                        colNames.forEach((col, idx) => {
                            obj[col] = row[idx];
                        });
                        data.push(obj);
                        newRows.push({
                            key: index,
                            custName: row[0],
                            idNo: row[1],
                            old_tin: row[2],
                            incorporationDate: row[3],
                            addressId: row[4],
                            lgaId: row[5],
                            stateId: row[6],
                            addressSub: row[7],
                            country: row[8],
                            customerEmail: row[9],
                            customerPhone: row[10],
                            entryPhone: row[11],
                            entryEmail: row[12],
                            businessNature: row[13],
                        });
                    }
                });
                console.log(newRows);
                setTestData(newRows);
                if (newRows.length === 0) {
                    // setErrorMessage('No data found in file!');
                    return false;
                } else {
                    // setCols(resp.cols);
                    // setRows(resp.rows);
                    // setFormattedRows(newRows);
                    let ff = formatData(newRows);
                    // setFinalData(ff);
                    // setErrorMessage(null);

                    return;
                }
            }
        });
        return false;
    };
    const [loading, setLoading] = useState(false);
    const handleSubmit = () => {
        setLoading(true);

        // Helper function to generate a random number of specified length
        const getRandomNumber = (length) => {
            return Math.floor(Math.random() * Math.pow(10, length))
                .toString()
                .padStart(length, '0');
        };

        // Set to keep track of used phone numbers
        const usedPhones = new Set();

        let arr = [];
        testData.forEach((item) => {
            // Ensure entryPhone is unique
            let entryPhone = item.entryPhone;
            while (usedPhones.has(entryPhone) || entryPhone == null) {
                entryPhone = getRandomNumber(10); // Generate a new 10-digit phone number
            }
            usedPhones.add(entryPhone);

            arr.push({
                username: item.idNo,
                password: '654321',
                org_name: item.custName,
                contact_name: item.custName,
                email: item.customerEmail,
                org_type: item.businessNature,
                org_email: item.customerEmail,
                role: 'user',
                accessTo: null,
                sector: item.businessNature,
                bvn: item.bvn, // BVN typically 11 digits in Nigeria
                company_name: item.custName,
                office_address: item.addressId,
                rc: item.rc, // Example length for RC number
                tin: item.tin, // Example length for TIN
                org_tin: item.old_tin,
                account_type: "org",
                phone: item.customerPhone,
                office_phone: item.customerPhone,
                state: item.stateId,
                ward: null,
                lga: item.lgaId,
                address: item.addressId,
                department: null,
                mda_name: null,
                mda_code: null,
                rank: null,
                contact_phone: entryPhone, // Use the unique entryPhone
                status: 'active',
                query_type: 'insert',
                taxID: null,
                user_id: item.user_id, // Example length for user ID
                limit: 50,
                offset: 0,
                name: item.custName,
                occupation: null,
                income_source: 'Source One',
                nationality: 'Nigeria',
                incorporation_date: item.incorporationDate,
                annual_turnover: 0,
                business_sector: item.businessNature,
                tax_station: 'KIRS Headquarter',
            });
        });
        console.log(arr);
        // _postApi(
        //     '/multi-sign-up',
        //     { users: arr },
        //     (resp) => {
        //         if (resp.success) {
        //             setLoading(false);
        //             toast.success('Done');
        //         }
        //     },
        //     (err) => {
        //         console.log(err);
        //         setLoading(false);
        //         toast.error('Error Occurred', err);
        //     },
        // );
    };

    return (
        <>
            <label>
                Click here to upload a file
                <input accept=".xlsx,.xls" type="file" onChange={handleUpload} />
            </label>
            <CustomButton loading={loading} onClick={handleSubmit}>
                Upload
            </CustomButton>
            {/* <Table bordered striped responsive>
                <thead>
                    <tr>
                        <th className="text-center">custName</th>
                        <th className="text-center">idNo</th>
                        <th className="text-center">old_tin</th>
                        <th className="text-center">incorporationDate</th>
                        <th className="text-center">addressId</th>
                        <th className="text-center">lgaId</th>
                        <th className="text-center">stateId</th>
                        <th className="text-center">addressSub</th>
                        <th className="text-center">country</th>
                        <th className="text-center">customerEmail</th>
                        <th className="text-center">entryPhone</th>
                        <th className="text-center">entryEmail</th>
                        <th className="text-center">businessNature</th>
                    </tr>
                </thead>
                <tbody>
                    {testData?.map((item, index) => (
                        <tr key={index + 1}>
                            <th className="text-center">{item.custName}</th>
                            <th className="text-center">{item.idNo}</th>
                            <th className="text-center">{item.old_tin}</th>
                            <th className="text-center">{item.incorporationDate}</th>
                            <th className="text-center">{item.addressId}</th>
                            <th className="text-center">{item.lgaId}</th>
                            <th className="text-center">{item.stateId}</th>
                            <th className="text-center">{item.addressSub}</th>
                            <th className="text-center">{item.country}</th>
                            <th className="text-center">{item.customerEmail}</th>
                            <th className="text-center">{item.entryPhone}</th>
                            <th className="text-center">{item.entryEmail}</th>
                            <th className="text-center">{item.businessNature}</th>
                        </tr>
                    ))}
                </tbody>
            </Table> */}
            {/* <Row className="p-3">
                <Col md={12}>
                    <Card>
                        <CardHeader style={{
                            fontWeight: "bolder",
                        }} className="bg-light">
                            Employee Registeration
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between align-center">
                                    <CardTitle tag="h3" className="mb-1">Step 1</CardTitle>
                                    <CardTitle tag="h6">Help</CardTitle>
                                </Col>
                                <Col lg={6} md={12}>
                                    <CardTitle tag="h6">Select salary structure</CardTitle>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Cleaner</option>
                                        <option value="1">Worker</option>
                                        <option value="2">Chef</option>
                                        <option value="3">Plumber</option>
                                        <option value="4">Carpenter</option>
                                        <option value="5">Trader</option>
                                        <option value="6">Manager</option>
                                    </select>
                                </Col>
                                <Col lg={6} md={12}></Col>
                                <Col lg={4} md={6} sm={12} className="mt-2">
                                    <button type="button" className="btn btn-dark text-white p-1 mb-3">Create Salary Structure</button>
                                </Col>
                            </Row>

                            <hr/>

                            <Row className="mt-3">
                                <Col md={12}>
                                    <CardTitle tag="h3" className="mb-0">Step 2</CardTitle>
                                    <hr className="m-0"/>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone No.</th>
                                                    <th>KIRMAS ID</th>
                                                    <th>Address</th>
                                                    <th>Paygroup</th>
                                                    <th>TIN</th>
                                                    <th>BVN</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-sucess">
                                                    <td>
                                                        1
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" placeholder="Nagudu"/>
                                                    </td>
                                                    <td>
                                                        <input type="email" class="form-control" placeholder="test@yahoo.com"/>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control"/>
                                                        <small>Eg. 07034000000</small>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control" placeholder="kn/2345"/>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control"/>
                                                    </td>
                                                    <td>
                                                        <select class="form-select" aria-label="Default select example">
                                                            <option selected>none</option>
                                                            <option value="1">11%</option>
                                                            <option value="2">12%</option>
                                                            <option value="3">13%</option>
                                                            <option value="4">5%</option>
                                                            <option value="5">16%</option>
                                                            <option value="6">17%</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control"/>
                                                        <small>Eg. 54654321</small>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control"/>
                                                        <small>Eg. 23435645</small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                            <div className="rounded-circle bg-secondary mt-5 mb-2 text-center d-flex justify-content-center" style={{width: 35, height: 35, fontSize: 20}}>+</div>
                            <Row>
                                <Col lg={4} md={12} style={{paddingBottom: 15}}>
                                    <p className="mb-1" style={{fontSize: 13}}>Download Employee's Template</p>
                                    <p className="mb-1" style={{fontSize: 13}}>Download Employee Upload Tutorial</p>
                                </Col>
                                <Col lg={4} md={12} style={{paddingBottom: 15}}>
                                    <CardTitle tag="h4" className="mb-0">Use Upload for Step 2</CardTitle>
                                    <CardTitle tag="h6" className="mb-0">Click here to choose a <span className="text-danger">*.xlsx</span> file</CardTitle>
                                    <div className="p-2 bg-light">
                                        <button className="me-1">Choose file</button>No file chosen
                                    </div>
                                    <small>*Recommended batch size of 1,000 employees per upload</small>
                                </Col>
                                <Col lg={4} md={12} style={{paddingBottom: 15}}>
                                    <button type="button" className="me-2 btn btn-outline-secondary">Back</button>
                                    <button type="submit" className="btn btn-secondary">Submit</button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
        </>
    );
};

export default EmployeeRegisteration;
