import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Spinner } from 'reactstrap';

import { _fetchApi, _postApi } from '../redux/action/api';
import CustomTypeahead from '../components/UI/CustomTypeahead';
import { Card } from '@themesberg/react-bootstrap';
// import { taxStations } from '../utils/tax-stations';
import { SelectInput } from '../components/UI';
import toast from 'react-hot-toast';
import { queries } from '@testing-library/react';

const TaxStationTeams = () => {
    const _form = {
        name: 'Team',
        tax_station: '',
        lga: '',
        staff_name: '',
        street: '',
        staff_id: ''
    }
    const [form, setForm] = useState(_form);
    const [taxStations, setTaxStations] = useState([])
    const [selectedStaff, setSelectedStaff] = useState([])
    const [staff, setStaff] = useState([])
    const [teams, setTeams] = useState([])
    const [newStaff, setNewStaff] = useState([])
    const [oldStaff, setOldStaff] = useState([])
    const [loading, setLoading] = useState(true)
    const [globalTeam, setGlobalTeam] = useState([])
    const [selectedStation, setSelectedStation] = useState([])
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'tax_station') {
            setForm({ ...form, [name]: value, lga: taxStations.find(x => x.name === value)?.lga });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    useEffect(() => {
        _fetchApi('/get-tax-stations',
            (res) => {
                if (res.success) {
                    setTaxStations(res.data)
                } else {
                    setTaxStations([])
                }
            },
            (err) => {
                toast.error('Tax stations not loaded')
                console.error(err);
                setTaxStations([])
            }
        )
    }, [])

    const handleAdd = async (e) => {
        e.preventDefault();
        setNewStaff((p) => [...p, form])
        setForm(_form)
        setSelectedStaff([])
        setSelectedStation([])
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        _postApi('/tax-station-teams',
            { data: newStaff?.map(x => ({ ...x, query_type: 'create' })) },
            (resp) => {
                if (resp.success) {
                    toast.success('Submitted')
                    setNewStaff([])
                }
            },
            (err) => {
                console.error(err);
                toast.error("Operation failed.")
            }
        )

    }
    const getStaff = useCallback(() => {
        setLoading(true);
        _postApi(
            "/auth/create-admins",
            {
                limit: 10000,
                offset: 0,
                lga: '',
                tax_station: '',
                query_type: 'select-staff-names'
            },
            (res) => {
                setLoading(false);
                if (res.success && res.data.length) {

                    setStaff(res.data);
                    // setTotalItems(res.data[0].total_rows)
                } else {
                    setStaff([]);
                    // setTotalItems(0)
                }
            },
            (error) => {
                setLoading(false);
                console.log(error);
            }
        );
               if(globalTeam){
        _postApi('/post-tax-stations',
            { query_type: 'select-teams' },
            (res) => {
                if (res.success) {
                    setTeams(res.data)
                } else {
                    setTeams([])
                }
            },
            (err) => {
                toast.error('Tax stations not loaded')
                console.error(err);
                setTeams([])
            }
        )
    }
    }, [globalTeam]);

    useEffect(() => {
        getStaff();
    }, [getStaff]);

    const handleAddStaff = (selected) => {
        setSelectedStaff(selected)
        // alert(JSON.stringify(selected))
        setForm((p) => ({
            ...p,
            staff_id: selected[0]?.taxID,
            taxID: selected[0]?.taxID,
            staff_name: selected[0]?.name,
        }))
    };


    const handleSelectStation = (selected) => {
        if (selected.length) {
            // alert(JSON.stringify(selected))
            setSelectedStation(selected)
            setForm((p) => ({
                ...p,
                tax_station: selected[0]?.name,
                lga: selected[0]?.lga,
            }))
        }
    };

    const handleGlobalChange = (selected, name) => {
        // alert(JSON.stringify(selected))
        const item = selected.length ? selected[0] : '';
        if (name === 'team') {
            setGlobalTeam(item);
            setLoading(true);
            _postApi('/post-tax-stations',
                { query_type: 'select-team-members',
                    address:item.name
                 },
                (res) => {
                    setLoading(false);
                    if (res.success) {
                        setOldStaff(res.data)
                    } else {
                        setOldStaff([])
                    }
                },
                (err) => {
                    setLoading(false);
                    toast.error('Tax stations not loaded')
                    console.error(err);
                    setTeams([])
                }
            )
        }
        setStaff((prevData) => {
            return prevData?.map(row => ({ ...row, [name]: name === 'team'?  item?.name?.toUpperCase() : item.name })
            );
        });
    };

    const deleteNew = (name) =>{
        setNewStaff((p)=> {return p.filter(x=>x.name!==name)})
    }
   const  deleteOld = (selected) =>{
    oldStaff((p)=> {return p.filter(x=>x.taxID!==selected.taxID)})
        setNewStaff((p) => [...p, { staff_id: selected?.staff_id,
            taxID: selected?.taxID,
            staff_name: selected?.name,
            name:'',
            tax_station:''
        }])
        setForm(_form)
        setSelectedStaff([])
        setSelectedStation([])
}

    return (
        <Card>
            <Card.Body>
                <h2>Tax Station Teams</h2>

                <Col md={4}>
                    <Label>Select Team</Label>
                    <CustomTypeahead
                        placeholder="TEAM"
                        name="name"
                        labelKey={i => `${i.name}`}
                        options={teams}
                        clearButton
                        value={[globalTeam]}
                        onChange={(e) => handleGlobalChange(e, 'team')}
                        col={12}
                    />
                </Col>
                <Form onSubmit={handleAdd}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="staff_id">Staff</Label>
                                <CustomTypeahead
                                    placeholder="Select Staff"
                                    type="select"
                                    name="tax_admin"
                                    labelKey={i => `${i.name} - ${i.staff_id}`}
                                    options={staff}
                                    clearButton
                                    selected={selectedStaff}
                                    onChange={handleAddStaff}
                                    onInputChange={(e)=>{ 
                                        setForm((p) => ({
                                            ...p,
                                            staff_id: '',
                                            taxID: '',
                                            staff_name: ''
                                        }))
                                    }}
                                    col={12}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="name">Team</Label>
                                <Input
                                required={true}
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                            <FormGroup>
                                <Label for="tax_station">Tax Station</Label>
                                <CustomTypeahead
                                    labelKey={i => `${i.name}`}
                                    name="tax_station"
                                    id="tax_station"
                                    options={taxStations}
                                    clearButton
                                    createNew
                                    selected={selectedStation}
                                    onChange={handleSelectStation}
                                    col={12}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for="street">Street</Label>
                                <Input
                                    type="text"
                                    name="street"
                                    id="street"
                                    value={form.street}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Col className='text-center'><Button type="submit" color="primary">Add to list</Button></Col>
                    {/* {JSON.stringify(form)} */}
                    <table>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Staff name</th>
                                <th>Tax Station</th>
                                <th>Team</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {loading? <Spinner />:<tbody>

                        {oldStaff?.map((staff, idx) => <tr key={idx}>
                                <th>{idx + 1}</th>
                                <th>{staff.name}</th>
                                <th>{staff.tax_station}</th>
                                <th>{staff.team}</th>

                                <td><Button onClick={()=>deleteOld(staff)} className='bg-danger text-white' sm>X</Button></td>
                            </tr>)}
                            {newStaff?.map((staff, idx) => <tr key={idx}>
                                <th>{idx + 1}</th>
                                <th>{staff.staff_name}</th>
                                <th>{staff.tax_station}</th>
                                <th>{staff.name}</th>
                                <td><Button onClick={()=>deleteNew(staff.name)} className='bg-danger text-white' sm>X</Button></td>
                            </tr>)}
                        </tbody>}
                    </table>
                    {newStaff.length ? <Col className='text-center'><Button onClick={handleSubmit} color="primary">Submit</Button></Col> : null}
                </Form>
            </Card.Body>
        </Card>
    );
};

export default TaxStationTeams;
