import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Card,
  Row,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import CustomBarChart from "./NewCharts/CustomBarChart";
import PieChartComponent from "./NewCharts/CustomPieChart";
import {
  data2,
  data3,
  data4,
  pieChartData,
  pieChartData1,
  pieChartData2,
  pieChartData3,
} from "./data";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
// import { _fetchApi } from "redux/action/api";
import reportsLineChartData from "./reportsLineChartData.js";
// import Arrow from "./Arrow";
// import { formatNumber } from "redux/action/api";
import { PDFViewer } from "@react-pdf/renderer";
// import PerformancePdf from "components/Tree/RevenuePerformancePdf";
import { unflatten } from "../../redux/action/api";
import { _fetchApi } from "../../redux/action/api";
import { formatNumber } from "../../utils";
import CustomDateRange from "../../pages/dashboard/CustomDateRange.js";
import ArraowChartRevenue from "./ArraowChartRevenue.jsx";

export const getAllData = (query_type, date_from, date_to, cb) => {
  _fetchApi(
    `/dashboard-budget?query_type=${query_type}&from=${date_from}&to=${date_to}`,
    (data) => {
      cb(data.results);
    },
    (err) => {
      console.log(err);
    }
  );
};

const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1;
  if (month >= 1 && month <= 3) return "Q1";
  if (month >= 4 && month <= 6) return "Q2";
  if (month >= 7 && month <= 9) return "Q3";
  if (month >= 10 && month <= 12) return "Q4";
};
const getQuarterDateRange = (quarter) => {
  const year = new Date().getFullYear();
  switch (quarter) {
    case "Q1":
      return { from: `${year}-01-01`, to: `${year}-03-31` };
    case "Q2":
      return { from: `${year}-04-01`, to: `${year}-06-30` };
    case "Q3":
      return { from: `${year}-07-01`, to: `${year}-09-30` };
    case "Q4":
      return { from: `${year}-10-01`, to: `${year}-12-31` };
    default:
      return { from: "", to: "" };
  }
};

function NewDashboard() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const moments = moment().format("YYYY-MM-DD");
  const yearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");
  const year = moment().format("YYYY-MM-DD");
  const [selectQuarter, setSelectQuarter] = useState({
    quarter: getCurrentQuarter(),
    from: yearAgo,
    to: moments,
  });

  const { sales, tasks } = reportsLineChartData;
  const [mda, setMda] = useState([]);
  const [budgeted, setRevenueed] = useState([]);
  const [expense, setExpense] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [budgetActual, setRevenueActual] = useState([]);
  const [revenueByEconomic, setrevenueByEconomic] = useState([]);
  const [expByEconomic, setExpByEconomic] = useState([]);
  const [mainSector, setMainSector] = useState([]);
  const [expByFunc, setExpByFunc] = useState([]);

  useEffect(() => {
    getAllData("mda", selectQuarter.from, selectQuarter.to, setMda);
    getAllData(
      "budget_amount",
      selectQuarter.from,
      selectQuarter.to,
      setRevenueed
    );
    getAllData("expenses", selectQuarter.from, selectQuarter.to, setExpense);
    getAllData(
      "exp_by_func",
      selectQuarter.from,
      selectQuarter.to,
      setExpByFunc
    );
    getAllData("revenue", selectQuarter.from, selectQuarter.to, setRevenue);
    getAllData(
      "revenue_economic",
      selectQuarter.from,
      selectQuarter.to,
      setrevenueByEconomic
    );
    getAllData(
      "exp_economic",
      selectQuarter.from,
      selectQuarter.to,
      setExpByEconomic
    );
    getAllData(
      "exp_main_sector",
      selectQuarter.from,
      selectQuarter.to,
      setMainSector
    );
    getAllData(
      "budget-actual",
      selectQuarter.from,
      selectQuarter.to,
      (resp) => {
        // setRevenueActual(resp);
        const finalData = [
          {
            name: "TOTAL REVENUE",
            "Total Revenue": resp[0]["Total Revenue"],
            "Total Actual": resp[0]["Total Actual"],
            Performance:
              parseFloat(
                parseFloat(resp[0]["Total Actual"] || 0) /
                parseFloat(resp[0]["Total Revenue"])
              ) * 100,
            // amt: 120,
          },
          {
            name: "TOTAL EXPENDITURE",
            "Total Revenue": resp[0]["Total Revenue"],
            "Total Actual": resp[0]["Total Actual"],
            Performance:
              parseFloat(
                parseFloat(resp[0]["Total Actual"] || 0) /
                parseFloat(resp[0]["Total Revenue"])
              ) * 100,
            // amt: 120,
          },
        ];
        setRevenueActual(finalData);
      }
    );
  }, [selectQuarter.from, selectQuarter.to]);

  const toggleModal = (chart) => {
    setSelectedChart(chart);
    setModalOpen(!modalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectQuarter((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "quarter" && value !== "custom") {
      const dateRange = getQuarterDateRange(value);
      setSelectQuarter((prevState) => ({
        ...prevState,
        from: dateRange.from,
        to: dateRange.to,
      }));

      console.log("Date Range:", dateRange);
    }
  };
  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    setSelectQuarter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // date picker setup
  const today = moment();
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  const getCustomDate = (quarter) => {
    if (quarter === "custom") {
      return (
        <div className="d-flex">
          <div>
            <label>From</label> <br />
            <Input
              type="date"
              id="from"
              name="from"
              className="w-100"
              value={selectQuarter.from}
              onChange={handleCustomDateChange}
            />
          </div>
          <div>
            <label>To</label> <br />
            <Input
              type="date"
              id="to"
              name="to"
              className="w-100"
              value={selectQuarter.to}
              onChange={handleCustomDateChange}
            />
          </div>
        </div>
      );
    }
    return null;
  };
  let newRev = revenueByEconomic?.map((item) => ({
    ...item,
    name: item.description,
    "Quarterly Revenue": item.approve_amount,
    "Actual for Quarter": item.actual_amount,
    Performance: 30,
  }));

  // let expByEconomic = sumRevenueData(expByEconomic);
  // let mainSector = sumMainData(mainSector);

  const [generate, setGenerate] = useState(false);
  const toggle = () => {
    setGenerate(!generate);
  };
  const [treeData, setTreeData] = useState([]);
  const [treeData1, setTreeData1] = useState([]);
  const [treeData2, setTreeData2] = useState([]);
  const [treeData3, setTreeData3] = useState([]);
  const [treeData4, setTreeData4] = useState([]);
  const [treeData5, setTreeData5] = useState([]);
  const [treeData6, setTreeData6] = useState([]);
  const [treeData7, setTreeData7] = useState([]);
  const [treeData8, setTreeData8] = useState([]);
  const [treeData9, setTreeData9] = useState([]);
  const [treeData10, setTreeData10] = useState([]);
  const [treeData11, setTreeData11] = useState([]);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=total_ex_by_function&budget_year=2024&like=null`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_function&budget_year=2024&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData1(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_function&budget_year=2024&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData2(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_function&budget_year=2024&like=23%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData3(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_program&budget_year=2024&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData4(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=total_revenue_by_admin&budget_year=2024&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData5(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=expenditure_by_department&budget_year=2024&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData6(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_admin&budget_year=2024&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData7(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_admin&budget_year=2024&like=23%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData8(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_admin&budget_year=2024&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData9(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=total_revenue_by_economic&budget_year=2024&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData10(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/reports?query_type=other_exp_func&budget_year=2024&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflatten(data?.results);
        setTreeData11(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);
  return (
    <Row className="m-0 p-0">
      {/* {JSON.stringify(expByFunc)} */}
      <Col md={12}>
        <CustomDateRange
          type={dateRange.type}
          month={dateRange.month}
          quarter={dateRange.quarter}
          year={dateRange.year}
          from={dateRange.from}
          to={dateRange.to}
          handleChangeDate={handleChangeDate}
        />
      </Col>
      <Modal
        size="lg"
        className="p-3 rounded"
        isOpen={modalOpen}
        toggle={toggleModal}
        centered
        style={{
          marginLeft: 300,
          fontSize: 12,
        }}
      >
        <ModalHeader toggle={toggleModal}>
          {selectedChart && selectedChart.title}
        </ModalHeader>
        <ModalBody>{selectedChart && selectedChart.chart}</ModalBody>
      </Modal>
      {/* <Row>
        <Col md={12}>
          <div className="d-flex justify-content-between mb-3">
            <div className="col-md-4">
              <label>Select Quarter</label>
              <Input
                type="select"
                name="quarter"
                value={selectQuarter.quarter}
                onChange={handleChange}
              >
                <option value="Q1">Q1</option>
                <option value="Q2">Q2</option>
                <option value="Q3">Q3</option>
                <option value="Q4">Q4</option>
                <option value="custom">Custom</option>
              </Input>
            </div>
            <div className="col-md-6 ">
              {getCustomDate(selectQuarter.quarter)}
            </div>
            <div className="col-md-3">
              <Button color="primary" onClick={toggle}>
                {generate
                  ? "Close"
                  : `Generate ${selectQuarter.quarter} Report`}
              </Button>
            </div>
          </div>
        </Col>
      </Row> */}
      {generate ? (
        <>
          {loading && (
            <div className="loading-container text-center">
              <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
              Loading...
            </div>
          )}
          <PDFViewer width={1500} height={2000}>
            {/* <PerformancePdf
              data={treeData}
              data2={treeData1}
              data3={treeData2}
              data4={treeData3}
              data5={treeData4}
              data6={treeData5}
              data7={treeData6}
              data8={treeData7}
              data9={treeData8}
              data10={treeData9}
              data11={treeData10}
              data12={treeData11}
            /> */}
          </PDFViewer>
        </>
      ) : (
        <>
          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      2024 AGGREGATE PERFORMANCE
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <CustomBarChart
                              data={budgetActual}
                              width={600}
                              height={400}
                              uvDataKey="Total Revenue"
                              pvDataKey="Total Actual"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={budgetActual}
                    width={420}
                    height={400}
                    uvDataKey="Total Revenue"
                    pvDataKey="Total Actual"
                  />
                </Row>
              </div>
            </Card>
          </Col>

          <ArraowChartRevenue />

          <Col md={7}>
            <Card
              body
              className="my-2"
            // style={{
            //   height: "24em",
            // }}
            >
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      {dateRange.year} REVENUE PERFORMANCE BY SECTORS
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <CustomBarChart
                              data={newRev}
                              width={600}
                              height={400}
                              uvDataKey="Quarterly Revenue"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={newRev}
                    width={420}
                    height={400}
                    uvDataKey="Quarterly Revenue"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      2024 REVENUE COMPOSITION BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: <PieChartComponent data={pieChartData} />,
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={pieChartData} />
                </Row>
              </div>
            </Card>
          </Col>

          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "80%",
                      }}
                    >
                      2024 EXPENDITURE PERFORMANCE BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <CustomBarChart
                              data={data2(expByEconomic)}
                              width={600}
                              height={400}
                              uvDataKey="Quarterly Revenue"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    // data={data2(expByEconomic)}
                    width={420}
                    height={400}
                    uvDataKey="Quarterly Revenue"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      2024 EXPENDITURE COMPOSITION BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <PieChartComponent
                              data={data2(expByEconomic, "pie")}
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data2(expByEconomic, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>

          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      2024 EXPENDITURE PERFORMANCE BY MAIN SECTOR
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <CustomBarChart
                              data={data3(mainSector)}
                              width={600}
                              height={400}
                              uvDataKey="Quarterly Revenue"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={data3(mainSector)}
                    width={420}
                    height={400}
                    uvDataKey="Quarterly Revenue"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      2024 EXPENDITURE COMPOSITION BY MAIN SECTOR
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <PieChartComponent
                              data={data3(mainSector, "pie")}
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data3(mainSector, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>

          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "80%",
                      }}
                    >
                      2024 EXPENDITURE PERFORMANCE BY FUNCTION
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <CustomBarChart
                              data={data4(expByFunc)}
                              width={600}
                              height={400}
                              uvDataKey="Quarterly Revenue"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={data4(expByFunc)}
                    width={420}
                    height={400}
                    uvDataKey="Quarterly Revenue"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      2024 EXPENDITURE COMPOSITION BY MAIN SECTOR
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                          chart: (
                            <PieChartComponent data={data4(expByFunc, "pie")} />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data4(expByFunc, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
}

export default NewDashboard;
