import React, { useState } from "react";
import { Nav } from "@themesberg/react-bootstrap";
import { toParagraph } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import useIsPhoneSize from "../../../utils/useIsPhoneSize";

let randomColors = [
  "#515566",
  "#EB7F9A",
  "#6EAF46",
  "#E1C75A",
  "#8D73F2",
  "#3FC2D2",
  "#D98263",
  "#7FBE57",
  "#BF4EA8",
  "#4765E3",
];

const CustomDropdown = ({
  data,
  form = {},
  department = null,
  setForm = (f) => f,
  openModal = (f) => f,
}) => {

  const isPhoneSize = useIsPhoneSize();
  const handleItemClick = (child) => {
    setForm((prevForm) => ({
      ...prevForm,
      ...child,
    }));
  };
  let valid_items;
  if (department) {
    valid_items = data.filter((item) => item.department === department);
  } else {
    valid_items = data;
  }
  const [isOpenMap, setIsOpenMap] = useState({}); // Dictionary-like object to store isOpen state for each CollapsableNavItem

  const CollapsableNavItem = ({ item, children = null, form }) => {
    const { id, title } = item;
    const isOpen = isOpenMap[id]; // Read isOpen state from the dictionary
    const toggleOpen = () => {
      setIsOpenMap((prevIsOpenMap) => ({
        ...prevIsOpenMap,
        [id]: !prevIsOpenMap[id], // Toggle the isOpen state for the specific item
      }));
    };

    return item?.tax_fee !== "0.00" ? (
      <div className="m-0 p-0" style={{ backgroundColor: randomColors[0], }}>
        <Nav.Item
          className="m-0 p-0 mx-1"
          onClick={() => {
            toggleOpen();
            handleItemClick({
              ...item,
              tax_code: item.tax_code,
              tax_parent_code: item.tax_code,
              parent: item.title,
              economic_code: item.economic_code,
            });
          }}
        >
          <Nav.Link
            className={`d-flex flex-direction-row btn-outline justify-content-between align-items-center 
            mx-0 ml-2 my-1 px-1 ${isOpen ? "bg-gray-700 text-light" : "bg-black text-light"
              }`}
          >
            <div className="p-1">{title}</div>
            <FontAwesomeIcon
              icon={isOpen ? faCaretUp : faCaretDown}
              size="lg"
              className="ms-1"
            />
          </Nav.Link>
        </Nav.Item>

        {isOpen && <Nav className="flex-column ms-4">{children}</Nav>}
      </div>
    ) : null;
  };

  const generateMenuItems = (items) => {
    return items?.map((item, index) => {
      if (item?.children && item?.children.length > 0) {
        return (
          <CollapsableNavItem
            key={index}
            index={index}
            eventKey={item.title}
            item={item}
            icon={item.icon}
            id={item.id}
            form={form}
            setForm={setForm}
            style={{ backgroundColor: "red" }}
          >
            <div
              className="ml-2"
              style={{ opacity: "0.9", color: "white", marginLeft: "3px" }}
            >
              {generateMenuItems(item.children)}
            </div>
          </CollapsableNavItem>
        );
      } else {
        return item?.tax_fee !== "0.00" ? (
          <Nav.Item
            key={index}
            style={{
              fontWeight: form.id === item.id ? "bolder" : "normal",
              border: "1px solid white",
              margin: "1.5px",
              borderRadius: "10px",
            }}
            onClick={() => {
              handleItemClick({
                ...item,
                parent: item.tax_parent_code || "",
              });
              openModal();
            }}
            className="mx-0 ml-2 py-1 px-2 "
          >
            <span
              className={`d-flex justify-content-between align-items-center p-1  ${form.id === item.id
                ? "bg-light text-dark"
                : "bg-dark text-light"
                }`}
            >
              <span>
                {item.icon ? (
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={item.icon || null} />{" "}
                  </span>
                ) : null}
                <span className="sidebar-text" onClick={openModal}>
                  {item.title}
                </span>
              </span>
            </span>
          </Nav.Item>
        ) : null;
      }
    });
  };

  return (
    <div className="list-group m-0 p-0">{generateMenuItems(valid_items)}</div>
  );
};

export default CustomDropdown;
