import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Modal, Row } from "@themesberg/react-bootstrap";
import { _fetchApi } from "../../redux/action/api";
import { Table } from "reactstrap";
import { SearchBar } from "../../components/UI";
import { formatNumber, toCurrency } from "../../utils";
import PaymentsContainer from "./components/PaymentsContainer";
import "./checkbox.css";
import CustomDropdown from "./components/TaxDropdown";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { WelcomeMessage } from "./LandPayments";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { convertToMenu } from "./NonTaxPayments";
import { TAX_ACCOUNT } from "../../redux/action/type";
import DaterangeSelector from "../dashboard/DaterangeSelector";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import AddAccout from "../AddAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function VehiclePayments() {
  const _form = {
    page_title: "VEHICLE LICENCE",
    type: "",
    month: "",
    org: "",
    amount: "",
    sector: "",
    tax_id: "",
    step: 0,
    attachment: null,
    rows: [],
    type_child: "",
    tax_code: "",
    tax_parent_code: "",
    taxes: "",
    selected: [],
    sector: "VEHICLES",
    isLastParent: false,
  };
  const [form, setForm] = useState(_form);
  const [filterText, setFilterText] = useState("");
  const [mainTaxes, setMainTaxes] = useState([]);
  const [subTaxList, setSubTaxList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [tax_payers, setTaxpayers] = useState([]);
  const [tax_payer, setTaxpayer] = useState('');
  
  const dispatch = useDispatch()
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);

  const handleFilterChange = (e) => {
    setFilterText(e);
  };
  const handleFocus = (name) => {
    setTaxpayer(name);
    setTimeout(() => {
        toast.success('You can now close and search the tax payer by name or ID');
        toggleModal();
        // myRef.current.focus();
    }, 2000);
};

  const handleChange = ({
    target: { name, value, selected, checked, type },
  }) => {
    if (type === "checkbox") {
      if (checked) {
        setForm((prevState) => ({
          ...prevState,
          [name]: prevState[name] ? `${prevState[name]}, ${value}` : value,
        }));
      } else {
        setForm((prevState) => {
          if (prevState[name]) {
            const updatedValues = prevState[name]
              .split(", ")
              .filter((item) => item !== value);
            const updatedValue = updatedValues.length
              ? updatedValues.join(", ")
              : "";
            return { ...prevState, [name]: updatedValue };
          }
          return prevState;
        });
      }
    } else if (type === "select") {
      setForm((prevState) => ({ ...prevState, [name]: selected }));
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const filteredTaxList = filterText.length
    ? subTaxList.filter((item) => {
      return (
        // item.economic_code
        //   .includes(filterText) ||
        item.title.toLowerCase().includes(filterText.toLowerCase())
      );
    })
    : subTaxList;

  const SELECTED_TAX_ITEMS = form.taxes
    ? subTaxList.filter((item) => {
      return form.taxes.includes(item.title);
    })
    : [];

  const getList1 = useCallback((tax_code) => {
    setLoading1(true);
    _fetchApi(
      `/kigra-taxes?query_type=select-sub&parent_code=${tax_code}`,
      (resp) => {
        if (resp.result.length) {
          setSubTaxList(resp.result);
          setForm((p) => ({
            ...p,
            taxes: resp.result
              ?.filter((item) => item.default === "checked")
              ?.map((item) => item.title)
              ?.join(", "),
          }));
          setLoading1(false);
        }
        setLoading1(false);
      },
      (err) => {
        console.error(err);
        setLoading1(false);
      }
    );
  }, []);

  useEffect(() => {
    getList1(form.tax_code);
  }, [getList1, form.tax_code]);
  const getList = useCallback(() => {
    setLoading(true);
    _fetchApi(
      "/kigra-taxes?query_type=select-heads&sector=VEHICLES",
      (resp) => {
        setMainTaxes(resp.result);
        setLoading(false);
      },
      () => {
        setMainTaxes([]);
        setLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    const _selected = subTaxList
      .filter((t) => t.default === "checked")
      ?.map((item) => item.title)
      ?.join(", ");
    setForm((p) => ({
      ...p,
      taxes: _selected,
    }));
  }, [subTaxList]);

  // Manually provide the rootData if not present in the data array
  const rootData = mainTaxes.find((item) => item.tax_code === "VEHICLES");
  //   const menuData = convertToMenu(data, rootData);
  //   console.log(menuData);
  const rearrangedArray = convertToMenu(mainTaxes, rootData);

  // console.log({ rearrangedArray, rootData });

  const filteredArray = rearrangedArray.flatMap((parent) => {
    // Check if the parent title includes the search text
    if (parent.title.toLowerCase().includes(searchText.toLowerCase())) {
      return [parent]; // Include the parent if there's a match
    }

    // Check if any of the children titles include the search text
    if (parent.children) {
      const matchingChildren = parent.children.filter((child) =>
        child.title.toLowerCase().includes(searchText.toLowerCase())
      );

      if (matchingChildren.length > 0) {
        return [
          {
            ...parent,
            children: matchingChildren,
          },
        ]; // Include the parent with matching children if any child matches
      }
    }

    return []; // Exclude the parent if no match found in title or children titles
  });

  const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);
  const handleDateRangeChange = ({ target: { name, value } }) => {
    if (name === 'from') {
      setForm((p) => ({ ...p, from: value }));
    } else if (name === 'to') {
      setForm((p) => ({ ...p, to: value }));
    }
  };


  const getTaxPayers = useCallback(() => {
    if (tax_payer.length > 2) {
        _fetchApi(`/users/search-tax-payers?user_id=${tax_payer}&max=50`, (resp) => {
            if (resp.success) {
                setTaxpayers(resp.data);
            }
        });
    } else if (tax_payer.length === 0) {
        _fetchApi(`/users/search-tax-payers?user_id=${tax_payer}&max=50`, (resp) => {
            if (resp.success) {
                setTaxpayers(resp.data);
            }
        });
    }
}, [tax_payer]);

useEffect(() => {
    getTaxPayers();
}, [getTaxPayers]);

const handleAddList = (cb = (f) => f) => {
  if (!form.from || !form.to) {
      toast.error('Please select date');
  } else {
      // setForm(_form);
      cb();
  }
};

  return (
    <PaymentsContainer
      open={open}
      openModal={openModal}
      form={form}
      handleAddList={handleAddList}
      search={
        <SearchBar
          filterText={searchText}
          onFilterTextChange={(val) => setSearchText(val)}
          placeholder="Search by tax name"
          className="bg-dark text-white"
        />
      }
      menu={
        loading ? (
          <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
            <p>
              <Skeleton count={10} height={50} />
            </p>
          </SkeletonTheme>
        ) : (
          <div className="p-1">
            <CustomDropdown
              form={form}
              setForm={setForm}
              data={filteredArray}
              openModal={openModal}
            />
          </div>
        )
      }
      selected_taxes={SELECTED_TAX_ITEMS.map((p) => ({
        ...p,
        checked:p.default==='checked'
      }))}
    >
      {/* {JSON.stringify(form, filteredTaxList)} */}
      {form.tax_code ? (
        <Row className="m-0 p-0">
          {/* {JSON.stringify(SELECTED_TAX_ITEMS)} */}
          <Col className="p-0 mb-5" style={{ zIndex: 999 }}>
            <div>
              {/* <hr /> */}
              {loading1 ? (
                <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
                  <p>
                    <Skeleton count={10} height={45} />
                  </p>
                </SkeletonTheme>
              ) : (

                <Row className="m-0">
                  <Col md={12} >
                    <DaterangeSelector from={form.from} to={form.to} handleChange={handleDateRangeChange} />
                    <Row  className="mb-2">
                      <Col md={8}>
                        <CustomTypeahead
                          onInputChange={(e) => {
                            setTaxpayer(e);
                            console.log(e, 'TYPED');
                          }}
                          labelKey={(e) =>
                            `${e.account_type === 'org' ? e.org_name : e.name}(${e.taxID
                            }) ${e.phone ? `:${e.phone || e.office_phone}` : ''}`
                          }
                          onChange={(e) => {
                            if (e && e[0]?.id) {
                              dispatch({
                                type: TAX_ACCOUNT,
                                payload: e[0],
                              });
                            }
                          }}
                          clearButton
                          col={12}
                          label={`Select Tax payer`}
                          options={tax_payers}
                          placeholder="Search Name | TaxID | Phone No."
                        />
                      </Col>
                      <Col md={4} className="pt-2 text-right text-end">
                        <Button
                          onClick={() => {
                            // alert(showDefault)
                            toggleModal();
                          }}
                          className="bg-white mt-4 ml--2 text-dark text-xs"
                        >
                          <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                        </Button>
                      </Col>
                    </Row>
                    <div className="mb-2">
                      <SearchBar
                        filterText={filterText}
                        onFilterTextChange={handleFilterChange}
                        placeholder="Search tax"
                      />
                    </div>
                    <div className="text-end">
                      Total Amount:{" "}
                      {formatNumber(
                        SELECTED_TAX_ITEMS.reduce(
                          (a, b) => a + parseFloat(b.tax_fee),
                          0
                        )
                      )}
                    </div>{" "}
                    <Col md={12} className="">
                    <table
                    size="sm"
                    style={{ width: "100%", border: "1px solid black" }}
                  >
                        <thead>
                          <tr>
                            <th
                              style={{ borderWidth: 1, borderColor: "black", paddingLeft:5 }}
                              width="5%"
                            >
                              SN
                            </th>
                            <th
                              style={{ borderWidth: 1, borderColor: "black" }}
                              width="80%"
                            >
                              Item
                            </th>
                            <th style={{ borderWidth: 1, borderColor: "black" }}>
                              Amount (₦){" "}
                            </th>
                            <th
                              style={{ borderWidth: 1, borderColor: "black" }}
                              width="5%"
                              className="text-right"
                            >
                              SELECT
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredTaxList?.map((item, idx) => (
                            <tr key={idx}>
                              <td
                                style={{ borderWidth: 1, borderColor: "black" }}
                                className="text-center"
                              >
                                {idx + 1}
                              </td>
                              <td
                                style={{ borderWidth: 1, borderColor: "black", paddingLeft:5 }}
                              >
                                {' '}{item.title}
                              </td>
                              <td
                                style={{ borderWidth: 1, borderColor: "black", paddingRight:5 }}
                                className="text-end"
                              >
                                {formatNumber(item.tax_fee)}
                              </td>
                              <td
                                style={{ borderWidth: 1, borderColor: "black" }}
                                className="text-center"
                              >
                                <input
                                  className="large-check"
                                  onChange={
                                    item.default === "checked"
                                      ? null
                                      : handleChange
                                  }
                                  checked={
                                    form.taxes.includes(item.title) ||
                                    item.default === "checked"
                                  }
                                  value={item.title}
                                  type="checkbox"
                                  name="taxes"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Modal as={Modal.Dialog} centered show={showDefault} size="lg" style={{ zIndex: showDefault ? 9999 : 1 }} scrollable>
            <Modal.Header>
              <Modal.Title className="h6">EXPRESS TAX PAYER FORM</Modal.Title>
              <Button variant="danger" className="bg-danger text-white" aria-label="Close" onClick={toggleModal}>
                X
              </Button>
            </Modal.Header>
            <Modal.Body>
              <AddAccout isModal={true} handleFocus={handleFocus} />
            </Modal.Body>
          </Modal>
        </Row>
      ) : (
        <WelcomeMessage />
      )}
    </PaymentsContainer>
  );
}
