export const IndurialSectors = [
    'Banks & Financial Institutions',
    'Professional Services',
    'Health',
    'Manufacturing',
    'Building & Construction',
    'Commercial & Trading',
    'Education',
    'Hotels & Catering',
    'Oil & Gas',
    'Agric & Plantation',
    'NGO',
    'MDA',
    'Transport & Haulage',
    'Technology & Telecoms',
    'LGA Council',
    'Media',
    'Religious',
    'Aviation',
    'Publishing, Printing & Packaging',
    'Others',
];

export const orgType = [
    'Federal Governement Organization(IPPIS)',
    'Federal Governement Organization(Self Accounting)',
    'State Governement Organization(Self Accounting)',
    'State Governement Organization(Others)',
    'Local Governement Organization',
    'Private Organization',
    'Informal Organization',
];
