import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // Added faDownload
import { Col, Row, Button, Card } from "@themesberg/react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"; // Added PDFDownloadLink
// import InvoicePdf from "../components/InvoicePdf";
import { useQuery } from "../utils";
import { _fetchApi, _postApi, apiURL } from "../redux/action/api";
import PaymentButton from "./payments/PaymentButton";
import { useHistory } from "react-router-dom";
import LandTermalInvoice from "../components/LandTermalInvoice";
import { LandAccountantInvoice } from "./LandAccountantInvoice";
import { useDispatch, useSelector } from "react-redux";
import { CertificateOfCompletion } from "../components/CertificateOfCompletion";
import useIsPhoneSize from "../utils/useIsPhoneSize";
import TermalInvoice from "../components/TermalInvoice";
import toast from "react-hot-toast";
import GenericInvoicePdf from "../components/GenericInvoicePdf";
import { emptyCart } from "../redux/action/cart";

export default function GenericInvoice({ refNo = null }) {
  const q = useQuery();
  const ref_no = refNo ? refNo : q.get("ref_no");
  const page = refNo ? refNo : q.get("page");
  const [qrcode, setQrCode] = useState(null);
  const userInfo = useSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [user, setUser] = useState({});
  const [user_id, setUserID] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const agent = useSelector((state) => state.auth.user);
  const { tax_account = [] } = useSelector((state) => state.auth);
  const isMobile = useIsPhoneSize();
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (ref_no) {
      setLoading(true);
      _fetchApi(
        `/transactions/retrieve?query_type=view_invoice&reference_number=${ref_no}&user_id=${userInfo.id}`,
        (resp) => {
          if (resp.success) {
            setList(resp.data);
            if (resp.data[0]?.user_id) {
              setLoading(false);
              dispatch(emptyCart())
              setUserID(resp.data[0].user_id);
            }
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setList([]);
          setLoading(false);
        }
      );
    }
    if (user_id) {
      _fetchApi(
        `/users/get-tax-payer?user_id=${user_id}`,
        (resp) => {
          if (resp.success) {
            setUser(resp.data);
          }
        },
        (err) => {
          console.error(err);
          setUser({});
        }
      );
    }
    if (ref_no) {
      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${ref_no}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [ref_no, user_id]);

  return (
    <div className="p-0 m-0 h-100 text-center">
      {loading ? (
        <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
          <p>
            <Skeleton count={15} height={50} />
          </p>
        </SkeletonTheme>
      ) : (
        page === 'pay-now' ?
          <>
            <Card>
              <Card.Header>
                <Col className="text-left" md={2}>
                  <Button onClick={() => history.goBack()}>Back</Button>
                </Col>
                <hr />
                Complete Payment
              </Card.Header>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col md={4} className="text-center p-3">
                    <p>Proceed with your <b>Online Payment</b> using the Reference Number <b>{ref_no}</b></p>
                    <PaymentButton
                      label="Complete Payment"
                      onMouseOver={() => { }}
                      addStyle={{ with: "100%", marginTop: 10 }}
                      amount={list.reduce((it, id) => it + parseFloat(id.dr), 0)}
                      reference_no={ref_no}
                      email={tax_account.org_email || tax_account.email}
                      name={tax_account.org_name || tax_account.name}
                      taxID={tax_account.taxID}
                      phone={tax_account.office_phone || tax_account.phone}
                      data={list}
                      sector={list[0]?.sector}
                      callback={()=>toast.success('Done')}
                      toggle={toggle}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
          : <>
            <Row className=" mb-2 pb-1 ">
              <Col className="text-left" md={2}>
                <Button onClick={() => history.goBack()}>Back</Button>
              </Col>
              <Col>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <div style={{ width: '300px', float: 'right' }}>
                      <PaymentButton
                        label="Pay Online"
                        onMouseOver={() => { }}
                        addStyle={{ with: "100%", marginTop: 10 }}
                        amount={list.reduce((it, id) => it + parseFloat(id.dr), 0)}
                        reference_no={ref_no}
                        email={tax_account.org_email || tax_account.email}
                        name={tax_account.org_name || tax_account.name}
                        taxID={tax_account.taxID}
                        phone={tax_account.office_phone || tax_account.phone}
                        data={list}
                        sector={list[0]?.sector}
                        callback={()=>toast.success('Done')}
                        toggle={toggle}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="mt-2 pb--2 text-right" offset='2'>
                {list[0]?.sector !== "LAND" ? null : (
                  <>
                    <PDFDownloadLink
                      document={
                        <LandTermalInvoice
                          user={user}
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button block variant="primary">
                            <FontAwesomeIcon icon={faDownload} className="me-2" />
                            Download Invoice PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                    {list[0]?.department === "SLTR" ? (
                      <PDFDownloadLink
                        document={
                          <CertificateOfCompletion
                            user={agent}
                            tax_account={tax_account}
                            data={list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                          />
                        }
                        fileName="SLTR-invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button block variant="primary">
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="me-2"
                              />
                              Certificate of Completion PDF
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : list[0]?.department === "Land" ? (
                      <PDFDownloadLink
                        document={
                          <LandAccountantInvoice
                            data={list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                          />
                        }
                        fileName="Land-invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button block variant="primary">
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="me-2"
                              />
                              Download Payment Schedule PDF
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                  </>
                )}
              </Col>
            </Row>
            <>
              {list[0]?.sector !== "LAND" ? (
                <Row style={{ height: '70vh' }}>
                  <Col md={8}>
                    <Row>
                      <Col md={12}>
                        <PDFDownloadLink
                          document={
                            <GenericInvoicePdf
                              user={user}
                              data={list
                                .filter((item) => item.cr > 0)
                                .map((l) => ({ ...l, tax_fee: l.cr }))}
                              qrcode={qrcode}
                            />
                          }
                          fileName="invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <Button block variant="primary">
                                <FontAwesomeIcon icon={faDownload} className="me-2" />
                                Download PDF
                              </Button>
                            )
                          }
                        </PDFDownloadLink>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ height: '70vh' }}>
                        <PDFViewer height="100%" width="70%">
                          <GenericInvoicePdf
                            user={user}
                            data={list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                            qrcode={qrcode}
                          />
                        </PDFViewer>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <PDFDownloadLink
                      document={
                        <TermalInvoice
                          user={user}
                          agent={userInfo}
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                        />
                      }
                      fileName="termal-invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button block variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Termal Invoice
                          </Button>
                        )
                      }
                    </PDFDownloadLink>

                    <PDFViewer height="100%" width="70%">
                      <TermalInvoice
                        user={user}
                        agent={userInfo}
                        data={list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                      />
                    </PDFViewer>
                  </Col>
                </Row>
              ) : (
                <>
                  {list[0]?.department === "Land" && isMobile ? (
                    <>
                      {" "}
                      <PDFViewer height="100%" width="50%">
                        <LandTermalInvoice
                          user={user}
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                          agent={agent}
                        />
                      </PDFViewer>
                      <PDFViewer height="100%" width="50%">
                        <LandAccountantInvoice
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      </PDFViewer>
                    </>
                  ) : list[0]?.department === "SLTR" && isMobile ? (
                    <>
                      <PDFViewer height="100%" width="50%">
                        <LandTermalInvoice
                          user={user}
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                          agent={agent}
                        />
                      </PDFViewer>
                      <PDFViewer height="100%" width="50%">
                        <CertificateOfCompletion
                          user={agent}
                          tax_account={tax_account}
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      </PDFViewer>
                    </>
                  ) : (
                    <>
                      {isMobile ? null : <><PDFViewer height="100%" width="100%">
                        <LandTermalInvoice
                          user={user}
                          data={list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                          agent={agent}
                        />
                      </PDFViewer></>}
                    </>)}
                </>
              )}
            </>
          </>
      )}
    </div>
  );
};
