import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Table,
  Spinner
} from "reactstrap";
import { _fetchApi } from "../redux/action/api";
import { SearchBar } from "./UI";
import { FaRegEye } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function ViewConsultant() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    limit: 50,
    offset: 0
  });

  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  // const [searchType, setSearchType] = useState("ID");

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/getAllTaxconsultant?query_type=${searchInput.length > 2 ? 'select' : 'select-all'}&limit=${form.limit}&offset=${form.offset}&taxID=${searchInput}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setData(res.data || [])
          setTotalItems(res.data[0].total_rows)
        }
      },
      (err) => {
        setLoading(false);
        setTotalItems(0)
        console.log(err);
      }
    );
  }, [form]);

  // const handleSearchInput = (e) => {
  //   setSearchInput(e.target.value);
  // };


  const getSector = type => {
    switch (type) {
      case 'LAND':
        return 'Land use Specialist'
      case 'NON TAX':
        return 'Non Tax Specialist'
      case 'VEHICLES':
        return 'Motor License Specialist'
      case 'LGA':
        return 'LGA Revenue Specialist'
      default:
        return 'Tax Revenue Specialist'
    }
  }

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / form.limit))
  }, [form.limit, totalItems]);

  const gotoPage = (x) => {
    setForm((prevForm) => ({
      ...prevForm,
      offset: x * form.limit,
    }));
  };

  const currentPage = Math.ceil((form.offset + 1) / form.limit);

  const handleSearch = () => {
    setForm(() => {

    })
  }
  return (
    <div>
      <Card>
        <CardHeader className="bg-light">
          <h4>Search For Consultant</h4>
        </CardHeader>
        <CardBody>
          {loading && (
            <div>
              <Spinner />
              Loading...
            </div>
          )}
          {totalItems > 1 ? (
            <Row>
              <Col md={3}>
                <Input
                  placeholder="Items page"
                  type="select"
                  width={"100px"}
                  value={form.limit}
                  onChange={({ target: { value } }) =>
                    setForm((p) => ({
                      ...p,
                      limit: value,
                      offset: value * (currentPage - 1),
                    }))
                  }
                >
                  {[50, 100, 200, 300, 400, 500].map((lmt, idx) => (
                    <option key={idx} value={lmt}>
                      Show {lmt} rows
                    </option>
                  ))}
                </Input>
              </Col>
              <Col>
                {currentPage > 1 && (
                  <>
                    <Button size="sm" onClick={() => gotoPage(0)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </Button>
                    <Button size="sm" onClick={() => gotoPage(currentPage - 1)}>
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                  </>
                )}
                {[...Array(Math.min(10, totalPages))].map((_, index) => {
                  const pageNumber = currentPage + index - Math.floor(10 / 2);
                  return pageNumber > 0 && pageNumber <= totalPages ? (
                    <Button
                      key={index}
                      size="sm"
                      className={currentPage === pageNumber ? "bg-light text-dark" : ""}
                      onClick={() => gotoPage(pageNumber - 1)}
                    >
                      {pageNumber}
                    </Button>
                  ) : null;
                })}
                {currentPage < totalPages && (
                  <>
                    <Button size="sm" onClick={() => gotoPage(currentPage)}>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                    <Button size="sm" onClick={() => gotoPage(1)}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Button>
                  </>
                )}
              </Col>
            </Row>) : null}
          <Form>
            <Row>
              <Col>
                <SearchBar
                  filterText={searchInput}
                  onFilterTextChange={(e) => setSearchInput(e)}
                />
              </Col>
              <Col md={2} style={{ marginTop: "29px" }}>
                <Button onClick={handleSearch}>Search</Button>
              </Col>
            </Row>
          </Form>
          <Table responsive>
            <thead>
              <tr>
                <th>Consultant ID</th>
                <th>KIRMAS ID</th>
                <th>Consultant Name</th>
                {/* <th>Rating Scores</th> */}
                <th>Tax sector</th>
                <th>Operations</th>
              </tr>
            </thead>

            {loading ?
              (
                <tbody>
                  <tr>
                    <td colSpan={4} className="text-center">
                      <Spinner
                        color="light"
                        className="spinner"
                        type="grow"
                        style={{ margin: "20px auto" }}
                      >
                        ""
                      </Spinner>
                    </td>
                  </tr>
                </tbody>
              ) : data.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={4} className="text-center">No data found</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {data?.map((item, idx) => (
                    (
                      <tr key={idx}>
                        <td scope="row">{idx + 1}</td>
                        <td>{item.taxID}</td>
                        <td>{item.name}</td>
                        {/* <td>{item.name}</td> */}
                        <td>{getSector(item.sector)}</td>
                        <td className="text-center"><Button size='sm' color='info' variant='info' className=""><FaRegEye /></Button></td>
                      </tr>
                    ))
                  )}
                </tbody>
              )
            }
          </Table>
        </CardBody>
      </Card>
    </div >
  );
}

export default ViewConsultant;
