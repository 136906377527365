import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, CardHeader, Label } from "reactstrap";

export const PayeSalaryType = () => {
  const navigate = useHistory();
  return (
    <div>
      <Card>
        <CardHeader
          style={{
            fontWeight: "bolder",
          }}
          className="bg-light"
        >
          PAYE - Salary Type
        </CardHeader>
        <CardBody>
          <Label>
            Salary Type<span className="text-danger">*</span>
          </Label>
          <Typeahead
            defaultInputValue="NON CONSOLIDATED SALARY"
            placeholder="Select Tax Type"
            options={["NON CONSOLIDATED SALARY"]}
            // onChange={handleChange}
            // selected={value}
          />
          <div className="d-flex justify-content-between p-3">
            {/* <div> */}
            <Button
              //   onClick={() => setStep(step - 1)}
              className="w-25 step === 1 ? 'd-none' : ''"
              onClick={() => {
                navigate.goBack();
              }}
              outline
            >
              Back
            </Button>
            {/* </div> */}
            <Button
              //   onClick={handleNext}
              className="w-25 "
              style={{
                backgroundColor: "#262B40",
                color: "white",
                border: "none",
                boxShadow: "none",
                float: "right",
              }}
            >
              Next
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
