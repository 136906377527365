import React from "react";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../assets/img/land.png";
import kano from "../assets/img/kano.png";
import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
import { formatNumber, toCurrency, toNairaWords } from "../utils";
import moment from "moment";
Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
      // fontWeight: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "20px",
    height: "400",
    fontSize: "16px",
    pageBreakInside: "avoid",
    // fontFamily: "Arial",
  },
  header: {
    textAlign: "center",
    // fontSize: "25px",
    fontWeight: "bolder",
    // marginTop: -40,
  },
  logo: {
    position: "absolute",
    width: "80px",
    height: "80px",
  },
  logo1: {
    width: "80px",
    height: "80px",
  },
  watermark: {
    opacity: 0.1,
    position: "absolute",
    top: 200,
    left: 170,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  watermarkText: { fontSize: "50px", transform: "rotate(60deg)" },
  itemContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "",
  },
  itemContainer2: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
  },
  borderBox: {
    border: "1px solid #000",
  },
  qrcodeContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  qrcodeImage: {
    maxWidth: "120px",
    height: "120px",
  },
  textNormal: {
    fontSize: "15px",
    textAlign: "right",
  },
  hederContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  textCol: { width: "20%", fontSize: 11, marginBottom: 10 },
  textCol1: { fontSize: 11, marginBottom: 10, width: "80%" },
});

const LandReceiptPdf = ({
  data = [],
  user = {},
  qrcode,
  total = 0,
  label = "ORIGINAL",
}) => {
  // const formattedTotal = total.toLocaleString("en-US", {
  //   style: "decimal",
  //   maximumFractionDigits: 2, // Set the number of decimal places you want
  // });
  return (
    <View wrap={false} style={styles.page}>
      {/* <Page size="A4"  fixed style={styles.page}> */}
      <View style={styles.watermark}>
        {/* <Image src={logo} style={styles.logo1} /> */}
        <Text style={styles.watermarkText}>{label}</Text>
      </View>

      <View wrap={false} style={{ flexDirection: "row" }}>
        <View style={{ width: "20%" }}>
          <Image src={logo} style={styles.logo} />
          {/* <Image src={kano} style={styles.logo} /> */}
        </View>
        <View style={{ width: "80%" }}>
          <Text style={[styles.header, { color: "red", fontWeight: "heavy" }]}>
            {"Kano State".toUpperCase()}
          </Text>
          <Text
            style={{
              textAlign: "center",
              marginTop: "2px",
              fontSize: 15,
              color: "green",
            }}
          >
            MINISTRY OF LAND AND PHYSICAL PLANNING
          </Text>
        </View>
        <View style={{ width: "20%" }}>
          <Image src={qrcode} style={styles.logo} />
          <Text
            style={{ position: "absolute", marginTop: "75px", fontSize: "9px" }}
          >
            {moment(data[0].paymentdate).format("DD/MM/YYYY HH:mm:ss")}
          </Text>
        </View>
      </View>
      <View style={{ width: "100%", flexDirection: "row", marginTop: "3px" }}>
        <Text
          style={{
            backgroundColor: "red",
            color: "#fff",
            paddingTop: 2,
            margin: "10% auto",
            padding: 3,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 1,
            marginBottom: 10,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginBottom: 10,
          }}
        >
          REVENUE COLLECTOR'S RECEIPT
        </Text>
      </View>
      <Text
        style={{
          textAlign: "center",
          width: "100%",
          marginTop: "-5px",
          fontSize: "9",
        }}
      >
        No. 2 Dr. Bala Mohd. Road, P.M.B. 3083, Kano
      </Text>
      <View style={styles.hederContainer}>
        <View
          style={{
            width: "100%",
            marginRight: 10,
          }}
        >
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { width: "25%" }]}>RECEIPT No.:</Text>
            <Text style={styles.textCol1}>{data[0]?.reference_number}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { with: "5%" }]}>MDA:</Text>
            <Text style={styles.textCol1}>{data[0]?.mda_name}</Text>
          </View>{" "}
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                FROM :{data[0].tax_payer}
              </Text>
            </View>
          </View>
          {data[0]?.payer_phone ? (
            <View style={styles.itemContainer2}>
              <View style={{ fontSize: 11 }}>
                <Text
                  style={{
                    fontSize: 10.5,
                    fontFamily: "DM_SANS",
                  }}
                >
                  PHONE :{data[0].payer_phone}
                </Text>
              </View>
            </View>
          ) : null}
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { with: "5%" }]}>FOR:</Text>
            <Text style={styles.textCol1}> {data[0]?.description}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { width: "20%" }]}>Amount:</Text>
            <Text style={styles.textCol1}>{formatNumber(total)}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { width: "20%" }]}>Status:</Text>
            <Text style={styles.textCol1}>{data[0].invoice_status}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { with: "5%" }]}>File No.:</Text>
            <Text style={styles.textCol1}>{data[0]?.mda_val}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { width: "25%" }]}>Location:</Text>
            <Text style={styles.textCol1}>{data[0]?.description}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={[styles.textCol, { width: "50%" }]}>
              Payment Method:
            </Text>
            <Text style={styles.textCol1}> {data[0]?.modeOfPayment}</Text>
          </View>
        </View>

        <View style={{ width: "60%" }}>
          <Text style={{ marginLeft: "3%", fontSize: "13px" }}></Text>
          <View style={styles.itemContainer2}>
            <Text
              style={{ width: "80px", textAlign: "left", fontSize: "13px" }}
            >
              HEAD:
            </Text>
            <Text
              style={{
                textAlign: "left",
                fontSize: "13px",
              }}
            >
              {data[0]?.item_code}
            </Text>
          </View>

          <View style={styles.itemContainer2}>
            <Text
              style={{ width: "80px", textAlign: "left", fontSize: "13px" }}
            >
              R.V.No:
            </Text>
            <Text
              style={{
                textAlign: "left",
                fontSize: "13px",
              }}
            >
              N/A
            </Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text
              style={{ width: "80px", textAlign: "left", fontSize: "13px" }}
            >
              SUB HEAD:
            </Text>
            <Text
              style={{
                textAlign: "left",
                fontSize: "13px",
              }}
            >
              {data[0]?.mda_code}
            </Text>
          </View>

          <View style={styles.itemContainer2}>
            <Text
              style={{ width: "80px", textAlign: "left", fontSize: "13px" }}
            >
              Eco. Code:
            </Text>
            <Text
              style={{
                textAlign: "left",
                fontSize: "13px",
              }}
            >
              {data[0]?.rev_code}
            </Text>
          </View>
          <View style={styles.itemContainer2}>
            <Text
              style={{ width: "80px", textAlign: "left", fontSize: "13px" }}
            >
              Item Code:
            </Text>
            <Text
              style={{
                textAlign: "left",
                fontSize: "13px",
              }}
            >
              {data[0]?.item_code}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default LandReceiptPdf;
