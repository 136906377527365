import React, { useState, useEffect, useRef, Fragment } from "react";
import "./contact.scss";
import {
  AiFillHome,
  AiFillMail,
  AiFillWechat,
  AiOutlineContacts,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { Button, Col, Input, Label, Modal, Row } from "reactstrap";
import { _postApi } from "../redux/action/api";
import { toast } from "react-hot-toast";

function ContactContainer({
  location,
  buttons,
  mainButtonIcon,
  menuIdentifier,
}) {
  const availablePositions = {
    topLeft: "top-left",
    topRight: "top-right",
    bottomLeft: "bottom-left",
    bottomRight: "bottom-right",
  };

  /****** custom hooks ******/
  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  const useTouchScreenDetect = () => {
    const isSSR = typeof window === "undefined",
      [isTouchScreen, setIsTouchScreen] = useState(false);

    useEffect(() => {
      if (!isSSR) {
        setIsTouchScreen(
          "ontouchstart" in document.documentElement ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
        );
      }
    }, [isTouchScreen, isSSR]);

    return isTouchScreen;
  };

  const {
    enable = false,
    icon,
    label = "Menu",
    className = "",
  } = menuIdentifier ?? {},
    [isHover, setIsHover] = useState(false),
    [isMenuIdentifier, setIsMenuIdentifier] = useState(true),
    isHasTouch = useTouchScreenDetect(),
    ref = useRef(null),
    radius = 25,
    buttonsLength = buttons.length,
    navigatorDimensions = radius * buttonsLength * 1.6,
    circleRadius = radius * buttonsLength,
    isOnLeft =
      location === availablePositions.topLeft ||
      location === availablePositions.bottomLeft;

  useEffect(() => {
    let timer;
    if (enable) {
      if (isHover) {
        setIsMenuIdentifier(false);
      } else {
        timer = setTimeout(() => {
          setIsMenuIdentifier(!isHover);
        }, (buttons.length * 200) / 2);
      }
    }
    return () => {
      if (enable) {
        clearTimeout(timer);
      }
    };
  }, [enable, isHover, buttons.length]);

  const showFloatingBtnMenu = () => {
    setIsHover(true);
  };
  const hideFloatingBtnMenu = () => {
    setIsHover(false);
  };

  const toggleFloatingBtnMenu = () => {
    setIsHover((prev) => !prev);
  };

  useOutsideClick(ref, () => {
    if (isHasTouch) {
      hideFloatingBtnMenu();
    }
  });

  const setNavigatorLocation = () => {
    switch (location) {
      case availablePositions.topLeft:
        return {
          container: {
            top: 10,
            right: "auto",
            bottom: "auto",
            left: 10,
          },
          mainButton: {
            top: 0,
            right: "auto",
            bottom: "auto",
            left: 0,
          },
          identifier: {
            top: 20,
            right: "auto",
            bottom: "auto",
            left: 55,
          },
        };
      case availablePositions.topRight:
        return {
          container: {
            top: 10,
            right: 10,
            bottom: "auto",
            left: "auto",
          },
          mainButton: {
            top: 0,
            right: 0,
            bottom: "auto",
            left: "auto",
          },
          identifier: {
            top: 20,
            right: 55,
            bottom: "auto",
            left: "auto",
          },
        };
      case availablePositions.bottomLeft:
        return {
          container: {
            top: "auto",
            right: "auto",
            bottom: 10,
            left: 10,
          },
          mainButton: {
            top: "auto",
            right: "auto",
            bottom: 0,
            left: 0,
          },
          identifier: {
            top: "auto",
            right: "auto",
            bottom: 20,
            left: 55,
          },
        };
      default:
        return {
          container: {
            top: "auto",
            right: 10,
            bottom: 10,
            left: "auto",
          },
          mainButton: {
            top: "auto",
            right: 0,
            bottom: 0,
            left: "auto",
          },
          identifier: {
            top: "auto",
            right: 55,
            bottom: 20,
            left: "auto",
          },
        };
    }
  };

  // (x, y) = (r * cos(θ), r * sin(θ))
  const setButtonPosition = (index) => {
    switch (location) {
      case availablePositions.topLeft:
        return {
          top: Math.round(
            circleRadius * Math.sin((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
          right: "auto",
          bottom: "auto",
          left: Math.round(
            circleRadius * Math.cos((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
        };
      case availablePositions.topRight:
        return {
          top: Math.round(
            circleRadius * Math.sin((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
          right: Math.round(
            circleRadius * Math.cos((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
          bottom: "auto",
          left: "auto",
        };
      case availablePositions.bottomLeft:
        return {
          top: "auto",
          right: "auto",
          bottom: Math.round(
            circleRadius * Math.sin((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
          left: Math.round(
            circleRadius * Math.cos((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
        };
      default:
        return {
          top: "auto",
          right: Math.round(
            circleRadius * Math.cos((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
          bottom: Math.round(
            circleRadius * Math.sin((Math.PI / 2 / (buttonsLength - 1)) * index)
          ),
          left: "auto",
        };
    }
  };

  const mouseEnterHandler = () => {
    showFloatingBtnMenu();
  };

  const mouseLeaveHandler = () => {
    hideFloatingBtnMenu();
  };

  const clickHandler = (handler) => {
    hideFloatingBtnMenu();
    handler();
  };

  const { container, mainButton, identifier } = setNavigatorLocation();
  return (
    <>
      {buttonsLength > 1 ? (
        <>
          <div
            ref={ref}
            onMouseEnter={!isHasTouch ? mouseEnterHandler : () => { }}
            onMouseLeave={!isHasTouch ? mouseLeaveHandler : () => { }}
            className="toggle-navss"
            style={{
              ...container,
              width: isHover ? navigatorDimensions : 40,
              height: isHover ? navigatorDimensions : 40,
            }}
          >
            <button
              className="main-button"
              style={{ ...mainButton }}
              onClick={isHasTouch ? toggleFloatingBtnMenu : () => { }}
              aria-label={`Main menu button ${location}`}
            >
              {mainButtonIcon}
            </button>
            {buttons.map((el, i) => (
              <Fragment key={i}>
                <button
                  className="sub-button"
                  style={{
                    opacity: isHover ? 0.9 : 0,
                    top: isHover
                      ? setButtonPosition(i).top
                      : setButtonPosition(i).top === "auto"
                        ? "auto"
                        : 0,
                    right: isHover
                      ? setButtonPosition(i).right
                      : setButtonPosition(i).right === "auto"
                        ? "auto"
                        : 0,
                    bottom: isHover
                      ? setButtonPosition(i).bottom
                      : setButtonPosition(i).bottom === "auto"
                        ? "auto"
                        : 0,
                    left: isHover
                      ? setButtonPosition(i).left
                      : setButtonPosition(i).left === "auto"
                        ? "auto"
                        : 0,
                    transition: `all 0.2s 0.${i + 1}s ease`,
                  }}
                  onClick={() => {
                    clickHandler(el.click);
                  }}
                >
                  {el.icon}
                </button>
              </Fragment>
            ))}
          </div>
          {enable && isMenuIdentifier && (
            <div
              className={`menu-identifier ${className}`}
              style={{
                ...identifier,
                flexDirection: isOnLeft ? "row" : "row-reverse",
              }}
            >
              {icon ? (
                icon
              ) : (
                <i
                  className={`far fa-hand-point-${isOnLeft ? "left" : "right"}`}
                />
              )}{" "}
              <span
                style={{
                  paddingLeft: isOnLeft ? 5 : 0,
                  paddingRight: !isOnLeft ? 5 : 0,
                }}
              >
                {label}
              </span>
            </div>
          )}
        </>
      ) : (
        <div
          className="toggle-navss"
          style={{
            ...container,
            width: 150,
          }}
        ></div>
      )}
    </>
  );
}

export default function Contact() {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newOpen, setNewOpen] = useState(false);

  const [form, setForm] = useState({
    fullname: user?.name,
    email: user?.email,
    insert_by: user?.username,
  });

  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      "/post-contact-us",
      form,
      () => {
        setLoading(false);
        toggle();
        toast.error("Successfully");
      },
      (err) => {
        console.log(err);
        setLoading(false);
        toast.error("Error Occured", err);
      }
    );
  };

  const toggle = () => setOpen(!open);
  const newToggle = () => setNewOpen(!newOpen);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const openNewTab = () => {
    const newTabUrl = "https://wa.me/2349032818956";
    window.open(newTabUrl, "_blank");
  };

  const buttons = [
    {
      icon: <AiOutlineContacts className="zoom-in-zoom-out" />,
      click: () => newToggle(),
    },
    {
      icon: <AiOutlineWhatsApp className="zoom-in-zoom-out" />,
      click: () => openNewTab(),
    },
    {
      icon: <AiFillMail className="zoom-in-zoom-out" />,
      click: () => toggle(),
    },
  ];

  return (
    <div className="containerss">
      <Modal isOpen={open} toggle={toggle} size="lg">
        <center>
          <h5 className="m-2">Contact us</h5>
        </center>
        {/* {JSON.stringify(user)} */}
        <div className="p-3">
          <Col>
            <Label>Name</Label>
            <Input
              type="text"
              name="fullname"
              value={form.fullname}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Label>Email</Label>
            <Input
              type="text"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Label>Phone</Label>
            <Input
              type="tel"
              name="phone"
              value={form.phone}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Label>Message</Label>
            <Input
              type="textarea"
              name="message"
              value={form.message}
              onChange={handleChange}
            />
          </Col>
          <center>
            <Button
              className="px-5 m-2"
              color="dark"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit now
            </Button>
          </center>
        </div>
      </Modal>
      <Modal isOpen={newOpen} toggle={newToggle} size="lg">
        <center>
          <h5 className="m-2">Contact List</h5>
        </center>
        <div className="p-3">
          <div className="m-3 p-1 border-start">
            <div className="display-4 text-muted ">Ibrahim Aliyu </div>
            08142925446, 08023715668 <br />
            ibrahimaliiyumdb@gmail.com <br />
            <b>Director Revenue monitoring and Evaluation</b>
          </div>
          <div className="m-3 p-1 border-start">
            <div className="display-4 text-muted">Ahmad Garba Auwal</div>
            08021052573 <br />
            agauwal19@gmail.com <br />
          </div>
          <div className="m-3 p-1 border-start">
            <div className="display-4 text-muted">Garba Yusuf Abubakar</div>
            08084437336 <br />
            gyabubakar@gmail.com <br />
            <b>Director Revenue</b>
          </div>
          <div className="m-3 p-1 border-start">
            <div className="display-4 text-muted">Developer Support Desk</div>
            09032818956 <br />
            contacat@kirmas.kn.gov.ng <br />
            <b>Developer Support Desk</b>
          </div>
        </div>
      </Modal>
      <ContactContainer
        location="bottom-right"
        buttons={buttons}
        mainButtonIcon={
          <AiFillWechat size={30} color="white" className="zoom-in-zoom-out" />
        }
      />
    </div>
  );
}
