import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Spinner,
  Table,
} from "reactstrap";
import { CustomButton, SearchBar } from "../../components/UI";
import { _fetchApi } from "../../redux/action/api";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import CustomModal from "../../components/UI/CustomModal";
import { ButtonGroup, Image, Row } from "@themesberg/react-bootstrap";
import RemarkAccordion from "./RemarkAccordion";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { formatNumber, toParagraph } from "../../utils";
import moment from "moment";
import { SearchBar1 } from "../../components/UI/SearchBar";
export const getColor = (status) => {
  switch (status?.toLowerCase()) {
    case "initiated":
      return "info";
    case "recommended":
      return "warning";
    case "rejected":
      return "danger";
    case "approved":
      return "secondary";
    case "printed":
      return "primary";
  }
};
export default function TaxClearanceTab() {
  const [getLoading, setGetLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [remarks, setRemarks] = useState([]);
  const isPhoneSize = useIsPhoneSize();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("initiated");

  useEffect(() => {
    setGetLoading(true);
    _fetchApi(
      `/get/tax-clearance?query_type=select-status&status=${status}&id=${search}`,
      (resp) => {
        if (resp.success) {
          setData(resp.data);
        }
        setGetLoading(false);
      },
      (e) => {
        console.log(e);
        setGetLoading(false);
      }
    );
  }, [status]);

  const getData = () => {
    setGetLoading(true);
    _fetchApi(
      `/search-tax-clearance-no-date?query_type=select-status&status=${status}&search=${search}`,
      (resp) => {
        if (resp.success) {
          setData(resp.data);
        }
        setGetLoading(false);
      },
      (e) => {
        console.log(e);
        setGetLoading(false);
      }
    );
  };
  // useEffect(() => {
  //   if (search.length === 0) {
  //     getData();
  //   } else if (search.length > 3) {
  //     getData(getData);
  //   }
  // }, [getData]);

  useEffect(() => {
    _fetchApi(
      `/get/tax-clearance?query_type=get-remarks&id=${selectedItem.tcc_ref}`,
      (resp) => {
        if (resp.success) {
          setRemarks(resp.data);
        }
      },
      (e) => {
        console.log(e);
        const arr = [];
      }
    );
  }, [selectedItem.tcc_ref]);

  return (
    <Card>
      <CardHeader className="bg-primary  d-flex justify-content-between">
        <Button
          onClick={() => {
            history.push(Routes.TaxClearance.path);
          }}
          size="sm"
        >
          Raise TCC
        </Button>

        <h4 style={{ color: "#fff" }} className="">
          Tax Clearance
        </h4>
        <div></div>
      </CardHeader>
      <div className="m-2 p-2">
        <Row>
          <Col md={6}>
            {" "}
            <SearchBar1
              filterText={search}
              onFilterTextChange={(e) => setSearch(e)}
              placeholder="Search By Tax Payer Name or Certificate No."
              getData={getData}
              getLoading={getLoading}
            />
          </Col>
          <Col className="pt-2 text-right">
            <ButtonGroup>
              <Button
                size="sm"
                onClick={() => setStatus("initiated")}
                color="info"
              >
                Initiated
              </Button>
              <Button
                size="sm"
                onClick={() => setStatus("recommended")}
                color="warning"
              >
                Recommended
              </Button>
              <Button
                size="sm"
                onClick={() => setStatus("rejected")}
                color="danger"
              >
                Rejected
              </Button>
              <Button
                size="sm"
                onClick={() => setStatus("Approved")}
                color="secondary"
              >
                Approved
              </Button>
              <Button
                size="sm"
                onClick={() => setStatus("Printed")}
                color="primary"
              >
                Printed
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {getLoading ? (
          <center>
            <Spinner size="sm" />
          </center>
        ) : null}
        <div style={{ overflow: "scroll", height: "73vh" }}>
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Action</th>
                <th>Tax Payer</th>
                <th>Type</th>
                <th>File No.</th>
                <th>TCC Ref.</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {data?.length
                ? data?.map((x, i) => (
                  <tr key={i}>
                    <td className="text-center">
                      <Button
                        onClick={() => {
                          setSelectedItem(x);
                          setShowModal(true);
                        }}
                        color="primary"
                        size="sm"
                      >
                        View
                      </Button>
                    </td>
                    <td>{x.tax_payer}</td>
                    <td>{x.type}</td>
                    <td>{x.tax_file_no}</td>
                    <td scope="row">{x.tcc_ref}</td>
                    <td scope="row">
                      <Badge color={getColor(x.status)}>
                        {toParagraph(x.status)}
                      </Badge>
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
          {!data?.length ? (
            <Alert className="text-center">No Record Found</Alert>
          ) : null}
        </div>
      </div>

      <CustomModal
        size="lg"
        toggle={() => {
          setShowModal(!showModal);
        }}
        logo={
          <center className="p-0 text-center">
            <Image
              src={require("../../assets/img/kigra.jpg")}
              className="img-fluid"
              alt="Logo"
              style={{
                borderRadius: "64px",
                height: isPhoneSize ? "10%" : "20%",
                width: isPhoneSize ? "10%" : "20%",
                marginTop: "-70px",
                marginBottom: "0px",
              }}
            />
          </center>
        }
        isOpen={showModal}
      >
        <div className="p-0 m-0 mt--5">
          <div
            style={{
              width: "100%",
              flexDirection: "row",
              marginTop: "-20px",
            }}
          >
            <span
              style={{
                backgroundColor: "#003311",
                color: "#fff",
                paddingTop: 6,
                marginLeft: "35%",
                paddingBottom: 6,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 2,
                marginBottom: 10,
              }}
            >
              TCC PRINTING PREVIEW
            </span>
          </div>
          <div
            style={{
              flexDirection: "row",
              marginBottom: 10,
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Tax Payer Name</span>
                <span>{selectedItem.tax_payer}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>TCC REF.</span>
                <span>{selectedItem.tcc_ref}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Type</span>
                <span>{selectedItem.type}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>TIN </span>
                <span>{selectedItem.tin || "N/A"}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Year </span>
                <span>{selectedItem.year}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Raised By </span>
                <span>{selectedItem.raised_by}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Issue Date</span>
                <span>{selectedItem.date_issued}</span>
              </div>
            </div>
          </div>
          <div>
            <Table size="sm" bordered responsive>
              <thead>
                <tr className="bg-success">
                  <th style={{ border: "none" }}></th>
                  <th className="text-right">
                    <b>
                      {moment(selectedItem.year)
                        .subtract(2, "year")
                        .format("YYYY")}
                    </b>
                  </th>
                  <th className="text-right">
                    <b>
                      {moment(selectedItem.year)
                        .subtract(1, "year")
                        .format("YYYY")}
                    </b>
                  </th>
                  <th className="text-right">
                    <b>{moment(selectedItem.year).format("YYYY")}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-right">
                    <i>Total Income</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.first_income)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.second_income)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.third_income)}</i>
                  </td>
                </tr>
                <tr>
                  <td className="text-right">
                    <i>Tax Paid</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.first_amount)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.second_amount)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.third_amount)}</i>
                  </td>
                </tr>
                <tr>
                  <td className="text-right">
                    <i>Date Tax Reciept Issued</i>
                  </td>
                  <td className="text-right">
                    <i>
                      {" "}
                      {moment(selectedItem.first_year).format("DD/MM/YYYY")}
                    </i>
                  </td>
                  <td className="text-right">
                    <i>
                      {moment(selectedItem.second_year).format("DD/MM/YYYY")}
                    </i>
                  </td>
                  <td className="text-right">
                    <i>
                      {" "}
                      {moment(selectedItem.third_year).format("DD/MM/YYYY")}
                    </i>
                  </td>
                </tr>{" "}
              </tbody>
            </Table>
          </div>
          {/* {JSON.stringify(selectedItem.printed )} */}
          {remarks.length ? <RemarkAccordion remarks={remarks} /> : null}
          <CardFooter className="text-center">
            {["rejected", "initiated"].includes(
              selectedItem?.status?.toLowerCase()
            ) ? (
              <CustomButton
                loading={loading}
                onClick={() => {
                  setShowModal(false);
                  history.push(
                    `${Routes.TaxClearance.path}?tcc_ref=${selectedItem.tcc_ref}`
                  );
                }}
              >
                Edit
              </CustomButton>
            ) : null}
          </CardFooter>
        </div>
      </CustomModal>
    </Card>
  );
}