import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Table,
} from "reactstrap";
import moment from "moment";

// const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

const AssessmentTableCard = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle
          style={{
            fontWeight: "bold",
          }}
        >
          Assessment Table
        </CardTitle>
        <FormGroup>
          <Label for="selectMonth">Select Month</Label>
          <Input type="select" id="selectMonth">
            <option value="">Select Month</option>
            {Array.from(Array(12), (_, i) => (
              <option value={moment().month(i).format("MMMM")} key={i}>
                {moment().month(i).format("MMMM")}
              </option>
            ))}
          </Input>
        </FormGroup>
        {/* <FormGroup>                                       //two ways you can either use the top or the buttom one
          <Label for="selectMonth">Select Month</Label>
          <Input type="select" id="selectMonth">
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option value={month} key={index}>
                {month}
              </option>
            ))}
          </Input>
        </FormGroup> */}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Employee Name</th>
              <th>Employee IRS_ID</th>
              <th>Annual Gross</th>
              <th>Total Refill</th>
              <th>Chargeable Income</th>
              <th>Tax Payable</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>John Doe</td>
              <td>JohnIRS11</td>
              <td>N100,000</td>
              <td>N50,000</td>
              <td>N80,000</td>
              <td>N20,000</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default AssessmentTableCard;
