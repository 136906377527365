import React from 'react';
import Kano from '../assets/img/kano2.png';
import { useParams } from 'react-router-dom';
import './zInvoice.css';

const ViewInvoice = () => {
  const { invoiceNo } = useParams();
  const invoice =  {
    id: 1,
    date: "2022-01-01",
    period: "Jan 2022",
    invoiceNo: "INV-001",
    description: "Paye State",
    amount: "N1000",
    status: "Paid",
  }

  return (
    <div className="invoice-box">
      <table className="table">
        <tbody>
          <tr className="top">
            <td colSpan="2">
              <table className="table-borderless">
                <tbody>
                  <tr>
                    <td className="title">
                      <img
                        src={Kano}
                        alt="Kirs logo"
                        style={{ maxWidth: '150px' }}
                      />
                    </td>
                    <td className="text-right">
                      <b>Cashworx Invoice No.: {invoice.invoiceNo}</b><br />
                      Created: {invoice.date}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr className="information">
            <td colSpan="2">
              <table className="table-borderless">
                <tbody>
                  <tr>
                    <td>
                      KADIRS HQ.<br />
                      No. 8/9 Bima Road, Marafa Estate<br />
                      Kaduna
                    </td>
                    <td className="text-right">
                      104-7619-0593<br />
                      Sani Musa<br />
                      8790fahadado@gmail.com
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr className="heading">
            <td>Period</td>
            <td className="text-right">{invoice.period}</td>
          </tr>

          <tr className="details">
            <td>Description</td>
            <td className="text-right">Amount</td>
          </tr>

          <tr className="item">
            <td>{invoice.description}</td>
            <td className="text-right">{invoice.amount}</td>
          </tr>

          <tr className="information">
            <td colSpan="2">
              <b>Payment Instructions for Paydirect (Bank Branch)</b><br />
              Visit any bank branch<br />
              Ensure you have your <b>Cashworx Invoice No./Reference ID</b> with you. It is <b>{invoice.invoiceNo}</b><br />
              Ask to make a <b>Paydirect</b> payment<br />
              Inform the teller that the payment is for <b>Kaduna State Tax</b> scheme
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ViewInvoice;
