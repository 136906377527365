import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Card, CardBody, CardHeader, Label, Button } from "reactstrap";
import CustomTypeahead from "./UI/CustomTypeahead";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../routes";

function FillingPaye() {
  const [taxType, setTaxType] = useState("");
  const navigate = useHistory();
  return (
    <div>
      <Card>
        <CardHeader
          style={{
            fontWeight: "bolder",
          }}
          className="bg-light"
        >
          TAX FILING
        </CardHeader>
        <CardBody>
          <Label>
            Tax Type<span className="text-danger">*</span>
          </Label>
          <CustomTypeahead
            id="10"
            col={12}
            placeholder="Select Tax Type"
            options={["PAYE SETUP", "PAYE REMITTANCE"]}
            onChange={(item) => {
              setTaxType(item[0]);
            }}
          />
          <div className="d-flex justify-content-between p-3">
            <div></div>
            <Button
              onClick={() => {
                if (taxType === "PAYE SETUP") {
                  navigate.push(Routes.PayeSalaryType.path);
                } else {
                  navigate.push(
                    `${Routes.PayeDateRange.path}?taxType=PAYE REMITTANCE`
                  );
                }
              }}
              className="w-25 "
              style={{
                backgroundColor: "#262B40",
                color: "white",
                border: "none",
                boxShadow: "none",
                float: "right",
              }}
            >
              Next
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default FillingPaye;
