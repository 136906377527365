import React from "react";
import { Button, Card, CardBody, CardTitle, Label ,Input, Table,Row,Col} from "reactstrap";
export default function PayGroup(){
    return (
        <Card>
          <CardTitle tag="h5" className="p-3 bg-light">PAYE - Paygroup setup</CardTitle>
          <CardBody>
           
            <div className="d-flex justify-content-between">
            <Label>
             Salary Structure
           </Label>
           <Label>
           help
           </Label>
            </div>
          
           <div className="p-2 bg-light rounded-2 mb-2">bits-GL2</div>
          
          <Table bordered responsive>
            <thead>
                <tr>
                    <th>
                        SN
                    </th>
                    <th>
                        Paygroup
                    </th>
                    <th>
                        Total Annual Gross Income(₦)
                    </th>
                    <th>
                        Deduction type
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        1
                    </td>
                    <td>
                        <Input value='cleaner' />
                    </td>
                    <td>
                        <Input value='12000' />
                    </td>
                    <td>
                    <Label className="px-2">
                            NHF(2.5%)
                        </Label>
                        <Input type='checkbox' />
                        <Label className="px-2">
                            NHIS Federal(1.75%)
                        </Label>
                        <Input type='checkbox' />
                        <Label className="px-2">
                            NHIS Others(5%)
                        </Label>
                        <Input type='checkbox' />
                        <Label className="px-2">
                            PENSION(8%)
                        </Label>
                        <Input type='checkbox' />
                        </td>
                </tr>
            </tbody>

          </Table>
          <div className="p-3 px-0">
            <Button className="p-3 py-2 border text-center" style={{
                borderRadius: 50
            }}>+</Button>
          </div>
          <Row>

            <Col md={4}>
            
            <p className="mb-1">
                Download Paygroup List template
            </p>
            <p>
                Donload Paygroup Upload Tutorial
            </p>
            </Col>
            <Col md={8} >
            <Row className=" align-items-start">
            <div className="col-xl-7 col-6">
                <Input type='file' className="w-100" />
                <p className="mb-1">
                    Upload Paygroup's template
                </p>
                <span className="text-secondary">
                    (Not more than 500 Paygroup's on a sheet)
                </span>
            </div>
            <div className=" col-xl-5 col-6 d-flex justify-content-between ">
                 <Button className="bg-light p-2 px-3 rounded-2 ml-2">
                Back
            </Button>
            <Button className="p-2 px-3 rounded-2 border border-success-subtle ">
                Skip
                          </Button>
            <Button className="bg-success px-5 py-2 rounded-2 ml-2">
                Submit
            </Button>
            </div>
           </Row>
            </Col>
          </Row>
         </CardBody>
        </Card>
       );
}