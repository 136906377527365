/* eslint-disable react/jsx-key */
import React, {useEffect } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useState } from 'react';
import { formatNumber, toParagraph, useQuery } from '../../utils';
import './invoice.css';
import { toast } from 'react-hot-toast';
import { Col, Row } from '@themesberg/react-bootstrap';
import {faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { _fetchApi, _postApi, apiURL } from '../../redux/action/api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../routes';
import { useMemo } from 'react';
import MButton from "reactstrap/lib/Button";
import { deleteCart } from '../../redux/action/cart';

export default function NewCart(props) {
    // const data = useSelector(s=>s.cart.invoices)
    const {data = []} = props
    const [finalData, setFinalData] = useState(data);
    const { tax_account, user } = useSelector((s) => s.auth);

    useEffect(()=>{
        if(data.length)
            // alert( JSON.stringify(data))
            setFinalData(data);
    },[data.length])

    const total = useMemo(() => {
        return finalData.filter((item) => item && item?.id>0).reduce((it, id) => it + parseFloat(id.amount), 0);
    }, [finalData]);

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const dispatch = useDispatch()

    const handleSubmit = (page_type) => {
        if(page_type==='pay-now'){
            setLoading2(true)
        }else{
            setLoading(true)
        }
        const tax_list = [];

        if (finalData.length) {
            finalData.filter((item) => item.id).forEach((item) => {
                tax_list.push({
                    ...item,
                    mda_var: item.mda_var,
                    mda_val: item.mda_val,
                    transaction_type: 'payment',
                    description: item.title,
                    rev_code: item.economic_code,
                    amount: item.tax_fee,
                    service_category: item.tax_parent_code,
                    item_code: item.id,
                    sector: item.sector,
                    phone: item.phone ? item.phone : tax_account.office_phone || tax_account.phone,
                });
            });
            tax_list.push({
                mda_var: finalData[0]?.mda_var,
                mda_val: finalData[0]?.mda_val,
                tax_code: finalData[0]?.tax_code,
                sector: finalData[0]?.sector,
                tax_parent_code: finalData[0]?.tax_parent_code,
                amount: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
                total: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
                description: finalData.map((itm) => itm.title).toString(),
                transaction_type: 'invoice',
                rev_code: finalData[0]?.economic_code || '',
                service_category: finalData[0]?.tax_parent_code || '',
                item_code: finalData[0].id || '',
                mda_code: finalData[0]?.mda_code || '',
                mda_name: finalData[0]?.mda_name || '',
                department: finalData[0]?.department,
                phone: finalData[0].phone ? finalData[0].phone : tax_account.office_phone || tax_account.phone,
            });
            const final_finalData = {
                total: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
                user_id: tax_account.taxID,
                nin: tax_account.nin,
                nin_id: tax_account.nin_id,
                agent_id: user.role === 'user' ? '' : user.id,
                tax_list,
                tax_station:  user.role === 'user' ? tax_account.tax_station:user.tax_station,
                transaction_type:'invoice',
                transaction_date: moment().format('YYYY-MM-DD'),
                start_date: moment(finalData[0].from).format('YYYY-MM-DD'),
                end_date: moment(finalData[0].to).format('YYYY-MM-DD'),
                tax_payer: (tax_account.org_name || tax_account.name) || finalData[0].tax_payer,
                phone: tax_list[0].phone || finalData[0].tax_payer,
                invoice_status: finalData[0]?.invoice_status || '',
            };
            _postApi(
                '/transactions/execute',
                final_finalData,
                (resp) => {
                    // alert(JSON.stringify(resp.data[0]))
                    if (resp.success) {
                        setLoading(false);
                        history.push(
                           user.role==='user' ?
                           `${Routes.GenericInvoice.path}?ref_no=${resp.data[0].ref_no}&page=${page_type}`
                           :`${Routes.AdminGenericInvoice.path}?ref_no=${resp.data[0].ref_no}&page=${page_type}`
                        );
                    } else {
                        toast.error('Error');
                        setLoading(false);
                        setLoading2(false);
                    }
                },
                (e) => {
                    console.error('Error', e);
                    setLoading(false);
                    setLoading2(false);
                },
            );
        }
    };


    // const getList = useCallback(() => {
    //     if (refNo) {
    //         setLoading(true);
    //         _fetchApi(
    //             `/transactions/retrieve?query_type=view_invoice&reference_number=${refNo}`,
    //             (resp) => {
    //                 setList(resp.finalData);
    //                 if (resp.finalData[0]?.user_id) {
    //                     setLoading(false);
    //                 }
    //             },
    //             (err) => {
    //                 console.error(err);
    //                 setLoading(false);
    //             },
    //             (err) => {
    //                 console.log(err);
    //                 setList([]);
    //                 setLoading(false);
    //             },
    //         );

    //         const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${refNo}`;
    //         fetch(qrCodeUrl, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 authorization: localStorage.getItem('@@auth_token'),
    //             },
    //         })
    //             .then((response) => {
    //                 if (!response.ok) {
    //                     throw new Error(`HTTP error! Status: ${response.status}`);
    //                 }
    //                 return response.blob();
    //             })
    //             .then((blob) => {
    //                 const qrCodeImageUrl = URL.createObjectURL(blob);
    //                 setQrCode(qrCodeImageUrl);
    //             })
    //             .catch((error) => {
    //                 console.error('Error:', error);
    //             });
    //     }
    // }, [refNo, tax_account.taxID]);

    // useEffect(() => {
    //     getList();
    // }, [getList]);

    return (
        <div className="cardss p-0 m-0">
            {/* {JSON.stringify(finalData)} */}
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h3>PAYMENT CHECKOUT</h3>
                <h5>Tax Payer: {tax_account.org_name || tax_account.name}</h5>
                <h5>Address: {(tax_account?.office_address || tax_account?.address) || finalData[0]?.address}</h5>
            </div>
            <div className="d-flex justify-content-between">
                <h6>({finalData.length}) Items selected</h6>
                <h6>Total: {formatNumber(total)}</h6>
            </div>
            {/* {JSON.stringify(form.page_title)} */}
            <table className="table-zebra" style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th width="5%">S/N</th>
                        <th width="35%"> Description</th>
                        <th width="15%">Revenue code</th>
                        <th width="35%">MDA</th>
                        <th width="15%" className="text-center">
                            Amount
                        </th>
                    <th width="20%" className="text-right">
                        Action
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {finalData
                        .filter((item) => item.id)
                        ?.map((it, idx) => (
                            <tr key={idx}>
                                <td width={'15x'}>{idx + 1}</td>
                                <td>{toParagraph(it.title)}</td>
                                <td>{it.economic_code}</td>
                                <td>{it.mda_name}</td>
                                <td className="text-right">{formatNumber(it.tax_fee)}</td>
                                    <td className="text-right">
                                        <Button
                                            size="sm"
                                            title="Delete"
                                            color="danger"
                                            onClick={() => dispatch(deleteCart(idx))}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} className="" />
                                        </Button>
                                    </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <br />
            {/* {form.trx_type === "invoice" ? ( */}
            {props.children}
       
            <Row>
                <Col>
                <MButton
                    color="warning"
                    className="mr-2"
                    block
                    onClick={() => {
                        handleSubmit('save-invoice');
                    }}
                    disabled={loading}
                >
                    {loading ? <Spinner size="sm" /> : null}
                    Save Invoices
                </MButton>
                </Col>
                <Col>
                <MButton
                    color="primary"
                    className="mr-2"
                    block
                    onClick={() => {
                        handleSubmit('pay-now');
                    }}
                    disabled={loading2}
                >
                    {loading2 ? <Spinner size="sm" /> : null}
                    Pay Online
                </MButton>
                </Col>
            </Row>
            {/* ) : ( */}
            <Row>
                <Col></Col>
            </Row>
            {/* )} */}
        </div>
    );
}
