import {
  AUTH,
  LOADING_LOGIN,
  LOADING_SIGNUP,
  LOGOUT,
  AUTH_ERROR,
  ERRORS,
  APPROVAL_DISPHATCH,
  TAX_ACCOUNT,
  TAX_ACCOUNTS,
  RECOVER_USER,
} from "../action/type";

// Load initial state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("tax_account");
    if (serializedState === null) {
      return {};
    }
    return { tax_account: JSON.parse(serializedState) };
  } catch (err) {
    return {};
  }
};

// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state.tax_account);
    localStorage.setItem("tax_account", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

const initialState = {
  authenticated: false,
  user: {},
  loadingLogin: false,
  loadingSignup: false,
  tax_account: {},
  tax_accounts: [],
  requestIsValid: true,
  error: "",
  loadingApp: false,
  item_code: "",
  recoverPhone: { code: "", phone: "" },
  ...loadState(),
};

export default function auth(state = initialState, action) {
  let newState;
  switch (action.type) {
    case AUTH:
      newState = {
        ...state,
        user: action.payload?.user,
        tax_account:
          action.payload && action.payload?.tax_account?.id > 0
            ? action.payload.tax_account
            : state.tax_account,
        tax_accounts: action.payload?.tax_accounts,
        authenticated: true,
      };
      break;

    case TAX_ACCOUNT:
      newState = {
        ...state,
        tax_account: action.payload,
      };
      break;

    case TAX_ACCOUNTS:
      newState = {
        ...state,
        tax_accounts: action.payload,
      };
      break;

    case RECOVER_USER:
      newState = {
        ...state,
        recoverPhone: action.payload,
      };
      break;

    case APPROVAL_DISPHATCH:
      newState = {
        ...state,
        item_code: action.payload,
      };
      break;

    case LOADING_LOGIN:
      newState = {
        ...state,
        loadingLogin: !state.loadingLogin,
      };
      break;

    case ERRORS:
      newState = {
        ...state,
        error: action.payload,
      };
      break;

    case LOADING_SIGNUP:
      newState = {
        ...state,
        loadingSignup: !state.loadingSignup,
      };
      break;

    case AUTH_ERROR:
      newState = {
        ...state,
        error: "",
      };
      break;

    case LOGOUT:
      newState = {
        ...state,
        authenticated: false,
        user: {},
        tax_account: {},
      };
      break;

    default:
      newState = state;
  }

  // Save the updated tax_account to localStorage
  saveState(newState);
  return newState;
}
