import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import { Button, Card, Container, Row, Col, Form } from "@themesberg/react-bootstrap";
// import { _post } from "components/Utils/Helper";
import { Label } from "reactstrap";
import { useParams } from "react-router";
// import { _get } from "components/Utils/Helper";
import { _fetchApi } from "../../redux/action/api";
import { useQuery } from "../../utils";
// import { _post } from "../components/Utils/Helper";

function ViewSlider() {
  const _form = {
    title: "",
    content: "",
    attechment: "",
  };
  const [form, setForm] = useState(_form);
  const [result, setResult] = useState([]);
  const [view, setView] = useState([]);
  // const slider = useParams();
  const q = useQuery();
  const slider_id = q.get("id");

  useEffect(() => {
    if (slider_id)
      _fetchApi(
        `/contentes/get_slider?query_type=view&id=${slider_id}`,
        (res) => {
          if (res.success && res.data.length) {
            setView(res.data[0]);
          }
        },
        (err) => {
          // setLoading(false)
          console.log(err);
        }
      );
  }, [slider_id]);
  return (
    <>
      <Container fluid>
        {/* {JSON.stringify({ id: view })} */}
        <Row>
          <Col md="12">
            <Card>
              {/* <Card.Header>
                <Card.Title as="h4">View</Card.Title>
              </Card.Header> */}
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <center>
                      <h4>{view.title}</h4>
                    </center>
                  </Col>
                  <Col md={12}>
                    <center>
                      <img
                        width={"40%"}
                        height={"550px"}
                        src={view.attechment}
                      />
                    </center>
                  </Col>
                  <Col md={12}>
                    <center className="mt-4">
                      <p>{view.content}</p>
                    </center>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ViewSlider;
