import React, { useCallback, useEffect, useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Col,
  Row,
  // Input,
  Card,
  ButtonGroup,
  // Dropdown,
  // ButtonGroup,
} from "@themesberg/react-bootstrap";

import MButton from "@themesberg/react-bootstrap/lib/Button";
// import { PageVisitsTable } from '../../components/Tables'
// import { trafficShares, totalOrders } from '../../data/charts'
// import DaterangeSelector from "./DaterangeSelector";
import { useHistory } from "react-router";
import { Routes } from "../../routes";
// import ChartComponent from "./ChartComponent";
// import PieChart from "./charts/PieChart";
// import VerticalComposedChart from "./charts/VerticalComposedChart";
import { useSelector } from "react-redux";
import { _fetchApi, _postApi } from "../../redux/action/api";
import {Spinner } from "reactstrap";
import moment from "moment";
import CustomDateRange from "./CustomDateRange";

import LeaderBoardChart from "./charts/LeaderBoardChart";

export default function PerformanceDashboard(){

  // date picker setup
  const today = moment();
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  // const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
  const [view, setView] = useState('individual');
  const [loading, setLoading] = useState(false);

  const [agentPerformance, setStaffPerformance] = useState([]);
  const [stationPerformance, setStationPerformance] = useState([]);


  // const total = data?.reduce((item, d) => item + parseFloat(d.total_amt), 0);

  const fetchPerformance = useCallback(() => {
    setLoading(true)
    _postApi('/auth/create-admins',
      {
        query_type: 'staff-performance',
        start_date: dateRange.from,
        end_date: dateRange.to
      },
      (resp) => {
        setLoading(false)
        if (resp.success && resp.data?.length) {
          setStaffPerformance(resp.data);
        }
      },
      (error) => {
        setLoading(false)
        setStaffPerformance([]);
        setStationPerformance([]);
        console.error('Failed to fetch performance data', error);
      })
    _postApi('/auth/create-admins',
      {
        query_type: 'station-performance',
        start_date: dateRange.from,
        end_date: dateRange.to
      },
      (resp) => {
        setLoading(false)
        if (resp.success && resp.data?.length) {
          setStationPerformance(resp.data);
        }
      },
      (error) => {
        setLoading(false)
        setStaffPerformance([]);
        setStationPerformance([]);
        console.error('Failed to fetch performance data', error);
      })

  }, [dateRange]);

  useEffect(() => {
    fetchPerformance();
  }, [fetchPerformance]);

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
          <h4 className="py-3 text-left">Dashboard Overview</h4>
          </Col>
          <Col className="text-right">
            <ButtonGroup>

            <MButton onClick={()=>setView('target')} variant={view==='target'?'primary':'light'}>
              By Revenue Target
              </MButton>
              <MButton onClick={()=>setView('individual')}  variant={view==='individual'?'primary':'light'}>
               By Individual Collections
              </MButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body></Card.Body>
      <CustomDateRange
        type={dateRange.type}
        month={dateRange.month}
        quarter={dateRange.quarter}
        year={dateRange.year}
        from={dateRange.from}
        to={dateRange.to}
        handleChangeDate={handleChangeDate}
      />
      {loading && <Spinner />}
      <Row style={{ height: '500px', marginBottom: '200px' }}>
        <Col md={12}>
          <LeaderBoardChart
            title="STAFF PERFORMANCE CHART"
            link={Routes.TaxLeaderboard.path}
            data={agentPerformance?.map(x=>({...x, staff_name: x.staff_name??'Others'}))}
            dataKey1='staff_name'
            dataKey2='amount' />
        </Col>
        <Col md={12}>
          <LeaderBoardChart
            title="STATION PERFORMANCE CHART"
            data={stationPerformance?.map(x=>({...x, tax_station: x.tax_station??'Others'}))}
            dataKey1='tax_station'
            link={Routes.TaxLeaderboard.path}
            dataKey2='amount' />
        </Col>
      </Row>
    </Card>
  );
};