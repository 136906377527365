import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Card,
  Container,
  InputGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import { LandAccountantInvoice } from "./LandAccountantInvoice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LandTermalInvoice from "../components/LandTermalInvoice";
import { useSelector } from "react-redux";
import { _fetchApi, apiURL } from "../redux/action/api";
import { useQuery } from "../utils";
import { Routes } from "../routes";
import { Link, useHistory } from "react-router-dom";
import useIsPhoneSize from "../utils/useIsPhoneSize";
import ReceiptPdf from "../components/InvoicePdf";
import {
  faAngleLeft,
  faArrowCircleLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import PaymentInvoice from "./PaymentInvoice";

function OnlinePayment() {
  const { tax_account, user } = useSelector((s) => s.auth);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsPhoneSize();
  const query = useQuery();
  const [PDFisOpen, setPDFIsOpen] = useState(false);
  const refNo = query.get("reference_number");
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [qrcode, setQrCode] = useState(null);
  const togglePDF = () => setPDFIsOpen(!PDFisOpen);

  const getList = useCallback(() => {
    if (refNo) {
      setLoading(true);
      _fetchApi(
        `/transactions/retrieve?query_type=view_invoice&reference_number=${refNo}`,
        (resp) => {
          setList(resp.data);
          if (resp.data[0]?.user_id) {
            setLoading(false);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setList([]);
          setLoading(false);
        }
      );

      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${refNo}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [refNo]);

  useEffect(() => {
    getList();
  }, [getList]);

  const final_list = list.some((item) => item.transaction_type === "payment")
    ? list
    : list.map((item) => ({ ...item, cr: item.dr }));

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <div className="text-center">
              <div
                style={{ cursor: "pointer", textDecoration: "underline" }}
                className="text-gray-700"
                onClick={() => history.goBack()}
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back
              </div>
            </div>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                {refNo ? (
                  <>
                    {" "}
                    <h5>
                      Congratulations you payment has successfully recorded
                      🎉😄, your reference number is:{" "}
                      <b>
                        <i>{refNo}</i>
                      </b>
                    </h5>
                    <Col className="text-center">
                      <Button
                        className="btn-link"
                        onClick={() => {
                          if (user.role === "user") {
                            history.push(
                              `${Routes.paymentInvoice.path}?ref_no=${refNo}`
                            );
                          } else {
                            if (refNo) {
                              setIsOpen(false);
                              setPDFIsOpen(true);
                            }
                          }
                        }}
                      >
                        Print
                      </Button>
                    </Col>
                  </>
                ) : (
                  <h5 className="text-center">Reference Number not found</h5>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal size="xl" fullscreen as={Modal.Dialog} centered show={PDFisOpen}>
        <Modal.Header>
          <Modal.Title className="h6">Invoice No.: {refNo}</Modal.Title>
          <Button
            variant="danger"
            className="bg-danger text-white"
            aria-label="Close"
            onClick={togglePDF}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>{refNo ? <PaymentInvoice refNo={refNo} /> : ""}</Modal.Body>
      </Modal>
    </main>
  );
}

export default OnlinePayment;
