import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Modal, Card } from "@themesberg/react-bootstrap";

import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { useQuery } from "../utils";
import { _fetchApi, _postApi, apiURL } from "../redux/action/api";
import { useCallback } from "react";
import { Alert, CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";
import AdminNewReceipt from "./AdminPaymentReceipt";
import AdminLandReceiptPdf from "../components/AdminLandReceiptPdf";
import toast from "react-hot-toast";
import { Prompt, useHistory } from "react-router-dom";

const lgStyle = {
  height: "99vh",
  overflowY: "scroll",
  scrollbarWidth: "thin", // For Firefox
  scrollbarColor: "transparent transparent", // For Firefox
  overflow: "-moz-scrollbars-none",
  zIndex: 1,
};

export default () => {
  const userInfo = useSelector((state) => state.auth.user);
  const q = useQuery();
  const ref_no = q.get("ref_no");
  const [qrcode, setQrCode] = useState(null);
  const [user, setUser] = useState({});
  // const [user_id, setUserID] = useState(null);
  const [list, setList] = useState([]);
  const [logs, setLogs] = useState([]);
  // const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState(false);
  const [print, setPrint] = useState(false);
  const [check, setCheck] = useState(false);
  const [form, setForm] = useState({
    remark: "",
    ref_no,
  });

  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);

  const agent = useSelector((state) => state.auth.user);
  // const history = useHistory();
  // useEffect(() => {
  //   _postApi(
  //     `/transactions/update-print-count`,
  //     { query_type: "view-logs", ref_no, sector: agent.sector },
  //     (resp) => {
  //       setloading(false);
  //       if (resp.success && resp.data.length) {
  //         setLogs(resp.data);
  //         // setShow(resp.data[0]?.printed > 0);
  //       }
  //     },
  //     (err) => {
  //       console.error(err);
  //       setloading(false);
  //     },
  //     (err) => {
  //       console.log(err);
  //       setLogs([]);
  //       setloading(false);
  //     }
  //   );
  // }, []);

  const notifyPrinted = () => {
    _postApi(
      `/transactions/update-print-count?sector=${agent.sector}`,
      {
        ref_no,
        user_id: userInfo.id || "",
        query_type: "print",
        sector: agent.sector,
      },
      (d) => {
        setPrint(true);
        console.log(d);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    if (check) {
      notifyPrinted();
    }
  }, [check]);
  const getList = useCallback(() => {
    setloading(true);
    if (ref_no) {
      _fetchApi(
        `/transactions/retrieve?query_type=paid_invoice&ref_no=${ref_no}&user_id=${userInfo?.id}`,
        (resp) => {
          // alert(resp.data)
          if (resp.success && resp.data && resp.data.length) {
            setloading(false);
            // console.log(resp.data[0])
            setList(resp.data);
            _fetchApi(
              `/users/get-tax-payer?user_id=${resp.data[0]?.user_id}`,
              (resp) => {
                setloading(false);
                setUser(resp.data);
                setCheck(true);
              },
              (err) => {
                setUser({});
                setloading(false);
              }
            );
          } else {
            if (resp.message) {
              setMessage(resp.message);
              toast.success(resp.message);
            }
          }
        },
        (err) => {
          setloading(false);
        }
      );
    }
    // const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${ref_no}`;
    // fetch(qrCodeUrl, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     authorization: localStorage.getItem("@@auth_token"),
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }
    //     return response.blob();
    //   })
    //   .then((blob) => {
    //     const qrCodeImageUrl = URL.createObjectURL(blob);
    //     setQrCode(qrCodeImageUrl);
    //     setloading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //     setloading(false);
    //   });
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  const total = list[0]?.paymentAmount || list
    ?.reduce((accumulator, currentValue) => {
      const currentDr = parseFloat(currentValue.cr);
      return accumulator + currentDr;
    }, 0)
    .toFixed(2);


  return (
    <div style={lgStyle} className="p-0 m-0 h-100 text-center">
      {/* {JSON.stringify(check)} */}
      {/* <Prompt
        when={check}
        message="Are you sure you want leave the page, data on this page will be lost?"
      /> */}

      {logs[0]?.status !== "saved" ? (
        <Row className="mb-0">
          <Col>
            <PDFDownloadLink
              document={
                <Document>
                  <Page
                    wrap={false}
                    // key={index}
                    size="A4"
                    style={{ pageBreakInside: "avoid" }}
                  >
                    {list[0]?.sector === "LAND" ? (
                      <>
                        {Array.from({ length: 3 }).map((_, index) => (
                          <AdminLandReceiptPdf
                            data={list
                              ?.filter((item) => item.cr > 0)
                              .map((l) => ({
                                ...l,
                                tax_fee: l.cr,
                              }))}
                            user={user}
                            qrcode={qrcode}
                            total={total}
                            label={
                              index === 1
                                ? "DUPLICATE"
                                : index === 2
                                ? "TRIPLICATE"
                                : "ORIGINAL"
                            }
                            copyNumber={index + 1}
                            agent={agent?.name}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: 3 }).map((_, index) => (
                          <AdminNewReceipt
                            data={list
                              ?.filter((item) => item.cr > 0)
                              .map((l) => ({
                                ...l,
                                tax_fee: l.cr,
                              }))}
                            user={user}
                            qrcode={qrcode}
                            total={total}
                            label={
                              index === 1
                                ? "DUPLICATE"
                                : index === 2
                                ? "TRIPLICATE"
                                : "ORIGINAL"
                            }
                            copyNumber={index + 1}
                          />
                        ))}
                      </>
                    )}
                  </Page>
                </Document>
              }
              fileName="invoice.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    Download PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          </Col>
        </Row>
      ) : (
        <Card>
          <CardHeader>
            <CardTitle className="h6">Receipt status </CardTitle>
          </CardHeader>
          <CardBody>
            <p></p>
            <Alert variant="danger" show={true} onClose={() => {}}>
              <div className="d-flex justify-content-between text-danger">
                <div>
                  <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                  <strong>Notice</strong> This transaction is not yet been paid.
                </div>
                <Button variant="close" size="xs" onClick={() => {}} />
              </div>
            </Alert>
            <Col>
              <Button>Request for verification</Button>
            </Col>
          </CardBody>
        </Card>
      )}
      {loading ? (
        <>
          <Spinner /> Loading ....
        </>
      ) : (
        <div>
          <center>
            <Alert>{message || ""}</Alert>
          </center>
        </div>
      )}
      {list.length ? (
        <PDFViewer
          style={{ width: "100%", height: "800px", pageBreakInside: "avoid" }}
        >
          <Document>
            <Page wrap={false} size="A4" style={{ pageBreakInside: "avoid" }}>
              {list[0]?.sector === "LAND" ? (
                <>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <AdminLandReceiptPdf
                      data={list
                        ?.filter((item) => item.cr > 0)
                        .map((l) => ({
                          ...l,
                          tax_fee: l.cr,
                        }))}
                      user={user}
                      qrcode={qrcode}
                      total={total}
                      label={
                        index === 1
                          ? "DUPLICATE"
                          : index === 2
                          ? "TRIPLICATE"
                          : "ORIGINAL"
                      }
                      copyNumber={index + 1}
                      name={agent?.name}
                    />
                  ))}
                </>
              ) : (
                <>
                  <AdminNewReceipt
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    user={user}
                    qrcode={qrcode}
                    total={total}
                  />
                  <AdminNewReceipt
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    user={user}
                    qrcode={qrcode}
                    total={total}
                  />
                  <AdminNewReceipt
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    user={user}
                    qrcode={qrcode}
                    total={total}
                  />
                </>
              )}
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
    </div>
  );
};
