import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Spinner, Table } from 'reactstrap';
import { _postApi } from '../../redux/action/api';
import moment from 'moment';
import { ButtonGroup, Card, Modal } from '@themesberg/react-bootstrap';
import CustomDateRange from './CustomDateRange';
import { formatNumber } from '../../utils';
import AgentHistory from '../AgentHistory';
import Leaderboard from './Leaderboard';


const TaxLeaderboard = () => {
  const [staffPerformance, setStaffPerformance] = useState([]);
  const [stationPerformance, setStationPerformance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [agentData, setAgentData] = useState({});

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const today = moment();
  const [form, setForm] = useState({
    query_type: 'staff-performance',
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${form.year}-${form.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${form.year}-${form.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (form.type) {
      case "daily":
        setForm({ ...form, from: today, to: today });
        break;
      case "weekly":
        setForm({ ...form, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setForm({ ...form, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setForm({ ...form, from: qStart, to: qEnd });
        break;
      case "yearly":
        setForm({ ...form, from: yearStart, to: yearEnd });
        break;
      default:
        break;
    }
  }, [form.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${form.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${form.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setForm({
          ...form,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setForm({
          ...form,
          from: yearStart,
          to: yearEnd,
        });
        break;
      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            break;
        }
        const qStart = moment(`'${form.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${form.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setForm({
          ...form,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setForm({
          ...form,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;
      case "to":
        setForm({
          ...form,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setForm((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  const fetchPerformance = useCallback(() => {
    setLoading(true)
    _postApi('/auth/create-admins',
      { ...form, start_date: form.from, end_date: form.to },
      (resp) => {
        setLoading(false)
        if (resp.success && (form.query_type === 'staff-performance'|| form.query_type === 'agents-performance')) {
          if (resp.data && resp.data.length) {
            setStaffPerformance(resp.data);
          }
          else
            setStaffPerformance([]);
        } else {
          if (resp.data && resp.data.length) {
            setStationPerformance(resp.data);
          } else setStationPerformance([]);
        }
      },
      (error) => {
        setLoading(false)
        setStaffPerformance([]);
        setStationPerformance([]);
        console.error('Failed to fetch performance data', error);
      })
  }, [form.from, form.to, form.query_type]);

  useEffect(() => {
    fetchPerformance();
  }, [fetchPerformance]);

  return (
    <Card>
      {/* {JSON.stringify(staffPerformance)} */}
      <Card.Header>
        <Row>
          <Col md="8"><h3>TAX LEADER BOARD</h3></Col>
          <Col className="text-right">
            <ButtonGroup>
              <Button
                color={form.query_type === 'staff-performance' ? 'primary' : 'light'}
                onClick={() => setForm((p) => ({ ...p, query_type: 'staff-performance' }))}
              >
                Staff Leaderboard
              </Button>
              <Button
                color={form.query_type === 'station-performance' ? 'primary' : 'light'}
                onClick={() => setForm((p) => ({ ...p, query_type: 'station-performance' }))}
              >
                Stations Leaderboard
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {loading && <Spinner />}
        <Row>
          <Col>
            <CustomDateRange
              type={form.type}
              month={form.month}
              quarter={form.quarter}
              year={form.year}
              from={form.from}
              to={form.to}
              handleChangeDate={handleChangeDate}
            />
          </Col>
        </Row>
        {form.query_type === 'station-performance' ?
          <Leaderboard
            data={stationPerformance}
            title="Station Performance"
            query_type={form.query_type}
            form={form}
            setForm={setForm}
          /> :
          <Leaderboard
            data={staffPerformance}
            toggleHistory={toggleHistory}
            setAgentData={setAgentData}
            title="Staff Performance"
            query_type={form.query_type}
            form={form}
            setForm={setForm}
          />
        }
      </Card.Body>
      <Modal as={Modal.Dialog} centered show={showHistory} size="xl">
        <Modal.Header>
          <Modal.Title className="h6">AGENT COLLECTION HISTORY</Modal.Title>
          <Button
            variant="danger"
            className="bg-danger text-white"
            aria-label="Close"
            onClick={toggleHistory}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentData.taxID && <AgentHistory agent={agentData} from={form.from} to={form.to} isModal={true} />}
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default TaxLeaderboard;