import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faArrowDown,
    faArrowUp,
    // faEdit,
    faExternalLinkAlt,
    // faEye,
    // faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    Col,
    Row,
    Nav,
    Card,
    Image,
    Button,
    Table,
    // Dropdown,
    ProgressBar,
    Pagination,
    Modal,
    Badge,
    // ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
import { _fetchApi } from "../redux/action/api";
// import { toast } from "react-hot-toast";
import { formatNumber, toCurrency, toParagraph } from "../utils";
import { useSelector } from "react-redux";
import PaymentLogs from "../pages/PaymentLogs";

export const CURRENCY = "₦";

const InvoiceTable = ({
    setRef = (f) => f,
    data = [],
    _ref = null
    // viewOnly = false,
}) => {
    const TableRow = (props) => {
        const location = useHistory();
        const {
            reference_number,
            tax_payer,
            amount,
            description,
            transaction_date,
            status,
            created_at,
            printed_at = "",
        } = props;
        const statusVariant =
            status.toLowerCase() == "paid"
                ? "secondary" : status === "success" ? "secondary"
                    : status.toLowerCase() === "saved"
                        ? "warning"
                        : status.toLowerCase() === "Canceled"
                            ? "danger"
                            : "primary";
        return (
            <tr>
                <td className="text-left px-2">
                    {moment(created_at).format("DD/MM/YYYY")}
                </td>
                <td className="text-left">{tax_payer}</td>
                <td className="text-left px-2">{reference_number} </td>
                <td className="text-left">{toParagraph(description)}</td>
                <td className="text-end pe-2">
                    <span className="fw-normal text-end pe-2">
                        {formatNumber(amount)}
                    </span>
                </td>
                <td>
                    <Badge className={`text-center text-${statusVariant} `}>
                        {status === "saved" ? "Invoice" : status}
                    </Badge>
                </td>
                {/* <td  className="text-center"> */}
                {/* {status != "saved" ? (
            <>
              {printed > 0 ? (
                <Button
                  size="sm"
                  onClick={() => {
                    setShow(true);
                    setCurrent(props);
                  }}
                >
                  View
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    loggedInUser.role === "admin"
                      ? history.push(
                          `/dashboard/payment-receipt?ref_no=${reference_number}`
                        )
                      : setRef(reference_number)
                  }
                  variant="primary"
                  size="sm"
                  className="me-1 text-white"
                >
                  Print
                </Button>
              )}
            </>
          ) : (
            status
          )} */}
                {/* </td> */}
            </tr>
        );
    };
    const [sort, setSort] = useState({
        type: "string",
        order: "asc",
        name: "description",
    });

    const sortData = (type, property, order) => (a, b) => {
        const modifier = order === "desc" ? -1 : 1;

        if (type === "date") {
            const dateA = new Date(a[property]);
            const dateB = new Date(b[property]);
            return (dateA - dateB) * modifier;
        } else if (type === "string") {
            const stringA = (a[property] || "").toLowerCase();
            const stringB = (b[property] || "").toLowerCase();
            return (stringA < stringB ? -1 : stringA > stringB ? 1 : 0) * modifier;
        } else {
            const numA = a[property] || 0;
            const numB = b[property] || 0;
            return (numA - numB) * modifier;
        }
    };

    const toggleOrder = () => {
        if (sort.order === "asc") {
            setSort((p) => ({ ...p, order: "desc" }));
        } else {
            setSort((p) => ({ ...p, order: "asc" }));
        }
    };

    return (
        <>
            {/* {JSON.stringify(sort)} */}
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    <table
                        ref={_ref}
                        style={{ border: "" }}
                        width={"100"}
                        className="user-table align-items-center table-hover"
                    >
                        <thead>
                            <tr>
                                <th width="10%" className="border-bottom  text-left">
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setSort((p) => ({
                                                ...p,
                                                name: "created_at",
                                                type: "date",
                                                order: sort.order,
                                            }));
                                            toggleOrder();
                                        }}
                                    >
                                        Date
                                    </a>
                                </th>
                                <th width="20%" className="border-bottom text-left">
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setSort((p) => ({
                                                ...p,
                                                name: "tax_payer",
                                                type: "string",
                                                order: sort.order,
                                            }));
                                            toggleOrder();
                                        }}
                                    >
                                        Tax payer
                                    </a>
                                </th>
                                <th className="border-bottom  text-center">Ref No</th>
                                <th width="40%" className="border-bottom  text-left">
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setSort((p) => ({
                                                ...p,
                                                type: "string",
                                                name: "description",
                                                order: sort.order,
                                            }));
                                            toggleOrder();
                                        }}
                                    >
                                        Description
                                    </a>
                                </th>
                                <th className="border-bottom text-right">
                                    Amount ({CURRENCY})
                                </th>
                                <th className="border-bottom text-right">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                .sort(sortData(sort.type, sort.name, sort.order))
                                .map((t, idx) => (
                                    <TableRow key={idx} {...t} />
                                ))}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </>
    );
}

export default InvoiceTable