import React, { useCallback, useEffect, useState } from "react";
import { _postApi } from "../redux/action/api";
import { Input, Spinner, Table } from "reactstrap";
import { CustomButton, SearchBar, SelectInput } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Row,
  Card,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import UserTransactions from "./UserTransactions";
import { formatNumber1, useQuery } from "../utils";
import { taxStations } from "../utils/tax-stations";
import { stateLga } from "../statelga";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

function TaxPayerStations() {
  const q = useQuery();
  const station = q.get("station");
  const _form = {
    limit: 50,
    offset: 0,
    lga: "Ajingi",
    query_type: "tax-payers-by-station",
    tax_station: station,
    department: "",
    role: "",
    rank: "",
    view_by: "Tax Station",
  };
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [globalTaxStation, setGlobalTaxStation] = useState("");
  const [selectedTaxPayers, setSelectedTaxPayers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [globalTaxAdmin, setGlobalTaxAdmin] = useState(false);
  const [admins, setAdmins] = useState([]);
  const history = useHistory();
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleChange2 = ({ target: { name, value } }, taxID) => {
    setData((prevData) => {
      return prevData.map((row) =>
        row.taxID === taxID ? { ...row, [name]: value, selected: true } : row
      );
    });
  };

  const handleChange3 = ({ selected, name, value }, taxID) => {
    // const newValue = selected.length ? selected[0].name : '';
    setSelectedTaxPayers((prevSelected) => {
      if (prevSelected.includes(taxID)) {
        return prevSelected.filter((id) => id !== taxID);
      } else {
        return [...prevSelected, taxID];
      }
    });
    setData((prevData) =>
      prevData.map((row) =>
        row?.taxID === taxID
          ? { ...row, [name]: value, staff_id: selected[0]?.staff_id }
          : row
      )
    );
  };

  const handleChangeLga = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value, query_type: "tax-payers-by-lga" }));
  };
  const handleChangeStation = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
      query_type: "tax-payers-by-station",
    }));
  };

  const handleSelectAllChange = () => {
    if (allSelected) {
      setSelectedTaxPayers([]);
    } else {
      setSelectedTaxPayers(tax_payers.map((tax_payer) => tax_payer.taxID));
    }
    setAllSelected(!allSelected);
  };

  const handleCheckboxChange = (taxID) => {
    setSelectedTaxPayers((prevSelected) => {
      if (prevSelected.includes(taxID)) {
        return prevSelected.filter((id) => id !== taxID);
      } else {
        return [...prevSelected, taxID];
      }
    });
  };

  const getData = useCallback(() => {
    setLoading(true);
    _postApi(
      "/auths/-post-users",
      {
        limit: form.limit,
        offset: form.offset,
        lga: form.lga,
        tax_station: form.tax_station,
        query_type: form.query_type,
      },
      (res) => {
        setLoading(false);
        if (res.success && res.data.length) {
          setData(res.data);
          setTotalItems(res.data[0].total_rows);
        } else {
          setData([]);
          setTotalItems(0);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, [form]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / form.limit));
  }, [form.limit, totalItems]);

  const gotoPage = (x) => {
    setForm((prevForm) => ({
      ...prevForm,
      offset: x * form.limit,
    }));
  };

  const currentPage = Math.ceil((form.offset + 1) / form.limit);

  const handleGlobalTaxStationChange = ({ target: { value } }) => {
    setData((prevData) => {
      return prevData.map((row) => {
        if (selectedTaxPayers.includes(row.taxID)) {
          return { ...row, tax_station: value, selected: true };
        }
        return row;
      });
    });
    setGlobalTaxStation(value);
  };

  const handleGlobalTaxAdminChange = (selected) => {
    if (selected.length) {
      setData((prevData) => {
        return prevData.map((row) => {
          if (selectedTaxPayers.includes(row.taxID)) {
            // alert(JSON.stringify(selected[0].name));
            return {
              ...row,
              tax_admin: selected[0].name,
              staff_id: selected[0].staff_id,
              selected: true,
            };
          }
          return row;
        });
      });
      setGlobalTaxAdmin(selected);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log(
      "Checked Rows:",
      data
        .filter((x) => x.selected)
        .map((d) => ({
          taxID: d.taxID,
          tax_station: d.tax_station,
          query_type: "update-admin",
        }))
    );
    _postApi(
      "/auth/create-admins",
      {
        list: data
          .filter((x) => x.selected)
          .map((d) => ({
            id: d.taxID,
            staff_id: d.staff_id,
            name: d.tax_admin,
            username: d.org_name,
            account_type: d.account_type,
            tax_station: d.tax_station,
            query_type: "save-taxpayer-mapping",
          })),
      },
      () => {
        setLoading(false);
        toast.success("Submitted");
      },
      (e) => {
        setLoading(false);
        console.error(e);
        toast.error("Failed to submit");
      }
    );
  };
  const getAdmins = useCallback(() => {
    setLoading(true);
    _postApi(
      "/auth/create-admins",
      {
        limit: form.limit,
        offset: form.offset,
        department: form.department,
        tax_station: form.tax_station,
        rank: form.rank,
        query_type: "select-staff-names",
      },
      (res) => {
        if (res.success) {
          setLoading(false);
          setAdmins(res.data);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, [form]);

  useEffect(() => {
    getAdmins();
  }, [getAdmins]);

  const tax_payers =
    filterText.length > 1
      ? data.filter(
          (x) =>
            (x.name?.toString() ?? "").includes(filterText) ||
            (x.org_name?.toString() ?? "").includes(filterText) ||
            (x.address?.toString() ?? "").includes(filterText) ||
            (x.taxID?.toString() ?? "").includes(filterText) ||
            (x.office_address?.toString() ?? "").includes(filterText)
        )
      : data;

  // const history = useHistory();

  return (
    <Card>
      {/* {JSON.stringify(station)} */}
      <Card.Header>
        <Row>
          {/* <Col>
                        <Button
                            variant={"primary"}
                            size="sm"
                            onClick={() => { history.push(Routes.AdminStationMngr.path) }}
                        >
                            GO TO STAFF MNGR
                        </Button>
                    </Col> */}
          <Col md={12}>
            <h4 className="text-center">PAYER STATIONS</h4>
          </Col>
        </Row>
      </Card.Header>
      {show ? (
        <Row>
          <Col>
            <Button onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </Col>
        </Row>
      ) : null}
      {show ? (
        <UserTransactions taxPayer={form.taxID} viewOnly />
      ) : (
        <Card.Body>
          {/* <Row className="d-flex justify-content-between">
                        {form.query_type === 'tax-payers-by-lga' ? (
                            <Col md={6} className="mb-1">
                                <SelectInput
                                    label={<b>List By LGA (Total: {formatNumber1(totalItems)})</b>}
                                    type="select"
                                    name="lga"
                                    options={stateLga.find((item) => item.state === 'Kano')?.lgas}
                                    value={form.lga}
                                    onChange={handleChangeLga}
                                />
                            </Col>
                            
                        ) : (
                            <Col md={6} className="mb-1">
                            <SelectInput
                                label={<b>List by Tax Station (Total: {formatNumber1(totalItems)})</b>}
                                placeholder="Select Tax Station"
                                name="tax_station"
                                options={taxStations}
                                clearButton
                                value={[form.tax_station]}
                                onChange={handleChangeStation}
                                col={12}
                            />
                        </Col>
                        )}
                      
                    <Col className="text-right mt-3" md={4}>
                    <br/>
                        <ButtonGroup>
                            <Button
                                variant={form.query_type !== 'tax-payers-by-lga' ? "outline-primary" : "primary"}
                                size="sm"
                                style={{ borderRadius: "10px 0px 0px 10px" }}
                                onClick={() => { setForm((p) => ({ ...p, query_type: 'tax-payers-by-lga' })) }}
                            >
                                VIEW BY LGA
                            </Button>
                            <Button
                                variant={form.query_type === 'tax-payers-by-station' ? "primary" : "outline-primary"}
                                onClick={() => { setForm((p) => ({ ...p, query_type: 'tax-payers-by-station' })) }}
                                size="sm"
                            >
                                VIEW BY TAX STATION
                            </Button>
                        </ButtonGroup>
                    </Col>
                        
                    </Row> */}
          <Col>
            <SearchBar
              onFilterTextChange={(e) => setFilterText(e)}
              placeholder="Search Name| Address"
              filterText={filterText}
            />
          </Col>

          {loading && (
            <div>
              <Spinner />
              Loading...
            </div>
          )}
          {totalItems > 1 ? (
            <Row>
              <Col md={3}>
                <Input
                  placeholder="Items page"
                  type="select"
                  width={"100px"}
                  value={form.limit}
                  onChange={({ target: { value } }) =>
                    setForm((p) => ({
                      ...p,
                      limit: value,
                      offset: value * (currentPage - 1),
                    }))
                  }
                >
                  {[5, 10, 20, 50, 100, 200, 300, 400, 500].map((lmt, idx) => (
                    <option key={idx} value={lmt}>
                      Show {lmt} rows
                    </option>
                  ))}
                </Input>
              </Col>
              <Col>
                {currentPage > 1 && (
                  <>
                    <Button size="sm" onClick={() => gotoPage(0)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </Button>
                    <Button size="sm" onClick={() => gotoPage(currentPage - 1)}>
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                  </>
                )}
                {[...Array(Math.min(10, totalPages))].map((_, index) => {
                  const pageNumber = currentPage + index - Math.floor(10 / 2);
                  return pageNumber > 0 && pageNumber <= totalPages ? (
                    <Button
                      key={index}
                      size="sm"
                      className={
                        currentPage === pageNumber ? "bg-light text-dark" : ""
                      }
                      onClick={() => gotoPage(pageNumber - 1)}
                    >
                      {pageNumber}
                    </Button>
                  ) : null;
                })}
                {currentPage < totalPages && (
                  <>
                    <Button size="sm" onClick={() => gotoPage(currentPage)}>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                    <Button size="sm" onClick={() => gotoPage(1)}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Button>
                  </>
                )}
              </Col>
              <Col>
                <SearchBar
                  onFilterTextChange={(e) => setFilterText(e)}
                  placeholder="Search Name| Address"
                  filterText={filterText}
                />
              </Col>
              <Col className="text-right">
                <CustomButton loading={loading} onClick={handleSubmit}>
                  Submit
                </CustomButton>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              <table
                style={{ border: "" }}
                width={"100"}
                className="user-table table-responsive align-items-center table-hover"
              >
                <thead>
                  <tr>
                    <th style={{ width: "5%" }} className="text-center">
                      KIRMAS ID
                    </th>
                    <th width="20%" className="text-left">
                      NAME
                    </th>
                    <th style={{ width: "30%" }} className="text-left">
                      ADDRESS
                    </th>
                    <th width="5%" className="text-center">
                      STATE
                    </th>
                    <th width="5%" className="text-center">
                      LGA
                    </th>
                    <th width="15%" className="text-center">
                      <SelectInput
                        placeholder="TAX STATION"
                        type="select"
                        name="tax_station"
                        options={taxStations}
                        value={globalTaxStation}
                        onChange={handleGlobalTaxStationChange}
                      />
                    </th>
                    <th width="10%" className="text-center">
                      <CustomTypeahead
                        placeholder="TAX ADMINISTRATOR"
                        type="select"
                        name="tax_admin"
                        labelKey={(i) => `${i.name} - ${i.staff_id}`}
                        options={admins}
                        clearButton
                        onChange={handleGlobalTaxAdminChange}
                        col={12}
                      />
                    </th>
                    <th width="10%" className="text-center">
                      <Input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAllChange}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tax_payers?.map((tax_payer, index) => (
                    <tr key={index}>
                      <td className="text-center">{tax_payer.taxID}</td>
                      <td>
                        {tax_payer.account_type === "org"
                          ? tax_payer.org_name
                          : tax_payer.name}
                      </td>
                      <td className="text-left">
                        {tax_payer.account_type === "org"
                          ? tax_payer.office_address
                          : tax_payer.address}
                      </td>
                      <td>{tax_payer.state}</td>
                      <td>{tax_payer.lga}</td>
                      <td className="text-center">
                        <SelectInput
                          type="select"
                          name="tax_station"
                          options={taxStations}
                          value={tax_payer.tax_station}
                          onChange={(e) => handleChange2(e, tax_payer.taxID)}
                        />
                      </td>
                      <td className="text-center">
                        <CustomTypeahead
                          placeholder="TAX ADMINISTRATOR"
                          type="select"
                          name="tax_admin"
                          labelKey={(i) => `${i.name} - ${i.staff_id}`}
                          options={admins}
                          clearButton
                          selected={
                            selectedTaxPayers.includes(tax_payer?.taxID)
                              ? globalTaxAdmin
                              : null
                          }
                          onChange={(e) => {
                            handleChange3(
                              {
                                selected: e,
                                name: "tax_admin",
                                value: e[0]?.name,
                              },
                              tax_payer.taxID
                            );
                          }}
                          col={12}
                        />
                      </td>
                      <td className="text-center">
                        <Input
                          type="checkbox"
                          checked={selectedTaxPayers.includes(tax_payer.taxID)}
                          onChange={() => handleCheckboxChange(tax_payer.taxID)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
            <Col className="mt-4 text-right">
              <CustomButton loading={loading} onClick={handleSubmit}>
                Submit
              </CustomButton>
            </Col>
          </Card>
        </Card.Body>
      )}
    </Card>
  );
}

export default TaxPayerStations;
