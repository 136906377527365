import React, { useEffect, useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import useIsPhoneSize from "../../../utils/useIsPhoneSize";
import { logout } from "../../../redux/action/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../../routes";
import { FaUser } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import CustomInput from "../../examples/CustomInput";
import SelectInput from "../../components/SelectInput";
import { _fetchApi } from "../../../redux/action/api";

export default function NewTaxPayment() {
  const { user, tax_account } = useSelector((a) => a.auth);
  const isPhoneSize = useIsPhoneSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState([]);
  const [mainTaxes, setMainTaxes] = useState([]);
  const [selectedTax, setSelectedTax] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const [form, setForm] = useState({
    date_from: "",
    date_to: "",
    amount: "",
    tax_payer: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleTypeaheadChange = (selected) => {
    setSelectedTax(selected[0]);
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      "/get_new_tax",
      (resp) => {
        setMainTaxes(resp.data);
        setLoading(false);
      },
      () => {
        setMainTaxes([]);
        setLoading(false);
        // x;
      }
    );
  }, []);

  useEffect(() => {
    const isFilled = Object.values(form).every((value) => value !== "");
    setIsFormFilled(isFilled);
  }, [form]);

  return (
    <div>
      <Row className="text-center bg-dark text-light p-0 m-0">
        <Col>
          <h5>
            KANO STATE INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM
            (KIRMAS)
          </h5>
        </Col>
        {isPhoneSize ? null : (
          <Col md={1}>
            <Button
              className="mt-1 mb-1"
              size="sm"
              onClick={() => dispatch(logout(history))}
              color="danger"
            >
              Logout
            </Button>
          </Col>
        )}
      </Row>
      {JSON.stringify(selectedTax)}
      {(user && user.mda_name === "Ministry of Land and Physical Planning") ||
      (user && user.role === "agent") ? null : (
        <Row>
          <center>
            <Button
              onClick={() => {
                history.push(Routes.TaxPayment.path);
              }}
              size="sm"
              className={`m-1 ${
                location.pathname.includes("/tax-payments")
                  ? "bg-tertiary"
                  : "btn-secondary"
              } text-white px-5`}
            >
              <FaUser icon={faWallet} className="me-1" />
              TAX
            </Button>
            <Button
              onClick={() => {
                history.push(Routes.NonTaxPayment.path);
              }}
              size="sm"
              className={`m-1 ${
                location.pathname.includes("non-tax")
                  ? "bg-tertiary"
                  : "btn-secondary"
              } text-white px-5`}
            >
              <FontAwesomeIcon icon={faCreditCard} /> NON TAX
            </Button>
            <Button
              onClick={() => {
                history.push(Routes.LandPayments.path);
              }}
              size="sm"
              className={`m-1 ${
                location.pathname.includes("land")
                  ? "bg-tertiary"
                  : "btn-secondary"
              } text-white px-5`}
            >
              <FontAwesomeIcon icon={faCreditCard} /> LAND USE
            </Button>
            <Button
              onClick={() => {
                history.push(Routes.LgaPayments.path);
              }}
              size="sm"
              className={`m-1 ${
                location.pathname.includes("lga")
                  ? "bg-tertiary"
                  : "btn-secondary"
              } text-white px-5`}
            >
              <FontAwesomeIcon icon={faCreditCard} /> LGA REVENUE
            </Button>
          </center>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "1%",
        }}
      >
        <h2
          style={{
            textTransform: "capitalize",
          }}
        >
          kano state internal revenue services
        </h2>
      </div>
      <Row className="mx-2 my-3">
        <Col
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Col md={4}>
            <Label>
              <b>Search by tax Name:</b>{" "}
            </Label>
            <Col md={12}>
              <Typeahead
                id="basic-typeahead"
                labelKey={(i) => `${i.title}`}
                options={mainTaxes}
                placeholder="Search by tax name"
                // selected={selectedTax}
                onChange={handleTypeaheadChange}
                border="none"
              />
            </Col>
          </Col>
          <Col md={4}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {/* <h4>Paye Local Govts</h4>
              <p> Revenue Code: {}</p> */}
            </div>
          </Col>
        </Col>
        {selectedOption.length > 0 && (
          <div>
            <h3>Selected Fruit:</h3>
            <p>{selectedOption[0]}</p>
          </div>
        )}
        <Col md={6}>
          <CustomInput
            label="From:"
            type="date"
            name="date_from"
            value={form.date_from}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            label="To:"
            type="date"
            name="date_to"
            value={form.date_to}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <SelectInput
            label="Tax Payer"
            type="text"
            name="tax_payer"
            value={form.tax_payer}
            onChange={handleChange}
            options={["TAX", "NON TAX", "VEHICLES", "LAND", "LGA"]}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            label="Amount:"
            type="Number"
            name="amount"
            value={form.amount}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {isFormFilled && (
        <Row className="mx-2 my-3">
          <Col md={12} className="text-center">
            <Button className="bg-warning text-white" style={{ opacity: "1" }}>
              Generate Invoice
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}
