import React, { useCallback, useEffect, useState } from "react";
import { _fetchApi, _postApi } from "../redux/action/api";
import { Input, Label, Spinner, Table } from "reactstrap";
import { CustomButton, SearchBar, SelectInput } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Card, ButtonGroup } from "@themesberg/react-bootstrap";
import UserTransactions from "./UserTransactions";
import { formatNumber1 } from "../utils";
// import { taxStations } from "../utils/tax-stations";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import toast from "react-hot-toast";
import { Routes } from "../routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function StaffTaxStation() {
    const [taxStations, setTaxStations] = useState([])
    const [teams, setTeams] = useState([])
    const _form = {
        limit: 50,
        offset: 0,
        tax_station: taxStations[0],
        query_type: 'select-staff',
        department: '',
        role: '',
        rank: '',
        view_by: 'Tax Station'
    }
    const [form, setForm] = useState(_form);
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [globalTaxStation, setGlobalTaxStation] = useState('');
    const [globalTeam, setGlobalTeam] = useState('');
    const history = useHistory()
    const handleChange = ({ target: { name, value } }, taxID) => {
        if (taxID) {
            setData((prevData) => {
                return prevData.map(row =>
                    row.taxID === taxID ? {
                        ...row,
                        [name]: value,
                        selected:true
                    } : row
                );
            });
        } else {
            setForm((p) => ({ ...p, [name]: value }));
        }
    };

    const handleChange2 = (selected, name) => {
        const item = selected.length ? selected[0] : '';
            if(name==='tax_station'){
                setGlobalTaxStation(item);
            }else if(name==='team'){
                setGlobalTeam(item);
            }
            setData((prevData) => {
                return prevData.map(row => selectedTaxPayers.includes(row.taxID)
                    ? { ...row, [name]: item }
                    : row
                );
            });
        };
    const handleChangeStation = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p,  
            view_by: p.view_by,
            limit: p.limit,
            offset: p.offset,
            [name]:  value, 
            query_type: 'staff-by-station' }));
    };

    const handleChangeRoles = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p,  view_by: p.view_by,
            limit: p.limit,
            offset: p.offset,
            [name]:  value, 
            query_type: 'staff-by-roles' }));
    };

    const handleChangeRanks = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p,  view_by: p.view_by,
            limit: p.limit,
            offset: p.offset,
            [name]:  value, 
            query_type: value===''?_form.query_type: 'staff-by-ranks' }));
    };
    const handleChangeDepartments = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p,  view_by: p.view_by,
            limit: p.limit,
            offset: p.offset,
            [name]:  value, 
            query_type: 'staff-by-department' }));
    };
    // const handleCheck = (taxID, tax_station) => {
    //     setCheckedRows((prevCheckedRows) => {
    //         const isChecked = prevCheckedRows.some(row => row.taxID === taxID);
    //         if (isChecked) {
    //             return prevCheckedRows.filter(row => row.taxID !== taxID);
    //         } else {
    //             return [...prevCheckedRows, { taxID, tax_station }];
    //         }
    //     });
    // };

    const getData = useCallback(() => {
        setLoading(true);
        _postApi(
            "/auth/create-admins",
            {
                limit: form.limit,
                offset: form.offset,
                department: form.department,
                tax_station: form.tax_station,
                rank: form.rank,
                query_type: form.query_type
            },
            (res) => {
                if (res.success) {
                    setLoading(false);
                    setData(res.data);
                    setTotalItems(res.data[0].total_rows)
                }
            },
            (error) => {
                setLoading(false);
                console.log(error);
            }
        );
    }, [form]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / form.limit))
    }, [form.limit, totalItems]);

    const gotoPage = (x) => {
        setForm((prevForm) => ({
            ...prevForm,
            offset: x * form.limit,
        }));
    };

    const currentPage = Math.ceil((form.offset + 1) / form.limit);

    const handleGlobalChange = (selected, name) => {
        // alert(JSON.stringify(selected))
    const item = selected.length ? selected[0] : '';
        if(name==='tax_station'){
            setGlobalTaxStation(item);
        }else if(name==='team'){
            setGlobalTeam(item);
        }
        setData((prevData) => {
            return prevData.map(row => selectedTaxPayers.includes(row.taxID)
                ? { ...row, [name]: item.name }
                : row
            );
        });
    };

    const handleSubmit = () => {
        setLoading(true)
        console.log("Checked Rows:", data.map(d => ({
            taxID: d.taxID,
            tax_station: d.tax_station,
            staff_id: d.taxID,
            query_type: 'update-tax-station'
        })));
        _postApi("/auth/create-admins", {
            list: data.map(d => ({
                taxID: d.taxID,
                staff_id: d.taxID,
                tax_station: d.tax_station,
                query_type: 'update-admin'
            }))
        },
            () => {
                setLoading(false)
                toast.success('Submitted')
            },
            (e) => {
                setLoading(false)
                console.error(e);
                toast.error('Failed to submit')
            }
        )
    };
    const [allSelected, setAllSelected] = useState(false);
    const [selectedTaxPayers, setSelectedTaxPayers] = useState([]);

    const handleSelectAllChange = () => {
        const newAllSelected = !allSelected;
        setAllSelected(newAllSelected);
        if (newAllSelected) {
            const allTaxPayers = data.map(tp => tp.taxID);
            setSelectedTaxPayers(allTaxPayers);
        } else {
            setSelectedTaxPayers([]);
        }
    };

    const handleCheckboxChange = (taxID) => {
        const newSelectedTaxPayers = selectedTaxPayers.includes(taxID)
            ? selectedTaxPayers.filter(id => id !== taxID)
            : [...selectedTaxPayers, taxID];
        setSelectedTaxPayers(newSelectedTaxPayers);
    };

    const tax_payers = filterText.length > 1 ? data.filter(x =>
        (x.name?.toString() ?? '').includes(filterText) ||
        (x.org_name?.toString() ?? '').includes(filterText) ||
        (x.address?.toString() ?? '').includes(filterText) ||
        (x.office_address?.toString() ?? '').includes(filterText)
    ) : data;

    useEffect(() => {
        _postApi('/post-tax-stations',
            {},
            (res) => {
                if (res.success) {
                    setTaxStations(res.data)
                } else {
                    setTaxStations([])
                }
            },
            (err) => {
                toast.error('Tax stations not loaded')
                console.error(err);
                setTaxStations([])
            }
        )
        _postApi('/post-tax-stations',
            {query_type:'select-teams'},
            (res) => {
                if (res.success) {
                    setTeams(res.data)
                } else {
                    setTeams([])
                }
            },
            (err) => {
                toast.error('Tax stations not loaded')
                console.error(err);
                setTeams([])
            }
        )
    }, [])

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                    <Button
                       
                        size="sm"
                        onClick={() => { history.push(Routes.TaxStationTeams.path)}}

                    >
                        TEAM MNGR

                            </Button></Col>
                    {/* {JSON.stringify(data.filter(x=>x.tax_station!=null))} */}
                    <Col>
                        <h4 className="text-center"> STAFF STATION MANAGEMENT</h4> </Col>

                    {/* <Col md={4}>
                        <ButtonGroup>
                            <Button
                                variant={
                                    form.query_type !== 'tax-payers-by-lga' ? "outline-primary" : "primary"
                                }
                                size="sm"
                                style={{ borderRadius: "10px 0px 0px 10px" }}
                                onClick={() => { setForm((p) => ({ ...p, query_type: '' })) }}

                            >
                                VIEW BY LGA

                            </Button>
                            <Button
                                variant={
                                    form.query_type === 'tax-payers-by-station'
                                        ? "primary"
                                        : "outline-primary"
                                }
                                onClick={() => { setForm((p) => ({ ...p, query_type: 'tax-payers-by-station' })) }}
                                size="sm"
                                style={{}}
                            >VIEW BY TAX STATION
                            </Button>
                        </ButtonGroup>
                    </Col> */}


                </Row>
            </Card.Header>
            {show ? (
                <Row>
                    <Col>
                        <Button onClick={() => setShow(false)}>
                            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
                        </Button>
                    </Col>
                </Row>
            ) : null}
            {show ? (
                <UserTransactions taxPayer={form.taxID} viewOnly />
            ) : (
                <Card.Body>
                    <Row className="d-flex justify-content-between">
                        <Col md={6} className="mb-1">
                            <SelectInput
                                label={<b>List By</b>}
                                type="select"
                                name="view_by"
                                options={['Tax Stations', 'Roles', 'Ranks']}
                                value={form.view_by}
                                onChange={handleChange}
                            />
                        </Col>

                        <Col md={4}>
                            {form.view_by === 'Tax Stations' ? <SelectInput
                                // label={`${form.view_by}`}
                                label="Select Tax Station"
                                name="tax_station"
                                options={taxStations?.map(x=>x.name)}
                                clearButton
                                value={form.tax_station}
                                onChange={handleChangeStation}
                                col={12}
                            /> : form.view_by === 'Roles' ?
                            <div>
                            <Label><b>{form.view_by}</b></Label>
                        <Input
                            type="select"
                            name="role"
                            value={form.role}
                            onChange={handleChangeRoles}>
                            <option value={'admin,staff'}>Staff</option>
                            <option value={'agent, bank'}>Tax consultants</option>
                        
                        </Input></div>: form.view_by === 'Ranks' ?
                                <div>
                                    <Label><b>{form.view_by}</b></Label>
                                <Input
                                    type="select"
                                    name="rank"
                                    value={form.rank}
                                    onChange={handleChangeRanks}>
                                    <option value={'ED,ED,CIT'}>Executives</option>
                                    <option value={'DIRECTOR ASSESSMENT,Director, Revenue Accounting,DIRECTOR'}>Directors</option>
                                    <option value={'D/DIRECTOR,AG DIRECTOR,D DIRECTOR'}>D/Directors</option>
                                    <option value={'PEO'}>PEOs</option>
                                    <option value={'HEO'}>HEOs</option>
                                    <option value={'MNG'}>Managers</option>
                                    <option value={'EO'}>EO</option>
                                    <option value={'CEO'}>CEO</option>
                                    <option value={'ASST'}>ASST.</option>
                                    <option value={'Officer'}>Officers</option>
                                    <option value={'CASUAL'}>CASUAL</option>
                                    <option value={''}>All</option>
                                
                                </Input></div>: form.view_by === 'Departments' ?
                                <SelectInput
                                    label={<b>{form.view_by}</b>}
                                    type="select"
                                    name="rank"
                                    options={['Assesment Department',
                                        'Revenue AccountIng Department',
                                        'COG',
                                        'HR',
                                        'ICT',
                                        'F & RA',
                                        'ASSESSMENT',
                                        'PAYE',
                                        'GB',
                                        'TAX AUDIT',
                                        'DEBT MGT',
                                        'LEGAL']}
                                    value={form.rank}
                                    onChange={handleChangeDepartments}
                                /> : null}</Col>
                        <Col className="mt-4 text-right">
                            <CustomButton loading={loading} onClick={handleSubmit}>Submit</CustomButton>
                        </Col>

                    </Row>


                    {loading && (
                        <div>
                            <Spinner />
                            Loading...
                        </div>
                    )}
                    {totalItems > 1 ? (
                        <Row>
                            <Col md={3}>
                                <Input
                                    placeholder="Items page"
                                    type="select"
                                    width={"100px"}
                                    value={form.limit}
                                    onChange={({ target: { value } }) =>
                                        setForm((p) => ({
                                            ...p,
                                            limit: value,
                                            offset: value * (currentPage - 1),
                                        }))
                                    }
                                >
                                    {[5,10, 20, 50, 100, 200, 300, 400, 500].map((lmt, idx) => (
                                        <option key={idx} value={lmt}>
                                            Show {lmt} rows
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                {currentPage > 1 && (
                                    <>
                                        <Button size="sm" onClick={() => gotoPage(0)}>
                                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                        </Button>
                                        <Button size="sm" onClick={() => gotoPage(currentPage - 1)}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </Button>
                                    </>
                                )}
                                {[...Array(Math.min(10, totalPages))].map((_, index) => {
                                    const pageNumber = currentPage + index - Math.floor(10 / 2);
                                    return pageNumber > 0 && pageNumber <= totalPages ? (
                                        <Button
                                            key={index}
                                            size="sm"
                                            className={currentPage === pageNumber ? "bg-light text-dark" : ""}
                                            onClick={() => gotoPage(pageNumber - 1)}
                                        >
                                            {pageNumber}
                                        </Button>
                                    ) : null;
                                })}
                                {currentPage < totalPages && (
                                    <>
                                        <Button size="sm" onClick={() => gotoPage(currentPage)}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </Button>
                                        <Button size="sm" onClick={() => gotoPage(1)}>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                                        </Button>
                                    </>
                                )}
                            </Col>

                        </Row>
                    ) : (
                        ""
                    )}
                    <Row>
                        <Col>
                            <SearchBar
                                onFilterTextChange={(e) => setFilterText(e)}
                                placeholder='Search Name| Address'
                                filterText={filterText}
                            />
                        </Col>
                    </Row>
                    <Card border="light" className="table-wrapper table-responsive shadow-sm">
                        <Card.Body className="pt-0">
                            <table
                                style={{ border: "" }}
                                width={"100"}
                                className="user-table align-items-center table-hover"
                            >
                                <thead>
                                    <tr>
                                        <th className="text-center">KIRMAS ID</th>
                                        <th className="text-center">NAME</th>
                                        <th className="text-center">DEPATMENT</th>
                                        <th className="text-center">UNIT</th>
                                        <th className="text-center">RANK</th>
                                        <th className="text-center">
                                            <CustomTypeahead
                                                placeholder="TAX STATION"
                                                name="tax_station"
                                                labelKey={i => `${i.name}`}
                                                options={taxStations}
                                                clearButton
                                                value={[globalTaxStation]}
                                                onChange={(e)=>handleGlobalChange(e,'tax_station')}
                                                col={12}
                                            />
                                        </th>
                                        <th className="text-center">
                                            <CustomTypeahead
                                                placeholder="TEAM"
                                                name="name"
                                                labelKey={i => `${i.name}`}
                                                options={teams}
                                                clearButton
                                                value={[globalTeam]}
                                                onChange={(e)=>handleGlobalChange(e,'team')}
                                                col={12}
                                            />
                                        </th>
                                        <th>
                                            <Input
                                                type="checkbox"
                                                checked={allSelected}
                                                onChange={handleSelectAllChange}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tax_payers?.map((tax_payer, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{tax_payer.taxID}</td>
                                            <td>{tax_payer.name}</td>
                                            <td className="text-left">{tax_payer.department}</td>
                                            <td>{tax_payer.unit}</td>
                                            <td>{tax_payer.rank}</td>
                                            <td className="text-center">
                                                <SelectInput
                                                    type="select"
                                                    name="tax_station"
                                                    options={taxStations?.map(x=>x.name)}
                                                    value={tax_payer.tax_station}
                                                    onChange={(e) => handleChange(e, tax_payer.taxID)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <SelectInput
                                                    type="select"
                                                    name="team"
                                                    options={teams?.map(x=>x.name)}
                                                    value={tax_payer.team}
                                                    onChange={(e) => handleChange(e, tax_payer.taxID)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="checkbox"
                                                    checked={selectedTaxPayers.includes(tax_payer.taxID)}
                                                    onChange={() => handleCheckboxChange(tax_payer.taxID)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        <Col className="mt-4 text-right">
                            <CustomButton loading={loading} onClick={handleSubmit}>Submit</CustomButton>
                        </Col>
                        </Card.Body>
                    </Card>
                </Card.Body>
            )}
        </Card>
    );
}

export default StaffTaxStation;
