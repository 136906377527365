export const Occupations = [
    "Accountant",
    "Actor",
    "Administrative Assistant",
    "Advertising Specialist",
    "Architect",
    "Artist",
    "Attorney/Lawyer",
    "Auditor",
    "Banker",
    "Bartender",
    "Biologist",
    "Carpenter",
    "Chef/Cook",
    "Chemist",
    "Civil Engineer",
    "Consultant",
    "Data Analyst",
    "Dentist",
    "Designer",
    "Doctor/Physician",
    "Driver",
    "Economist",
    "Editor",
    "Electrician",
    "Engineer",
    "Entrepreneur",
    "Civil Servant",
    "Farmer",
    "Freelancer",
    "Financial Advisor",
    "Graphic Designer",
    "Healthcare Worker",
    "Human Resources Specialist",
    "IT Specialist",
    "Journalist",
    "Judge",
    "Lawyer",
    "Librarian",
    "Machinist",
    "Manager",
    "Marketing Specialist",
    "Mechanic",
    "Musician",
    "Nurse",
    "Pharmacist",
    "Photographer",
    "Pilot",
    "Plumber",
    "Police Officer",
    "Professor",
    "Programmer/Developer",
    "Psychologist",
    "Real Estate Agent",
    "Researcher",
    "Salesperson",
    "Seflf employed",
    "Scientist",
    "Secretary",
    "Social Worker",
    "Software Engineer",
    "Teacher",
    "Technician",
    "Translator",
    "Tradesperson",
    "Truck Driver",
    "Taxi Driver",
    "Tricycle Driver",
    "Veterinarian",
    "Waiter/Waitress",
    "Writer/Author",
    "Others"
];
