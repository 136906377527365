import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { _fetchApi } from "../redux/action/api";
import moment from "moment";
import { ImCheckmark } from "react-icons/im";

export default function Viewverify({ match }) {
  const { id } = match.params;
  const [data, setData] = useState();
  const getVerify = () => {
    _fetchApi(
      `/verify-mda?id=${id}`,
      (res) => {
        setData(res.results[0]);
      },
      (err) => {
        console.error(err);
      }
    );
  };

  useEffect(() => {
    getVerify();
  }, []);
  return (
    <div className="d-flex align-items-center justify-content-center mt-5">
      {/* <Row> */}

      <Col md={3}></Col>
      <Col md={6} className="mt-5">
        <Container className="mt-5">
          <div className="mb-4 mb-lg-0 bg-white shadow-soft border mt-5 rounded border-light p-4 p-lg-5 w-100 fmxw-80 align-items-center justify-content-center mt-5">
            <div className="text-center text-md-center mb-4 mt-md-0 ">
              {/* <h3 className="mb-0">Verify</h3> */}
            </div>
            {/* {JSON.stringify(data)} */}
            <center>
              <ImCheckmark color="green" size={80} /> <br />
              <h5>Verified</h5>
              <br />
            </center>
            <span
              style={{
                fontSize: "30px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <b style={{ fontSize: "30px" }}>MDA Name: {""}</b>
              {data && data[0]?.mda_name.toUpperCase()}{" "}
            </span>
            <br />
            <span style={{ fontSize: "30px" }}>
              <b style={{ fontSize: "30px" }}>MDA code: {""}</b>
              {data && data[0]?.mda_code}
            </span>
            <br />
            <span style={{ fontSize: "30px" }}>
              <b style={{ fontSize: "30px" }}>Date: {""}</b>
              {moment(data && data[0]?.created_at).format("D  MMMM, YYYY")}
            </span>
          </div>
        </Container>
      </Col>
      <Col md={3}></Col>
      {/* </Row> */}
    </div>
  );
}
