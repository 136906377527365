import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Input, Button, Form, Card, CardTitle, CardBody } from 'reactstrap';
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useHistory } from "react-router-dom";

function Apply() {
  const { user } = useSelector(s => s.auth);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    main_source_of_income: '',
    address: '',
    bvn: '',
    tin: '',
    license_membership: '',
    email: '',
    phone_number: '',
    upload_certificate: null,
    upload_membership_certificate: null,
    taxID: user?.taxID || ''
  });

  useEffect(() => {
    if (user?.taxID) {
      setFormData(prevFormData => ({ ...prevFormData, taxID: user.taxID }));
    }
  }, [user]);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    _postApi(
      '/taxconsultant',
      {
        ...formData,
        status: 'pending',
        query_type: "insert"
      },
      (response) => {
        console.log('Success:', response);
        history.push('/components/view-consultant');
      }
    );
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === 'file' ? files[0] : value;
    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  return (
    <div>
      <Card className=''>
        {/* {JSON.stringify(formData)} */}
        <CardTitle tag="h5" className="bg-light p-3 mb-1">Consultant Registration</CardTitle>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Consultant Name</Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                placeholder="Consultant name"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="main_source_of_income">Main Source of Income</Label>
              <Input
                id="main_source_of_income"
                name="main_source_of_income"
                value={formData.main_source_of_income}
                placeholder="Source of income"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                id="address"
                name="address"
                value={formData.address}
                placeholder="Address"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="bvn">Enter RC/BVN Number</Label>
              <Input
                id="bvn"
                name="bvn"
                value={formData.bvn}
                placeholder="BVN Number"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            {/* //here we go */}
            <FormGroup>
              <Label for="tin">TIN Number</Label>
              <Input
                id="tin"
                name="tin"
                value={formData.tin}
                placeholder="TIN Number"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="license_membership">License/Membership</Label>
              <Input
                id="license_membership"
                name="license_membership"
                value={formData.license_membership}
                placeholder="License/Membership"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                value={formData.email}
                placeholder="Email"
                type="email"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone_number">Phone Number</Label>
              <Input
                id="phone_number"
                name="phone_number"
                value={formData.phone_number}
                placeholder="Phone Number"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="upload_certificate">Upload Certificate of Incorporation</Label>
              <Input
                id="upload_certificate"
                name="upload_certificate"
                type="file"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="upload_membership_certificate">Upload Membership Certificate or CITIN Practicing License</Label>
              <Input
                id="upload_membership_certificate"
                name="upload_membership_certificate"
                type="file"
                onChange={handleChange}
              />
            </FormGroup>
            <Button color="secondary" className="me-2" type="button" onClick={() => history.goBack()}>
              Back
            </Button>
            <Button color="primary" type="submit">
              Register
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default Apply;