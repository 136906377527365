import React, { useEffect, useState, useRef } from "react";
import {
    Col,
    Row,
    Card,
    Spinner,
    Button,
    ButtonGroup,
    Table,
    FormGroup,
} from "@themesberg/react-bootstrap";
// import { useHistory } from "react-router";
// import { Routes } from "../routes";
// import DaterangeSelector from "./dashboard/DaterangeSelector";
import { _fetchApi, _postApi } from "../redux/action/api";
import { CounterWidget } from "../components/Widgets";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { TransactionLogs } from "../components/Tables2";
// import CustomTypeahead from "../components/UI/CustomTypeahead";
import { useSelector } from "react-redux";
import CustomDateRange from "./dashboard/CustomDateRange";
// import PaymentInvoice from "./PaymentInvoice";
import PaymentReciptPdf from "./PaymentReciptPdf";
import { downloadExcel } from "react-export-table-to-excel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CollectionsPDF from "../components/CollectionsPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    // faAngleDoubleLeft,
    // faAngleDoubleRight,
    // faAngleLeft,
    // faAngleRight,
    faDownload,
    // faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import CollectionsSummaryPDF from "../components/CollectionsSummaryPDF";
import { formatNumber, formatNumber1, toCurrency, toParagraph } from "../utils";
// import { FaAngleDoubleRight } from "react-icons/fa";
import { SearchBar } from "../components/UI";
import InvoiceTable from "../components/InvoiceTable";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import toast from "react-hot-toast";
import { isSuper } from "../components/UI/helpers";
// import { CounterWidget } from "../components/Widgets";

function InvoiceReport() {
    const { tax_account, user } = useSelector((s) => s.auth);
    const tableRef = useRef(null);
    const [refNo, setRef] = useState(null);
    const [searchText, setSearchText] = useState("");

    const [mdas, setMDAs] = useState([]);
    const [form, setForm] = useState({
        query_type: "view-history",
        view: "all",
        limit: 200,
        mda_code: isSuper(user) ? '' : user.mda_code,
        offset: 0,
    });
    const viewContent = (view = "") => {
        setForm((p) => ({ ...p, view }));
    };
    useEffect(() => {
        _fetchApi(
            "/get/mdas?query_type=get-mdas",
            (resp) => {
                if (resp.success) {
                    setMDAs(resp.data);
                }
            },
            (err) => {
                console.error(err);
                toast.error("Errorr occured!");
            }
        );
    }, []);
    const [totalItems, setTotalItems] = useState(0);

    const gotoPage = (x) => {
        setForm((prevForm) => ({
            ...prevForm,
            offset: x * form.limit,
        }));
    };
    const currentPage = Math.ceil((form.offset + 1) / form.limit);
    const totalPages = Math.ceil(totalItems / form.limit);

    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState([]);
    const [total, setTotal] = useState(0);
    // date picker setup
    const today = moment();
    const [dateRange, setDateRange] = useState({
        type: "daily",
        month: today.format("MM"),
        quarter: Math.ceil(today.format("MM") / 3).toString(),
        year: today.format("YYYY"),
        from: today.format("YYYY-MM-DD"),
        to: today.format("YYYY-MM-DD"),
    });

    useEffect(() => {
        const today = moment().format("YYYY-MM-DD");
        const weekStart = moment().startOf("week").format("YYYY-MM-DD");
        const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
        const monthStart = moment().startOf("month").format("YYYY-MM-DD");
        const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
        const yearStart = moment().startOf("month").format("YYYY-MM-DD");
        const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

        const qStart = moment(
            `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
        ).format("YYYY-MM-DD");
        const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
            .endOf("month")
            .format("YYYY-MM-DD");

        switch (dateRange.type) {
            case "daily":
                setDateRange({ ...dateRange, from: today, to: today });
                break;
            case "weekly":
                setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
                break;
            case "monthly":
                setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
                break;
            case "quarterly":
                setDateRange({ ...dateRange, from: qStart, to: qEnd });
                break;
            case "yearly":
                setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

            default:
                break;
        }
    }, [dateRange.type]);

    const handleChangeDate = ({ target: { name, value } }) => {
        switch (name) {
            case "month":
                const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
                    "YYYY-MM-DD"
                );
                const monthEnd = moment(`'${dateRange.year}-${value}'`)
                    .endOf("month")
                    .format("YYYY-MM-DD");

                setDateRange({
                    ...dateRange,
                    from: monthStart,
                    to: monthEnd,
                    month: value,
                });
                break;
            case "year":
                const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
                const yearEnd = moment(`'${value}-12'`)
                    .endOf("year")
                    .format("YYYY-MM-DD");

                setDateRange({
                    ...dateRange,
                    from: yearStart,
                    to: yearEnd,
                });
                break;

            case "quarter":
                let startMonth, endMonth;
                let selectedQuarter = value;
                switch (selectedQuarter) {
                    case "Q1":
                        startMonth = 1;
                        endMonth = 3;
                        break;
                    case "Q2":
                        startMonth = 4;
                        endMonth = 6;
                        break;
                    case "Q3":
                        startMonth = 7;
                        endMonth = 9;
                        break;
                    case "Q4":
                        startMonth = 10;
                        endMonth = 12;
                        break;
                    default:
                        // Handle unexpected quarter values
                        break;
                }
                const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
                    "YYYY-MM-DD"
                );
                const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
                    .endOf("month")
                    .format("YYYY-MM-DD");

                setDateRange({
                    ...dateRange,
                    from: qStart,
                    to: qEnd,
                    quarter: selectedQuarter,
                });
                break;
            case "from":
                setDateRange({
                    ...dateRange,
                    from: moment(value).format("YYYY-MM-DD"),
                });
                break;

            case "to":
                setDateRange({
                    ...dateRange,
                    to: moment(value).format("YYYY-MM-DD"),
                });
                break;
            default:
                break;
        }
        setDateRange((prevDateRange) => ({
            ...prevDateRange,
            [name]: value,
        }));
    };

    // useEffect(() => {
    //   _postApi(`/transactions/update-print-count`, {query_type: 'all_summary'}, resp => {
    //     if(resp && resp.data && resp.data.length) {
    //       setAllSummary(resp.data[0])
    //     }
    //   })
    // }, [])

    const containerStyle = {
        height: "80vh",
        overflowY: "scroll",
        scrollbarWidth: "thin", // For Firefox
        scrollbarColor: "transparent transparent", // For Firefox
        overflow: "-moz-scrollbars-none",
        zIndex: 1,
    };
    useEffect(() => {
        setLoading(true);
        if (form.query_type) {
            _postApi(
                `/transactions/update-print-count?sector=${user.sector}`,
                {
                    query_type: form.query_type,
                    from: dateRange.from,
                    to: dateRange.to,
                    view: form.view,
                    sector: user.sector,
                    mda_code: form.mda_code ? form.mda_code : isSuper(user) ? '' : user.mda_code,
                    limit: form.limit,
                    offset: form.offset,
                    ref_no: searchText.length > 3 ? searchText : "",
                },
                (resp) => {
                    setLoading(false);
                    if (resp && resp.data.length) {
                        setTotalItems(resp.data[0]?.total_rows);
                        setTotal(resp.data[0]?.total_revenue);
                        if (form.view === "receipt") {
                            setReport(resp.data.filter((itm) => itm.status != "saved"));
                        } else {
                            setReport(resp.data);
                        }
                    } else {
                        setReport([]);
                        setTotalItems(0);
                        setTotal(0);
                    }
                },
                (err) => {
                    setLoading(false);
                    setReport([]);
                    setTotalItems(0);
                    setTotal(0);
                    console.log(err);
                }
            );
        }
    }, [dateRange, form, user])

    const payer = tax_account;

    // const userIsAdminAndHOD = user.role === "admin";
    const header = [
        "SN",
        "DATE",
        "Ref No.",
        "TAX PAYER NAME",
        "DESCRIPTION OF PAYMENT",
        "AMOUNT",
        "DEPARTMENT",
        "STATUS",
    ];
    // const summary_header = [
    //     "SN",
    //     "DEPARTMENT",
    //     "REVENUE GENERATED",
    //     "START DATE",
    //     "END DATE",
    // ];
    const RECORDS = report?.length
        ? report
            ?.map((t) => ({
                ...t,
                amount: Math.max(t.cr, t.dr),
            }))
            .filter((_) => _.amount)
            .map((item, idx) => ({
                id: idx + 1,
                date: moment(item.created_at).format('DD/MM/YYYY'),
                ref_no: item.reference_number,
                tax_payer: item.tax_payer,
                description: item.description,
                amount: Math.max(item.dr),
                department: item.department,
                status: item.status,
            }))
        : [];

    // const SUMMARY = report?.length
    // ? report

    //     .filter((_) => _.total)
    //     .map((item, idx) => ({
    //         id: idx + 1,
    //         department: item.department,
    //         total: toCurrency(item.total),
    //         start_date: item.start_date,
    //         end_date: item.end_date,
    //     }))
    // : [];
    function handleDownloadExcel() {
        downloadExcel({
            fileName:
                "Invoice report",
            sheet: "Invoices",
            tablePayload: {
                header: header,
                body: RECORDS.filter((_) => _.amount > 0)

            },
        });
    }
    const handleChange = ({ target: { name, value } }) => {
        setForm((prevState) => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        if (searchText && searchText.length >= 3) {
            setForm((p) => ({ ...p, query_type: "search-history" }));
        } else setForm((p) => ({ ...p, query_type: "view-history" }));
    }, [searchText]);

    return (
        <div>
            {refNo ? (
                <Row
                    className="w-100"
                    style={{ border: "1px solid black", height: "1000px !important" }}
                >
                    <Col
                        md={12}
                        className="w-100 h-100"
                        style={{ height: "1000px !important" }}
                    >
                        <Button onClick={() => setRef(null)} className="btn-warning">
                            Close
                        </Button>
                        {/* {JSON.stringify(refNo)} */}
                        <PaymentReciptPdf refNo={refNo} />
                    </Col>
                </Row>
            ) : (
                <>
                    <div className="my-1">
                        <CustomDateRange
                            type={dateRange.type}
                            month={dateRange.month}
                            quarter={dateRange.quarter}
                            year={dateRange.year}
                            from={dateRange.from}
                            to={dateRange.to}
                            handleChangeDate={handleChangeDate}
                        />
                    </div>
                    <Row>
                        <Col md={6}>
                            {!isSuper(user) ? (
                                <div className="mb-1">
                                    <Input type="text" value={user.mda_name} />
                                </div>
                            ) : (
                                <CustomTypeahead
                                    placeholder="Select MDA"
                                    name="sector"
                                    labelKey={(i) => `${i.mda_name}`}
                                    value={form.mda_name}
                                    clearButton
                                    options={mdas}
                                    onChange={(e) => {
                                        if (e.length) {
                                            console.log(e[0]);
                                            setForm((p) => ({
                                                ...p,
                                                mda_code: e[0].mda_code,
                                                mda_name: e[0].mda_name,
                                            }));
                                        }
                                    }}
                                    onInputChange={() => {
                                        setForm((p) => ({
                                            ...p,
                                            mda_code: '',
                                            mda_name: '',
                                        }))
                                    }}
                                    col={12}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Card className="bg-white p-2">
                                <CounterWidget
                                    color="lightblue"
                                    category="Number of Transactions"
                                    count={formatNumber1(totalItems)}
                                    period="Feb 1 - Apr 1"
                                    percentage={18.2}
                                    icon={faPrint}
                                    iconColor="shape-secondary"
                                    showChanges={false}
                                // onClick={() => history.push(Routes.ReceiptReportDetails.path+`?from=${dateRange.from}&to=${dateRange.to}`)}
                                />
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="bg-white p-2">
                                <CounterWidget
                                    color="lightblue"
                                    category={`${toParagraph(dateRange.type.replace('custom', ''))} Invoice`}
                                    title={total}
                                    period="Feb 1 - Apr 1"
                                    percentage={18.2}
                                    icon={faPrint}
                                    iconColor="shape-secondary"
                                    showChanges={false}
                                // onClick={() => history.push(Routes.ReceiptReportDetails.path)}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <>
                        {["view-history", "search-history"].includes(form.query_type) ? (
                            <Card>
                                <Card.Body>
                                    {loading && (
                                        <div className="text-center">
                                            <Spinner />
                                            Loading...
                                        </div>
                                    )}
                                    <Row className="d-flex flex-direction-row justify-content-between align-items-end mb-2">
                                        <Col md={12} className="m-0 p-0">
                                            {totalItems ? (
                                                <Row>
                                                    <Col md={2} className="d-flex flex-direction-row justify-content-between align-items-end">
                                                        <Input
                                                            placeholder="Items page"
                                                            type="select"
                                                            width={"100px"}
                                                            value={form.limit}
                                                            onChange={({ target: { value } }) =>
                                                                setForm((p) => ({
                                                                    ...p,
                                                                    limit: value,
                                                                    offset: value * (currentPage - 1),
                                                                }))
                                                            }
                                                        >
                                                            {[100, 200, 300, 400, 500].map((lmt, idx) => (
                                                                <option key={idx} value={lmt}>
                                                                    {lmt}/page
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            {Array.from({ length: totalPages }).map(
                                                                (_, page) => (
                                                                    <Button
                                                                        key={page}
                                                                        size="sm"
                                                                        className={
                                                                            currentPage === page + 1
                                                                                ? "bg-light text-dark"
                                                                                : ""
                                                                        }
                                                                        onClick={() => gotoPage(page)}
                                                                    >
                                                                        {page + 1}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <strong className="text-right text-end">Total: {toCurrency(total)}</strong>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6} className="mb-2">
                                            <SearchBar
                                                filterText={searchText}
                                                placeholder="Search Ref No."
                                                onFilterTextChange={(e) => {
                                                    setSearchText(e);
                                                }}
                                            />
                                        </Col>
                                        <Col md={6} className="text-right m-0 p-0 mb-1">
                                            <ButtonGroup>
                                                <Button
                                                    variant={
                                                        form.view !== "all" ? "outline-primary" : "primary"
                                                    }
                                                    size="sm"
                                                    style={{ borderRadius: "10px 0px 0px 10px" }}
                                                    onClick={() => viewContent("all")}
                                                >
                                                    View all
                                                </Button>
                                                <Button
                                                    variant={
                                                        form.view === "invoice"
                                                            ? "primary"
                                                            : "outline-primary"
                                                    }
                                                    onClick={() => viewContent("invoice")}
                                                    size="sm"

                                                >
                                                    View Invoices
                                                </Button>
                                                <Button
                                                    variant={
                                                        form.view === "receipt"
                                                            ? "primary"
                                                            : "outline-primary"
                                                    }
                                                    onClick={() => viewContent("receipt")}
                                                    size="sm"
                                                   
                                                    style={{ borderRadius: "0px 10px 10px 0px" }}
                                                >
                                                    View Paid
                                                </Button>

                                                <PDFDownloadLink
                                                    document={
                                                        <CollectionsPDF
                                                            user={user}
                                                            data={RECORDS.filter((_) => _.amount > 0)}
                                                        />
                                                    }
                                                    fileName={`Invoice report-${moment().format("YYYYMMDDhhmm")}.pdf`}
                                                >
                                                    {({ blob, url, loading, error }) =>
                                                        loading ? (
                                                            "Loading document..."
                                                        ) : (
                                                            <Button
                                                                variant={
                                                                    form.view !== "pdf"
                                                                        ? "outline-primary"
                                                                        : "primary"
                                                                }
                                                                size="sm"
                                                                // style={{ borderRadius: "0px 0px 0px 0px" }}
                                                                style={{ borderRadius: "10px 0px 0px 10px" }} P
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faDownload}
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Export PDF
                                                            </Button>
                                                        )
                                                    }
                                                </PDFDownloadLink>
                                                <Button
                                                    variant={
                                                        form.view !== "excel"
                                                            ? "outline-primary"
                                                            : "primary"
                                                    }
                                                    size="sm"
                                                    onClick={handleDownloadExcel}
                                                >
                                                    Export Excel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <div style={containerStyle}>
                                        <InvoiceTable
                                            setRef={setRef}
                                            viewOnly={false}
                                            _ref={tableRef}
                                            data={report
                                                ?.map((t) => ({
                                                    ...t,
                                                    amount: Math.max(t.cr, t.dr),
                                                }))
                                                .filter((_) => _.amount)}
                                            user={payer}
                                        />
                                    </div>

                                </Card.Body>
                            </Card >
                        ) : (
                            <Card.Body>
                                {/* {JSON.stringify(report)} */}
                                <div style={containerStyle}>
                                    <Table hover className="user-table align-items-center pt-0">
                                        <thead>
                                            <tr>
                                                <th className="border-bottom">#</th>
                                                <th className="border-bottom">Department</th>
                                                <th className="border-bottom">Revenue generated</th>
                                                <th className="border-bottom">Start Date</th>
                                                <th className="border-bottom">End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {report.map((t, idx) => (
                                                <tr key={idx}>
                                                    <td className="border-bottom">{idx + 1}</td>
                                                    <td className="border-bottom">{t.department}</td>
                                                    <td className="border-bottom">
                                                        {formatNumber(t.total)}
                                                    </td>
                                                    <td className="border-bottom">{t.start_date}</td>
                                                    <td className="border-bottom">{t.end_date}</td>
                                                </tr >
                                            ))
                                            }
                                        </tbody >
                                    </Table >
                                </div>
                            </Card.Body >
                        )}
                    </>
                </>
            )}
            {/* {JSON.stringify(user)} */}
        </div>
    );
}

export default InvoiceReport;
