import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
} from '@themesberg/react-bootstrap'

import { TransactionsTable, ViewTable } from '../components/Tables'
import DaterangeSelector from './dashboard/DaterangeSelector'
import { _fetchApi, apiURL } from '../redux/action/api'

export default () => {
  const [results, setResults] = useState([])

  useEffect(() => {
    _fetchApi(
      `/transaction-history?query_type=select`,
      (res) => {
        if (res.success) {
          setResults(res.results)
        }
      },
      (err) => {
        console.error(err)
      },
    )
  }, [])
  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0"></Card.Body>
      </Card>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>KIGRA</Breadcrumb.Item>
            <Breadcrumb.Item active>Payments History</Breadcrumb.Item>
          </Breadcrumb> */}
          <h4>Payment History Report</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {/* {JSON.stringify(results)} */}
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={12} md={6} lg={9} xl={8} className="ps-md-0 text-end">
            <DaterangeSelector inline  />
          </Col>
        </Row>
      </div>
      <ViewTable results={results} />
    </>
  )
}
