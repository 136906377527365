import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';
import { FaBuilding, FaDollarSign, FaChartLine, FaUser } from 'react-icons/fa';
import { _fetchApi } from '../../redux/action/api';
import moment from 'moment';
import { toCurrency } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Routes } from '../../routes';

const DashboardCard = ({ title, value, icon, size = 'lg' }) => {
    return (
        <Card style={{ cursor: 'pointer' }} className="text-center mb-4">
            <CardBody>
                {icon}
                <CardText className="mt-2">{title}</CardText>
                {size === 'sm' ? <h5 className="pt-2">{value}</h5> : <h3>{value}</h3>}
            </CardBody>
        </Card>
    );
};

const RevDashboardCard = () => {
    const [data, setData] = useState({})
    const history = useHistory()

    useEffect(() => {
        _fetchApi(
            `/assessments/paye-list?year=${moment().format('YYYY')}&month=${moment().format('MM')}&query_type=assessment-dashbord`,
            (resp) => {
                if (resp.success) {
                    setData(resp.data[0]);
                }
            }
        );
    }, []);
    return (<Card className="mb-4">
        <CardBody>
            <CardTitle tag="h5" className='text-center'>PIT Collection Summary from {moment().startOf("month").format('DD/MM/YYYY')} to {moment().format('DD/MM/YYYY')}</CardTitle>
            <Row>
                <Col onClick={() => history.push(Routes.AllOrgs.path)} lg="3" md="6" sm="6" xs="12">
                    <DashboardCard
                        title="Total Organizations"
                        value={toCurrency(data.total_orgs || 0).toString().split('.')[0]}
                        icon={<FaBuilding size={40} className="mb-2" />}
                    />
                </Col>
                <Col onClick={() => history.push(Routes.UnpaidOrgs.path)} lg="3" md="6" sm="6" xs="12">
                    <DashboardCard
                        title="Total liability"
                        value={toCurrency(data.total_expected || 0).toString().split('.')[0]}
                        icon={<FaChartLine size={40} className="mb-2" />}

                    />
                </Col>
                <Col lg="3" md="6" sm="6" xs="12">
                    <DashboardCard
                        title="Total Paid"
                        value={`₦${toCurrency(data.total_paid || 0)}`}
                        size='sm'
                        icon={<FaChartLine size={40} className="mb-2" />}
                    />
                </Col>
                <Col onClick={() => history.push(Routes.UnpaidOrgs.path)} lg="3" md="6" sm="6" xs="12">
                    <DashboardCard
                        title="Total Due"
                        value={`${toCurrency(data.total_due || 0).toString().split('.')[0]}`}
                        icon={<FaChartLine size={40} className="mb-2" />}
                        size='lg'
                    />
                </Col>

            </Row>
        </CardBody>
    </Card>
    );
};

export default RevDashboardCard;
