import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupText,
  Form,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { _fetchApi, _postApi } from "../../redux/action/api";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import moment from "moment";
import toast from "react-hot-toast";
import { useQuery } from "../../utils";
import { CustomButton } from "../../components/UI";
import AddAccout from "../AddAccount";
import { ButtonGroup, Modal } from "@themesberg/react-bootstrap";
import CustomModal from "../../components/UI/CustomModal";
import { useSelector } from "react-redux";
const _data = [
  {
    type: "Formal Businesses",
    idetifier: "F",
    value: "Employment",
    display: "Salary",
  },
  {
    type: "Formal Businesses",
    idetifier: "F",
    value: "Tax-Expatriate",
    display: "Salary",
  },
  {
    type: "Formal Businesses",
    idetifier: "F",
    value: "Pension",
    display: "Pension",
  },
  {
    type: "Informal Businesses",
    idetifier: "I",
    value: "Trade",
    display: "Trade",
  },
  {
    type: "Informal Businesses",
    idetifier: "I",
    value: "Profesion",
    display: "Profesion",
  },
  {
    type: "Informal Businesses",
    idetifier: "I",
    value: "Vocation",
    display: "Profesion",
  },
  {
    type: "Informal Businesses",
    idetifier: "I",
    value: "High-Networth",
    display: "Trade",
  },
  {
    type: "Informal Businesses",
    value: "Private Business/Enterprises",
    display: "Private Business/Enterprises",
    idetifier: "I",
  },
  {
    type: "Informal Businesses",
    idetifier: "I",
    value: "Tax-Expatriate",
    display: "Trade",
  },
  {
    type: "KRIT",
    value: "Valid For Land Transaction",
    display: "Land Transaction",
    idetifier: "KRIT",
  },
  {
    type: "KRIT",
    idetifier: "KRIT",
    value: "Valid For BTA",
    display: "BTA",
  },
  {
    type: "KRIT",
    idetifier: "KRIT",
    value: "Valid For Multilated Currency",
    idetifier: "KRIT",
    display: "Multilated Currency",
  },

  {
    type: "KRIT",
    value: "Others",
    display: "Others",
    idetifier: "KRIT",
  },
];
export default function TaxClearancePublic() {
  const year = moment().subtract(1, "year").format("YYYY");
  const date = moment().format("YYYY-MM-DD");
  const q = useQuery();
  const tcc_ref = q?.get("tcc_ref");
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);
  const [taxId, setTaxId] = useState(0);
  const { tax_account } = useSelector((s) => s.auth);

  function randomInt() {
    return Math.floor(10000 + Math.random() * 90000);
}

  const _form = {
    date_issued: date,
    tin:tax_account.account_type==='org'? tax_account.org_tin:tax_account.org_tin,
    tax_file_no: `KIRS-${year}-${randomInt()}`,
    taxID: tax_account.taxID,
    income_source: tax_account.income_source,
    year,
    first_amount: "",
    second_amount: "",
    third_amount: "",
    first_income: "",
    second_income: "",
    third_income: "",
    first_year: `${year - 2}-12-31`,
    second_year: `${year - 1}-12-31`,
    third_year: `${year}-12-31`,
    tax_payer: tax_account.account_type==='org'?tax_account.org_name:tax_account.name,
    query_type: "create",
    source_of_income: tax_account.income_source,
    org_id:tax_account.account_type==='org'?tax_account.taxID:"",
    org_name: tax_account.account_type==='org'?tax_account.org_name:"",
    type: "",
    address: tax_account.account_type==='org'?tax_account.office_address:tax_account.address,
    others: "",
  };
  //   r'^[1-9.,]+$'
  const [form, setForm] = useState(_form);
  const history = useHistory();
  const [tax_payer, setTaxpayer] = useState("");
  const [org_name, setOrgName] = useState(tax_account.account_type==='org'?tax_account.org_name:"");
  const [tax_payers, setTaxPayers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);

  const toggle = () => {
    setShow(!show);
  };
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  let val = _data?.filter((item) => item.value === form.income_source);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.taxID === "") {
      toast.error("Please Select  Tax Payer Name");
    } else if (form.address === "" && form.org_id === "") {
      toast.error(
        "The user's address field is blank. Please log in to the user dashboard and  update his/her profile."
      );
    } else {
      setLoading(true);
      _postApi(
        "/post/tax-clearance",
        {
          ...form,
          staff_name: 'KIRMAS ONLINE',
          first_amount: parseFloat(
            form.first_amount.toString().replace(/,/g, "")
          ).toFixed(2),
          second_amount: parseFloat(
            form.second_amount.toString().replace(/,/g, "")
          ).toFixed(2),
          third_amount: parseFloat(
            form.third_amount.toString().replace(/,/g, "")
          ).toFixed(2),
          first_income: parseFloat(
            form.first_income.toString().replace(/,/g, "")
          ).toFixed(2),
          second_income: parseFloat(
            form.second_income.toString().replace(/,/g, "")
          ).toFixed(2),
          third_income: parseFloat(
            form.third_income.toString().replace(/,/g, "")
          ).toFixed(2),
          income_source: form.others || val[0]?.display,
          identifier: val[0]?.idetifier,
          query_type: "create",
          status: tcc_ref ? "initiated" : "",
        },
        (res) => {
          setLoading(false);
          if (res.success) {
            setForm(_form);
            toast.success("Submitted");
            toggle();
            getData();
            history.goBack();
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Error occured");
          console.log(err);
        }
      );
    }
  };
  const getTaxPayer = useCallback(
    (type = "individual") => {
      if (tax_payer.length > 2) {
        _fetchApi(
          `/search-tax-payer-by-all?user_id=${tax_payer}&max=50&type=${type}`,
          (resp) => {
            if (resp.success) {
              setTaxPayers(resp.data);
            }
          }
        );
      } else if (tax_payer.length === 0) {
        _fetchApi(
          `/search-tax-payer-by-all?user_id=${tax_payer}&max=50&type=${type}`,
          (resp) => {
            if (resp.success) {
              setTaxPayers(resp.data);
            }
          }
        );
      }
    },
    [tax_payer]
  );

  const getOrg = useCallback(
    (type = "org") => {
      if (org_name.length > 2) {
        _fetchApi(
          `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=${type}`,
          (resp) => {
            if (resp.success) {
              setOrgs(resp.data);
            }
          }
        );
      } else if (org_name.length === 0) {
        _fetchApi(
          `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=${type}`,
          (resp) => {
            if (resp.success) {
              setOrgs(resp.data);
            }
          }
        );
      }
    },
    [org_name]
  );
  useEffect(() => {
    getOrg();
    getTaxPayer();
  }, [org_name, tax_payer]);
  const [getLoading, setGetLoading] = useState(false);

  const getData = useCallback(() => {
    setGetLoading(true);
    _fetchApi(
      `/get/tax-clearance?query_type=${tcc_ref ? "select-ref" : "select-all"}&${
        tcc_ref ? "tcc_ref" : "id"
      }=${tcc_ref ? tcc_ref : search}`,
      (resp) => {
        if (resp.success) {
          setGetLoading(false);
          setData(resp.data);
          if (tcc_ref) {
            setForm((p) => ({ ...p, ...resp?.data[0] }));
          }
        }
      },
      (e) => {
        console.log(e);
        setGetLoading(false);
      }
    );
  }, [search]);
  useEffect(() => {
    if (search.length === 0) {
      getData();
    } else if (search.length > 3) {
      getData(getData);
    }
  }, [getData]);
  return tcc_ref && getLoading ? (
    <>
      <center>
        {" "}
        <Spinner />
      </center>
    </>
  ) : (
      <Card
        className="mt--3 mx-6 mb-3 pt--2"
        style={{ overflow: "scroll", height: "85vh" }}
      >
        <CardHeader className="  d-flex justify-content-between">
          
          <h4  className="">
           Tax Clearance Application Form
          </h4>
          <div></div>
        </CardHeader>
        {/* {JSON.stringify(form)} */}

        <CardBody className="">
          <Form onSubmit={handleSubmit}>
            <Row>
             {/* {tax_account.account_type==='org' ?<Col md={12}>
                <Label>Type Of Tax Clearance</Label>
                <Input
                    className="border border-primary"
                    value={_data?.filter(
                        (value) => value.type?.includes(tax_account.org_type?.split(' ')[0])
                      )}
                />
                </Col>
                :<Col md={12}>
                <Input
                  className="border border-primary"
                  type="select"
                  disabled={tcc_ref ? true : false}
                  name="type"
                  value={form.type}
                  defaultValue={tcc_ref ? form.type : ""}
                  onChange={handleChange}
                >
                  <option>{"<---Select--->"}</option>
                  {_data.map(
                    (value) => (
                      <option selected={form.value === 'Others'}>{value}</option>
                    )
                  )}
                </Input>
              </Col>} */}
               <Col md={12}>
                <Label>Type Of Tax Clearance</Label>
                <Input
                  className="border border-primary"
                  type="select"
                  disabled={tcc_ref ? true : false}
                  name="type"
                  value={form.type}
                  defaultValue={tcc_ref ? form.type : ""}
                  onChange={handleChange}
                >
                  <option>{"<---Select--->"}</option>
                  {[...new Set(_data?.map((item) => item.type))]?.map(
                    (value) => (
                      <option selected={form.type === value}>{value}</option>
                    )
                  )}
                </Input>
              </Col>
              <Col md={12}>
                <Label>Date Issued</Label>
                <Input
                  type="date"
                  className="border border-primary"
                  name="date_issued"
                  value={form.date_issued}
                  onChange={handleChange}
                />
              </Col>
              <Col md={12}>
                <Label>Enter TIN</Label>
                <Input
                  className="border border-primary"
                  name="tin"
                  type="text"
                  required
                  value={form.tin}
                  onChange={handleChange}
                />
              </Col>
              <Col md={12}>
                <Label>Tax File No.</Label>
                <Input
                  className="border border-primary"
                  name="tax_file_no"
                  type="text"
                  required
                  value={form.tax_file_no}
                  onChange={handleChange}
                />
              </Col>
              {_data?.filter((item) => item.type === form.type)[0]
                ?.idetifier === "F" ? (
                <Col md={12}>
                  <Row>
                    <Col>
                      {form.org_id ? (
                        <>
                          <Label>Organization</Label>
                          <Input
                            className="border border-primary"
                            type="text"
                            name="org_name"
                            value={form.org_name}
                          />
                        </>
                      ) : (
                        <CustomTypeahead
                          onInputChange={(e) => {
                            setOrgName(e);
                          }}
                          labelKey={(e) =>
                            `${
                              e.account_type === "org" ? e.org_name : e.name
                            }(${e.taxID}) ${
                              e.phone ? `:${e.phone || e.office_phone}` : ""
                            }`
                          }
                          className="border border-primary rounded"
                          onChange={(e) => {
                            if (e && e[0]?.id) {
                              setForm((p) => ({
                                ...p,
                                org_id: e[0]?.taxID,
                                org_name: e[0]?.org_name,
                              }));
                              setTaxId(e[0]?.taxID);
                            }
                          }}
                          clearButton
                          col={12}
                          label={`Select Organization`}
                          options={orgs}
                          placeholder="Search Name | TaxID | Phone No."
                        />
                      )}
                    </Col>
                    
                  </Row>
                </Col>
              ) : null}
              {tax_account.account_type!=='org' && <Col md={12}>
                <Row>
                  <Col>
                    {/* {form.taxID && tcc_ref ? (
                      <>
                        <Label>
                          Select Tax payer{" "}
                          {_data?.filter((item) => item.type === form.type)[0]
                            ?.idetifier === "F"
                            ? ""
                            : `(${form.address})`}
                        </Label>
                        <Input
                          className="border border-primary"
                          type="text"
                          name="tax_payer"
                          value={form.tax_payer}
                        />
                      </>
                    ) : (
                      <CustomTypeahead
                        onInputChange={(e) => {
                          setTaxpayer(e);
                        }}
                        labelKey={(e) =>
                          `${e.account_type === "org" ? e.org_name : e.name}(${
                            e.taxID
                          }) ${e.phone ? `:${e.phone || e.office_phone}` : ""}`
                        }
                        className="border border-primary rounded"
                        onChange={(e) => {
                          if (e && e[0]?.id) {
                            setForm((p) => ({
                              ...p,
                              taxID: e[0]?.taxID,
                              tax_payer: e[0]?.name,
                              address: e[0]?.address,
                            }));
                            setTaxId(e[0]?.taxID);
                          }
                        }}
                        clearButton
                        col={12}
                        label={`Select Tax payer`}
                        options={tax_payers}
                        placeholder="Search Name | TaxID | Phone No."
                      />
                    )} */}

                        <Label>
                        Tax payer{" "}
                          </Label>
                    <Input 
                        type="text"
                        value={form.tax_payer}
                    />
                  </Col>
                
                </Row>
              </Col>}
              {_data?.filter((item) => item.type === form.type)[0]
                ?.idetifier === "F" ? null : (
                <Col md={12}>
                  <Label>Address</Label>
                  <Input
                    className="border border-primary "
                    type="text"
                    name="address"
                    value={form.address}
                    onChange={handleChange}
                  />
                </Col>
              )}
              <Col md={12}>
                <Label>Select Income Source</Label>
                {tax_account.income_source?
                    <Input
                    className="border border-primary "
                    type="text"
                    name="income_source"
                    value={tax_account.income_source}/>
                :<Input
                  className="border border-primary "
                  type="select"
                  name="income_source"
                  value={form.income_source}
                  defaultValue={tcc_ref ? form.income_source : ""}
                  onChange={handleChange}
                >
                  {tcc_ref && form.income_source ? (
                    <option>{form.income_source}</option>
                  ) : (
                    <option>{"<---Select--->"}</option>
                  )}
                  {_data
                    ?.filter((item) => item.type === form.type)
                    ?.map((item) => (
                      <option>{item.value}</option>
                    ))}
                </Input>}
              </Col>
              {form.income_source === "Others" ? (
                <Col md={12}>
                  <Label>Specify Others</Label>
                  <Input
                    className="border border-primary "
                    type="text"
                    name="others"
                    value={form.others}
                    onChange={handleChange}
                  />
                </Col>
              ) : null}
              <Col md={4} className="mt-4">
                <InputGroup className="border border-primary text-center">
                  <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                    Select Year
                  </InputGroupText>
                  <Input
                    type="select"
                    name="year"
                    className="text-center"
                    value={form.year}
                    onChange={({ target: { value } }) => {
                      setForm((p) => ({
                        ...p,
                        first_year: `${value - 2}-12-31`,
                        second_year: `${value - 1}-12-31`,
                        third_year: `${value}-12-31`,
                        year: value,
                      }));
                    }}
                  >
                    {Array.from({ length: 30 }, (_, index) => {
                      const year = new Date().getFullYear() - index - 1;
                      return (
                        <option selected={form.year === year} key={year}>
                          {year}
                        </option>
                      );
                    })}
                  </Input>
                </InputGroup>
              </Col>
              <Col md={9}></Col>
              <Col md={8} className="mt-4">
                <Row>
                  <Col>
                    <InputGroup className="border border-primary ">
                      <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                        Income {form.year}
                      </InputGroupText>
                      <Input
                        type="text"
                        name="third_income"
                        className="text-right"
                        placeholder=""
                        required
                        value={form.third_income}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup className="border border-primary ">
                      <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                        Tax paid (₦)
                      </InputGroupText>
                      <Input
                        type="text"
                        name="third_amount"
                        className="text-right"
                        required
                        placeholder=""
                        value={form.third_amount}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-4">
                <InputGroup className="border border-primary ">
                  <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                    Receipt Date{" "}
                  </InputGroupText>
                  <Input
                    type="date"
                    name="third_year"
                    className="text-center"
                    value={form.third_year}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Col>{" "}
              <Col md={8} className="mt-4">
                <Row>
                  <Col>
                    <InputGroup className="border border-primary ">
                      <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                        Income {form.year - 1}
                      </InputGroupText>
                      <Input
                        type="text"
                        name="second_income"
                        className="text-right"
                        placeholder=""
                        value={form.second_income}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup className="border border-primary ">
                      <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                        Tax paid (₦)
                      </InputGroupText>
                      <Input
                        type="text"
                        name="second_amount"
                        className="text-right"
                        placeholder=""
                        value={form.second_amount}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-4">
                <InputGroup className="border border-primary ">
                  <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                    Receipt Date{" "}
                  </InputGroupText>
                  <Input
                    type="date"
                    className="text-center"
                    name="second_year"
                    value={form.second_year}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Col>{" "}
              <Col md={8} className="mt-4">
                <Row>
                  <Col>
                    <InputGroup className="border border-primary ">
                      <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                        Income {form.year - 2}
                      </InputGroupText>
                      <Input
                        type="text"
                        name="first_income"
                        className="text-right"
                        placeholder=""
                        required
                        value={form.first_income}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup className="border border-primary ">
                      <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                        Tax paid (₦)
                      </InputGroupText>
                      <Input
                        type="text"
                        name="first_amount"
                        className="text-right"
                        required
                        placeholder=""
                        value={form.first_amount}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-4">
                <InputGroup className="border border-primary ">
                  <InputGroupText style={{ backgroundColor: "#F1F1F1" }}>
                    Receipt Date{" "}
                  </InputGroupText>
                  <Input
                    type="date"
                    name="first_year"
                    className="text-center"
                    value={form.first_year}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Col>{" "}
              <center>
                <CustomButton
                  loading={loading}
                  onClick={handleSubmit}
                  className="mt-3"
                  type="submit"
                >
                  Submit
                </CustomButton>
              </center>
            </Row>
          </Form>
        </CardBody>

      <CustomModal toggle={toggleModal} centered isOpen={showDefault} size="md">
        <div style={{ marginTop: "-60px", padding: "0px 20px" }}>
          <Row style={{ marginTop: "-60px" }}>
            <ButtonGroup>
              <Button
                className={!tab ? `` : `bg-white text-dark`}
                onClick={() => setTab(0)}
                size="sm"
              >
                New Tax payer
              </Button>
              <Button
                className={tab ? `` : `bg-white text-dark`}
                onClick={() => setTab(1)}
                size="sm"
              >
                Link with existing account
              </Button>
            </ButtonGroup>
          </Row>
          <Card>
            <CardBody>
              <AddAccout
                type="tax_clearance"
                isAddAccount={tab === 1}
                account_type={form.account_type}
                isModal={true}
                handleFocus={() => {
                  toast.success("Updated");
                  getOrg();
                  getTaxPayer();
                }}
              />
            </CardBody>
          </Card>
        </div>
      </CustomModal>
      </Card>
  );
}
