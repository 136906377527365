import toast from "react-hot-toast";
import { ADD_CART, EMPTY_CART, VIEW_CART, CART_COUNT, DELETE_CART } from "../action/type";

// Load initial state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("cart");
    if (serializedState === null) {
      return {
        invoices: [],
        error: "",
        loading: false,
        count: 0,
      };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {
      invoices: [],
      error: "",
      loading: false,
      count: 0,
    };
  }
};

// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cart", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

const initialState = loadState();

const cartReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ADD_CART:
      if (action.payload.length) {
        const itemExists = action.payload.some(newItem =>
          state.invoices.some(invoice =>
            invoice.id === newItem.id && (invoice.amount === newItem.amount||invoice.tax_fee === newItem.tax_fee)
          )
        );

        if (itemExists) {
          toast.error('Item already exists in the cart');
          return state;
        }

        const newInvoices = [...state.invoices, ...action.payload];
        newState = {
          ...state,
          invoices: newInvoices,
          count: newInvoices.length,
        };
      } else {
        toast.error('Invalid Item');
        return state;
      }
      break;

    case EMPTY_CART:
      newState = {
        ...state,
        invoices: [],
        count: 0,
      };
      break;

    case VIEW_CART:
      newState = {
        ...state,
        invoices: state.invoices,
      };
      break;

    case CART_COUNT:
      newState = {
        ...state,
        count: state.invoices.length,
      };
      break;

    case DELETE_CART:
      const updatedInvoices = state.invoices.filter((_, index) => index !== action.payload);
      newState = {
        ...state,
        invoices: updatedInvoices,
        count: updatedInvoices.length,
      };
      break;

    default:
      newState = state;
  }

  saveState(newState);
  return newState;
};

export default cartReducer;
