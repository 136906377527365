import React, { useState } from 'react';
import { Button, Card, CardFooter, CardHeader, Col, Row, Table } from 'reactstrap';
import { formatNumber } from '../../utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import {
    _postApi,
    calculateOnPercent,
    calculateTax,
    cgiFun,
    formatData,
    generateCRA,
    grossIncomeFun,
    nationalMinimumWage,
    taxableIncome,
} from '../../redux/action/api';
import { CustomButton } from '../../components/UI';
import CustomModal from '../../components/UI/CustomModal';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { Routes } from '../../routes';
function PayeSetup() {
    let [testData, setTestData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const { user, tax_account } = useSelector((s) => s.auth);
    const [, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [, setFinalData] = useState([]);
    const history = useHistory();
    const downloadExcel = () => {
        setLoading(true);
        fetch('https://docs.google.com/spreadsheets/d/1oj-q7C6xcYqqhLnoKoGAtYGoMUdRUhIp/export?format=xlsx')
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'NOMINAL ROLL SAMPLE.xlsx');
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error downloading Excel file: ', error);
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        setLoading(true);
        _postApi(
            '/assessments/org-staffs',
            { list: testData },
            (rep) => {
                console.log(rep);
                console.log(rep);
                console.log(rep);
                if (rep.success) {
                    toast.success(
                        `Congrats! You've added ${testData.length} staff. Redirecting you to generate the invoice.`,
                        {
                            duration: 5000,
                        },
                    );
                    setTimeout(() => {
                        history.push('/user/nominal-roll-upload');
                    }, 2000);
                } else {
                    setLoading(false); // Ensure loading is set to false if success is false
                }
            },
            (err) => {
                console.error(err);
                setLoading(false);
                toast.error('Error Occurred');
            },
        );
    };
    const handleUpload = (event) => {
        let fileObj = event.target.files[0];
        if (!fileObj) {
            setErrorMessage('No file uploaded!');
            return false;
        }
        console.log('fileObj.type:', fileObj.type);
        if (
            !(
                fileObj.type === 'application/vnd.ms-excel' ||
                fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        ) {
            console.log('just pass the fileObj as parameter');
            setErrorMessage('Unknown file format. Only Excel files are uploaded!');
            return false;
        }
        //just pass the fileObj as parameter
        console.log('just pass the fileObj as parameter');
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                let newRows = [];
                let data = [];
                // let basicInfo = []
                // let beginIndex = 0
                // let detailInfo = []
                // let items = []

                let colNames = resp.rows[6];

                resp.rows.slice(6).forEach((row, index) => {
                    if (row && row !== 'undefined' && row.length !== 0) {
                        console.log(row);
                        let obj = {};
                        colNames.forEach((col, idx) => {
                            obj[col] = row[idx];
                        });
                        data.push(obj);
                        newRows.push({
                            key: index,
                            jtb_no: row[0],
                            nin_no: row[1],
                            phone: row[2],
                            staff_id: row[3],
                            staff_name: row[4],
                            org_name: tax_account.org_name,
                            org_id: tax_account?.taxID,
                            nin: row[0],
                            basic_salary: parseFloat(row[5] / 12).toFixed(2),
                            other_allowances: row[6],
                            nhf: row[7],
                            nhis: row[8],
                            pension: row[9],
                            query_type: 'insert',
                            gross: grossIncomeFun(row[6], row[5]),
                            org_name: tax_account.org_name,
                            org_kirmas_id: user?.taxID,
                            cgi:
                                row[5] <= nationalMinimumWage
                                    ? 0
                                    : cgiFun(grossIncomeFun(row[6], row[5]), row[9], row[7], row[8]),
                            cra:
                                row[5] <= nationalMinimumWage
                                    ? 0
                                    : generateCRA(
                                        grossIncomeFun(row[6], row[5]),
                                        cgiFun(grossIncomeFun(row[6], row[5]), row[9], row[7], row[8]),
                                    ),
                            taxable_income:
                                row[5] <= nationalMinimumWage
                                    ? 0
                                    : taxableIncome(row[9], row[7], row[8], row[6], row[5]),
                            payable:
                                row[5] <= nationalMinimumWage
                                    ? 0
                                    : taxableIncome(row[9], row[7], row[8], row[6], row[5]) === 0
                                        ? calculateOnPercent(grossIncomeFun(row[6], row[5]))
                                        : calculateTax(taxableIncome(row[9], row[7], row[8], row[6], row[5])),
                        });
                    }
                });
                console.log(newRows);
                setTestData(newRows);
                if (newRows.length === 0) {
                    setErrorMessage('No data found in file!');
                    return false;
                } else {
                    // setCols(resp.cols);
                    // setRows(resp.rows);
                    // setFormattedRows(newRows);
                    let ff = formatData(newRows);
                    setFinalData(ff);
                    setErrorMessage(null);

                    return;
                }
            }
        });
        return false;
    };

    return (
        <>
            <CustomButton
                size="sm"
                onClick={() => {
                    history.goBack();
                }}
            >
                Back
            </CustomButton>
            <Card>
                <CardHeader className="text-">
                    <h4>Upload Nominal Roll</h4>
                </CardHeader>

                {testData.length ? (
                    <>
                        <h4 className="text-center">UPLOAD PREVIEW</h4>
                        <Table bordered striped responsive>
                            <thead>
                                <tr>
                                    <th className="text-center">S/N</th>
                                    <th className="text-center">JTB NO.</th>
                                    <th className="text-center">NIN NO.</th>
                                    <th className="text-center">Phone NO.</th>
                                    <th className="text-center">Staff Name</th>
                                    <th className="text-center">Basic Salary(Month)</th>
                                    <th className="text-center">Other Allowances(Annual)</th>
                                    <th className="text-center">NHF(Annual 2.5%)</th>
                                    <th className="text-center">NHIS(Annual 5%)</th>
                                    <th className="text-center">PENSION(Annual 8%)</th>
                                    <th className="text-center">Gross Income</th>
                                    <th className="text-center">CGI(Annual)</th>
                                    <th className="text-center">CRA(Annual)</th>
                                    <th className="text-center">Taxable Income(Annual)</th>
                                    <th className="text-center">Annual Paye</th>
                                    <th className="text-center">Monthly Paye</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testData?.map((item, index) => (
                                    <tr key={index + 1}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{item.jtb_no || item.tin}</td>
                                        <td className="text-center">{item.nin_no || item.nin}</td>
                                        <td className="text-center">{item.phone}</td>
                                        <td className="">{item.staff_name || item.name}</td>
                                        <td className="text-right text-end">{formatNumber(item.basic_salary)}</td>
                                        <td className="text-right text-end">{formatNumber(item.other_allowances)}</td>
                                        <td className="text-right text-end">{formatNumber(item.nhf)}</td>
                                        <td className="text-right text-end">{formatNumber(item.nhis)}</td>
                                        <td className="text-center">{formatNumber(item.pension)}</td>
                                        <td className="text-right text-end">
                                            {formatNumber(item.gross || item.gross_salary)}
                                        </td>
                                        <td className="text-right text-end">{formatNumber(item.cgi)}</td>
                                        <td className="text-right text-end">{formatNumber(item.cra)}</td>
                                        <td className="text-right text-end">{formatNumber(item.taxable_income)}</td>
                                        <td className="text-right text-end">{formatNumber(item.payable)}</td>
                                        <td className="text-right text-end">{formatNumber(item.payable / 12)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                ) : null}
                {testData.length ? (
                    <center>
                        <CardFooter>
                            <CustomButton size="sm" onClick={toggle}>
                                Submit Nominal Roll
                            </CustomButton>
                        </CardFooter>
                    </center>
                ) : (
                    <Row className="text-center m-2 p-2">
                        <Col>
                            <label>
                                Click here to upload a file
                                <input accept=".xlsx,.xls" type="file" onChange={handleUpload} />
                            </label>
                        </Col>
                        <Col>
                            <Button onClick={downloadExcel} size="sm">
                                {loading ? 'loading...' : 'Download Excel Template'}
                            </Button>
                        </Col>
                    </Row>
                )}
                <CustomModal size="lg" toggle={toggle} isOpen={isOpen}>
                    <>
                        <div className="text-center">
                            <h2>Are you sure you want add {testData.length} staff</h2>
                        </div>
                    </>
                    <center>
                        <CustomButton size="sm" onClick={handleSubmit} loading={loading}>
                            Submit
                        </CustomButton>
                    </center>
                </CustomModal>
            </Card>
        </>
    );
}

export default PayeSetup;
