import { ADD_CART, DELETE_CART, EMPTY_CART, VIEW_CART } from "./type";

export const addCart = (objects) => {
  // alert(JSON.stringify(objects))
  return (dispatch) => {
    dispatch({
      type: ADD_CART,
      payload: objects,
    });
  };
};

export const emptyCart = () => {
  return (dispatch) => {
    dispatch({ type: EMPTY_CART });
  };
};


export const viewCart = () => {
  return (dispatch) => {
    dispatch({ type: VIEW_CART });
  };
};


export const deleteCart = (index) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CART, payload: index });
  };
};


