import React from "react";
import { Card, CardBody, CardText, Row, Col, CardTitle, CardHeader, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PayrollUpload = () => {
    return(
        <>
            <Row className="p-3">
                <Col md={12}>
                    <Card>
                        <CardHeader style={{
                            fontWeight: "bolder",
                        }} className="bg-light">
                            Upload payroll and evidence of statutory deductions
                        </CardHeader>
                        <CardBody>
                            <Col md={12} className="mt-5">
                                <CardTitle tag="h6">Upload documents</CardTitle>
                                <div class="input-group mb-3">
                                    <input type="file" class="form-control" id="inputGroupFile01"/>
                                </div>
                            </Col>
                            <button type="button" className="btn btn-dark text-white p-2 mb-3 mt-0">Upload</button>


                            <Col lg={6} md={6} sm={12}>
                                <ul>
                                    <li class="d-flex justify-content-between align-items-center">
                                        A list item
                                        <button type="button" class="btn-close btn-close-bg-danger" aria-label="Close"></button>
                                    </li>
                                    <li class="d-flex justify-content-between align-items-center">
                                        A second list item
                                        <button type="button" class="btn-close btn-close-bg-danger" aria-label="Close"></button>
                                    </li>
                                </ul>
                            </Col>
                            <Col className="mt-3">
                                <ul>
                                    Statutory Deductions
                                    <li>NHIS</li>
                                    <li>NHF</li>
                                    <li>Pension</li>
                                    <li>Life Insurance. etc</li>
                                </ul>
                                <ul>
                                    Note
                                    <li>Uploaded files should be in doc, docx, pdf, jpg, jpeg or png formats.</li>
                                    <li>Each uploaded file should not exceed 5MB</li>
                                </ul>
                            </Col>
                            <button type="button" className="btn btn-dark text-white py-2 px-4 mb-3 mt-0 float-end">Next</button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default PayrollUpload;