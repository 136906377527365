import { Card } from '@themesberg/react-bootstrap';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Label,
} from 'recharts';

const LeaderBoardChart = ({ data, dataKey1 = 'staff_name', dataKey2 = 'amount', title = '', link='#' }) => {
  const history = useHistory();

  // Calculate the amount value of dataKey2
  const amount = data?.reduce((sum, item) => sum + Number(item[dataKey2]), 0);

  // Find the maximum value in the data to set the Y-axis domain
  const maxValue = Math.max(...data?.map(item => Number(item[dataKey2])));

  // Formatter function for NGN currency
  const currencyFormatter = (value) => new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(value);

  return (
    <Card>
      <Card.Header>
        <h3 className='text-center'>{title}</h3>
      </Card.Header>
      <Card.Body className='text-center' style={{ height: '600px', overflowY: 'auto' }}>
        <center>
          <BarChart
            width={1100}
            height={500}
            data={data.slice(0, 10)}
            margin={{
              top: 20,
              right: 30,
              left: 80,
              bottom: 100, // Increase bottom margin to ensure labels are visible
            }}
            onClick={() => history.push(link)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey1} angle={-45} textAnchor="end" interval={0} height={100}>
              <Label className='text-danger' value={`Total: ${currencyFormatter(amount)}`} offset={-60} position="insideBottom" /> {/* Add label with the amount value */}
            </XAxis>
            <YAxis domain={[0, maxValue * 1.1]} tickFormatter={currencyFormatter} /> {/* Set Y-axis domain to max value with some padding */}
            <Tooltip formatter={currencyFormatter} />
            <Legend />
            <Bar dataKey={dataKey2} fill="#82ca9d" barSize={30}>
              <LabelList dataKey={dataKey2} position="top" offset={10} formatter={currencyFormatter} /> {/* Adjust offset to make labels visible */}
            </Bar>
          </BarChart>
        </center>
      </Card.Body>
    </Card>
  );
};

export default LeaderBoardChart;
