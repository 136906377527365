import React, { useCallback, useEffect, useState } from "react";
import { _fetchApi, _postApi } from "../redux/action/api";
import { Input, Spinner, Table, Modal, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
// import { Input, Spinner, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CustomButton, SearchBar, SelectInput } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight, faArrowCircleLeft, faPenAlt, faEyeDropper } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Card, ButtonGroup } from "@themesberg/react-bootstrap";
import UserTransactions from "./UserTransactions";
import { formatNumber1 } from "../utils";
import { taxStations } from "../utils/tax-stations";
import { stateLga } from "../statelga";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import toast from "react-hot-toast";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Routes } from "../routes";
import { useHistory } from "react-router-dom";

function TaxStationMngr() {
  const _form = {
    limit: 50,
    offset: 0,
    lga: '',
    query_type: 'tax-payers-by-lga',
    tax_station: '',
    department: '',
    role: '',
    rank: '',
    view_by: 'Tax Station'
  }
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [data, setData] = useState([]);
  const history = useHistory()
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [globalTaxStation, setGlobalTaxStation] = useState('');
  const [selectedTaxPayers, setSelectedTaxPayers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [globalTaxAdmin, setGlobalTaxAdmin] = useState(false);
  const [taxStations, setTaxStations] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStation, setSelectedStation] = useState({
    name: '',
    lga: '',
    address: ''
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newStation, setNewStation] = useState({
    name: '',
    lga: '',
    address: ''
  });

  const handleNewStationChange = (e) => {
    const { name, value } = e.target;
    setNewStation((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleSubmitNewStation = () => {
    setLoading(true);
    _postApi('/post-tax-stations', {query_type: "create", ...newStation }, 
      () => {
        setLoading(false);
        toggleModal();
        toast.success('Tax Station Created');
      },
      (e) => {
        setLoading(false);
        console.error(e);
        toast.error('Failed to create tax station');
      }
    );
  };


  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditClick = (station) => {
    setSelectedStation(station);
    setEditMode(true);
    toggleModal();
  };

  const handleStationChange = ({ target: { name, value } }) => {
    setSelectedStation((prev) => ({ ...prev, [name]: value }));
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setSelectedStation({ name: '', lga: '', address: '' });
      setEditMode(false); 
    }
  };


  const handleSubmitStation = () => {
    setLoading(true);
    const queryType = editMode ? "update" : "create";
    _postApi('/post-tax-stations', { query_type: queryType, ...selectedStation }, 
      () => {
        setLoading(false);
        toggleModal();
        toast.success(`Tax Station ${editMode ? 'Updated' : 'Created'}`);
      },
      (e) => {
        setLoading(false);
        console.error(e);
        toast.error(`Failed to ${editMode ? 'update' : 'create'} tax station`);
      }
    );
  };



  const handleSubmit = () => {
    setLoading(true)
    console.log("Checked Rows:", data.filter(x => x.selected).map(d => ({
      taxID: d.taxID,
      tax_station: d.tax_station,
      query_type: 'update-admin'
    })));
    _postApi('/auth/create-taxStations', {
      list: data.filter(x => x.selected).map(d => ({
        id: d.taxID,
        staff_id: d.staff_id,
        name: d.tax_admin,
        username: d.org_name,
        account_type: d.account_type,
        tax_station: d.tax_station,
        query_type: 'save-taxpayer-mapping'
      }))
    },
      () => {
        setLoading(false)
        toast.success('Submitted')
      },
      (e) => {
        setLoading(false)
        console.error(e);
        toast.error('Failed to submit')
      }
    )
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      "/get-tax-stations",
      (res) => {
        if (res.success) {
          setLoading(false);
          setTaxStations(res.data);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, []);

  const filteredStations = filterText.length > 1 ? data.filter(x =>
    (x.name?.toString() ?? '').includes(filterText) ||
    (x.address?.toString() ?? '').includes(filterText) ||
    (x.lga?.toString() ?? '').includes(filterText) 
  ) : taxStations;

  // const history = useHistory();

  return (
    <Card>
      {/* {JSON.stringify(data.filter(x=>x.selected, {selectedTaxPayers}))} */}
      <Card.Header>
        <Row>
          {/* <Col>
                        <Button
                            variant={"primary"}
                            size="sm"
                            onClick={() => { history.push(Routes.AdminStationMngr.path) }}
                        >
                            GO TO STAFF MNGR
                        </Button>
                    </Col> */}
          <Col md={12}>
            <h4 className="text-center">TAX STATION MANAGEMENT</h4>
            <Button onClick={toggleModal}>Create New</Button>
          </Col>
        </Row>
      </Card.Header>
      {loading && <Spinner />}


      <Card.Body className="pt-0">
        <table
          style={{ border: "" }}
          width={"100"}
          className="user-table table-responsive align-items-center table-hover"
        >
          <thead>
            <tr>  
              <th style={{ width: '5%' }} className="text-center">#</th>
              <th width='20%' className="text-left">TAX STATION</th>
              <th width='15%' className="text-center">LGA</th>
              <th width='10%' className="text-center">TOTAL STAFF</th>
              <th width='10%' className="text-center">TOTAL TAXPAYERS</th>
              <th width= '30%' className="text-left">ADDRESS</th>
              <th width='15%' className="text-center">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredStations?.map((station, index) => (
              <tr key={index}>
                <td className="text-center">{index+1}</td>
                <td>
                  {station.name}
                </td>
                <td>{station.lga}</td>
                <td className="text-center">
                    0
                </td>
                <td className="text-center">
                  0
                </td>

                <td className="text-left">
                  {station.address}
                </td>
                <td className="text-center">
                <Button onClick={() => handleEditClick(station)}>
                    <FontAwesomeIcon icon={faPenAlt} />
                  </Button>
              <Button onClick={()=>history.push(`${Routes.TaxPayerStations.path}?station=${station.name}`)}><FontAwesomeIcon icon={faEye} /></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Body>
      {/* Modal for creating new tax station */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {editMode ? 'Edit Tax Station' : 'Create New Tax Station'}
        </ModalHeader>x
        <ModalBody>
          <Input
            type="text"
            name="name"
            placeholder="Tax Station"
            value={selectedStation.name}
            onChange={handleStationChange}
          />
          <Input
            type="text"
            name="lga"
            placeholder="LGA"
            value={selectedStation.lga}
            onChange={handleStationChange}
          />
          <Input
            type="text"
            name="address"
            placeholder="Address"
            value={selectedStation.address}
            onChange={handleStationChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitStation}>
            {editMode ? 'Save Changes' : 'Save'}
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      </Card>
    );
}

export default TaxStationMngr;
