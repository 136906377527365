import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useCallback } from "react";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { toast } from "react-hot-toast";
import moment from "moment";
import { Spinner } from "reactstrap";

export default function TaxCodeFormModal({ _form = {}, toggle, _getTaxHeads }) {
    const [form, setForm] = useState(_form);
    const [loading, setLoading] = useState(false)
    const [budgetRevs, setBudgetRevs] = useState([])

    const handleChanges = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }));
    };




    const arr = [
        {
            ...form,
            query_type: "update-tax",
        },
    ];

    const handleSubmit = () => {
        // if (form.tax_parent_code === "" || form.title === "") {
        //     toast.error("Uncompleted form");
        // } else {
        _postApi(
            "/kigra-taxes",
            arr,
            (res) => {
                console.log(res);
                toast.success("Tax updated successfully"
                );
                _getTaxHeads();
                toggle();
            },
            (err) => {
                console.error(err);
                toast.error("Error Occured", err);
            }
        );
        console.log(form);
        // }
    };

    useEffect(() => {
        setLoading(false);
        fetch(`https://galaxybis.ebudgetkano.ng/budget-api/revenue-api-list?query_type=select`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
        })
            .then((raw) => raw.json())
            .then((response) => {
                if (response.status >= 400) {
                    if (response.status === 500) {
                        toast.error('Endpoint failed')
                    }
                } else {
                    if (response.success) {
                        setLoading(false);
                        setBudgetRevs(response.data);

                    } else {
                        setLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.error(err)
                toast.error('Network failed')
            });
    }, []);

    const myRef = useRef(null)

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            {/* {JSON.stringify(form.sector)} */}
            {loading ? <Col className="text-center"><Spinner /> </Col> : null}
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <h5 className="mb-4">Tax head / economic code Mapping</h5>

                </div>
                {/* {JSON.stringify(form)} */}
                <Form>
                    <Row>
                        <Col>
                            <CustomTypeahead
                                onInputChange={(e) => {
                                    // setForm(e);
                                    console.log(e, "TYPED");
                                }}
                                // _ref={myRef}
                                labelKey='description'
                                // labelKey={(e) => {
                                //     `${e?.description} - ${e?.economic_code}`
                                // }}
                                onChange={(e) => {
                                    console.log({ e });
                                    if (e && e[0]?.
                                        revenue_code) {
                                        setForm((p) => ({
                                            ...p,
                                            economic_code: e[0]?.
                                                revenue_code
                                        }));
                                    }
                                }}
                                // onInputChange={(e) => {
                                //   setTapayer(e);
                                // }}
                                clearButton
                                col={12}
                                label={`Select Revenue Source`}
                                options={budgetRevs}
                                placeholder="Search Revenue | Label | Code"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6} className="mb-3">
                            <Form.Group className="mb-2">
                                <Form.Label>Tax title</Form.Label>
                                <Form.Control
                                    name="title"
                                    value={form.title}
                                    onChange={handleChanges}
                                    disabled={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6} md={6} className="mb-3">
                            <Form.Group id="city">
                                <Form.Label>Revenue code</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Revenue code"
                                    value={form.economic_code}
                                    name="economic_code"
                                    onChange={handleChanges}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    {/* <Row>{JSON.stringify({ form })}</Row> */}
                </Form>
                <Row>
                    <Col className="text-center mt-3">
                        <Button
                            variant="warning"
                            onClick={() => setForm(_form)}
                            className="px-3"
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col className="text-center mt-3">
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            className="px-3"
                        > Submit
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
