import React, { useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
} from "@themesberg/react-bootstrap";
// import { _post } from "components/Utils/Helper";
// import { server_url } from "components/Utils/Helper";
import { _postApi, apiURL } from "../../redux/action/api";
import { Route, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../routes";
import toast from "react-hot-toast";
import { CustomButton } from "../UI";
// import { _post } from "../components/Utils/Helper";

function Slider() {
  const _form = {
    title: "",
    content: "",
    attechment: "",
  };
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const handleAdd = (e) => {
    e.preventDefault();
    setForm(_form);

    setLoading(true);
    _postApi(
      "/contentes/slider?query_type=insert",
      form,
      (res) => {
        setLoading(false);
        if (res.success) {
          toast.success('Submitted')
          setForm(_form);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        toast.error("Not Submitted");
      }
    );
    console.log(form);
  };

  const onChangeAttachment = (e) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);

    // Sending the attachment to the server
    fetch(`${apiURL}/contentes/upload-pictures`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming server returns URL of the uploaded image
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, attechment: data.url }));
        // You can store the URL in your component state or use it accordingly
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="div">
                  <Row>
                    <Col md={6}>CREATE SLIDER</Col>
                    <Col md={6} className="text-right">
                      <Button
                        size="sm"
                        onClick={() =>
                          history.push(Routes.AdminSliderList.path)
                        }
                      >
                        Slider List
                      </Button>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label> Title</label>
                        <Form.Control
                          placeholder="Title"
                          type="text"
                          name="title"
                          value={form.title}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Attechment</label>
                        <Form.Control
                          placeholder="Attechment"
                          disabled={form.attechment.length > 10}
                          type="file"
                          name="attechment"
                          onChange={onChangeAttachment}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Content</label>
                        <Form.Control
                          placeholder="Description"
                          rows="4"
                          as="textarea"
                          name="content"
                          value={form.content}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <CustomButton
                    className="btn-fill pull-right mt-3"
                    type="submit"
                    loading={loading}
                    variant="info"
                    onClick={handleAdd}
                  >
                    Submit
                  </CustomButton>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Slider;
