import React, { useCallback, useEffect, useState } from "react";
import { _fetchApi, _postApi } from "../redux/action/api";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import { SearchBar } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Row,
  ButtonGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import UserTransactions from "./UserTransactions";
import { useQuery } from "../utils";
import { TransactionLogs, TransactionsTable } from "../components/Tables2";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function ReceiptReportDetails() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [taxPayer, setTaxPayer] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [type, setType] = useState("individual");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const query = useQuery();
  const fromDate = query.get("from");
  const toDate = query.get("to");
  const { user } = useSelector((s) => s.auth);
  const getData = useCallback(() => {
    setLoading(true);
    _postApi(
      `/transactions/update-print-count?sector="${user.sector}"`,
      { query_type: "details-by-date", fromDate, toDate , sector:user.sector},
      (resp) => {
        setLoading(false);
        if (resp && resp.data && resp.data.length) {
          setData(resp.data);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [fromDate, toDate]);

  useEffect(() => {
    getData();
  }, [getData]);

  const filterByType = data?.filter((item) => item?.account_type === type);

  const filtered_data = filterByType?.filter(
    (item) =>
      item?.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.taxID.toString().includes(filterText)
  );

  const totalTaxPayer = filterByType.length;
  return (
    <>
      <Card>
        <Row>
          <Col>
            <Button onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </Col>
        </Row>

        <CardBody>
          <div className="d-flex justify-content-between">
            <div>
              <h4>RECEIPTS PRINTED</h4>
            </div>
          </div>

          <div className="mb-2">
            <SearchBar
              filterText={filterText}
              onFilterTextChange={(val) => setFilterText(val)}
              placeholder="Search Tax Payer by Name or Tax ID"
            />
          </div>
          <div className="text-right">
            Total Receipts: <b>{totalTaxPayer}</b>{" "}
          </div>
          {loading && (
            <div>
              <Spinner />
              Loading...
            </div>
          )}
          <TransactionLogs
            setRef={() => {}}
            // viewOnly={viewOnly}
            // _ref={tableRef}
            data={data
              ?.map((t) => ({
                ...t,
                amount: Math.max(t.cr, t.dr),
              }))
              .filter((_) => _.amount)}
            // user={payer}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default ReceiptReportDetails;
