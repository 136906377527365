import React, { useEffect, useState } from "react"
import { Badge, Button, Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap"
import { _fetchApi } from "../../redux/action/api"
import CustomTypeahead from "../../components/UI/CustomTypeahead"
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes"
import moment from "moment"
import toast from "react-hot-toast"
import { toCurrency, useQuery } from "../../utils"
import { SearchBar } from "../../components/UI"
import { FaArrowCircleLeft } from "react-icons/fa"
const today = moment().format("YYYY-MM-DD");

const _form = {
  from: moment().format("YYYY") + '-01-01',
  to: today,
}

const OrgLedger = () => {
  const [assesments, setAssessments] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [org_name, setOrgName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(_form)
  const q = useQuery()
  const org_id = q.get('org_kirmas_id')
  const [staff, setStaff] = useState('')
  const [data_status, setDataStatus] = useState(null);
  const [trx, setTrx] = useState([]);
  const history = useHistory()
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }))
  }

  useEffect(() => {
    if (org_name) {
      _fetchApi(
        `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=org`,
        (resp) => {
          if (resp.success) {
            setOrgs(resp.data);
          } else {
            setDataStatus('Record not found')
          }
        })
    }
  }, []);

  useEffect(() => {
    if (form.taxID) {
      _fetchApi(
        `/assessments/paye-list?org_id=${form.taxID}&year=${moment().format('YYYY')}&query_type=annual-tax`,
        (resp) => {
          if (resp.success && resp.data.length) {
            setDataStatus(null)
            setAssessments(resp.data);
          } else {
            setDataStatus('Record not found!')
          }
        })
    }
  }, [form.taxID])

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/transactions/retrieve?query_type=view_payer_ledger&user_id=${org_id}&start_date=${form.from}&end_date=${form.to}`,
      (resp) => {
        if (resp.data.length) {
          setTrx(resp.data);
        } else {
          setDataStatus('Record not found')
        }
      },
      (err) => {
        console.error(err);
        setDataStatus('Record not found')
        toast.error("Record not found")
        setTrx([]);
      })
  }, [org_id, form]);



  useEffect(() => {
    if (org_name.length > 2) {
      _fetchApi(
        `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=org`,
        (resp) => {
          if (resp.success) {

            setOrgs(resp.data);
          }
        }
      )
    }
  },
    [org_name]
  );
  const containerStyle = {
    height: "80vh",
    overflowY: "scroll",
    scrollbarWidth: "thin", // For Firefox
    scrollbarColor: "transparent transparent", // For Firefox
    overflow: "-moz-scrollbars-none",
    zIndex: 1,
  };
  const new_data = org_name.length ? assesments.filter(item => { return item.tax_payer.toLowerCase().includes(org_name.toLowerCase()) || item.office_address.toLowerCase().includes(org_name.toLowerCase()) || item.phone.includes(org_name) }) : assesments
  return <div>
    <Card>
      <CardHeader>
        <Row>
          <Col className="text-left col-md-2 col-sm-3 col-lg-2" >
            <Button color="dark" onClick={() => history.goBack()}><FaArrowCircleLeft /> Back</Button>
          </Col>
          <Col className="">
            <SearchBar
              filterText={staff}
              className="border border-primary rounded"
              onFilterTextChange={(e) => setStaff(e)}
              clearButton
              col={12}
              placeholder="Search staff name || ID"
            />
          </Col>
          {/* <Col className="text-right col-md-2 col-sm-2 col-lg-2" md={3}><Button onClick={() => setShowModal(true)}>Add staff</Button></Col> */}
        </Row>
      </CardHeader>
      <CardBody>
        <div style={containerStyle}>
          <div hover className="user-table align-items-center pt-0">
            <h5>ORG. NAME: <em>{trx[0]?.tax_payer?.toUpperCase()}</em></h5>
            <h5>No Of Staff: <em>{parseInt(trx[0]?.total_staff || 0)}</em></h5>
            <Table
              className="table-zebra table-bordered"
              size="sm"
              style={{ width: "100%", border: "1px solid black" }}
            >
              <thead>
                <tr>
                  <th>DATE PAID</th>
                  <th>KIRMAS ID</th>
                  <th>REF NO.</th>
                  <th>TAX DESCRIPTION</th>
                  <th>AMOUNT(₦)</th>
                  <th>DATE COVERED</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              {trx.length && !data_status ? <tbody>
                {trx.map((tax, idx) => <tr key={idx}>
                  <td>{tax.status === 'saved' ? moment(tax.created_at).format('DD/MM/YYYY') : moment(tax.paymentdate).format('DD/MM/YYYY')}</td>

                  <td>{tax.user_id}</td>
                  <td>{tax.reference_number}</td>
                  <td>{tax.description}</td>
                  <td className="text-right">{toCurrency(tax.dr)}</td>
                  <td>{moment(tax.date_from).format('DD/MM/YYYY')} - {moment(tax.date_to).format('DD/MM/YYYY')}</td>
                  <td className="text-center">
                    <Badge color={tax.status === 'saved' ? 'danger' : 'primary'}>{tax.status === 'saved' ? 'Invoice' : 'Paid'}</Badge>
                  </td>
                </tr>)}
              </tbody> :
                <h3 className="text-danger text-center">{data_status}</h3>}
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>
  </div>
}

export default OrgLedger