import React, { useCallback, useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import { Button, Card, Container, Row, Col, Form } from "@themesberg/react-bootstrap";
// import { _post } from "components/Utils/Helper";
// import { server_url } from "components/Utils/Helper";
import { useParams } from "react-router";
// import { _get } from "components/Utils/Helper";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useQuery } from "../../utils";
import { CustomButton } from "../UI";
// import View from "./View";
// import { _post } from "../components/Utils/Helper";

function UpdateBlog() {
  const _form = {
    title: "",
    content: "",
    attechment: "",
    query_type: "update",
  };
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  const q = useQuery();
  const blog_id = q.get("id");
  const [view, setView] = useState([]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const handleAdd = (e) => {
    e.preventDefault();
    setForm(_form);

    setLoading(true);
    _postApi(
      "/contentes/blog",
      form,
      (res) => {
        setLoading(false);
        setForm(_form);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    console.log(form);
  };

  const onChangeAttachment = (e) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);

    // Sending the attachment to the server
    _postApi(`/contentes/upload-pictures`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming server returns URL of the uploaded image
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, attechment: data.url }));
        // You can store the URL in your component state or use it accordingly
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  const getBlog = useCallback(() => {
    if (blog_id)
      _fetchApi(
        `/contentes/get_blog?query_type=view&id=${blog_id}`,
        (res) => {
          if (res.success && res.data.length) {
            setView(res.data[0]);
            setForm((p) => ({ ...p, ...res.data[0] }));
          }
        },
        (err) => {
          // setLoading(false)
          console.log(err);
        }
      );
  }, [blog_id]);

  useEffect(() => {
    getBlog();
  }, [getBlog]);

  return (
    <>
      <Container fluid>
        {/* {JSON.stringify(view)} */}
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Edit BLOG</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label> Title</label>
                        <Form.Control
                          placeholder="Title"
                          type="text"
                          name="title"
                          value={form.title}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Attechment</label>
                        <Form.Control
                          placeholder="Attechment"
                          type="file"
                          name="attechment"
                          onChange={onChangeAttachment}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Content</label>
                        <Form.Control
                          placeholder="Description"
                          rows="4"
                          as="textarea"
                          name="content"
                          value={form.content}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <CustomButton
                    className="btn-fill pull-right mt-3"
                    type="submit"
                    variant="info"
                    onClick={handleAdd}
                    loading={loading}
                  >
                    Submit
                  </CustomButton>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UpdateBlog;
