import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import moment from "moment";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { toCurrency } from "../../utils";

export default function FinanlApproval({
  identifier = "Final Approval",
  getValue = "Approved authrization",
  callValue = "Approved",
}) {
  const { user } = useSelector((s) => s.auth);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const [remarsks, setRemarks] = useState();
  const [remarkLoading, setRemarkLoading] = useState(false);

  const getRmarks = (item) => {
    setRemarkLoading(false);
    _fetchApi(
      `/get-remarks?ref_no=${item.reference_number}&ticket_id=${item.ticket_id}`,
      (res) => {
        console.log(res);
        if (res.success) {
          setRemarkLoading(false);
          setRemarks(res.data);
          setSelectedItem(item);
          toggle();
        }
      },
      (err) => {
        console.error(err);
        setRemarkLoading(false);
      }
    );
  };

  const getAuthorizedData = () => {
    setLoading(true);
    _fetchApi(
      `/get-trans-req?tracking_status=${getValue}&in_sector=${user.sector}`,
      (resp) => {
        if (resp.success) {
          setLoading(false);
          setData(resp.data);
        }
      },
      (err) => {
        console.log(err);
        toast.error("Transaction failed");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getAuthorizedData();
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setSelectedItem((p) => ({ ...p, [name]: value }));
  };

  return (
    <div>
      <Row>
        <h2 className="mt-3">{identifier}</h2>
        <div
          style={{
            width: "100%",
            backgroundColor: "#fff",
            overflowY: "auto",
            margin: 10,
            borderRadius: 10,
            border: "1px solid #E8EDF2",
          }}
        >
          {loading ? (
            <center>
              <Spinner size="lg" />
            </center>
          ) : null}
          <Table
            hover
            className="user-table align-items-center table-hover"
            style={{
              width: "100%",
              overflow: "scroll",
            }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Tay Payer</th>
                <th>Transaction ID</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter((x) => x.dr > 0)
                ?.map((item, id) => (
                  <tr key={id}>
                    <td>{moment(item.created_at).format("YYYY/MM/DD")}</td>
                    <td>{item.tax_payer}</td>
                    <td>{item.reference_number}</td>
                    <td>{item.description}</td>
                    <td>{toCurrency(item.dr)}</td>
                    <td style={{ fontSize: 15 }}>
                      <Badge color="blue">{item.status}</Badge>
                    </td>
                    <td>
                      <Col>
                        <Button
                          disabled={remarkLoading}
                          color="dark"
                          onClick={() => getRmarks(item)}
                        >
                          {remarkLoading ? "Viewing..." : "View"}
                        </Button>
                      </Col>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Row>
      {selectedItem && (
        <Item
          {...selectedItem}
          toggle={toggle}
          modal={modal}
          getAuthorizedData={getAuthorizedData}
          remarks={remarsks}
          handleChange={handleChange}
          identifier={identifier}
          getValue={getValue}
          callValue={callValue}
        />
      )}
    </div>
  );
}

const Item = ({
  tax_payer,
  reference_number,
  interswitch_ref,
  description,
  status,
  toggle,
  modal,
  getAuthorizedData,
  remarks = [],
  handleChange = (f) => f,
  ticket_id = "",
  identifier = "",
  callValue = "",
}) => {
  const [remark, setRemark] = useState("");
  const { user } = useSelector((s) => s.auth);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(false);
    _postApi(
      `/reciept_logs_up?query_type=${identifier}&in_sector=${user.sector}`,
      {
        ref_no: reference_number,
        status,
        invoice_status: callValue,
        remark: remark,
        staff_name: user.name,
        staff_id: user.id,
        interswitch_ref_no: interswitch_ref,
        ticket_id,
      },
      (res) => {
        setLoading(false);
        if (res.success) {
          getAuthorizedData(); // Refresh data after approval/rejection
          toggle();
        }
      },
      (err) => {
        console.log(err);
        setLoading(false);
        toast.error("Error Occured", err);
      }
    );
  };
  const handleReject = () => {
    setLoading(true);
    _postApi(
      `/reject-req?ref_no=${reference_number}`,
      {},
      (res) => {
        setLoading(false);
        if (res.success) {
          getAuthorizedData(); // Refresh data after approval/rejection
          toggle();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        toast.error("Error Occured", err);
      }
    );
  };
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalBody>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 50,
            }}
          >
            <div>
              {/* {JSON.stringify(remarsks)} */}
              <p style={{ fontSize: 12 }}>
                <b>Tay Payer:{tax_payer}</b>
              </p>
              <p style={{ fontSize: 12 }}>
                <b>Transaction ID: {reference_number}</b>
              </p>
              <p style={{ fontSize: 12 }}>
                <b>Description:{description}</b>
              </p>
              {/* <Col md={12}> */}
              <Label>Interswitch Reference</Label>
              <Input
                type="Text"
                onChange={handleChange}
                value={interswitch_ref}
                name="interswitch_ref"
              />
              {/* </Col> */}
              <div>
                <p style={{ fontSize: 12 }}>
                  <b>Remark:</b>
                  <br />
                </p>
                {remarks?.map((item) => (
                  <p style={{ fontSize: 12 }}>
                    <b>{item.remark}</b>
                    <br />
                  </p>
                ))}
              </div>
            </div>
          </div>
          <hr />
          <Col md={12}>
            <Label>Remark</Label>
            <Input
              value={remark}
              name="remark"
              type="textarea"
              onChange={({ target: { value } }) => {
                setRemark(value);
              }}
            />
          </Col>
          <Row>
            <center style={{ display: "flex", flexDirection: "row" }}>
              <Col md={6}>
                <Button
                  disabled={loading}
                  color="dark"
                  className="mt-3"
                  onClick={handleReject}
                >
                  {loading ? "Rejecting..." : "Reject"}
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  color="primary"
                  disabled={loading}
                  className="mt-3"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loading ? "Approving..." : "Approved"}
                </Button>
              </Col>
            </center>
          </Row>
        </>
      </ModalBody>
    </Modal>
  );
};
