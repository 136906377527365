import { combineReducers } from 'redux';

import auth from './auth';
import cartReducer from './cart';

const rootReducer = combineReducers({
  auth,
  cart: cartReducer,
});

export default rootReducer;
