import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useCallback } from "react";
import { Modal, ModalBody, Table } from "reactstrap";
import { Input } from "react-bootstrap-typeahead";
import { SearchBar } from "../../components/UI";
import { toast } from "react-hot-toast";
// import NewTaxForm from "../tax-mngr/NewTaxForm";
import NewTaxFormModal from "./NewTaxFormModal";
import moment from "moment";
import { toCurrency } from "../../utils";

export default function DevTaxManager() {
  const _form = {
    sector: "",
    tax_parent_code: "",
    description: "",
    tax_code: moment().format("YYMMDDhhms"),
    uom: "",
    default: "",
    tax_fee: "",
    query_type: "create-tax",
    is_department: "0",
    mda_code: "",
    mda_name: "",
    is_head: false,
  };

  const [form, setForm] = useState(_form);
  const [taxHeads, setTaxHeads] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingRows, setEditingRows] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleChanges = ({ target: { name, value } }) => {
    if (name === "sector") {
      setSelectedRows([]);
      setEditingRows(false);
    }
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getTaxHeads = useCallback(() => {
    _fetchApi(
      `/kigra-taxes?query_type=select-sector-taxes&sector=${form.sector}`,
      (resp) => {
        if (resp.success) {
          setTaxHeads(resp.result);
        }
      },
      () => {
        setTaxHeads([]);
      }
    );
  }, [form.sector]);

  const handleCheckboxChange = (row) => {
    const curRows = [...selectedRows];
    const existingIndex = curRows.findIndex((item) => item.id === row.id);
    if (existingIndex !== -1) {
      curRows.splice(existingIndex, 1);
    } else {
      curRows.push(row);
    }
    setSelectedRows(curRows);
  };

  const handleEditClick = () => {
    setEditingRows(!editingRows);
  };

  const handleChangeItem = ({ target: { value, name } }, row) => {
    setTaxHeads((prevTaxHeads) =>
      prevTaxHeads.map((item) =>
        item.id === row.id ? { ...item, [name]: value } : item
      )
    );
  };

  const handleDeleteClick = () => {
    const updatedData = taxHeads.filter(
      (row) => !selectedRows.includes(row.id)
    );
    setTaxHeads(updatedData);
    setSelectedRows([]);
  };

  useEffect(() => {
    // getSubHeads();
    getTaxHeads();
  }, [getTaxHeads]);

  const handleSubmit = () => {
    _postApi(
      "/kigra-taxes?query_type=update-taxes",
      selectedRows.map((item) => ({
        ...taxHeads.filter((tax) => tax.id === item.id)[0],
        query_type: "update-tax",
      })),
      (res) => {
       if(res.success){
        toast.success("Submited");
        setEditingRows(false);
        setSelectedRows([]);
       }
      },
      (err) => {
        if(err){
        toast.error("Failed to submit");
        console.error(err);
        }
      }
    );
  };
  const FilteredTaxHeads =
    taxHeads?.length && filterText.length
      ? taxHeads.filter(
        (item) =>
          (item.title &&
            item.title.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.tax_parent_code &&
            item.tax_parent_code
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.tax_code &&
            item.tax_code.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.mda_name &&
            item.mda_name.toLowerCase().includes(filterText.toLowerCase()))
      )
      : taxHeads;
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      {/* {JSON.stringify(selectedRows)} */}
      <Card.Body>
        <h5 className="mb-4">Tax information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Tax category</Form.Label>
                <Form.Select
                  required
                  value={form.sector}
                  name="sector"
                  type="select"
                  onChange={handleChanges}
                  placeholder="Select tax category"
                >
                  <option value="">Select tax category</option>
                  <option value="TAX">Tax</option>
                  <option value={"NON TAX"}>Non Tax Charges/Fees</option>
                  <option value={"VEHICLES"}>Vehicles Registrations</option>
                  <option value={"LAND"}>Land Charges</option>
                  <option value={"LGA"}>LGA Revenue</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Button
                color="danger"
                style={{
                  width: "100%",
                  marginBottom: "-25%",
                }}
                onClick={toggle}
              >
                Add New
              </Button>
            </Col>
            <Modal
              isOpen={modal}
              toggle={toggle}
              size="lg"
              style={
                {
                  // mar
                }
              }
            >
              <ModalBody>
                <NewTaxFormModal _form={form} />
              </ModalBody>
              {/* <Col md={12} style={{marginBottom: 20}}>
                <center>
                  <Button
                    color="secondary" 
                    onClick={toggle}
                    style={{
                      width: '30%'
                    }}
                  >
                    Submit
                  </Button>
                </center>
              </Col> */}
            </Modal>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <SearchBar
                onFilterTextChange={(val) => setFilterText(val)}
                filterText={filterText}
              />
            </Col>
            <Col md={3}>
              {!editingRows ? (
                <Button
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={handleEditClick}
                  disabled={selectedRows.length === 0}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              )}
            </Col>
            <Col md={3}>
              <Button
                color="danger"
                style={{ width: "100%", backgroundColor: "red" }}
                onClick={handleDeleteClick}
                disabled={selectedRows.length === 0}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Form>
        <Row
          style={{
            height: "65vh",
            overflowY: "scroll",
            scrollbarWidth: "thin", // For Firefox
            scrollbarColor: "transparent transparent", // For Firefox
            // Webkit scrollbar styles
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            overflow: "-moz-scrollbars-none",
          }}
        >
          <Col md={12}>
            <table className="table-zebra" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "20&" }}>Tax Head</th>
                  <th width="20%">Tax title</th>
                  <th width="10%">Revenue code</th>
                  <th width="10%">MDA Name</th>
                  <th width="10%">MDA Code</th>

                  <th width="10%">UOM</th>
                  <th width="10%">Default</th>
                  <th width="10%">Tax Fee</th>
                  <th width="5%">Select</th>
                </tr>
              </thead>
              {FilteredTaxHeads.map((item, idx) => (
                <tbody>
                  <tr>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          id={`col1-${item.tax_parent_code}`}
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.tax_parent_code}
                          name="tax_parent_code"
                        />
                      ) : (
                        item.tax_parent_code
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          id={`col1-${item.title}`}
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.title}
                          name="title"
                        />
                      ) : (
                        item.title
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          id={`col1-${item.economic_code}`}
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.economic_code}
                          name="economic_code"
                        />
                      ) : (
                        item.economic_code
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.mda_name}
                          name="mda_name"
                        />
                      ) : (
                        item.mda_name
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.mda_code}
                          name="mda_code"
                        />
                      ) : (
                        item.mda_code
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.uom}
                          name="uom"
                        />
                      ) : (
                        item.uom
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.default}
                          name="default"
                        />
                      ) : (
                        toCurrency(item.default)
                      )}
                    </td>
                    <td>
                      {editingRows &&
                        selectedRows.some((itm) => itm.id === item.id) ? (
                        <input
                          type="text"
                          onChange={(e) => handleChangeItem(e, item)}
                          value={item.tax_fee}
                          name="tax_fee"
                        />
                      ) : (
                        toCurrency(item.tax_fee)
                      )}
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name={`checkbox-${idx}`}
                        checked={selectedRows.some((itm) => itm.id === item.id)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
