// AUTHS
export const LOADING_SIGNUP = "LOADING_SIGNUP";
export const AUTH = "SET_USER";
export const TAX_ACCOUNT = "TAX_ACCOUNT";
export const TAX_ACCOUNTS = "TAX_ACCOUNTS";
export const LOADING_LOGIN = "LOADING_LOGIN";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const LOADING_APP = "___LOADING_APP";
export const ERRORS = "ERRORS";
export const PAYER_ID = "PAYER_ID";
export const RECOVER_USER = "RECOVER_USER";

// FINANCE (PAYMENTS)
export const PAYMENT_SCHEDULE = "Payment-Schedule";
export const PAYMENT_SCHEDULES = "Payment-Schedules";
export const BATCH_NO = "BATCH_NO-Schedules";
export const APPROVAL_DISPHATCH = "APPROVAL_DISPHATCH";

//KIGRA TYPES
export const ADD_CART = "ADD_CART";
export const EMPTY_CART = "EMPTY_CART";
export const VIEW_CART ='VIEW_CART'
export const CART_COUNT ='CART_COUNT'
export const DELETE_CART ='CART_DELETE'

export   const PREVILLAGES = 'Dashboard,MDA Reports, Tax Payers, Tax Setup, Tax Admins, TAX, NON TAX, VEHICLES, LAND, LGA, Receipt Reports,Collection Report,Invoice Report,Raise Request,Request Confirmation,Final Request,Period Adjustment,Generate QRCODE, Generate Receipts,Raise TCC,TCC Recommendation,TCC Approval,TCC Printing, Assessments,Tax Stations Mngr,Blog Manager,Slider,TaxPayers Assessment,Station Staff Mngr,Demand Notice'