import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import CustomInput from "../pages/examples/CustomInput";
import SelectInput from "../pages/components/SelectInput";
import { _fetchApi, _postApi } from "../redux/action/api";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import CustomTypeahead from "./UI/CustomTypeahead";

export default function Verify() {
  const [form, setForm] = useState({
    mda_name: "",
    mda_code: "",
    code: "",
    quantity: "",
    date: "",
    recieptType: "",
    num: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    const selectedMda = data.find((item) => item.mda_name === value);

    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
      mda_code:
        name === "mda_name"
          ? selectedMda
            ? selectedMda.mda_code
            : ""
          : prevForm.mda_code,
    }));
  };

  const [data, setData] = useState([]);
  const navigate = useHistory();
  const getMdaList = () => {
    _fetchApi(
      "/get-mda-list",
      (resp) => {
        if (resp.results) {
          setData(resp.results[0]);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  useEffect(() => {
    getMdaList();
  }, []);

  const handleSubmit = () => {
    if (form.mda_name !== "" || form.mda_code !== "") {
      setLoading(true);
      _postApi(
        "/post-mda-list",
        form,
        (res) => {
          setLoading(false);
          if (res.success) {
            navigate.push(`/admin/get-verify/${res.id}?type=${form.num}`);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        }
      );
    } else {
      toast.success("Please complete the form");
    }
  };
  return (
    <div className="  shadow-sm mb-4 d-flex align-items-center justify-content-center mt-4 ">
      <Col md={8} className="mt-5">
        <Container>
          <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
            <div className="text-center text-md-center mb-4 mt-md-0">
              <h3 className="mb-0">Generate Qr Code</h3>
            </div>
            {/* {JSON.stringify(form)} */}
            {/* <Container> */}
            <SelectInput
              label="Type"
              type="text"
              name="type"
              required={true}
              options={["Books", "Reciepts"]}
              value={form.type}
              onChange={handleChange}
            />

            {form.type === "Reciepts" ? (
              <>
                <SelectInput
                  label="Select MDA"
                  type="text"
                  name="mda_name"
                  // required={true}
                  options={data?.map((i) => i.mda_name)}
                  value={form.mda_name}
                  onChange={handleChange}
                />
                <CustomInput
                  label="MDA Code"
                  type="text"
                  name="ward"
                  value={form.mda_code}
                  onChange={handleChange}
                />

                <CustomTypeahead
                  options={[
                    { name: "BKS-D", num: 2 },
                    { name: "BKS-T", num: 3 },
                    { name: "BKS-Q", num: 4 },
                    { name: "100-L", num: 1 },
                    { name: "50-L", num: 1 },
                  ]}
                  labelKey={"name"}
                  clearButton
                  placeholder="Select Receipts Type"
                  id="20"
                  onChange={(selected) => {
                    if (selected && selected.length) {
                      setForm((prevForm) => ({
                        ...prevForm,
                        recieptType: selected[0]?.name,
                        num: selected[0]?.num,
                      }));
                    }
                  }}
                  col={12}
                  style={{ width: "80% !important" }}
                  onInputChange={(text) => console.log(text)}
                />
              </>
            ) : form.type === "Books" ? (
              <>
                <Col md={12}>
                  <CustomInput
                    label="Org/Individual Name"
                    type="text"
                    name="mda_name"
                    value={form.mda_name}
                    onChange={handleChange}
                  />
                </Col>

                <div className="row">
                  <Col md={4}>
                    <Label>Code</Label>
                    <InputGroup>
                      <InputGroupText>GP.KANO</InputGroupText>
                      <Input
                        name="code"
                        value={form.code}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Col>

                  <Col md={4}>
                    <CustomInput
                      label="Date"
                      type="date"
                      name="date"
                      value={form.date}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col md={4}>
                    <Label>Quantity</Label>
                    <InputGroup>
                      <Input
                        name="quantity"
                        value={form.quantity}
                        onChange={handleChange}
                      />
                      <InputGroupText>-C</InputGroupText>
                    </InputGroup>
                  </Col>
                  {form?.code === "" ? null : (
                    <span>{`GP.KAN0 ${form.code}/${form.date}/${form.quantity}-C`}</span>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <br />
            <Button
              onClick={handleSubmit}
              color="dark"
              type="submit"
              className="w-100"
              disabled={loading}
            >
              {loading ? "Creating...." : "Create"}
            </Button>
            {/* </Container> */}
          </div>
        </Container>
      </Col>
    </div>
  );
}
