export const IncomeSources = [
    "Salary/Wages",
    "Business Income",
    "Freelancing",
    "Consulting",
    "Investments",
    "Dividends",
    "Interest",
    "Rental Income",
    "Royalties",
    "Pensions",
    "Annuities",
    "Social Security",
    "Alimony",
    "Child Support",
    "Capital Gains",
    "Partnership Income",
    "Estate or Trust Income",
    "Grants",
    "Stipends",
    "Gifts",
    "Inheritance",
    "Government Assistance",
    "Disability Benefits",
    "Insurance Payouts",
    "Contract Work",
    "Sales of Goods",
    "Service Fees",
    "Commissions",
    "Bonuses",
    "Tips",
    "Gig Economy Work",
    "Crowdfunding",
    "Peer-to-Peer Lending",
    "Affiliate Marketing",
    "Advertising Revenue",
    "Online Courses",
    "Digital Products",
    "Subscription Services",
    "Membership Fees",
    "Others"
];
