import React from 'react'
import { Card, CardBody, CardTitle, Button, Table } from 'reactstrap';
import { IoClose } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
function Emloyee() {
  const circularBtnStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    margin: '10px'
  };
  return (
    <div>
      <Card>
        <CardTitle tag="h5" className="bg-light p-3 mb-1">Employee</CardTitle>
        <CardBody>

          <div className="">
            <Button color="primary" className="mr-2 m-3">Add <IoMdAdd /></Button>
            <Button color="secondary">Download Employee</Button>
          </div>
          <Table bordered>
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Kadri's ID</th>
                <th>Operations</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>bashir</td>
                <td>10 -34 -4</td>
                <td>
                  <Button size="sm" className="mr-2">Detail</Button>
                  <Button color="dark" size="sm  m-2">Filling History</Button>
                  <Button color="danger" size="sm" style={circularBtnStyle}>
                    <IoClose />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>bashir</td>
                <td>10 -34 -4</td>
                <td>
                  <Button size="sm" className="mr-2">Detail</Button>
                  <Button color="dark" size="sm m-2">Filling History</Button>
                  <Button color="danger" size="sm" style={circularBtnStyle}>
                    <IoClose />
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Button className='d-flex flex-start' color="danger" size="sm" >down</Button>
    </div>
  )
}

export default Emloyee