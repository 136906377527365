import React, { useCallback, useEffect, useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faCashRegister,
  faChartBar,
  faChartLine,
  // faCloudUploadAlt,
  // faPlus,
  // faRocket,
  // faTasks,
  // faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Input,
  // Dropdown,
  // ButtonGroup,
} from "@themesberg/react-bootstrap";

import {
  CounterWidget,
  // CircleChartWidget,
  // BarChartWidget,
  // TeamMembersWidget,
  // ProgressTrackWidget,
  // RankingWidget,
  SalesValueWidget,
  // SalesValueWidgetPhone,
  // AcquisitionWidget,
} from "../../components/Widgets";
// import { PageVisitsTable } from '../../components/Tables'
// import { trafficShares, totalOrders } from '../../data/charts'
import DaterangeSelector from "./DaterangeSelector";
import { useHistory } from "react-router";
import { Routes } from "../../routes";
import ChartComponent from "./ChartComponent";
import PieChart from "./charts/PieChart";
import VerticalComposedChart from "./charts/VerticalComposedChart";
import { useSelector } from "react-redux";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { Card, Spinner } from "reactstrap";
import moment from "moment";
import { toCurrency, getMonth, formatNumber, isAdmin } from "../../utils";
import { Table } from "@themesberg/react-bootstrap/lib/esm";
import CustomDateRange from "./CustomDateRange";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LineChartComponent from "./charts/LineChartComponent";
import { isSuper } from "../../components/UI/helpers";
import LeaderBoardChart from "./charts/LeaderBoardChart";
import BarChartComponent from "./charts/BarChartComponent";
// import { Chart } from "chart.js";
// import BarChart from "./Newchart";

export default () => {
  const history = useHistory();
  const { user } = useSelector((s) => s.auth);
  // date picker setup
  const today = moment();
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total_revenue, setTotalRevenue] = useState(0);
  const [today_revenue, setTodayRevenue] = useState(0);
  const [monthlyRevenues, setMonthlyRevenues] = useState([]);
  const [banklyRevenues, setBanklyRevenues] = useState([]);

  const [agentPerformance, setStaffPerformance] = useState([]);
  const [stationPerformance, setStationPerformance] = useState([]);

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/get/mdas?query_type=bankly-reports&start_date=${dateRange.from}&end_date=${dateRange.to}`,
      (res) => {
        setLoading(false);
        setBanklyRevenues(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=monthly-reports&start_date=${dateRange.from}&end_date=${dateRange.to}`,
      (res) => {
        setLoading(false);
        setMonthlyRevenues(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=total-revenue`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setTotalRevenue(res.data[0]);
        }
      },
      (err) => {
        setLoading(false);
        setTotalRevenue([]);

        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=total-revenue&start_date=${dateRange.from}&end_date=${dateRange.to}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setTodayRevenue(res.data[0]);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get-transaction-details?from=${dateRange.from}&to=${dateRange.to}&query_type=sector`,
      (res) => {
        setLoading(false);
        setData(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [dateRange]);

  // const total = data?.reduce((item, d) => item + parseFloat(d.total_amt), 0);

  const fetchPerformance = useCallback(() => {
    setLoading(true);
    _postApi(
      "/auth/create-admins",
      {
        query_type: "select-staff-performance",
        start_date: dateRange.from,
        end_date: dateRange.to,
      },
      (resp) => {
        setLoading(false);
        if (resp.success) {
          setStaffPerformance(resp.data);
        }
      },
      (error) => {
        setLoading(false);
        setStaffPerformance([]);
        setStationPerformance([]);
        console.error("Failed to fetch performance data", error);
      }
    );
    _postApi(
      "/auth/create-admins",
      {
        query_type: "select-station-performance",
        start_date: dateRange.from,
        end_date: dateRange.to,
      },
      (resp) => {
        setLoading(false);
        if (resp.success) {
          setStationPerformance(resp.data);
        }
      },
      (error) => {
        setLoading(false);
        setStaffPerformance([]);
        setStationPerformance([]);
        console.error("Failed to fetch performance data", error);
      }
    );
  }, [dateRange]);

  useEffect(() => {
    fetchPerformance();
  }, [fetchPerformance]);

  return (
    <div style={{ margin: "5px" }}>
      <h4 className="py-3 text-center">Dashboard Overview</h4>

      <CustomDateRange
        type={dateRange.type}
        month={dateRange.month}
        quarter={dateRange.quarter}
        year={dateRange.year}
        from={dateRange.from}
        to={dateRange.to}
        handleChangeDate={handleChangeDate}
      />

      {loading && <Spinner />}
      {/* {JSON.stringify({dateRange})} */}
      <Row className="justify-content-md-center">
        {/* <Col xs={12} sm={6} xl={4} className="mb-1">
          <SalesValueWidget
            title="Collections"
            value={toCurrency(total_revenue?.total)}
            percentage={toCurrency(
              (today_revenue?.total / total_revenue?.total) * 100
            )}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            className=" bg-primary text-white"
            category="Tax Payers Summary"
            title={total}
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
            onClick={() => history.push(Routes.TaxPayers.path)}
          />
        </Col> */}
        {/* {JSON.stringify(data)} */}
        {data?.length
          ? data
              ?.filter((item) => parseInt(item.total_amt) > 0)
              ?.map((item) => (
                <Col className="mb-4 mt-2" key={item.sector}>
                  <CounterWidget
                    className="shadow"
                    color="light"
                    category={item.sector === "VEHICLES" ? "MLO" : item.sector}
                    title={item.total_amt}
                    period={
                      `${moment(dateRange.dateRange).format("MMMM D")}` -
                      `${moment(dateRange.to).format("MMMM D")}`
                    }
                    percentage={28.4}
                    icon={faChartLine}
                    iconColor="shape-tertiary"
                    showChanges={false}
                    onClick={() =>
                      history.push(
                        `${Routes.RevenueCollectionReportView.path}?from=${dateRange.from}&to=${dateRange.to}&sector=${item.sector}`
                      )
                    }
                  />
                </Col>
              ))
          : null}
      </Row>
      {isSuper(user) ? (
        <Row>
          <Col md={6} className="mt-3 card card-body">
            {/* <BarChart /> */}
            {/* <BarChartComponent
              data={data
                ?.filter((x) => x.sector && x.total_amt > 0)
                ?.map((x) => ({
                  ...x,
                  Collections: x?.total_amt,
                  sector: x.sector.replace("VEHICLES", "MLO"),
                }))}
              bars={[
                { title: "Collections", label: true },
                // { title: '', label: true },
              ]}
            /> */}
          </Col>
          <Col md={6} className="mt-3">
            <PieChart
              rows={data?.map((item) => ({
                name: item.sector && item.sector.replace("VEHICLES", "MLO"),
                value: parseInt(item.total_amt),
              }))}
            />
          </Col>
        </Row>
      ) : null}
      {isSuper(user) ? (
        <Row>
          {/* <Card>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Bank name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {banklyRevenues?.map((row, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{row.bank_name || "Others"}</td>
                  <td className="text-right">{toCurrency(row.total)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card> */}
        </Row>
      ) : null}
      {/* <Row style={{ height: '500px', marginBottom: '200px' }}>
        <Col md={12}>
          <LeaderBoardChart
            title="STAFF PERFORMANCE CHART"
            data={agentPerformance}
            dataKey1='staff_name'
            dataKey2='total' />
        </Col>
        <Col md={12}>
          <LeaderBoardChart
            title="STATION PERFORMANCE CHART"
            data={stationPerformance}
            dataKey1='tax_station'
            dataKey2='total' />
        </Col>
      </Row> */}
    </div>
  );
};
