import React, { useRef } from "react";
import {
  faArrowCircleLeft,
  faDownload,
  faHistory,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
// import { Button, Modal, Card } from "react-bootstrap";
// import { _fetchApi } from "../../redux/action/api"; // assuming you're using a similar function to fetch data
import { CURRENCY } from "../../components/Tables2";
import {
  Badge,
} from "reactstrap";
// import moment from "moment";
import {
  Button,
  ButtonGroup,
  Col,
  Row,
  Modal,
  Table,
  Card,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
 import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { toast } from "react-hot-toast";
import { useCallback } from "react";

import { downloadExcel } from "react-export-table-to-excel";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import LegderPDF from "../components/LegderPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import DaterangeSelector from "./DaterangeSelector";
import { useQuery } from "../../utils";
import { PaymentDetailsTable, TransactionsTable } from "../../components/Tables2";
import { SearchBar } from "../../components/UI";
import LegderPDF from "../../components/LegderPDF";
// import { _fetchApi } from "../../redux/action/api";

export default function BankTransactions({ taxPayer = {}, viewOnly = false, org_id }) {
  const tableRef = useRef(null);
  const query = useQuery();
  const today = moment();
  const date_from = query.get("date_from");
  const date_to = query.get("date_to");
  const taxid = query.get("taxpayer");
  const item_code = query.get("item_code");
  const description = query.get("description");
  //   const today = moment().format("YYYY-MM-DD");
  const { tax_account, user } = useSelector((s) => s.auth);
  const [trx, setTrx] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const _ref = useRef(null);
  const [dataStatus, setDataStatus] = useState("");
  const [TotalItems, setTotalItems] = useState();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: date_from || today.format("YYYY-MM-DD"),
    to: date_to || today.format("YYYY-MM-DD"),
  });
  const [form, setForm] = useState({
    from: `${moment().format('YYYY')}-01-01`,
    to: `${moment().format('YYYY')}-12-30`
});
  const q = useQuery();
  const trx_ref = q.get("trx_ref");
  const _form = {
    from: moment(today).add(-1, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    user_id: null,
  };

  const [filterText, setFilterText] = useState("");

  //recent
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (show) {
      setLoading(true);
      _fetchApi(
        `/transactions/retrieve?query_type=view_payer_ledger&user_id=${filtered_trx[0]?.user_id}&start_date=${'0000-00-00'}&end_date=${'3030-01-01'}`,
        (resp) => {
          if (resp.data.length) {
            setTrx(resp.data);
            setDataStatus("");
          } else {
            setDataStatus("Record not found");
            setTrx([]);
          }
          setLoading(false);
        },
        (err) => {
          console.error(err);
          setDataStatus("Error Occoured");
          setTrx([]);
          setLoading(false);
        }
      );
    }
  }, [user?.taxID, form, show]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  useEffect(() => {
    _fetchApi(
      `/rev-collection-report-history?date_from=${dateRange.from}&date_to=${dateRange.to}&tax_payer=${taxid}&service_category=${item_code}&query_type=Item by Taxpayer Details`,
      (res) => {
        if (res.success) {
          setData(res.results);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [dateRange.from, dateRange.to]);

  const getTrx = useCallback(() => {
    if (form.user_id > 1 && !trx_ref) {
      _fetchApi(
        `/transactions/retrieve?query_type=view_payer_ledger&user_id=${form.user_id}&start_date=${form.from}&end_date=${form.to}`,
        (resp) => {
          setTrx(resp.data);
        },
        (err) => {
          setTrx([]);
          toast.error("Failed to retrieve transactions");
        }
      );
    } else if (trx_ref) {
      _fetchApi(
        `/transactions/retrieve?query_type=view_payer_ledger&user_id=${form.user_id}&start_date=${form.from}&end_date=${form.to}`,
        (resp) => {
          setTrx(resp.data);
        },
        (err) => {
          setTrx([]);
          toast.error("Failed to retrieve transactions");
        }
      );
    }
  }, [form.from, form.to, form.user_id, trx_ref]);

  useEffect(() => {
    getTrx();
  }, [getTrx]);

  const getUser = useCallback(() => {
    _postApi(
      "/auths/-post-users",
      {
        user_id: filterText,
      
      },
      (res) => {
        if (res.success && res.data.length) {
          setLoading(false);
          setData(res.data);
          setTotalItems(0);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, [taxid]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const filtered_trx = filterText.length
    ? data.filter(
        (item) =>
          item.description.toLowerCase().includes(filterText.toLowerCase()) ||
          item?.transaction_id.toString().includes(filterText)
      )
    : data;

  const header = [
    "SN",
    "DATE",
    "Ref No.",
    "NIN",
    "TIN",
    "TAX ID",
    "DESCRIPTION",
    "AMOUNT",
    "STATUS",
  ];
  const RECORDS = data?.length
    ? data.map((item, idx) => ({
        id: idx + 1,
        date: item.transaction_date,
        ref_no: item.reference_number,
        nin: taxPayer.nin ? taxPayer.nin : tax_account?.nin,
        tin: taxPayer.tin ? taxPayer.tin : tax_account?.tin,
        tax_id: taxPayer.taxID ? taxPayer.taxID : tax_account?.taxID,
        description: item.description,
        amount: Math.max(item.dr),
        status: item.status,
      }))
    : [];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "kirmas-user_ledger",
      sheet: "user_ledger",
      tablePayload: {
        header,
        body: RECORDS.filter((_) => _.amount > 0),
      },
    });
  }
  //   const payer = taxPayer.id ? taxPayer : tax_account;
  const history = useHistory();
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="d-block mb-4 mb-md-0">
          <p className="mb-0">
            {" "}
            <Button size="sm" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </p>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHistory} />
            </Breadcrumb.Item>

            <Breadcrumb.Item active>My Transactions</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <PDFDownloadLink
              document={
                <LegderPDF
                  //   user={payer}
                  data={RECORDS.filter((_) => _.amount > 0)}
                />
              }
              fileName={`ledger-${moment().format("YYYYMMDDMMSS")}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    style={{ borderRadius: "10px 0px 0px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="sm"
                      className="me-2"
                    />
                    Export PDF
                  </Button>
                )
              }
            </PDFDownloadLink>

            <Button
              onClick={handleDownloadExcel}
              variant="outline-primary"
              size="sm"
            >
              Export Excel{" "}
              <FontAwesomeIcon icon={faDownload} size="sm" className="me-2" />
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {/* {JSON.stringify(filtered_trx)} */}
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <SearchBar
              filterText={filterText}
              onFilterTextChange={(val) => setFilterText(val)}
            />
          </Col>
          <Col>
          <Button variant="primary" size="sm" className="text-white" onClick={handleShow}>
        View Ledger
      </Button>
               </Col>
          <Col xs={12} md={12} lg={12} xl={4}className="ps-md-0 text-end">
            <Row>
              <Col md={3}></Col>
              <Col>
                <DaterangeSelector
                  from={form.from}
                  to={form.to}
                  handleChange={handleChange}
                  inline
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <PaymentDetailsTable
        setRef={() => {}}
        viewOnly={viewOnly}
        _ref={tableRef}
        data={filtered_trx
          ?.map((t) => ({
            ...t,
            amount: Math.max(t.cr, t.dr),
          }))
          .filter((_) => _.amount)}
        // user={payer}
      />
 {/* Modal to display the fetched data */}
 <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Ledger Details</Modal.Title>
          <Badge>{trx.length}</Badge>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : dataStatus ? (
            <p>{dataStatus}</p>
          ) : (
            <Card>
              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Period</th>
                      <th>Ref. No.</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trx.map((invoice, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(moment.dr).format("DD/MM/YYYY")}</td>
                        <td>{invoice.description}</td>
                        <td>{invoice.date_from}-{invoice.date_to}</td>
                        <td>{invoice.reference_number}</td>
                        <td className="text-right">{Number(invoice.dr).toLocaleString()}</td>
                        <td>
                          <Badge>{invoice.status}</Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
// import React, { useEffect, useState } from "react";
// import { formatNumber, useQuery } from "../../utils";
// import moment from "moment";
// import CustomDateRange from "./CustomDateRange";
// import { _fetchApi } from "../../redux/action/api";
// import { Link } from "react-router-dom";
// import { Routes } from "../../routes";
// import { FaArrowLeft } from "react-icons/fa";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { Button } from "reactstrap";
// import { CURRENCY } from "../../components/Tables2";
// function default function BankTransactions() {
//   const query = useQuery();
//   const date_from = query.get("date_from");
//   const sector = query.get("sector");
//   const date_to = query.get("date_to");
//   const description = query.get("description");
//   const taxid = query.get("taxid");
//   const today = moment();
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [dateRange, setDateRange] = useState({
//     type: "daily",
//     month: today.format("MM"),
//     quarter: Math.ceil(today.format("MM") / 3).toString(),
//     year: today.format("YYYY"),
//     from: date_from || today.format("YYYY-MM-DD"),
//     to: date_to || today.format("YYYY-MM-DD"),
//   });
//   const handleChangeDate = ({ target: { name, value } }) => {
//     switch (name) {
//       case "month":
//         const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
//           "YYYY-MM-DD"
//         );
//         const monthEnd = moment(`'${dateRange.year}-${value}'`)
//           .endOf("month")
//           .format("YYYY-MM-DD");

//         setDateRange({
//           ...dateRange,
//           from: monthStart,
//           to: monthEnd,
//           month: value,
//         });
//         break;
//       case "year":
//         const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
//         const yearEnd = moment(`'${value}-12'`)
//           .endOf("year")
//           .format("YYYY-MM-DD");

//         setDateRange({
//           ...dateRange,
//           from: yearStart,
//           to: yearEnd,
//         });
//         break;

//       case "quarter":
//         let startMonth, endMonth;
//         let selectedQuarter = value;
//         switch (selectedQuarter) {
//           case "Q1":
//             startMonth = 1;
//             endMonth = 3;
//             break;
//           case "Q2":
//             startMonth = 4;
//             endMonth = 6;
//             break;
//           case "Q3":
//             startMonth = 7;
//             endMonth = 9;
//             break;
//           case "Q4":
//             startMonth = 10;
//             endMonth = 12;
//             break;
//           default:
//             // Handle unexpected quarter values
//             break;
//         }
//         const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
//           "YYYY-MM-DD"
//         );
//         const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
//           .endOf("month")
//           .format("YYYY-MM-DD");

//         setDateRange({
//           ...dateRange,
//           from: qStart,
//           to: qEnd,
//           quarter: selectedQuarter,
//         });
//         break;
//       case "from":
//         setDateRange({
//           ...dateRange,
//           from: moment(value).format("YYYY-MM-DD"),
//         });
//         break;

//       case "to":
//         setDateRange({
//           ...dateRange,
//           to: moment(value).format("YYYY-MM-DD"),
//         });
//         break;
//       default:
//         break;
//     }
//     setDateRange((prevDateRange) => ({
//       ...prevDateRange,
//       [name]: value,
//     }));
//   };
//   useEffect(() => {
//     _fetchApi(
//       `/rev-collection-report-history?description=${description}&date_from=${dateRange.from}&date_to=${dateRange.to}&taxid=${taxid}&sector=${sector}&query_type=Sector Report by Tax Item`,
//       (res) => {
//         if (res.success) {
//           setData(res.results);
//         }
//         setLoading(false);
//       },
//       (err) => {
//         setLoading(false);
//         console.log(err);
//       }
//     );
//   }, [dateRange.from, dateRange.to]);
//   const history = useHistory();
//   return (
//     <div style={{ margin: "5px" }}>
//       <Button
//         size="sm"
//         className="btn btn-link"
//         onClick={() => history.goBack()}
//       >
//         <FaArrowLeft /> Go back
//       </Button>
//       <h4 className="py-3 text-center">{description} Reports</h4>
//       <CustomDateRange
//         type={dateRange.type}
//         month={dateRange.month}
//         quarter={dateRange.quarter}
//         year={dateRange.year}
//         from={dateRange.from}
//         to={dateRange.to}
//         handleChangeDate={handleChangeDate}
//       />
//       <div className="text-right">
//         <h4>
//           <span>Total {description}: </span>
//           <span>
//             {CURRENCY}
//             {formatNumber(
//               data.reduce((it, id) => it + parseFloat(id.amount), 0)
//             )}
//           </span>
//         </h4>
//       </div>
//       <table
//         style={{ width: "100%", border: "1px solid black" }}
//         className="table table-responsive  table-striped table-zebra  pt-0 table-bordered"
//       >
//         <thead>
//           <tr>
//             <th className="border-bottom  text-">Tax Payer</th>
//             <th className="border-bottom text-right">Amount</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item) => (
//             <tr>
//               {/* <Link
//                 to={Routes.BankTransactions.path}
//                 style={{ color: "blue", textDecoration: "underline" }}
//               >
//                 {item.tax_payer}
//               </Link>
//               <Link
//                 to={Routes.BankTransactions.path}
//                 style={{ color: "blue", textDecoration: "underline" }}
//               >
//                 {formatNumber(item.amount)}
//               </Link> */}
//               <td className="text-">{item.tax_payer}</td>
//               <td className="text-right">{formatNumber(item.amount)}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }
