import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Form, Button, Container } from "@themesberg/react-bootstrap";
import { _fetchApi } from "../redux/action/api";
import { FaShoppingCart } from "react-icons/fa";
import CustomTypeahead from "./UI/CustomTypeahead";
import SelectedTaxModal from "./SelectedTaxModal";

export default function SelectedTax() {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    dateFrom: "",
    dateTo: "",
    amount: "",
  });
  const [cartItems, setCartItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const typeaheadRef = useRef(null);

  const getMDAList = () => {
    _fetchApi(
      "/kigra-taxes?query_type=select-taxes",
      (resp) => {
        console.log("API Response:", resp);
        if (resp && resp.success && resp.result && resp.result.length > 0) {
          setOptions(resp.result);
        } else {
          console.error("No results found in API response.");
        }
      },
      (err) => {
        console.error("Error fetching options:", err);
      }
    );
  };

  useEffect(() => {
    getMDAList();
  }, []);

  useEffect(() => {
    // Fetch amount from the database when an option is selected
    if (selectedOption) {
      // Assuming you have an API endpoint to fetch amount by ID
      _fetchApi(
        `/fetch-amount?id=${selectedOption.head}`,
        (resp) => {
          if (resp && resp.success && resp.result) {
            setFormData((prevData) => ({
              ...prevData,
              amount: resp.result.amount,
            }));
          } else {
            console.error("No amount found for selected option.");
          }
        },
        (err) => {
          console.error("Error fetching amount:", err);
        }
      );
    }
  }, [selectedOption]);

  const handleSelectChange = (selected) => {
    if (selected && selected.length) {
      const selectedItem = selected[0];
      setSelectedOption(selectedItem);
      setFormData({
        dateFrom: "",
        dateTo: "",
        amount: "", // Reset amount when a new option is selected
      });
    } else {
      setSelectedOption(null); // Clear selected option if nothing is selected
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddToList = (event) => {
    event.preventDefault();
    if (selectedOption) {
      setCartItems((prevItems) => [
        ...prevItems,
        {
          ...formData,
          id: `${selectedOption.tax_parent_code}-${selectedOption.title}`,
        },
      ]);
      // Reset the form and selection
      setSelectedOption(null);
      setFormData({
        dateFrom: "",
        dateTo: "",
        amount: "",
      });
      if (typeaheadRef.current) {
        typeaheadRef.current.clear();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-3 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <div
              style={{
                marginLeft: "20px",
                position: "relative",
              }}
            >
              <FaShoppingCart
                size={24}
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "10px",
                  fontSize: 25,
                }}
              />
              <span
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "12px",
                }}
              >
                {cartItems.length}
              </span>
            </div>
            <Col
              style={{
                marginTop: 30,
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-900">
                <h3 className="mb-4">Reset your password</h3>
                <Col md={12}>
                  <Form.Label>Select Tax</Form.Label>
                  <CustomTypeahead
                    ref={typeaheadRef}
                    options={options}
                    labelKey={(e) => `${e.tax_parent_code}-${e.title}`}
                    clearButton
                    placeholder="Select MDA"
                    id="20"
                    onChange={handleSelectChange}
                    col={12}
                    style={{ width: "100%" }}
                    onInputChange={(text) => console.log(text)}
                    filterBy={(option, props) =>
                      Object.keys(option).some((key) =>
                        String(option[key])
                          .toLowerCase()
                          .includes(props.text.toLowerCase())
                      )
                    }
                  />
                </Col>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formDateFrom">
                        <Form.Label>Date From:</Form.Label>
                        <Form.Control
                          type="date"
                          name="dateFrom"
                          value={formData.dateFrom}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formDateTo">
                        <Form.Label>Date To:</Form.Label>
                        <Form.Control
                          type="date"
                          name="dateTo"
                          value={formData.dateTo}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formAmount">
                        <Form.Label>Amount:</Form.Label>
                        <Form.Control
                          type="number"
                          name="amount"
                          value={formData.amount}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <center className="mt-3" style={{ gap: 10 }}>
                    <Button
                      variant="primary"
                      onClick={handleAddToList}
                      style={{
                        marginRight: 10,
                      }}
                    >
                      Add to List
                    </Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                  </center>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <SelectedTaxModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedOption={selectedOption}
        cartItems={cartItems}
        formData={formData}
      />
    </main>
  );
}
