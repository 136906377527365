import React from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { ButtonGroup } from '@themesberg/react-bootstrap';
import { formatNumber } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Leaderboard = ({ data, title, form, setForm, query_type = '', toggleHistory, setAgentData }) => (
	<div>
		{(query_type.includes('staff') || query_type.includes('agents')) && (
			<Row>
				<Col>
					<ButtonGroup>
						<Button
							color={form?.query_type === 'staff-performance' ? 'primary' : 'light'}
							onClick={() => setForm((p) => ({ ...p, query_type: 'staff-performance' }))}
						>
							View Staff
						</Button>
						<Button
							color={form?.query_type === 'agents-performance' ? 'primary' : 'light'}
							onClick={() => setForm((p) => ({ ...p, query_type: 'agents-performance' }))}
						>
							View Agents
						</Button>
					</ButtonGroup>
				</Col>
				<Col><h3 className='text-center'>{query_type.includes('staff') ? 'STAFF PERFORMANCE' :
					query_type.includes('agents') ? 'AGENTS PERFORMANCE' : title?.toUpperCase()}</h3></Col>
			</Row>
		)}

		<div className="table-responsive">
			<Table>
				<thead>
					<tr>
						<th>RANK</th>
						{query_type.includes('staff') || query_type.includes('agents') ? (
							<>
								<th>KIRMAS ID</th>
								<th>STAFF NAME</th>
								{/* <th>PHONE NO.</th> */}
							</>
						) : (
							<>
								<th>TAX STATION</th>
							</>)}
						<th>COUNT</th>
						<th className='text-center'>TOTAL</th>
						<th className='text-center'>POINTS</th>
						<th className='text-center'>INCENTIVE</th>
					</tr>
				</thead>
				<tbody>
					{data.filter(x => x.amount > 1).map((item, index) => {
						let rowStyle = {};
						let position = 'th';
						if (index === 0) {
							rowStyle = { background: 'rgb(0, 153, 51)', color: 'white' };
							position = 'st';
						} else if (index === 1) {
							rowStyle = { background: 'rgb(102, 204, 102)', color: 'white' };
							position = 'nd';
						} else if (index === 2) {
							rowStyle = { background: 'rgb(153, 255, 153)', color: '#000' };
							position = 'rd';
						}

						const handleRowClick = (item) => {
							// alert(JSON.stringify(item))
							setAgentData({ taxID: item.agent_id });
							toggleHistory();
						};

						return (
							<tr onClick={() => handleRowClick(item)} style={{ ...rowStyle, cursor: 'pointer' }} key={index}>
								<td>{index + 1}{position}</td>
								{query_type.includes('staff') || title.includes('agents') ? (
									<>
										<td>{item.agent_id}</td>
										<td>{item.staff_name}</td>
										<td className='text-center'>{item.count_id}</td>
										<td className='text-right'>{formatNumber(item.amount)}</td>
										<td className='text-center'>
											<div style={{ position: 'relative', display: 'inline-block' }} className='text-center'>
												<FontAwesomeIcon icon={faStar} size="2x" color='yellow' style={{ textShadow: '0.5 0.7 5px white, 0.5 0.7 10px white' }} />
												<span style={{
													position: 'absolute',
													top: '50%',
													left: '50%',
													transform: 'translate(-50%, -50%)',
													color: 'black',
													fontSize: '0.75em',
													fontWeight: 'bold'
												}}>
													{item.points}
												</span>
											</div>
										</td>
										<td className='text-right'>{formatNumber(item.payable_incentive)}</td>
									</>
								) : (
									<>
										<td>{item.tax_station}</td>
										<td className='text-center'>{item.count_id}</td>
										<td className='text-right'>{formatNumber(item.amount)}</td>
										<td className='text-center'>
											<div style={{ position: 'relative', display: 'inline-block' }}>
												<FontAwesomeIcon icon={faStar} size="2x" color='yellow' style={{ textShadow: '0.5 0.7 5px white, 0.5 0.7 10px white' }} />
												<span style={{
													position: 'absolute',
													top: '50%',
													left: '50%',
													transform: 'translate(-50%, -50%)',
													color: 'black',
													fontSize: '0.75em',
													fontWeight: 'bold'
												}}>
													{item.points}
												</span>
											</div>
										</td>
										<td className='text-right'>{formatNumber(item.payable_incentive)}</td>
									</>
								)}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</div>
	</div>
);

export default Leaderboard;
