import moment from "moment";
import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Col,
  Table,
} from "reactstrap";

function RemarkAccordion({ remarks = [] }) {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <Accordion flush open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">File history</AccordionHeader>
          <AccordionBody accordionId="1">
            <div >
              <Table bordered style={{ display: "flex", justifyContent: "center" }}>
                <body>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Remark</th>
                    <th>Remark By</th>
                  </tr>
                  {remarks.map((remark, ind) => (
                    <tr>
                      <td className="text-right">
                        {moment(remark.created_at).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        <Badge>{remark.status?.toLowerCase()}</Badge>
                      </td>
                      <td>{remark.remark||"N/A"}</td>
                      <td>{remark.remark_by}</td>
                    </tr>
                  ))}
                </body>
              </Table>
            </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default RemarkAccordion;
