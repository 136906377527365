import React, { useEffect, useState } from "react";
import { Row, Button } from "@themesberg/react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useQuery } from "../utils";
import { _fetchApi, _postApi } from "../redux/action/api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Card, CardFooter, CardHeader, Col, Table } from "reactstrap";
import { useSelector } from "react-redux";
import { CustomButton } from "../components/UI";

export default ({ refNo = null }) => {
  const q = useQuery();
  const ref_no = refNo ? refNo : q.get("ref_no");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { user } = useSelector((s) => s.auth);
  useEffect(() => {
    if (ref_no) {
      setLoading(true);
      _postApi(
        `/transactions/update-print-count`,
        { query_type: "view-logs", ref_no, sector: user.sector },
        (resp) => {
          setLoading(false);
          if (resp.success && resp.data.length) {
            setList(resp.data);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setList([]);
          setLoading(false);
        }
      );
    }
  }, [ref_no]);

  return (
    <div className="p-0 m-0 h-100 text-center">
      {loading ? (
        <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
          <p>
            <Skeleton count={15} height={50} />
          </p>
        </SkeletonTheme>
      ) : (
        <Card>
          <Col md={2}>
            <Button onClick={() => history.goBack()}>Back</Button>
          </Col>
          <CardHeader>{ref_no} Print log </CardHeader>
          {list.length ? (
            <>
              <Row className="mb-4">
                <p>Item {list[0]?.description}</p>
                <p>Tax payer: {list[0]?.tax_payer}</p>
              </Row>
              <Row className="mb-4">
                <Table className="table-zebra table-responsive" responsive>
                  <thead>
                    <tr>
                      <td>Printed by</td>
                      <td>Printed on</td>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((hist) => (
                      <tr>
                        <td>{hist.printed_by}</td>
                        <td>
                          {moment(hist.printed_at).format(
                            "DD/MM/YYYY hh:mm:ss A"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
              <CardFooter>
                {user.rank === 'Department Head' ? <Button
                  onClick={() =>
                    history.push(`/dashboard/payment-receipt?ref_no=${ref_no}`)
                  }
                  variant="primary"
                  className="me-1 text-white"
                >
                  Reprint
                </Button> : ""}
              </CardFooter>
            </>
          ) : (
            <p>N/A</p>
          )}
        </Card>
      )}
    </div>
  );
};
