import React, { useCallback, useEffect, useState } from "react";
import { Col, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import DaterangeSelector from "./dashboard/DaterangeSelector";
import moment from "moment";
import { _fetchApi, _postApi } from "../redux/action/api";
import { formatNumber, useQuery } from "../utils";
import PrintWrapper from "../components/PrintWrapper";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ReportPDF from "./dashboard/ReportPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import CustomDateRange from "./dashboard/CustomDateRange";
import toast from "react-hot-toast";

export default () => {
  // date picker setup
  const today = moment();
  const query = useQuery();
  const from = query.get("from") || today;
  const to = query.get("to") || today;
  const [form, setForm] = useState({ from, to });
  const [sector, setSector] = useState([]);
  const [mda, setMda] = useState([]);
  const [revenue, setrevenue] = useState([]);
  const [top50, setTop50] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [banklyRevenues, setBanklyRevenues] = useState([]);

  // const handleChangeDate = (updated) => setForm(updated);

  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  const getData = useCallback(
    (cb, query) => {
      setLoading(true);
      _fetchApi(
        `/get-transaction-details?from=${dateRange.from}&to=${dateRange.to}&query_type=${query}`,
        (res) => {
          setLoading(false);
          cb(res.data);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      );
    },
    [dateRange]
  );

  const fetchData = useCallback(() => {
    let data = [];

    _postApi(
      `/transactions/update-print-count`,
      { query_type: "total-sector-reports" },
      (res) => {
        if (res.success) {
          console.log(res.data);
          res.data.forEach((item) => {
            _postApi(
              `/transactions/update-print-count?query_type=total-sector-reports`,
              {
                query_type: "sector-details",
                sector: item.sector_code,
                date_from: dateRange.date_from,
                date_to: dateRange.date_to,
              },
              (resp) => {
                if (resp.success) {
                  data.push({
                    sector: item.categorization,
                    sector_code: item.sector_code,
                    total_revenue: item.total_revenue,
                    arr: resp.data,
                  });
                } else {
                  toast.error(
                    `Failed to fetch sector details for sector ${item.sector_code}`
                  );
                }
              },
              (err) => {
                console.error(err);
                toast.error(
                  `Error fetching sector details for sector ${item.sector_code}`
                );
              }
            );
          });
          setSector(data);
        } else {
          toast.error("Failed to fetch initial data");
        }
      },
      (err) => {
        console.error(err);
        toast.error("Error fetching initial data");
      }
    );
  }, [dateRange.date_from, dateRange.date_to]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    _fetchApi(
      `/get-sector-report-api`,
      (res) => {
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=bankly-reports`,
      (res) => {
        setLoading(false);
        setBanklyRevenues(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );

    // getData(setSector, 'sector');
    getData(setMda, "mda");
    getData(setrevenue, "get_revenue");
    getData(setTop50, "top_50");
  }, [getData]);

  const printReport = () => {
    setDisplay(!display);
  };

  return (
    <div className="card card-body" style={{ zIndex: 1 }}>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="d-block mb-4 mb-md-0">
          <h4>All Payments Reports</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
            <Button variant="outline-primary" size="sm" onClick={printReport}>
              Print
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {/* {JSON.stringify({ sector })} */}
      {display ? (
        <div>
          <div style={{ height: 200 }}>
            <PDFViewer
              style={{
                position: "absolute",
                border: 0,
                height: "200%",
                width: "80%",
                left: 0,
              }}
            >
              <ReportPDF
                sector={sector}
                mda={mda}
                revenue={revenue}
                top50={top50}
                banklyRevenues={banklyRevenues}
              />
            </PDFViewer>
          </div>
        </div>
      ) : (
        <div>
          <CustomDateRange
            type={dateRange.type}
            month={dateRange.month}
            quarter={dateRange.quarter}
            year={dateRange.year}
            from={dateRange.from}
            to={dateRange.to}
            handleChangeDate={handleChangeDate}
          />

          {loading && <Spinner />}
          <center>
            <h4 className="">Sector Reports</h4>
          </center>

          {/* {JSON.stringify(sector)} */}

          {sector?.map((item) => (
            <div className="row m-2">
              <div className="d-flex text-center justify-content-around ">
                <h5 className="">
                  {item.sector}({formatNumber(item.total_revenue)})
                </h5>
              </div>
              {item.arr.map((item) => (
                <>
                  <Col md={4}>{item.mda_name}</Col>
                  <Col md={4} className="text-center">
                    {item.mda_code}
                  </Col>
                  <Col md={4} className="text-right">
                    {formatNumber(item.amount)}
                  </Col>
                </>
              ))}
            </div>
          ))}
          <hr />
          <center>
            <h4 className="">MDA Reports</h4>
          </center>
          {mda?.map((item) => (
            <div className="row m-2">
              <Col md={6}>{item.mda_name}</Col>
              <Col md={4}>{item.sector}</Col>
              <Col md={2} className="text-right">
                {formatNumber(item.total_amt)}
              </Col>
            </div>
          ))}
          <hr />
          <center>
            <h4 className="">Report By Revenue Revenue Clasification</h4>
          </center>
          {revenue?.map((item) => (
            <div className="row m-2">
              <Col md={6}>{item.description}</Col>
              <Col md={3}>{item.rev_code}</Col>
              <Col md={3} className="text-right">
                {formatNumber(item.total_amt)}
              </Col>
            </div>
          ))}

          <hr />
          <center>
            <h4 className="">Report by bank</h4>
          </center>
          {banklyRevenues?.map((item) => (
            <div className="row m-2">
              <Col md={8}>{item.bank_name || "Others"}</Col>
              <Col md={4} className="text-right">
                {formatNumber(item.total)}
              </Col>
            </div>
          ))}
          <hr />
          <center>
            <h4 className="">Top 50 tax payers Reports</h4>
          </center>
          {top50?.map((item) => (
            <div className="row m-2">
              <Col md={6}>{item.tax_payer}</Col>
              <Col md={4}>{item.description}</Col>
              <Col md={2} className="text-right">
                {formatNumber(item.dr)}
              </Col>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
