import React from 'react';
import './arrow-chart.css';

const ArraowChartRevenue = () => {


    return (
        <div className='body'>
            <div class="arrow green">
                <div class="shaft">
                    <div className="label label-right">Right Side</div>
                </div>
                <div className="head-right"></div>
            </div>
            <div className="arrow">
                <div className="head-left"></div>
                <div className="shaft">
                    <div className="label label-left">Left Side</div>
                </div>
            </div>
        </div>
    );
};

export default ArraowChartRevenue;
