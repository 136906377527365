import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap";
import { _fetchApi } from "../../redux/action/api";
import { SearchBar } from "../../components/UI";
import CustomModal from "../../components/UI/CustomModal";
import CustomForm from "../../components/UI/CustomForm";
import CustomTable from "../../components/UI/CustomTable";
import { FaArrowCircleLeft, FaBackward, FaRegEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Button } from "@themesberg/react-bootstrap";
import { useQuery } from "../../utils";
import { Routes } from "../../routes";

const _form = {
    staff_name: "",
    jtb_no: "",
    nin: "",
    staff_id: "",
    phone: "",
    basic_salary: "",
    other_allowance: "",
    NHF: "",
    pension: "",
    life_insurance: "",
    NHIS: "",
    gross: "",
};

const OrgLiabilities = () => {
    const [staff, setStaff] = useState([]);
    const [staff_name, setStaffName] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const q = useQuery()
    const org_kirmas_id = q.get('org_kirmas_id')

    const [form, setForm] = useState(_form);
    const history = useHistory();

    const handleChange = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }));
    };

    useEffect(() => {
        if (org_kirmas_id) {
            _fetchApi(
                `/assessments/org-staff-list?org_kirmas_id=${org_kirmas_id}&max=50&query_type=select`,
                (resp) => {
                    if (resp.success) {
                        setStaff(resp.data);
                    }
                }
            );
        }
    }, [org_kirmas_id]);





    const _form_fields = [
        {
            name: "staff_id",
            label: "Staff ID",
            value: form.staff_id,
        },
        {
            name: "staff_name",
            label: "Staff name",
            value: form.staff_name,
        },

        {
            name: "taxID",
            label: "KIRMAS ID.",
            value: form.phone,
        },
        {
            name: "phone",
            label: "Phone No.",
            value: form.phone,
        },
        {
            name: "nin",
            label: "NIN",
            value: form.nin,
        },
        {
            name: "jtb_no",
            label: "JTB No.",
            value: form.jtb_no,
        },
        {
            name: "pension",
            label: "Pension",
            value: form.pension,
        },
        {
            name: "NHF",
            label: "NHF",
            value: form.NHF,
        },
        {
            name: "NHIS",
            label: "NHIS",
            value: form.NHIS,
        },
        {
            name: "basic_salary",
            label: "Basic Salary",
            value: form.basic_salary,
        },
        {
            name: "gross_salary",
            label: "Gross Salary",
            value: form.gross_salary,
        },
    ];
    const _data = [
        {
            staff_name: "Issa Mustapha",
            staff_id: "BITS-01",

            nin: "176312787128",

            jtb_no: "12908218",

            phone: "0901289128921",

            pension: "10,000,000.00",

            NHF: "20,000.00",

            NHIS: "5,000.00",

            basic_salary: "150,000.00",

            gross_salary: "12,000,000.00",
        },
        {
            staff_name: "Adewale Muritala Akinyemi",
            staff_id: "BITS-02",

            nin: "3312787128",

            jtb_no: "3908218",

            phone: "08036128921",

            pension: "10,000,000.00",

            NHF: "20,000.00",

            NHIS: "5,000.00",

            basic_salary: "150,000.00",

            gross_salary: "12,000,000.00",
        },
    ];
    const new_data = staff_name.length
        ? staff.filter((item) => {
            return item.staff_name.toLowerCase().includes(staff_name.toLowerCase());
        })
        : staff;

    ;
    const containerStyle = {
        // height: "80vh",
        overflowY: "scroll",
        scrollbarWidth: "thin", // For Firefox
        // scrollbarColor: "transparent transparent", // For Firefox
        // overflow: "-moz-scrollbars-none",
        zIndex: 1,
    };
    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col className="text-left  col-md-2 col-sm-3 col-lg-2">
                            <Button onClick={() => history.goBack()}>
                                <FaArrowCircleLeft />
                                Back
                            </Button>
                        </Col>
                        <Col className="text-center jumbothron">
                            <h3>STAFF LIST</h3>
                        </Col>
                        {/* <Col className="text-right col-md-2 col-sm-3 col-lg-2">
                            <Button onClick={() => setShowModal(true)}>Add staff</Button>
                        </Col> */}
                    </Row>
                </CardHeader>
                <CardBody>
                    <Col>
                        <SearchBar
                            onFilterTextChange={(text) => setStaffName(text)}
                            filterText={staff_name}
                            placeholder="Search user"
                        />
                    </Col>
                    <div style={containerStyle}>
                        <div hover className="user-table align-items-center pt-0">
                            <Table
                                className="table-zebra table-bordered"
                                size="sm"
                                style={{ width: "100%", border: "1px solid black" }}
                            >
                                <thead>
                                    <tr>
                                        <th>ACTION</th>
                                        <th>
                                            <center>STAFF ID</center>
                                        </th>
                                        <th>STAFF NAME</th>
                                        <th>KIRMAS ID</th>
                                        <th>PHONE</th>
                                        <th>NIN</th>
                                        <th>JTB No.</th>
                                        {/* <th>PENSION</th>
                                        <th>NHIS</th>
                                        <th>NHF</th>
                                        <th>OTHER ALLAWANCE</th>
                                        <th>GROSS SALARY</th>
                                        <th>BASIC SALARY</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {new_data.map((usr, idx) => (
                                        <tr key={idx}>
                                            {/* {JSON.stringify(usr)} */}
                                            <td><Button
                                                onClick={() => history.push(`${Routes.StaffLedger.path}?org_id=${usr.parent_tax_id}&user_id=${usr.taxID}`)}
                                                // color="dark"
                                                variant="dark"
                                                title="Viiew"
                                                size='sm'
                                            >
                                                <FaRegEye />
                                            </Button></td>
                                            <td>
                                                <center>{idx + 1}</center>
                                            </td>
                                            <td>{usr.name}</td>
                                            <td>{usr.taxID}</td>
                                            <td>{usr.phone}</td>
                                            <td>{usr.nin}</td>
                                            <td>{usr.tin}</td>
                                            {/* <td>{usr.pension}</td>
                                            <td>{usr.NHIS}</td>
                                            <td>{usr.NHF}</td>
                                            <td>{usr.other_allowance}</td>
                                            <td>{usr.basic_salary}</td>
                                            <td>{usr.gross_salary}</td> */}
                                            {/* <td>
                                                <Button
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setForm((p) => ({ ...p, ...usr }));
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
                <CustomModal
                    size="lg"
                    toggle={() => {
                        setShowModal(!showModal);
                    }}
                    isOpen={showModal}
                >
                    <Card>
                        <CardHeader>New Staff Form</CardHeader>
                        <CardBody>
                            <CustomForm fields={_form_fields} handleChange={handleChange} />
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button>Submit</Button>
                        </CardFooter>
                    </Card>
                </CustomModal>
            </Card>
        </div>
    );
};

export default OrgLiabilities;
