import React from "react"
import { Button } from "reactstrap"
import { _postApi } from "../redux/action/api"

export const taxStations = [
    "KIRS Headquarter",
    "Bachirawa",
    "Bompai Road",
    "Brigade",
    "Challawa",
    "City North",
    "City West",
    "Dakata",
    "Dorayi",
    "Dawanau",
    "Fagge I",
    "Fagge II",
    "Farawa",
    "Gyadi-Gyadi",
    "Hotoro",
    "Katsina Road",
    "Kwari",
    "Kuntau",
    "Kwanar Dawaki",
    "Kofar Ruwa",
    "Sabon Gari",
    "Municipal",
    "Nassarawa",
    "Norman’s Land",
    "New Road",
    "Na’ibawa",
    "Rijiyar Zaki",
    "Sharada",
    "Sheka",
    "African Allience",
    "Bichi Sub Tax Station",
    "Gwarzo Sub Tax Station",
]

export default function Abdurahan() {
    const submit = () => {
    
        _postApi("/post-taxstation", { data:taxStations})
}
    return (
        <>
            <Button onClick={submit}>submit</Button>
        </>
    )
}


