import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // Added faDownload
import { Col, Row, Button } from "@themesberg/react-bootstrap";

import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page,
  // StyleSheet,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { useQuery } from "../utils";
import { _fetchApi, apiURL } from "../redux/action/api";
import ReceiptPdf from "../components/ReceiptPdf";
import LandReceiptPdf from "../components/LandReceiptPdf";
import { Alert, Spinner } from "reactstrap";

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     backgroundColor: "#fff",
//     padding: "20px",
//     height: "50",
//     fontSize: "16px",
//     marginBottom: "10px", // Control spacing between pages
//   },
//   pdfViewer: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
// });

export default ({ refNo }) => {
  const q = useQuery();
  const ref_no = refNo || q.get("ref_no");
  const userInfo = useSelector((state) => state.auth.user);
  const [qrcode, setQrCode] = useState(null);
  const [user, setUser] = useState({});
  const [user_id, setUserID] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    if (ref_no) {
      _fetchApi(
        `/transactions/retrieve?query_type=view_invoice&reference_number=${ref_no}&user_id=${userInfo.id}`,
        (resp) => {
          if (resp.success && resp.data && resp.data.length) {
            setloading(false);
            setList(resp.data);
            setUserID(resp.data[0]?.user_id);
          }
        },
        (err) => {
          setloading(false);
          setList([]);
        }
      );
    }
    if (user_id) {
      _fetchApi(
        `/users/get-tax-payer?user_id=${user_id}`,
        (resp) => {
          setloading(false);
          // alert(JSON.stringify(resp.data));
          setUser(resp.data);
        },
        (err) => {
          setUser({});
          setloading(false);
        }
      );
    }
    if (ref_no) {
      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${ref_no}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
          setloading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setloading(false);
        });
    }
  }, [ref_no, user_id]);

  const total = parseFloat(
    list?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.cr;
    }, 0)
  ).toFixed(2);

  // Check if total is not undefined

  const formattedTotal = total.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  // alert(JSON.parse(formattedTotal));

  return (
    <div className="p-0 m-0 h-100 text-center">
      <Row className="mb-4">
        {list.length ? (
          <Col>
            <PDFDownloadLink
              document={
                list[0]?.sector === "LAND" ? (
                  <LandReceiptPdf
                    user={user}
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    qrcode={qrcode}
                    total={total}
                  />
                ) : (
                  <ReceiptPdf
                    user={user}
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    qrcode={qrcode}
                    total={total}
                  />
                )
              }
              fileName="invoice.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  `Loading document...`
                ) : (
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    Download PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          </Col>
        ) : (
          <center>
            <Alert>No Data Found, please try again later!!!</Alert>
          </center>
        )}
      </Row>

      {/* {JSON.stringify(loading)} */}
      {/* {JSON.stringify(
        list
          ?.filter((item) => item.cr > 0)
          .map((l) => ({
            ...l,
            tax_fee: l.cr,
          }))
      )} */}
      <div
        style={{
          height: "99vh",
          overflowY: "scroll",
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "transparent transparent", // For Firefox
          overflow: "-moz-scrollbars-none",
          zIndex: 1,
        }}
      >
        <PDFViewer
          style={{ width: "100%", height: "800px", pageBreakInside: "avoid" }}
        >
          <Document>
            {Array.from({ length: 1 }).map((_, index) => (
              <Page
                wrap={false}
                key={index}
                size="A4"
                style={{ pageBreakInside: "avoid" }}
              >
                {list[0]?.sector === "LAND" ? (
                  <LandReceiptPdf
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    user={user}
                    qrcode={qrcode}
                    total={total}
                    label={
                      index === 1
                        ? "DUPLICATE"
                        : index === 2
                        ? "TRIPLICATE"
                        : "ORIGINAL"
                    }
                    copyNumber={index + 1}
                  />
                ) : (
                  <ReceiptPdf
                    data={list
                      ?.filter((item) => item.cr > 0)
                      .map((l) => ({
                        ...l,
                        tax_fee: l.cr,
                      }))}
                    user={user}
                    qrcode={qrcode}
                    total={total}
                    label={
                      index === 1
                        ? "DUPLICATE"
                        : index === 2
                        ? "TRIPLICATE"
                        : "ORIGINAL"
                    }
                    copyNumber={index + 1}
                  />
                )}
              </Page>
            ))}
          </Document>
        </PDFViewer>
      </div>
    </div>
  );
};
