import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useCallback } from "react";
import { Modal, ModalBody, Table } from "reactstrap";
import { Input } from "react-bootstrap-typeahead";
import { SearchBar } from "../../components/UI";
import { toast } from "react-hot-toast";
// import NewTaxForm from "../tax-mngr/NewTaxForm";
import TaxCodeFormModal from "./TaxCodeFormModal";


export default function TaxCodeManager() {
    const _form = {
        economic_code: "",
        title: "",
        sector: "TAX"
    };

    const [form, setForm] = useState(_form);
    const [taxHeads, setTaxHeads] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editingRows, setEditingRows] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const handleChanges = ({ target: { name, value } }) => {
        if (name === "sector") {
            setSelectedRows([]);
            setEditingRows(false);
        }
        setForm((p) => ({ ...p, [name]: value }));
    };

    const getTaxHeads = useCallback(() => {
        _fetchApi(
            `/kigra-taxes?query_type=select-sector-taxes&sector=${form.sector}`,
            (resp) => {
                if (resp.success) {
                    setTaxHeads(resp.result);
                }
            },
            () => {
                setTaxHeads([]);
            }
        );
    }, [form.sector]);



    const handleEditClick = (tax) => {
        setForm(tax)
    };


    useEffect(() => {
        // getSubHeads();
        getTaxHeads();
    }, [getTaxHeads]);

    const handleSubmit = () => {
        _postApi(
            "/kigra-taxes?query_type=update-taxes",
            selectedRows.map((item) => ({
                ...taxHeads.filter((tax) => tax.id === item.id)[0],
                query_type: "update-tax",
            })),
            () => {
                toast.success("Submited");
                setEditingRows(false);
                setSelectedRows([]);
            },
            (err) => {
                toast.error("Failed to submit");
                console.error(err);
            }
        );
    };
    const FilteredTaxHeads =
        taxHeads?.length && filterText.length
            ? taxHeads.filter(
                (item) =>
                    (item.title &&
                        item.title.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.tax_parent_code &&
                        item.tax_parent_code
                            .toLowerCase()
                            .includes(filterText.toLowerCase())) ||
                    (item.tax_code &&
                        item.tax_code.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.mda_name &&
                        item.mda_name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.economic_code &&
                        item.economic_code.includes(filterText))
            )
            : taxHeads;
    const list = FilteredTaxHeads.filter(x => x.tax_fee >= 0 || x.tax_fee === 0.0)

    const getTaxTitle = (tax) => {
        switch (tax) {
            case 'TAX':
                return 'Tax Revenue'
            case 'NON TAX':
                return 'Non Tax fees'
            case 'LAND':
                return 'Land Use Charges'
            default:
                return 'Tax Revenue'
        }
    }
    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            {/* {JSON.stringify(selectedRows)} */}
            <Card.Header>
                <h5 className="mb-4 text-center">{getTaxTitle(form.sector)} Code Mapping</h5>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col md={3} className="mb-3">
                            <Form.Group id="firstName">
                                {/* <Form.Label>Tax category</Form.Label> */}
                                <Form.Select
                                    required
                                    value={form.sector}
                                    name="sector"
                                    type="select"
                                    onChange={handleChanges}
                                    placeholder="Select tax category"
                                >
                                    <option value="">Select tax category</option>
                                    <option value="TAX">Tax</option>
                                    <option value={"NON TAX"}>Non Tax</option>
                                    {/* <option value={"VEHICLES"}>Vehicles tax</option> */}
                                    <option value={"LAND"}>Land use charges</option>
                                    {/* <option value={"LGA"}>LGA Revenue</option> */}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={6} className="mb-3">
                            <SearchBar
                                onFilterTextChange={(val) => setFilterText(val)}
                                filterText={filterText}
                            />
                        </Col>
                    </Row>

                </Form>
                <Row
                    style={{
                        height: "65vh",
                        overflowY: "scroll",
                        scrollbarWidth: "thin", // For Firefox
                        scrollbarColor: "transparent transparent", // For Firefox
                        // Webkit scrollbar styles
                        scrollbarWidth: "thin",
                        scrollbarColor: "transparent transparent",
                        overflow: "-moz-scrollbars-none",
                    }}
                >
                    <Col md={12}>
                        <table className="table-zebra" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "30%" }}>Tax Head</th>
                                    <th width="10%">Economic code</th>
                                    <th width="30%">MDA Name</th>
                                    <th width="10%">Tax Fee</th>
                                    <th width="10%">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {list.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>
                                            {item.title}
                                        </td>

                                        <td>
                                            {item.economic_code}
                                        </td>
                                        <td>
                                            {item.mda_name}
                                        </td>
                                        <td>
                                            {item.tax_fee}
                                        </td>
                                        <td>
                                            <Button onClick={() => {
                                                handleEditClick(item);
                                                setModal(true)
                                            }}>Edit</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Card.Body>

            <Modal
                isOpen={modal}
                toggle={toggle}
                size="lg"
                style={
                    {
                        // mar
                    }
                }
            >
                <ModalBody>
                    <TaxCodeFormModal _form={form} _getTaxHeads={getTaxHeads} />
                </ModalBody>
                {/* <Col md={12} style={{marginBottom: 20}}>
                <center>
                  <Button
                    color="secondary" 
                    onClick={toggle}
                    style={{
                      width: '30%'
                    }}
                  >
                    Submit
                  </Button>
                </center>
              </Col> */}
            </Modal>
        </Card>
    );
}
