import React from 'react';
import { Document, Page, PDFViewer, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import leftLogoSrc from '../../assets/img/kigra.jpg';
import rightLogoSrc from '../../assets/img/kano-govt.png';

// Define the styles for the document
const styles = StyleSheet.create({
  body: {
    padding: 50,
    backgroundColor: '#fff',
    fontSize: 12, // Set a default font size for the entire document
    lineHeight: 1.5, // Set a default line height for the entire document
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  logo: {
    width: 50,
    height: 50,
  },
  titleContainer: {
    textAlign: 'center',
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 13,
    color: '#777',
  },
  section: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  footer: {
    fontSize: 10,
    marginTop: 30,
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    paddingTop: 10,
    textAlign: 'center',
  },
});

// Create Document Component
const MyDocument = ({
  taxAdminName,
  taxStation,
  adminEmailAddress,
  adminPhoneNumber,
  demandDate,
  taxPayerName,
  taxPayersAddress,
  lga,
  amount,
  dueDate,
  paymentInstructions,
  taxAdminPhoneNumber,
  taxAdminEmailAddress,
}) => (
  <PDFViewer style={{ width: '100%', height: '96vh' }}>
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header}>
          <Image src={leftLogoSrc} style={styles.logo} />
          <View style={styles.titleContainer}>
            <Text style={styles.title}>KANO INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM (KIRMAS)</Text>
            <Text style={styles.subtitle}>MDA: Kano State Internal Revenue Service (KIRS)</Text>
          </View>
          <Image src={rightLogoSrc} style={styles.logo} />
        </View>

        <View style={styles.section}>
          <Text>[Tax Admin Name]</Text>
          <Text>[Tax Station]</Text>
          <Text>[Kano, State]</Text>
          <Text>[Admin Email Address]</Text>
          <Text>[Admin Phone Number]</Text>
          <Text>[Demand Date]</Text>
          <Text>[Tax Payer Name]</Text>
          <Text>[Tax Payer’s Address]</Text>
          <Text>[LGA, State]</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.bold}>{taxPayerName}</Text>
          <Text>{taxPayersAddress}</Text>
          <Text>{lga}, Kano State</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.bold}>Subject:</Text> <Text>Demand Notice for Tax Payment - 2024 Personal Income Tax (PAYE)</Text>
        </View>

        <View style={styles.section}>
          <Text>Dear {taxPayerName},</Text>
          <Text>This letter serves as a formal demand notice for the payment of taxes owed to Kano State Internal Revenue Service (KIRS) for the year 2024. As per our records, you have an outstanding tax liability of NGN {amount}.</Text>
          <Text>Please find the details of the tax demand below:</Text>
          <Text>- Tax Year: 2024</Text>
          <Text>- Tax Type: Personal Income Tax (PAYE)</Text>
          <Text>- Amount Due: NGN {amount}</Text>
          <Text>- Due Date: {dueDate}</Text>
          <Text>You are required to settle this tax demand within 30 days from the date of this notice. Failure to comply may result in additional penalties and interest.</Text>
          <Text>To avoid any further action, please settle the tax demand by {paymentInstructions}.</Text>
          <Text>If you have already paid the tax demand or have any disputes, please contact us at {taxAdminPhoneNumber} or {taxAdminEmailAddress} to avoid any unnecessary action.</Text>
        </View>

        <View style={styles.section}>
          <Text>Yours Sincerely,</Text>
          <Text>{taxAdminName}</Text>
          <Text>Tax Administrator</Text>
          <Text>MDA Kano State Internal Revenue Service (KIRS)</Text>
        </View>

        <View style={styles.footer}>
          <Text>Thank you for your prompt attention to this matter.</Text>
        </View>
      </Page>
    </Document>
  </PDFViewer>
);

export default MyDocument;
