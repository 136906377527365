import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "../../routes";
import { CustomButton } from "../../components/UI";
import { _fetchApi } from "../../redux/action/api";
import toast from "react-hot-toast";
import { RECOVER_USER } from "../../redux/action/type";
import { useDispatch } from "react-redux";

export default () => {
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    _fetchApi(
      `/user/forgot-password?phone=${phone}`,
      (resp) => {
        if (resp.success) {
          toast.success(resp.message);
          history.push(Routes.RecoverCode.path);
          dispatch({ type: RECOVER_USER, payload: resp.results });
          setLoading(false);
        } else {
          toast.success(resp.message);
          setLoading(false);
        }
      },
      (error) => {
        toast.success(error);
        setLoading(false);
      }
    );
  };
  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">
                  Don't fret! Just type in your phone number and we will send
                  you a code to reset your password! 
                </p>
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Phone Number</Form.Label>
                    <InputGroup id="email">
                      <Form.Control
                        required
                        autoFocus
                        type="phone"
                        placeholder=""
                        value={phone}
                        onChange={({ target: { value } }) => {
                          setPhone(value);
                        }}
                      />
                    </InputGroup>
                  </div>
                  <CustomButton
                    loading={loading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    Recover password
                  </CustomButton>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
