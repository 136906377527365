import React, { useEffect, useState } from "react"
import { Badge, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import { _fetchApi } from "../../redux/action/api"
import CustomTypeahead from "../../components/UI/CustomTypeahead"
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes"
import moment from "moment"
import toast from "react-hot-toast"
import { toCurrency, useQuery } from "../../utils"
const today = moment().format("YYYY-MM-DD");

const _form = {
    from: moment().format("YYYY") + '-01-01',
    to: today,
}

const StaffLedger = () => {
    const [assesments, setAssessments] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [org_name, setOrgName] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(_form)
    const q = useQuery()
    const org_id = q.get('org_id')
    const user_id = q.get('user_id')

    const [data_status, setDataStatus] = useState(null);
    const [trx, setTrx] = useState([]);

    const handleChange = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }))
    }
    const trx_new = trx.filter(x => x.description === 'Pay As You earn')
    // useEffect(() => {
    // if (org_name) {
    //     _fetchApi(
    //         `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=org`,
    //         (resp) => {
    //             if (resp.success) {
    //                 setOrgs(resp.data);
    //             } else {
    //                 setDataStatus('Record not found')
    //             }
    // })
    //         }
    // }, []);

    // useEffect(() => {
    //     if (form.taxID) {
    //         _fetchApi(
    //             `/assessments/paye-list?org_id=${form.taxID}&year=${moment().format('YYYY')}&query_type=annual-tax`,
    //             (resp) => {
    //                 if (resp.success && resp.data.length) {
    //                     setDataStatus(null)
    //                     setAssessments(resp.data);
    //                 } else {
    //                     setDataStatus('Record not found!')
    //                 }
    //             })
    //     }
    // }, [form.taxID])

    useEffect(() => {
        setLoading(true);
        _fetchApi(
            `/assessments/paye-list?query_type=select-ledger&taxID=${user_id}&org_id=${org_id}&start_date=${form.from}&end_date=${form.to}`,
            (resp) => {
                if (resp.data.length) {
                    setTrx(resp.data);
                } else {
                    setDataStatus('Record not found')
                }
            },
            (err) => {
                console.error(err);
                setDataStatus('Record not found')
                toast.error("Record not found")
                setTrx([]);
            })
    }, [org_id, form]);



    useEffect(() => {
        if (org_name.length > 2) {
            _fetchApi(
                `/search-tax-payer-by-all?user_id=${org_name}&max=50&type=org`,
                (resp) => {
                    if (resp.success) {

                        setOrgs(resp.data);
                    }
                }
            )
        }
    },
        [org_name]
    );
    const containerStyle = {
        height: "80vh",
        overflowY: "scroll",
        scrollbarWidth: "thin", // For Firefox
        scrollbarColor: "transparent transparent", // For Firefox
        overflow: "-moz-scrollbars-none",
        zIndex: 1,
    };
    const new_data = org_name.length ? assesments.filter(item => { return item.tax_payer.toLowerCase().includes(org_name.toLowerCase()) || item.office_address.toLowerCase().includes(org_name.toLowerCase()) || item.phone.includes(org_name) }) : assesments
    return <div>
        <Card>
            <CardHeader>
                {/* <Row>
                    {/* <Col className="text-right mt-2 col-md-3 col-sm-3 col-lg-3" >Search Organization</Col> *}
                    <Col className="col-md-9 col-sm-10 col-lg-7">
                        <CustomTypeahead
                            onInputChange={(e) => {
                                setOrgName(e);
                            }}
                            labelKey={(e) =>
                                `${e.account_type === "org" ? e.org_name : e.name}(${e.taxID
                                }) ${e.phone ? `:${e.phone || e.office_phone}` : ""}`
                            }
                            className="border border-primary rounded"
                            onChange={(e) => {
                                if (e && e[0]?.id) {
                                    setForm((p) => ({
                                        ...p,
                                        taxID: e[0]?.taxID,
                                        ...e
                                    }));
                                }
                            }}
                            clearButton
                            col={12}
                            // label={`Select Organization`}
                            options={orgs}
                            placeholder="Search Org. Name | TaxID | Phone No."
                        />
                    </Col>
                    {/* <Col className="text-right col-md-2 col-sm-2 col-lg-2" md={3}><Button onClick={() => setShowModal(true)}>Add staff</Button></Col> *}
                </Row> */}
            </CardHeader>
            <CardBody>
                <div style={containerStyle}>
                    <div hover className="user-table align-items-center pt-0">
                        <p><b>STAFF NAME:</b> <em>{trx[0]?.staff_name}</em></p>
                        <p><b>TAX PAID:</b> <em>{toCurrency(trx[0]?.total_paid || 0)}</em></p>
                        <p><b>BALANCE:</b> <em>₦{toCurrency(trx[0]?.payable || 0)}</em></p>
                        <Table
                            className="table-zebra table-bordered"
                            size="sm"
                            style={{ width: "100%", border: "1px solid black" }}
                        >
                            <thead>
                                <tr>
                                    <th>DATE</th>
                                    <th>ORG. NAME</th>
                                    {/* <th>REF NO.</th> */}
                                    <th>TAX DESCRIPTION</th>
                                    <th>AMOUNT (₦)</th>
                                    <th>STATUS</th>
                                </tr>
                            </thead>
                            {trx_new.length && !data_status ? <tbody>
                                {trx_new.map((tax, idx) => <tr key={idx}>
                                    <td>{tax.status === 'saved' ? moment(tax.created_at).format('DD/MM/YYYY') : moment(tax.paymentdate).format('DD/MM/YYYY')}</td>

                                    <td>{tax.org_name}</td>
                                    <td>{tax.description}</td>
                                    <td>{toCurrency(tax.amount_paid)}</td>
                                    <td className="text-center">
                                        <Badge color={tax.status === 'pending' ? 'danger' : 'primary'}>{tax.status === 'pending' ? 'Pending' : 'Paid'}</Badge>
                                    </td>
                                </tr>)}
                            </tbody> :
                                <h3 className="text-danger text-center">{data_status}</h3>}
                        </Table>
                    </div>
                </div>
            </CardBody>
        </Card>
    </div>
}

export default StaffLedger