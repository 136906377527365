import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
import { formatNumber } from "../utils";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingHorizontal: 30,
    fontFamily: "DM_SANS",
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 15,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "black",
  },
  leftColumn: {
    flex: 1,
    padding: 8,
    textAlign: "left",
    fontSize: 20,
    width: "40%",
  },
  rightColumn: {
    // flex: 1,
    padding: 10,
    textAlign: "left",
    fontSize: 17,
    width: "60%",
  },
  leftColumn1: {
    flex: 1,
    padding: 8,
    textAlign: "left",
    fontSize: 17,
    //   borderWidth: 1,
    // width:'40%'
  },
  rightColumn1: {
    flex: 1,
    padding: 10,
    textAlign: "left",
    fontSize: 15,
    //   borderWidth: 1,
    // width:'60%'
  },
  table: {
    margin: "auto",
    width: "100%",
    border: "1px solid #000",
    fontSize: 12,
    textTransform: "uppercase",
    marginTop: 0,
    // marginLeft: 50,
    flexDirection: "row",
    marginBottom: 0,
  },
  table1: {
    margin: "auto",
    width: "100%",
    border: "1px solid #000",
    fontSize: 12,
    textTransform: "uppercase",
    marginTop: 0,
    // marginLeft: 50,
    flexDirection: "row",
    marginBottom: 0,
  },
});

export const LandAccountantInvoice = ({ data = [] }) => {
  const descriptions = data?.map((item) => item?.description);
  const joinedDescriptions = descriptions?.join(", ");
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.title}>MINISTRY OF LAND AND PHYSICAL PLANNING</Text>
        <Text style={styles.subTitle}>SCHEDULE OF PAYMENT</Text>
        <Text style={styles.sectionTitle}>ACCOUNTANT:</Text>
        <Text style={{ marginTop: 10, marginBottom: 12 }}>
          Please receive payment as per details below
        </Text>
        <View style={styles.table}>
          <View style={{ width: "50%", borderRight: "1px solid #000" }}>
            <Text
              style={{
                borderBottom: "1px solid #000",
                padding: 10,
                width: "100%",
              }}
            >
              NAME
            </Text>
            <Text
              style={{
                borderBottom: "1px solid #000",
                padding: 10,
                width: "100%",
              }}
            >
              File Number
            </Text>
            <Text
              style={{
                borderBottom: "1px solid #000",
                padding: 10,
                width: "100%",
              }}
            >
              Purpose Of Payment
            </Text>
            <Text style={{ padding: 10, width: "100%" }}>Amount</Text>
          </View>
          <View
            style={{
              width: "50%",
              textAlign: "left",
              borderRight: "1px solid #000",
              fontWeight: "bold",
            }}
          >
            <Text
              style={{
                borderBottom: "1px solid #000",
                padding: 10,
                width: "100%",
              }}
            >
              {data[0]?.tax_payer}
            </Text>
            <Text
              style={{
                borderBottom: "1px solid #000",
                padding: 10,
                width: "100%",
              }}
            >
              {data[0]?.mda_val}
            </Text>
            <Text
              style={{
                borderBottom: "1px solid #000",
                padding: 10,
                width: "100%",
              }}
            >
              {joinedDescriptions}
            </Text>
            <Text style={{ padding: 10, width: "100%" }}>
              {formatNumber(data[0].tax_fee)}
            </Text>
          </View>
        </View>
        <View
          style={{
            borderLeft: 1,
            borderRight: 1,
            padding: 3,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Text>BANK/ACCOUNT NUMBER (TICK WHERE APPROPRIATE)</Text>
        </View>
        <View style={styles.table1}>
          <View style={{ width: "50%", borderRight: "1px solid #000" }}>
            <Text style={{ padding: 10, width: "100%" }}>
              1. LAND RELATED CHARGES
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              textAlign: "left",
              borderRight: "1px solid #000",
              fontWeight: "bold",
            }}
          >
            <View style={{ flexDirection: "row", padding: 10 }}>
              <Text
                style={{ border: "1px solid #000", width: 40, marginRight: 5 }}
              >
                {" "}
              </Text>
              <Text style={{ width: "100%" }}>JAIZ BANK 0001876653</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: "30" }}>
          <View style={{ width: "50%" }}>
            <Text>Names: ..............................................</Text>
          </View>

          <View>
            <Text>Rank: ..............................................</Text>
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: "30" }}>
          <View style={{ width: "50%" }}>
            <Text>Signature: ........................................</Text>
          </View>

          <View>
            <Text>Date: ..............................................</Text>
          </View>
        </View>

        <Text style={{ marginTop: "40", fontSize: 16 }}>
          Forwading file is subject to the submission of the photocopy of Bank
          Teller to the schedule officer for filling.
        </Text>
        <View style={{ flexDirection: "row", marginTop: "30" }}>
          <View style={{ width: "50%" }}>
            <Text>Teller No: ...............................</Text>
          </View>

          <View>
            <Text>Date Of Payment: ...............................</Text>
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: "40" }}>
          <View style={{ width: "50%" }}>
            <Text>
              ............................................................
            </Text>
            <Text style={{ textAlign: "center" }}>Director Deeds</Text>
          </View>

          <View>
            <Text>
              ............................................................
            </Text>
            <Text style={{ textAlign: "center", marginLeft: 30 }}>
              Permanent Secretary
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
