import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap'
import CustomTypeahead from '../../components/UI/CustomTypeahead'
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { TAX_ACCOUNT } from "../../redux/action/type";
import { _fetchApi, _postApi } from '../../redux/action/api';
import moment from 'moment';
import { CustomButton } from '../../components/UI';


const DemandForm = () => {
    const { tax_account, tax_accounts = [], user } = useSelector((s) => s.auth);
    const today = moment().format('YYYY-MM-DD')
    const _form = {
        query_type: "CREATE",
        mda_name: user.mda_name||"Kano State Internal Revenue Service",
        taxID: tax_account.taxID,
        ref_no: null,
        mda_code: user.mda_code||'022000800100',
        tax_station: user.tax_station,
        staff_kirmas_id: user.taxID,
        staff_name: user.name,
        staff_phone: user.phone,
        staff_email: user.email,
        demand_date: today,
        tax_payer_name: tax_account.account_type === 'org' ? tax_account.org_name : tax_account.name,
        tax_payer_address: tax_account.account_type === 'org' ? tax_account.office_address : tax_account.address,
        tax_payer_state: tax_account.state,
        tax_payer_lga: tax_account.lga,
        tax_year: 2024,
        tax_type: "",
        economic_code: "",
        amount: 0,
        from_date: today,
        to_date: today,
        due_date: today,
        sector:user.sector||'TAX',
        payment_instructions: 'You are required to settle this tax demand within [30 days] from the date of this notice. Failure to comply may result in additional penalties and interest.',
        comment: 'If you have already paid the tax demand or have any disputes, please contact us via Email or Phone Number Above, to avoid any unnecessary action.'
    }
    const [tax_payer, setTapayer] = useState("");
    const dispatch = useDispatch();
    const [tax_payers, setTaxPayers] = useState([]);
    const myRef = useRef(null);
    const [showDefault, setShowDefault] = useState(false);
    const toggleModal = () => setShowDefault(!showDefault);
    const [mainTaxes, setMainTaxes] = useState([]);
    const [form, setForm] = useState(_form)
    const [loading,setLoading] = useState(false)
    const handleChange = ({ target: { name, value } }) => {
        setForm((s) => ({ ...s, [name]: value }))
    }

    // const handleFocus = (name) => {
    //     setTapayer(name);
    //     setTimeout(() => {
    //         toast.success("You can now close and search the tax payer by name or ID");
    //         toggleModal();
    //     }, 2000);
    // };

    const getTaxPayers = useCallback(() => {
        if (tax_payer.length > 2) {
            _fetchApi(
                `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
                (resp) => {
                    if (resp.success) {
                        setTaxPayers(resp.data);
                    }
                }
            );
        } else if (tax_payer.length === 0) {
            _fetchApi(
                `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
                (resp) => {
                    if (resp.success) {
                        setTaxPayers(resp.data);
                    }
                }
            );
        }
    }, [tax_payer]);

    useEffect(() => {
        getTaxPayers();
    }, [getTaxPayers]);


    const getList = useCallback(() => {
        setLoading(true);
        _fetchApi(
            `/kigra-taxes?query_type=mda-taxes&mda_code=${form.mda_code}&sector=${form.sector}`,
            (resp) => {
                setMainTaxes(resp.result);
                setLoading(false);
            },
            (err) => {
                console.log(err);
                setMainTaxes([]);
                setLoading(false);
            }
        );
    }, [user.mda_code]);
    useEffect(() => {
        getList();
    }, [getList]);

    const handleSubmit = () =>{
        setLoading(true)
        _postApi('/demand-notice',
            form,
            (resp)=>{
                setLoading(false)
                    if(resp.success){
                        toast.success('Submitted')
                    }
            },
            (err)=>{
                console.error(err);
                setLoading(false)
                toast.error('Failed to Submit')
            }
        )
    }

    return (
        <Card>
            <CardHeader style={{ textAlign: 'center' }}>
                <h3>Kano State InternalRevenue Service</h3>
                <h4>Demand Notice</h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={4}>
                        <Label>Date From</Label>
                        <Input
                            type='date'
                            value={form.from_date}
                            name='from_date'
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <Label>Date To</Label>
                        <Input
                            type='date'
                            name='to_date'
                            value={form.to_date}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <Label>Due Date</Label>
                        <Input
                            type='date'
                            value={form.due_date}
                            name='due_date'
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col md={6}>
                        {/* <Label>Tax Payer</Label> */}
                        <CustomTypeahead
                            onInputChange={(e) => {
                                // setTapayer(e);
                                console.log(e, "TYPED");
                            }}
                            _ref={myRef}
                            labelKey={(e) =>
                                `${e.account_type === "org" ? e.org_name : e.name
                                }(${e.taxID}) ${e.phone ? `:${e.phone}` : ""}`
                            }
                            onChange={(e) => {
                                if (e && e[0]?.id) {
                                    dispatch({
                                        type: TAX_ACCOUNT,
                                        payload: e[0],
                                    });
                                    setForm((p)=>({...p, taxID:e[0]?.taxID, tax_payer_name : e[0]?.account_type==='org'? e[0]?.org_name: e[0]?.name}))
                                }
                            }}
                            // onInputChange={(e) => {
                            //   setTapayer(e);
                            // }}
                            clearButton
                            col={12}
                            label={`Select Tax payer`}
                            options={tax_payers}
                            placeholder="Search Name | TaxID | Phone No."
                        />
                    </Col>
                    <Col md={6}>
                        <CustomTypeahead
                            onInputChange={(e) => {
                                setForm((p)=>({...p, tax_type:'', economic_code:''}));
                                console.log(e, "TYPED");
                            }}
                            labelKey={(e) =>
                                `${e.title} ${e.economic_code}`
                            }
                            onChange={(e) => {
                                if (e && e[0]?.id) {
                                    
                                setForm((p)=>({...p, 
                                    tax_type:e.title, 
                                    economic_code:e.economic_code}));
                                }
                            }}
                            clearButton
                            col={12}
                            label={`Select Tax Type`}
                            options={mainTaxes}
                            placeholder="Tax Head | Economic Code "
                        />
                    </Col>
                    <Col md={6}>
                        <Label>Amount(N)</Label>
                        <Input
                        onChange={handleChange}
                            type='number'
                            name='amount'
                            value={form.amount}
                            placeholder='Amount'
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col md={6}>
                        <Label>payment instruction</Label>
                        <Input
                            style={{ paddingTop: '0', height: '150px' }}
                            name="payment_instructions"
                            value={form.payment_instructions}
                            type="textarea"
                        />
                    </Col>
                    <Col md={6}>
                        <Label>Comment</Label>
                        <Input
                            style={{
                                paddingTop: '0', height: '150px'
                            }}
                            name="comment"
                            type="textarea"
                            value={form.comment}
                        />
                    </Col>
                </Row>
                <Container className='d-flex justify-content-end pt-3'>
                    <CustomButton loading={loading} variant="primary" onClick={handleSubmit} type="submit">Submit</CustomButton>
                </Container>
            </CardBody>
        </Card>
    )
}

export default DemandForm
