import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { FaEdit } from "react-icons/fa";
import toast from "react-hot-toast";
import { _postApi, _fetchApi } from "../../../redux/action/api";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import { taxStations } from "../../../utils/tax-stations";
import { Card } from "@themesberg/react-bootstrap";
import { formatNumber } from "../../../utils";
import CustomTypeahead from "../../../components/UI/CustomTypeahead";

const TargetSystem = () => {
  const _form = {
    selectedMonth: moment().format("MMMM"),
    selectedYear: moment().year(),
    target: "",
    tax_station: "",
    generalTarget: "",
  };

  const [budgeted, setBudgeted] = useState({});
  const [date, setDate] = useState(moment().format("YYYY-MM"));
  const [generalTarget, setGeneralTarget] = useState("");
  const [taxStation, setTaxStation] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [generateModal, setGenerateModal] = useState(false);
  const [budgetedAmount, setBudgetedAmount] = useState(0);

  // State to hold form data for selected month and year
  const [form, setForm] = useState(_form);

  useEffect(() => {
    calculateBudgetedAmount();
  }, [list, form.selectedMonth, form.selectedYear]);

  // const fetchData = () => {
  //   _postApi('/revenue/targets',
  //     { query_type: 'select' },
  //     (response) => {
  //       console.log('Data fetched:', response);
  //       setList(Array.isArray(response.data) ? response.data : []);
  //     },
  //     (error) => {
  //       console.error('Error fetching data:', error);
  //       toast.error('Error fetching data');
  //     },
  //     () => {
  //       console.log('Empty response');
  //       setList([]);
  //     }
  //   );
  // }

  const getData = (selectedMonth, selectedYear) => {
    if (selectedMonth && selectedYear) {
      setLoading(true);
      _postApi(
        "/revenue/targets",
        {
          month: selectedMonth,
          year: selectedYear,
          query_type: "select-others",
        },
        (response) => {
          setLoading(false);
          if (response.success && response.data.length) {
            console.log("Data fetched:", response);
            setList(response.data);
            setBudgeted(response.data.find((x) => x.tax_station === "General"));
          } else {
            setList([]);
          }
        },
        (error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
          setList([]);
          toast.error("Error fetching data");
        },
        () => {
          console.log("Empty response");
          setList([]);
        }
      );
    }
  };
  ///fghjhhkjnhj

  const nformatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const addToList = () => {
    if (taxStation && form.target) {
      const targetValue = form.target?.replace(/,/g, "");
      const generalTargetValue = parseFloat(generalTarget?.replace(/,/g, ""));
      const newItem = {
        new: true,
        target: targetValue,
        percentage: ((targetValue / generalTargetValue) * 100).toFixed(2),
        month: form.selectedMonth,
        year: form.selectedYear,
        tax_station: form.tax_station,
      };
      setList([...list, newItem]);
      setForm(_form);
    }
  };

  const handleSubmit = () => {
    const formattedList = list
      .filter((x) => x.new)
      .map((item) => ({
        tax_station: item.tax_station,
        target: parseFloat(item.target?.replace(/,/g, "")),
        percentage: parseFloat(item.percentage?.replace("%", "")),
        month: item.month,
        year: item.year,
      }));

    _postApi(
      "/revenue/targets",
      formattedList.map((x) => ({ ...x, query_type: "insert" })),
      (res) => {
        console.log("Items submitted:", res.data);
        setList([]);
        setGeneralTarget("");
        toast.success("Submitted");
        getData(form.selectedMonth, form.selectedYear);
      },
      (err) => {
        console.error("Error submitting items:", err);
        toast.error("Error submitting items");
      }
    );
  };

  const toggleModal = () => setModal(!modal);
  const toggleGenerateModal = () => setGenerateModal(!generateModal);

  const handleEdit = (index) => {
    setEditItem({ ...list[index], index });
    toggleModal();
  };

  const handleSaveEdit = () => {
    const updatedList = list.slice();
    const targetValue = parseFloat(editItem.target?.replace(/,/g, ""));
    const generalTargetValue = parseFloat(generalTarget?.replace(/,/g, ""));

    updatedList[editItem.index] = {
      ...editItem,
      target: nformatNumber(targetValue),
      percentage: ((targetValue / generalTargetValue) * 100).toFixed(2) + "%",
    };

    setList(updatedList);
    getData(form.selectedMonth, form.selectedYear);
    toggleModal();
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveGenerate = () => {
    const payload = {
      target: form.generalTarget?.replace(/,/g, ""),
      tax_station: "General",
      month: form.selectedMonth,
      year: form.selectedYear,
    };

    _postApi(
      "/revenue/targets",
      { query_type: "save", ...payload },
      (res) => {
        console.log("Target generated:", res.data);
        toast.success("Target generated");
        toggleGenerateModal();
        getData(form.selectedMonth, form.selectedYear);
      },
      (err) => {
        console.error("Error generating target:", err);
        toast.error("Error generating target");
      }
    );
  };

  const handleMonthChange = (selectedMonth) => {
    const selectedYear = moment().year();
    const monthName = moment(selectedMonth, "MM").format("MMMM");
    const dateValue = `${selectedYear}-${selectedMonth}`;

    setForm({
      selectedMonth: monthName,
      selectedYear: selectedYear,
    });
    setDate(dateValue);
    getData(monthName, selectedYear);
  };

  const currentMonth = moment().format("MM");

  const calculateBudgetedAmount = () => {
    const generalTargetItems = list.filter(
      (item) => item.tax_station === "General"
    );
    const totalBudgetedAmount = generalTargetItems.reduce(
      (acc, item) => acc + parseFloat(item.target?.replace(/,/g, "")),
      0
    );
    setBudgetedAmount(totalBudgetedAmount);
  };
  const [cursorPositions, setCursorPositions] = useState({});

  // Function to format number with commas
  const formatNumberWithCommas = (number) => {
    if (!number) return number;
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  const handleCurrencyChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9.]/g, "");

    setForm((prevForm) => ({
      ...prevForm,
      [name]: numericValue,
    }));

    const cursorPos = e.target.selectionStart;
    setCursorPositions((prevPositions) => ({
      ...prevPositions,
      [name]: cursorPos,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatNumberWithCommas(value);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: formattedValue,
    }));
  };

  const handleFocus = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setForm((prevForm) => ({
      ...prevForm,
      [name]: numericValue,
    }));
  };

  const [budgetModal, setBudgetModal] = useState(false);
  const [editedBudget, setEditedBudget] = useState(budgetedAmount);

  const toggleEditBudgetModal = () => {
    setEditedBudget(budgetedAmount);
    setBudgetModal(!budgetModal);
  };

  const handleBudgetChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setEditedBudget(value);
  };

  const saveBudget = () => {
    const updatedBudget = parseFloat(editedBudget.replace(/,/g, ""));
    setBudgetedAmount(updatedBudget);
    setBudgetModal(false);

    const payload = {
      target: updatedBudget,
      id: budgeted.id,
    };

    _postApi(
      "/revenue/targets",
      { query_type: "update", ...payload },
      (res) => {
        console.log("Budget saved:", res.data);
        toast.success("Budget saved successfully");
        getData(form.selectedMonth, form.selectedYear);
      },
      (err) => {
        console.error("Error saving budget:", err);
        toast.error("Error saving budget");
      }
    );
  };

  const setCursorPosition = useCallback(
    (inputName) => {
      const input = document.getElementsByName(inputName)[0];
      if (cursorPositions[inputName] !== undefined && input) {
        input.setSelectionRange(
          cursorPositions[inputName],
          cursorPositions[inputName]
        );
      }
    },
    [cursorPositions]
  );

  useEffect(() => {
    Object.keys(cursorPositions).forEach((inputName) => {
      setCursorPosition(inputName);
    });
  }, [form, setCursorPosition]);

  return (
    <Card>
      <Card.Body>
        <Form>
          {!list.some((item) => item.tax_station === "General") && (
            <Button color="primary" onClick={toggleGenerateModal}>
              Generate Target
            </Button>
          )}
          {loading && <Spinner />}
          {/* Budgeted header */}
          {budgetedAmount > 0 && (
            <div className="text-center">
              {/* {JSON.stringify(budgeted)} */}
              <h3>
                Budgeted for {form.selectedMonth} {form.selectedYear}{" "}
                {formatNumber(budgetedAmount)}
                <Button color="link" onClick={toggleEditBudgetModal}>
                  <FaEdit />
                </Button>
              </h3>
            </div>
          )}

          {/* Month Selection */}
          <FormGroup>
            <Label for="monthSelect">Select Month</Label>
            <Input
              type="select"
              id="monthSelect"
              defaultValue={currentMonth} // Set the current month as the default value
              onChange={(e) => handleMonthChange(e.target.value)}
            >
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Input>
          </FormGroup>

          {/* Form Inputs */}
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="taxStation">Tax Station</Label>
                <CustomTypeahead
                  id="taxStation"
                  options={taxStations.filter(
                    (station) =>
                      !list.some((item) => item.tax_station === station)
                  )}
                  onInputChange={() => {
                    setTaxStation([]);
                    setForm((p) => ({ ...p, tax_station: "" }));
                  }}
                  clearButton
                  selected={taxStation ? [taxStation] : []}
                  onChange={(selected) => {
                    setTaxStation(selected[0]);
                    setForm((p) => ({ ...p, tax_station: selected[0] }));
                  }}
                  col={12}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="target">Revenue Target</Label>
                <Input
                  type="text"
                  id="target"
                  name="target"
                  value={form.target}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleCurrencyChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Col className="text-center">
            {" "}
            <Button color="primary" onClick={addToList}>
              Add to List
            </Button>
          </Col>
        </Form>

        {/* Table */}
        <Table striped responsive>
          <thead>
            <tr>
              <th>Tax Station</th>
              <th>Revenue Target</th>
              <th>%</th>
              <th>Month</th>
              <th>Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((item) => item.tax_station !== "General")
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.tax_station}</td>
                  <td>{formatNumber(item.target)}</td>
                  <td>
                    {formatNumber(
                      (item.target /
                        list.find((item) => item.tax_station === "General")
                          ?.target) *
                        100
                    )}
                  </td>
                  <td>{item.month}</td>
                  <td>{item.year}</td>
                  <td>
                    <Button color="link" onClick={() => handleEdit(index)}>
                      <FaEdit />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Col className="text-center">
          <Button color="success" onClick={handleSubmit}>
            Submit
          </Button>
        </Col>

        {/* Edit Modal */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Edit Item</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="editTaxStation">Tax Station</Label>
                <Input
                  type="text"
                  id="editTaxStation"
                  name="tax_station"
                  value={editItem?.tax_station || ""}
                  onChange={handleEditChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="editTarget">Revenue Target</Label>
                <Input
                  type="text"
                  id="editTarget"
                  name="editTarget"
                  value={editItem?.target || ""}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleCurrencyChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="editPercentage">Percentage</Label>
                <Input
                  type="text"
                  id="editPercentage"
                  name="percentage"
                  value={editItem?.percentage || ""}
                  onChange={handleEditChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="editMonth">Month</Label>
                <Input
                  type="text"
                  id="editMonth"
                  name="month"
                  value={editItem?.month || ""}
                  onChange={handleEditChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="editYear">Year</Label>
                <Input
                  type="text"
                  id="editYear"
                  name="year"
                  value={editItem?.year || ""}
                  onChange={handleEditChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSaveEdit}>
              Save
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* Generate Modal */}
        <Modal isOpen={generateModal} toggle={toggleGenerateModal}>
          <ModalHeader toggle={toggleGenerateModal}>
            Generate Target
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="generalTarget">All stations Revenue Target</Label>
                <Input
                  type="text"
                  id="generalTarget"
                  name="generalTarget"
                  value={form.generalTarget}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleCurrencyChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="monthSelect">Select Target</Label>
                <Input
                  type="month"
                  id="monthSelect"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSaveGenerate}>
              Generate
            </Button>
            <Button color="secondary" onClick={toggleGenerateModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* {budgeted modal} */}
        <Modal isOpen={budgetModal} toggle={() => setBudgetModal(false)}>
          <ModalHeader toggle={() => setBudgetModal(false)}>
            Edit Budget
          </ModalHeader>
          <ModalBody>
            <Input
              type="text"
              value={editedBudget}
              onChange={(e) => setEditedBudget(e.target.value)}
              placeholder="Enter new budget amount"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={saveBudget}>
              Save
            </Button>
            <Button color="secondary" onClick={() => setBudgetModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default TargetSystem;
